import { FileExcelOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, PageHeader } from 'antd';
import moment from 'moment';
import React from 'react';
import { Props } from '.';
import { defaultFormat } from '../../config/constants';
import { translations } from '../../config/translations';
import { downloadFile } from '../../helpers/FileHelper';
import { PeopleScheduleClient } from '../../utils/api';

interface State {
    isExporting: boolean;
}

class PeopleSchedule extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = { isExporting: false };
    }

    private handleSubmit = async (elem: any) => {
        this.setState({ isExporting: true });
        let result = await new PeopleScheduleClient().generateXlsx(elem.date);
        downloadFile(result);
        this.setState({ isExporting: false });
    };

    public render(): React.ReactNode {
        const { isExporting } = this.state;

        return (
            <>
                <PageHeader title={translations.peopleSchedule.title} />
                <Form onFinish={this.handleSubmit}>
                    <Form.Item
                        name="date"
                        label={translations.workHourRecods.form.date}
                        initialValue={moment(new Date())}
                        style={{
                            width: 400,
                            marginTop: 20,
                            marginLeft: 10,
                        }}
                    >
                        <DatePicker
                            placeholder={translations.general.chooseDate}
                            format={defaultFormat}
                        />
                    </Form.Item>

                    <Button
                        style={{
                            marginLeft: 10,
                        }}
                        htmlType="submit"
                    >
                        {isExporting ? <SyncOutlined spin /> : <FileExcelOutlined />}
                        {translations.general.export}
                    </Button>
                </Form>
            </>
        );
    }
}

export default PeopleSchedule;
