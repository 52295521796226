export const capitalizeFirstLetter = (stringToCapitalize?: string): string | undefined => {
    return stringToCapitalize
        ? stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1)
        : undefined;
};

export const truncateString = (str: string, num: number) => {
    if (str.length > num) {
        return str.slice(0, num) + '...';
    }

    return str;
};
