import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../../core/models/RouteParams';
import { ProjectDetailVm } from '../../../../utils/api';
import ProjectDetails from './ProjectDetails';

interface OwnProps {
    userProfile: Profile;
    project: ProjectDetailVm;
    onUpdate: () => void;
    onDelete: () => Promise<void>;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(ProjectDetails);
