const replaceStrings = {
    USER_ID: ':userId',
    PROJECT_ID: ':projectId',
    EMPLOYEE_ID: ':employeeId',
    COOPERATOR_ID: ':cooperatorId',
    ENTITY_ID: ':entityId',
    MODE: ':mode',
};

export default replaceStrings;
