import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Drawer,
    PageHeader,
    Select,
    Space,
    DatePicker,
    Switch,
    Dropdown,
    MenuProps,
    Tabs,
} from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import TabPane from 'antd/lib/tabs/TabPane';
import moment from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { DRAWER_WIDTH, DRAWER_WIDTH_MEDIUM } from '../../config/constants';
import { defaultFormat, universalFormat } from '../../config/constants';
import Routes from '../../config/routes';
import { translations, documents } from '../../config/translations';
import { DocumentTypeTranslations } from '../../core/models/Translations';
import { ActionType, DrawerState, ModuleName } from '../../core/models/enum';
import { authorizeAction } from '../../helpers/CheckPermissionHelper';
import {
    getDocumentTypeTranslations,
    getPossibleChildrenTypes,
} from '../../helpers/DocumentHelper';
import { buildRoute } from '../../helpers/RoutingHelper';
import {
    DocumentItemsClient,
    DocumentItemVm,
    DocumentsClient,
    DocumentTypeEnum,
    DocumentStatusEnum,
    DocumentVm,
    ProjectsClient,
    ProjectVm,
} from '../../utils/api';
import DocumentForm from './document-form';
import DocumentItemTable from './document-item-table';
import DocumentTable from './document-table';
import { Props } from './index';
import { applySearchParams, getFilterParamsString } from '../../helpers/SearchParamsHelper';

export interface DocumentFilter {
    projectId: number;
    dateFrom: Date;
    dateTo: Date;
}

export interface DocumentParams {
    dateFrom: Date;
    dateTo: Date;
    showItems: boolean;
    projectId: number;
    activeTab: string;
}

interface State {
    documents: DocumentVm[];
    documentItems: DocumentItemVm[];
    selectedItems?: DocumentItemVm[];
    newTypeId?: DocumentTypeEnum;
    currentTypeId?: DocumentTypeEnum;
    projects: ProjectVm[];
    loading?: boolean;
    allowCreate: boolean;
    drawerState: DrawerState;
    docTypeTranslations: DocumentTypeTranslations;
}

const { RangePicker } = DatePicker;

const DEFAULT_FILTER: DocumentFilter = {
    projectId: 0,
    dateFrom: moment().startOf('year').toDate(),
    dateTo: moment().endOf('year').toDate(),
};

class Documents extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        const {
            userProfile,
            location: { pathname },
        } = this.props;

        this.state = {
            documents: [],
            documentItems: [],
            projects: [],
            allowCreate: authorizeAction(userProfile, ModuleName.Documents, ActionType.Create),
            drawerState:
                pathname === Routes.ROUTE_DOCUMENTS_NEW ? DrawerState.Create : DrawerState.Closed,
            docTypeTranslations: documents.documents,
        };
    }

    public componentDidMount() {
        this.initFilter();
        this.initDrawer();
        this.getProjects();
    }

    public componentDidUpdate(prevProps: Props) {
        const {
            location: { pathname, search },
        } = this.props;

        if (prevProps.location.search !== search) {
            this.initFilter();
        }

        if (prevProps.location.pathname !== pathname) {
            this.initDrawer();
        }
    }

    private handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<DocumentVm> | SorterResult<DocumentVm>[] = {}
    ) => {
        const {
            history,
            location: { search },
        } = this.props;

        const currSearchParams = new URLSearchParams(search);
        const tableSearchParams = this.createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    private createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<DocumentVm> | SorterResult<DocumentVm>[] = {}
    ): URLSearchParams => {
        const { currentTypeId } = this.state;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const filterParamsString = getFilterParamsString(filters);
        const singleSorter = sorter as SorterResult<DocumentVm>;
        const tableSearchParams = new URLSearchParams(filterParamsString);
        const { showItems, projectId, dateFrom, dateTo } =
            this.formatParamsFromUrl();
        const activeTab = params.get('activeTab') ? params.get('activeTab') : 'active';

        tableSearchParams.append('typeId', currentTypeId?.toString() || '');
        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );
        tableSearchParams.append('sortBy', singleSorter.field?.toString() && singleSorter.order ? singleSorter.field?.toString() : '');
        tableSearchParams.append('sortOrder', singleSorter.order || '');
        tableSearchParams.append(
            'projectId',
            projectId?.toString() || DEFAULT_FILTER.projectId.toString()
        );
        tableSearchParams.append('showItems', showItems.toString());
        tableSearchParams.append('activeTab', activeTab ? activeTab : 'active');
        tableSearchParams.append('dateFrom', moment(dateFrom).format(universalFormat));
        tableSearchParams.append('dateTo', moment(dateTo).format(universalFormat));

        return tableSearchParams;
    };

    private formatParamsFromUrl = (): DocumentParams => {
        const {
            location: { search },
        } = this.props;

        const params = new URLSearchParams(search);
        const showItems = params.get('showItems') ? params.get('showItems') === 'true' : false;
        const projectId = params.get('projectId')
            ? params.get('projectId')
            : DEFAULT_FILTER.projectId;
        const dateFrom = params.get('dateFrom');
        const dateTo = params.get('dateTo');
        const activeTab = params.get('activeTab') ? params.get('activeTab') : 'active';

        const documentParams: DocumentParams = {
            dateFrom: dateFrom ? moment(dateFrom).toDate() : DEFAULT_FILTER.dateFrom,
            dateTo: dateTo ? moment(dateTo).toDate() : DEFAULT_FILTER.dateTo,
            showItems: showItems,
            projectId: projectId ? Number(projectId) : DEFAULT_FILTER.projectId,
            activeTab: activeTab ? activeTab : 'active'
        };
        return documentParams;
    };

    public initFilter = () => {
        const {
            location: { search },
        } = this.props;
        const params = new URLSearchParams(search);

        const typeId = parseInt(params.get('typeId') || '');

        this.setState(
            {
                currentTypeId: typeId in DocumentTypeEnum ? typeId : undefined,
                docTypeTranslations: getDocumentTypeTranslations(typeId),
            },
            () => {
                this.getDocuments();
                this.getDocumentItems();
            }
        );
    };

    private initDrawer = () => {
        const {
            location: { pathname },
        } = this.props;

        this.setState({
            drawerState:
                pathname === Routes.ROUTE_DOCUMENTS_NEW ? DrawerState.Create : DrawerState.Closed,
        });
    };

    public getDocuments = async () => {
        this.setState({
            loading: true,
        });
        const { currentTypeId } = this.state;
        const { projectId, dateFrom, dateTo, activeTab } = this.formatParamsFromUrl();

        const documents = await new DocumentsClient().getAll(
            currentTypeId,
            dateFrom,
            dateTo,
            projectId ? projectId : null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            activeTab === 'inactive'
                ? [DocumentStatusEnum.Completed]
                : [
                      DocumentStatusEnum.Draft,
                      DocumentStatusEnum.Processing,
                      DocumentStatusEnum.Processed,
                  ]
        );

        this.setState({
            documents: documents,
            loading: false,
        });
    };

    public getDocumentItems = async () => {
        const { currentTypeId } = this.state;
        this.setState({
            loading: true,
        });
        const { projectId, dateFrom, dateTo, activeTab } = this.formatParamsFromUrl();

        const documentItems = await new DocumentItemsClient().getAll(
            currentTypeId,
            dateFrom,
            dateTo,
            projectId ? projectId : null,
            null,
            null,
            activeTab === 'inactive'
                ? [DocumentStatusEnum.Completed]
                : [DocumentStatusEnum.Draft, DocumentStatusEnum.Processing]
        );

        this.setState({
            documentItems: documentItems,
            loading: false,
        });
    };

    private getProjects = async () => {
        this.setState({
            projects: await new ProjectsClient().getAll(),
        });
    };

    private handleProjectChange = async (projectId?: number) => {
        const { history, location } = this.props;
        const params = new URLSearchParams(location.search);

        if (projectId !== undefined && projectId !== null) {
            if (params.has('projectId')) {
                params.set('projectId', projectId.toString());
            } else {
                params.append('projectId', projectId.toString());
            }
        }

        history.push({
            pathname: history.location.pathname,
            search: params.toString(),
        });
    };

    private handleDateRangeChange = async (range: RangeValue<moment.Moment>) => {
        const { history, location } = this.props;
        const params = new URLSearchParams(location.search);

        if (range?.[0]) {
            if (params.has('dateFrom')) {
                params.set('dateFrom', range?.[0]?.format(universalFormat));
            } else {
                params.append('dateFrom', range?.[0]?.format(universalFormat));
            }
        }

        if (range?.[1]) {
            if (params.has('dateTo')) {
                params.set('dateTo', range?.[1]?.format(universalFormat));
            } else {
                params.append('dateTo', range?.[1]?.format(universalFormat));
            }
        }

        history.push({
            pathname: history.location.pathname,
            search: params.toString(),
        });
    };

    private handleShowItemsToggle = () => {
        const { history, location } = this.props;
        const params = new URLSearchParams(location.search);
        const { showItems } = this.formatParamsFromUrl();

        if (params.has('showItems')) {
            params.set('showItems', (!showItems).toString());
        } else {
            params.append('showItems', (!showItems).toString());
        }

        history.push({
            pathname: history.location.pathname,
            search: params.toString(),
        });

        this.setState((prevState: State) => ({
            selectedItems: undefined,
        }));
    };

    private handleShowInactiveViewToggle = async (key: string) => {
        const { history, location } = this.props;
        const params = new URLSearchParams(location.search);

        if (params.has('activeTab')) {
            params.set('activeTab', key);
        } else {
            params.append('activeTab', key);
        }

        history.push({
            pathname: history.location.pathname,
            search: params.toString(),
        });
    };

    private handleDocumentSaved = (document: DocumentVm) => {
        const { history } = this.props;

        history.push({
            pathname: buildRoute(
                Routes.ROUTE_DOCUMENTS,
                Routes.ROUTE_DOCUMENTS_NEW,
                Routes.ROUTE_DOCUMENTS_READ,
                Routes.ROUTE_DOCUMENTS_EDIT,
                DrawerState.Closed,
                document.id.toString()
            ),
            search: `?typeId=${document.documentTypeId}`,
        });
    };

    private handleItemSelectionChange = (items: DocumentItemVm[]) => {
        this.setState({
            selectedItems: items,
        });
    };

    private handleNewDocument = () => {
        const {
            history,
            location: { search },
        } = this.props;
        const { currentTypeId } = this.state;

        this.setState({ selectedItems: undefined, newTypeId: currentTypeId });

        history.push({
            pathname: buildRoute(
                Routes.ROUTE_DOCUMENTS,
                Routes.ROUTE_DOCUMENTS_NEW,
                Routes.ROUTE_DOCUMENTS_READ,
                Routes.ROUTE_DOCUMENTS_EDIT,
                DrawerState.Create
            ),
            search,
        });
    };

    private handleGenerateDocument: MenuProps['onClick'] = ({ key }) => {
        const {
            history,
            location: { search },
        } = this.props;
        const { selectedItems } = this.state;

        this.setState({
            selectedItems: selectedItems,
            newTypeId: parseInt(key, 10) as DocumentTypeEnum,
        });

        history.push({
            pathname: buildRoute(
                Routes.ROUTE_DOCUMENTS,
                Routes.ROUTE_DOCUMENTS_NEW,
                Routes.ROUTE_DOCUMENTS_READ,
                Routes.ROUTE_DOCUMENTS_EDIT,
                DrawerState.Create
            ),
            search,
        });
    };

    private handleCloseDrawer = () => {
        const {
            history,
            location: { search },
        } = this.props;

        history.push({
            pathname: Routes.ROUTE_DOCUMENTS,
            search,
        });
    };

    public render() {
        const {
            currentTypeId,
            documents,
            documentItems,
            selectedItems,
            newTypeId,
            projects,
            allowCreate,
            drawerState,
            loading,
            docTypeTranslations,
        } = this.state;
        const { showItems, dateFrom, dateTo, projectId, activeTab } =
            this.formatParamsFromUrl();

        if (!currentTypeId) return null;

        var childrenTypes = getPossibleChildrenTypes(currentTypeId);

        return (
            <>
                <PageHeader
                    title={docTypeTranslations.title}
                    extra={
                        allowCreate
                            ? [
                                  childrenTypes.length ? (
                                      <Dropdown
                                          trigger={['click']}
                                          key="generate"
                                          disabled={!showItems || !selectedItems?.length}
                                          menu={{
                                              items: childrenTypes.map(
                                                  (typeId: DocumentTypeEnum) => ({
                                                      label: getDocumentTypeTranslations(typeId)
                                                          .generate,
                                                      key: typeId,
                                                      icon: <PlusOutlined />,
                                                  })
                                              ),
                                              onClick: this.handleGenerateDocument,
                                          }}
                                      >
                                          <Button type="primary" icon={<PlusOutlined />}>
                                              {translations.general.generate} <DownOutlined />
                                          </Button>
                                      </Dropdown>
                                  ) : (
                                      []
                                  ),
                                  <Button
                                      key="new"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                      }}
                                      onClick={() => this.handleNewDocument()}
                                      className="no-print"
                                  >
                                      <PlusOutlined />
                                      {docTypeTranslations.add}
                                  </Button>,
                              ]
                            : []
                    }
                >
                    <Space>
                        <Tabs
                            type="editable-card"
                            activeKey={activeTab}
                            onTabClick={(key: string) => this.handleShowInactiveViewToggle(key)}
                            hideAdd
                            destroyInactiveTabPane={true}
                            tabBarStyle={{ marginBottom: 0, marginRight: 16 }}
                        >
                            <TabPane tab={'Aktivno'} key={'active'} closable={false} />
                            <TabPane tab={'Završeno'} key={'inactive'} closable={false} />
                        </Tabs>

                        <Select<number>
                            value={Number(projectId)}
                            onChange={(value?: number) => this.handleProjectChange(value)}
                            style={{ width: 300 }}
                            showSearch
                            optionFilterProp="children"
                        >
                            <Select.Option key={0} value={0}>
                                {translations.projects.all}
                            </Select.Option>
                            {projects.map((p: ProjectVm) => (
                                <Select.Option key={p.id} value={p.id}>
                                    {p.name}
                                </Select.Option>
                            ))}
                        </Select>

                        <RangePicker
                            value={[moment(dateFrom), moment(dateTo)]}
                            onChange={(value: RangeValue<moment.Moment>) =>
                                this.handleDateRangeChange(value)
                            }
                            format={defaultFormat}
                            allowClear={false}
                            placeholder={[
                                translations.general.dateFrom,
                                translations.general.dateTo,
                            ]}
                        />
                        <Switch
                            checked={showItems}
                            onChange={this.handleShowItemsToggle}
                            checkedChildren={translations.documents.items.view}
                            unCheckedChildren={translations.documents.items.view}
                        />
                    </Space>
                </PageHeader>

                {showItems ? (
                    <DocumentItemTable
                        documentTypeId={currentTypeId}
                        documentItems={documentItems}
                        onSelectionChange={this.handleItemSelectionChange}
                        selectedItemsKeys={selectedItems?.map((value) => value.id.toString())}
                    />
                ) : (
                    <DocumentTable
                        documents={documents}
                        typeId={currentTypeId}
                        loading={loading}
                        onHandleTableChange={this.handleTableChange}
                    />
                )}

                {newTypeId && (
                    <Drawer
                        title={
                            currentTypeId === newTypeId
                                ? docTypeTranslations.add
                                : getDocumentTypeTranslations(newTypeId).generate
                        }
                        open={!!drawerState}
                        onClose={() => this.handleCloseDrawer()}
                        width={selectedItems?.length ? DRAWER_WIDTH_MEDIUM : DRAWER_WIDTH}
                        destroyOnClose
                    >
                        <DocumentForm
                            typeId={newTypeId}
                            documentItems={selectedItems}
                            onClose={() => this.handleCloseDrawer()}
                            onSuccess={this.handleDocumentSaved}
                        />
                    </Drawer>
                )}
            </>
        );
    }
}

export default Documents;
