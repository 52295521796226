import { EmployeeDetailVm } from '../../../../utils/api';
import EmployeeDetails from './EmployeeDetails';

interface OwnProps {
    employee: EmployeeDetailVm;
}

export type Props = OwnProps;

export default EmployeeDetails;
