import { CheckOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { Props } from '.';
import { defaultFormat, defaultTablePagination } from '../../../../config/constants';
import { translations } from '../../../../config/translations';
import { formatDate } from '../../../../helpers/DateHelper';
import { downloadFile } from '../../../../helpers/FileHelper';
import {
    getDateFilter,
    getDatePickerFilter,
    getDefaultFilter,
    getKeyFilter,
    getRadioFilter,
    getSearchFilter,
} from '../../../../helpers/FilterHelper';
import {
    getDateComparer,
    getDefaultComparer,
    getNumberComparer,
} from '../../../../helpers/SortHelper';
import { getTableLocale } from '../../../../helpers/TableHelper';
import { AttachmentsClient, EmployeeGearRecordVm, EmployeesClient } from '../../../../utils/api';

interface State {
    records?: EmployeeGearRecordVm[];
    loading: boolean;
}

class EmployeeGearTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    public componentDidMount(): void {
        this.getRecords();
    }

    private getRecords = async () => {
        const { employee } = this.props;

        this.setState({
            records: await new EmployeesClient().getEmployeeGearRecords(employee.id),
            loading: false,
        });
    };

    private calcSummary = (data: readonly EmployeeGearRecordVm[]) => {
        let totalGear: any = {
            shoes: 0,
            shoesPRO: 0,
            pants: 0,
            overalls: 0,
            workJacket: 0,
            winterJacket: 0,
            fleeseJacket: 0,
            helmet: 0,
            vest: 0,
            protectionGloves: 0,
            goggles: 0,
            hydrodemolitionSuit: 0,
        };

        data.forEach((value: any) => {
            Object.keys(totalGear).forEach((key: string) => {
                totalGear[key] += value[key] ?? 0;
            });
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell align="center" index={0} colSpan={2}>
                    {translations.general.total}
                </Table.Summary.Cell>
                {Object.keys(totalGear).map((value, index) => {
                    return (
                        <Table.Summary.Cell key={index} align="center" index={index + 2}>
                            {totalGear[value]}
                        </Table.Summary.Cell>
                    );
                })}
                <Table.Summary.Cell index={13} />
            </Table.Summary.Row>
        );
    };

    private downloadAttachment = async (id: number) => {
        const client = new AttachmentsClient();
        const result = await client.downloadFile(id);

        downloadFile(result);
    };

    public render(): React.ReactNode {
        const { records, loading } = this.state;

        const checkboxFilterOptions = [
            { id: '1', name: translations.gear.gearSupplied },
            { id: '0', name: translations.gear.gearNotSupplied },
        ];

        const columns: ColumnsType<EmployeeGearRecordVm> = [
            {
                title: translations.projects.project,
                key: 'project',
                dataIndex: 'project',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('project'),
                sorter: getDefaultComparer('project'),
            },
            {
                title: translations.general.date,
                key: 'date',
                dataIndex: 'date',
                ...getDatePickerFilter(),
                onFilter: getDateFilter('date'),
                sorter: getDateComparer('date'),
                render: (value: Date) => formatDate(value, defaultFormat),
            },
            {
                title: translations.gear.shoes,
                key: 'shoes',
                dataIndex: 'shoes',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('shoes'),
                sorter: getNumberComparer('shoes'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.gear.shoesPro,
                key: 'shoesPRO',
                dataIndex: 'shoesPRO',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('shoesPRO'),
                sorter: getNumberComparer('shoesPRO'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.gear.pants,
                key: 'pants',
                dataIndex: 'pants',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('pants'),
                sorter: getNumberComparer('pants'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.gear.overalls,
                key: 'overalls',
                dataIndex: 'overalls',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('overalls'),
                sorter: getNumberComparer('overalls'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.gear.workJacket,
                key: 'workJacket',
                dataIndex: 'workJacket',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('workJacket'),
                sorter: getNumberComparer('workJacket'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.gear.winterJacket,
                key: 'winterJacket',
                dataIndex: 'winterJacket',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('winterJacket'),
                sorter: getNumberComparer('winterJacket'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.gear.fleeseJacket,
                key: 'fleeseJacket',
                dataIndex: 'fleeseJacket',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('fleeseJacket'),
                sorter: getNumberComparer('fleeseJacket'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.gear.helmet,
                key: 'helmet',
                dataIndex: 'helmet',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('helmet'),
                sorter: getNumberComparer('helmet'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.gear.vest,
                key: 'vest',
                dataIndex: 'vest',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('vest'),
                sorter: getNumberComparer('vest'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.gear.protectionGloves,
                key: 'protectionGloves',
                dataIndex: 'protectionGloves',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('protectionGloves'),
                sorter: getNumberComparer('protectionGloves'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.gear.goggles,
                key: 'goggles',
                dataIndex: 'goggles',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('goggles'),
                sorter: getNumberComparer('goggles'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.gear.hydrodemolitionSuit,
                key: 'hydrodemolitionSuit',
                dataIndex: 'hydrodemolitionSuit',
                align: 'center',
                ...getRadioFilter(checkboxFilterOptions),
                onFilter: getKeyFilter('hydrodemolitionSuit'),
                sorter: getNumberComparer('hydrodemolitionSuit'),
                render: (value: number) => (value === 1 ? <CheckOutlined /> : ''),
            },
            {
                title: translations.attachments.attachment,
                dataIndex: 'attachmentName',
                render: (value: string, item: EmployeeGearRecordVm): React.ReactElement => {
                    return (
                        <Button
                            type="link"
                            onClick={async () => {
                                if (item.attachmentId) {
                                    await this.downloadAttachment(item.attachmentId);
                                }
                            }}
                        >
                            {value}
                        </Button>
                    );
                },
            },
        ];

        return (
            <Table
                locale={getTableLocale()}
                columns={columns}
                loading={loading}
                dataSource={records}
                pagination={defaultTablePagination}
                rowKey={(record: EmployeeGearRecordVm): string => record.id?.toString()}
                bordered
                size="small"
                summary={this.calcSummary}
            />
        );
    }
}

export default EmployeeGearTable;
