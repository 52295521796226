import { EmployeeVm } from '../../../../utils/api';
import EmployeeGearTable from './EmployeeGearTable';

interface OwnProps {
    employee: EmployeeVm;
}

export type Props = OwnProps;

export default EmployeeGearTable;
