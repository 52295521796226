import { Select, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React from 'react';
import { getTableLocale } from '../../helpers/TableHelper';
import { ColumnContentValue, Props } from './index';

interface State {
    values: ColumnContentValue;
}

class ColumnContentPicker extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            values: {} as ColumnContentValue,
        };
    }

    public componentDidMount = () => {
        this.init();
    };

    public componentDidUpdate = (prevProps: Props) => {
        const { columns } = this.props;

        if (JSON.stringify(prevProps.columns) !== JSON.stringify(columns)) {
            this.init();
        }
    };

    private init = () => {
        const { onChange, initialValue } = this.props;

        this.setState({
            values: initialValue,
        });

        if (onChange) {
            onChange(initialValue);
        }
    };

    private handleChange(key: React.Key | undefined, value: string) {
        const { values } = this.state;
        const { onChange } = this.props;

        if (!key) return;

        const newValues = { ...values };
        newValues[key] = value;

        this.setState({
            values: newValues,
        });

        if (onChange) {
            onChange(newValues);
        }
    }

    public render(): React.ReactElement {
        const { columns, options, disabled, selectWidth, disabledColumns } = this.props;
        const { values } = this.state;

        const newColumns = columns.map(
            (col: ColumnType<ColumnContentValue>): ColumnType<ColumnContentValue> => {
                return {
                    ...col,
                    key: values.key,
                    render: (): React.ReactElement => (
                        <Select
                            disabled={disabled || disabledColumns?.includes(col.key as string)}
                            value={values[col.key as string]}
                            style={{ width: selectWidth }}
                            onSelect={(value: string) => this.handleChange(col.key, value)}
                            allowClear={true}
                            onClear={() => this.handleChange(col.key, '')}
                        >
                            {options.map((value) => (
                                <Select.Option key={value.id} value={value.id}>
                                    {value.name}
                                </Select.Option>
                            ))}
                        </Select>
                    ),
                };
            }
        );

        return (
            <Table
                locale={getTableLocale()}
                scroll={{ x: 'max-content' }}
                columns={newColumns}
                dataSource={[values]}
                bordered
                size="small"
                pagination={false}
            />
        );
    }
}

export default ColumnContentPicker;
