export type KeyValueOrder = { key: string; value: string; order?: number };

export const ExcelProjectItemsFields = [
    { key: 'Level', value: 'Razina', order: undefined },
    { key: 'Ordinal', value: 'Redni broj', order: 0 },
    { key: 'Name', value: 'Opis/Naziv', order: 1 },
    { key: 'UnitOfMeasurement', value: 'Jedinica mjere', order: 2 },
    { key: 'Quantity', value: 'Količina', order: 3 },
    { key: 'UnitPrice', value: 'Jedinična cijena', order: 4 },
    { key: 'TotalPrice', value: 'Ukupna cijena', order: 5 },
] as KeyValueOrder[];
