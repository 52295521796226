import { FormProps } from 'antd/lib/form';
import { DocumentItemVm, DocumentVm } from '../../../utils/api';
import DocumentItemForm from './DocumentItemForm';

interface OwnProps {
    document: DocumentVm;
    item: DocumentItemVm;
    onClose: () => void;
    onSuccess: (item: DocumentItemVm) => void;
}

export type Props = OwnProps & FormProps;

export default DocumentItemForm;
