import { ColumnsType } from 'antd/lib/table';
import { DropdownOption } from '../../core/models/DropdownOption';
import ColumnContentPicker from './ColumnContentPicker';

export interface ColumnContentValue {
    [key: string]: string;
    key: string;
}
interface OwnProps {
    columns: ColumnsType<ColumnContentValue>;
    options: DropdownOption[];
    disabled?: boolean;
    disabledColumns?: string[];
    onChange?: (e: any) => void;
    initialValue: ColumnContentValue;
    selectWidth: number;
}

export type Props = OwnProps;

export default ColumnContentPicker;
