import { PrinterOutlined } from '@ant-design/icons';
import { Button, PageHeader } from 'antd';
import React from 'react';
import { translations } from '../../config/translations';
import { Props } from './index';
import WarehouseTable from './warehouse-table';

interface State {
}

class Warehouses extends React.Component<Props, State> {
    private tableRef: any = React.createRef();

    public constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    public render(): React.ReactElement {
        return (
            <>
                <PageHeader
                    title={translations.warehouses.warehouses}
                    extra={[
                        <Button
                            key="2"
                            style={{
                                zIndex: 10,
                                float: 'right',
                            }}
                            onClick={() => window.print()}
                            className="no-print margin-right-10"
                        >
                            <PrinterOutlined />
                            {translations.general.print}
                        </Button>,
                    ]}
                />

                <WarehouseTable wrappedComponentRef={this.tableRef} />
            </>
        );
    }
}

export default Warehouses;
