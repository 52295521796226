import { gold } from '@ant-design/colors';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import Table, { ColumnsType, ColumnType } from 'antd/lib/table';
import React from 'react';
import { Link } from 'react-router-dom';
import { defaultTablePagination } from '../../../config/constants';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import {
    getDocumentItemStatusBadge,
    isOutgoingDocumentType,
} from '../../../helpers/DocumentHelper';
import {
    getCheckboxFilter,
    getDefaultFilter,
    getKeyFilter,
    getNumberFilter,
    getSearchFilter,
} from '../../../helpers/FilterHelper';
import { showConfirm } from '../../../helpers/NotificationHelper';
import { formatPrice } from '../../../helpers/PriceHelper';
import { getDefaultComparer, getNumberComparer } from '../../../helpers/SortHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import {
    CodebooksClient,
    DocumentItemStatusEnum,
    DocumentItemVm,
    DocumentTypeEnum,
    SelectOptionVm,
    WarehouseItemVm,
} from '../../../utils/api';
import { Props } from './index';

interface State {
    statuses: SelectOptionVm[];
}

export default class DocumentTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            statuses: [],
        };
    }

    public componentDidMount = () => {
        this.getStatuses();
    };

    private getStatuses = async () => {
        this.setState({
            statuses: await new CodebooksClient().getByCodebookName('DocumentItemStatus'),
        });
    };

    private getWarehouseItemTypeColumns = (): ColumnsType<WarehouseItemVm> => {
        const name: ColumnType<WarehouseItemVm> = {
            title: translations.warehouseItems.item,
            dataIndex: 'name',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('name'),
            sorter: getDefaultComparer('name'),
        };
        const project: ColumnType<WarehouseItemVm> = {
            title: translations.projects.project,
            key: 'projectName',
            sorter: getDefaultComparer('projectName'),
            render: (item: WarehouseItemVm) => item.projectName,
        };
        const sourceDocument: ColumnType<WarehouseItemVm> = {
            title: translations.warehouseItems.sourceDocument,
            dataIndex: 'sourceDocumentItemDocumentCode',
            render: (_: string | undefined, record: WarehouseItemVm) =>
                record.sourceDocumentItemDocumentId ? (
                    <Link
                        to={{
                            pathname: Routes.ROUTE_DOCUMENTS_READ.replace(
                                ReplaceStrings.ENTITY_ID,
                                record.sourceDocumentItemDocumentId.toString()
                            ),
                            search: `?typeId=${record.sourceDocumentItemDocumentTypeId}`,
                        }}
                    >
                        {record.sourceDocumentItemDocumentCode}
                    </Link>
                ) : null,
        };
        const supplier: ColumnType<WarehouseItemVm> = {
            title: translations.documents.supplier,
            dataIndex: 'supplier',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('supplier'),
            sorter: getDefaultComparer('supplier'),
        };
        const unit: ColumnType<WarehouseItemVm> = {
            title: translations.warehouseItems.unit,
            key: 'unitOfMeasurement.shortName',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('unitOfMeasurement.shortName'),
            sorter: getDefaultComparer('unitOfMeasurement.shortName'),
            render: (item: WarehouseItemVm) => item.unitOfMeasurement?.shortName,
        };
        const quantity: ColumnType<WarehouseItemVm> = {
            title: translations.warehouseItems.quantity,
            dataIndex: 'quantity',
            align: 'right',
            ...getSearchFilter(),
            onFilter: getNumberFilter('quantity'),
            sorter: getNumberComparer('quantity'),
            render: (value: number) => {
                return value ? formatPrice(value) : '0,00';
            },
        };
        const unitPrice: ColumnType<WarehouseItemVm> = {
            title: translations.warehouseItems.unitPrice,
            dataIndex: 'unitPrice',
            align: 'right',
            ...getSearchFilter(),
            onFilter: getNumberFilter('unitPrice'),
            sorter: getNumberComparer('unitPrice'),
            render: (value: number | null) => {
                return value ? formatPrice(value) : value === null ? null : '0,00';
            },
        };
        const totalPrice: ColumnType<WarehouseItemVm> = {
            title: translations.warehouseItems.totalPrice,
            dataIndex: 'totalPrice',
            align: 'right',
            ...getSearchFilter(),
            onFilter: getNumberFilter('totalPrice'),
            sorter: getNumberComparer('totalPrice'),
            render: (value: number | null) => {
                return value ? formatPrice(value) : value === null ? null : '0,00';
            },
        };

        return [name, project, sourceDocument, supplier, unit, quantity, unitPrice, totalPrice];
    };

    private getDocumentItemTypeColumns = (): ColumnsType<DocumentItemVm> => {
        const { documentTypeId, isSummary } = this.props;
        const { statuses } = this.state;

        const warehouseItemRequired = isOutgoingDocumentType(documentTypeId);

        // Generic columns start
        const name: ColumnType<DocumentItemVm> = {
            title: translations.documents.items.name,
            dataIndex: 'name',           
            ...getSearchFilter(),
            onFilter: getDefaultFilter('name'),
            sorter: getDefaultComparer('name'),
            onCell: () => ({
                style: {
                    whiteSpace: 'nowrap',
                    maxWidth: 200,
                },
            }),
            render: (value?: string) => (
                <Tooltip title={value}>
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{value}</div>
                </Tooltip>
            ),
        };
        const project: ColumnType<DocumentItemVm> = {
            title: translations.documents.project,
            dataIndex: 'project',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('project'),
            sorter: getDefaultComparer('project'),
            onCell: () => ({
                style: {
                    whiteSpace: 'nowrap',
                    maxWidth: 150,
                },
            }),
            render: (value?: string) => (
                <Tooltip title={value}>
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{value}</div>
                </Tooltip>
            ),
        };
        const unit: ColumnType<DocumentItemVm> = {
            title: translations.documents.items.unit,
            key: 'unitOfMeasurement.shortName',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('unitOfMeasurement.shortName'),
            sorter: getDefaultComparer('unitOfMeasurement.shortName'),
            render: (item: DocumentItemVm) => item.unitOfMeasurement?.shortName,
        };
        const quantity: ColumnType<DocumentItemVm> = {
            title: translations.documents.items.quantity,
            dataIndex: 'quantity',
            align: 'right',
            ...getSearchFilter(),
            onFilter: getNumberFilter('quantity'),
            sorter: getNumberComparer('quantity'),
            render: (value: number) => {
                return value ? formatPrice(value) : '0,00';
            },
        };
        const unitPrice: ColumnType<DocumentItemVm> = {
            title: translations.documents.items.unitPrice,
            dataIndex: 'unitPrice',
            align: 'right',
            ...getSearchFilter(),
            onFilter: getNumberFilter('unitPrice'),
            sorter: getNumberComparer('unitPrice'),
            render: (value: number | null) => {
                return value ? formatPrice(value) : value === null ? null : '0,00';
            },
        };
        const totalPrice: ColumnType<DocumentItemVm> = {
            title: translations.documents.items.totalPrice,
            dataIndex: 'totalPrice',
            align: 'right',
            ...getSearchFilter(),
            onFilter: getNumberFilter('totalPrice'),
            sorter: getNumberComparer('totalPrice'),
            render: (value: number | null) => {
                return value ? formatPrice(value) : value === null ? null : '0,00';
            },
        };
        const comment: ColumnType<DocumentItemVm> = {
            title: translations.documents.items.comment,
            dataIndex: 'comment',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('comment'),
            sorter: getDefaultComparer('comment'),
            onCell: () => ({
                style: {
                    whiteSpace: 'nowrap',
                    maxWidth: 150,
                },
            }),
            render: (value?: string) => (
                <Tooltip title={value}>
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{value}</div>
                </Tooltip>
            ),
        };

        // Generic columns end

        // Type specific columns start

        const destinationProject: ColumnType<DocumentItemVm> = {
            title: translations.documents.destinationProject,
            dataIndex: 'destinationProject',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('destinationProject'),
            sorter: getDefaultComparer('destinationProject'),
            render: (_: string | undefined, record: DocumentItemVm) =>
                !isSummary && !record.destinationProjectId ? (
                    <Tooltip title={translations.documents.items.chooseDestinationWarehouse}>
                        <ExclamationCircleOutlined style={{ color: gold.primary }} />
                    </Tooltip>
                ) : (
                    <>{record.destinationProject}</>
                ),
        };
        const supplier: ColumnType<DocumentItemVm> = {
            title: translations.documents.supplier,
            dataIndex: 'supplier',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('supplier'),
            sorter: getDefaultComparer('supplier'),
            onCell: () => ({
                style: {
                    whiteSpace: 'nowrap',
                    maxWidth: 150,
                },
            }),
            render: (value?: string) => (
                <Tooltip title={value}>
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{value}</div>
                </Tooltip>
            ),
        };
        const parentItemDocumentCode: ColumnType<DocumentItemVm> = {
            title: translations.documents.parentDocument,
            dataIndex: 'parentItemDocumentCode',
            width: 150,
            ...getSearchFilter(),
            onFilter: getDefaultFilter('parentItemDocumentCode'),
            sorter: getDefaultComparer('parentItemDocumentCode'),
            render: (_: string | undefined, record: DocumentItemVm) =>
                record.parentItemDocumentId ? (
                    <Link
                        to={{
                            pathname: Routes.ROUTE_DOCUMENTS_READ.replace(
                                ReplaceStrings.ENTITY_ID,
                                record.parentItemDocumentId.toString()
                            ),
                            search: `?typeId=${record.parentItemDocumentTypeId}`,
                        }}
                    >
                        {record.parentItemDocumentCode}
                    </Link>
                ) : null,
        };
        const warehouseItem: ColumnType<DocumentItemVm> = {
            title: translations.documents.items.warehouseItem,
            dataIndex: 'warehouseItem',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('warehouseItem'),
            sorter: getDefaultComparer('warehouseItem'),
            render: (_: string | undefined, record: DocumentItemVm) =>
                !isSummary && warehouseItemRequired && !record.warehouseItemId ? (
                    <Tooltip title={translations.documents.items.chooseWarehouseItem}>
                        <ExclamationCircleOutlined style={{ color: gold.primary }} />
                    </Tooltip>
                ) : (
                    <>{record.warehouseItem}</>
                ),
        };
        const projectItem: ColumnType<DocumentItemVm> = {
            title: translations.documents.items.projectItem,
            dataIndex: 'projectItem',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('projectItem'),
            sorter: getDefaultComparer('projectItem'),
            onCell: () => ({
                style: {
                    whiteSpace: 'nowrap',
                    maxWidth: 150,
                },
            }),
            render: (value?: string) => (
                <Tooltip title={value}>
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{value}</div>
                </Tooltip>
            ),
        };
        // const expenseType: ColumnType<DocumentItemVm> = {
        //     title: translations.documents.items.expenseType,
        //     key: 'expenseGroup.expenseType',
        //     ...getSearchFilter(),
        //     onFilter: getDefaultFilter('expenseGroup.expenseType'),
        //     sorter: getDefaultComparer('expenseGroup.expenseType'),
        //     render: (item: DocumentItemVm) => item.expenseGroup?.expenseType,
        // };
        // const expenseGroup: ColumnType<DocumentItemVm> = {
        //     title: translations.documents.items.expenseGroup,
        //     key: 'expenseGroup.name',
        //     ...getSearchFilter(),
        //     onFilter: getDefaultFilter('expenseGroup.name'),
        //     sorter: getDefaultComparer('expenseGroup.name'),
        //     render: (item: DocumentItemVm) => item.expenseGroup?.name,
        // };
        const status: ColumnType<DocumentItemVm> = {
            title: translations.documents.status,
            dataIndex: 'statusId',
            ...getCheckboxFilter(statuses),
            onFilter: getKeyFilter('statusId'),
            sorter: getDefaultComparer('status'),
            onCell: () => ({
                style: {
                    whiteSpace: 'nowrap',
                    maxWidth: 150,
                },
            }),
            render: (value: DocumentItemStatusEnum, record: DocumentItemVm): React.ReactElement =>
                getDocumentItemStatusBadge(value, record.status),
        };

        // Type specific columns end

        switch (documentTypeId) {
            case DocumentTypeEnum.RequisitionOrder:
                return [
                    name,
                    project,
                    ...(isSummary ? [] : [projectItem]),
                    // ...(isSummary ? [] : [expenseType]),
                    // ...(isSummary ? [] : [expenseGroup]),
                    supplier,
                    unit,
                    quantity,
                    unitPrice,
                    totalPrice,
                    ...(isSummary ? [] : [comment]),
                    ...(isSummary ? [] : [status]),
                ];
            case DocumentTypeEnum.PurchaseOrder:
                return [
                    name,
                    project,
                    parentItemDocumentCode,
                    ...(isSummary ? [] : [projectItem]),
                    // ...(isSummary ? [] : [expenseType]),
                    // ...(isSummary ? [] : [expenseGroup]),
                    supplier,
                    unit,
                    quantity,
                    unitPrice,
                    totalPrice,
                    ...(isSummary ? [] : [comment]),
                    ...(isSummary ? [] : [status]),
                ];
            case DocumentTypeEnum.PurchaseReceivedNote:
                return [
                    name,
                    project,
                    parentItemDocumentCode,
                    warehouseItem,
                    ...(isSummary ? [] : [projectItem]),
                    supplier,
                    unit,
                    quantity,
                    unitPrice,
                    totalPrice,
                    ...(isSummary ? [] : [comment]),
                ];
            case DocumentTypeEnum.TransferNote:
                return [
                    name,
                    project,
                    destinationProject,
                    parentItemDocumentCode,
                    warehouseItem,
                    ...(isSummary ? [] : [projectItem]),
                    unit,
                    quantity,
                    unitPrice,
                    totalPrice,
                    ...(isSummary ? [] : [comment]),
                    ...(isSummary ? [] : [status]),
                ];
            case DocumentTypeEnum.TransferReceivedNote:
                return [
                    name,
                    project,
                    destinationProject,
                    parentItemDocumentCode,
                    warehouseItem,
                    ...(isSummary ? [] : [projectItem]),
                    unit,
                    quantity,
                    unitPrice,
                    totalPrice,
                    ...(isSummary ? [] : [comment]),
                ];
            case DocumentTypeEnum.ConsumptionNote:
                return [
                    name,
                    project,
                    parentItemDocumentCode,
                    warehouseItem,
                    ...(isSummary ? [] : [projectItem]),
                    unit,
                    quantity,
                    unitPrice,
                    totalPrice,
                    ...(isSummary ? [] : [comment]),
                ];
            case DocumentTypeEnum.WriteOffNote:
                return [
                    name,
                    project,
                    parentItemDocumentCode,
                    warehouseItem,
                    ...(isSummary ? [] : [projectItem]),
                    unit,
                    quantity,
                    unitPrice,
                    totalPrice,
                    ...(isSummary ? [] : [comment]),
                ];
            case DocumentTypeEnum.Liquidation:
                return [
                    name,
                    project,
                    parentItemDocumentCode,
                    supplier,
                    unit,
                    quantity,
                    unitPrice,
                    totalPrice,
                    ...(isSummary ? [] : [comment]),
                ];
            case DocumentTypeEnum.Order:
                return [
                    name,
                    project,
                    parentItemDocumentCode,
                    ...(isSummary ? [] : [projectItem]),
                    // ...(isSummary ? [] : [expenseType]),
                    // ...(isSummary ? [] : [expenseGroup]),
                    supplier,
                    unit,
                    quantity,
                    unitPrice,
                    totalPrice,
                    ...(isSummary ? [] : [comment]),
                ];
        }
    };

    public render(): React.ReactElement {
        const {
            documentItems,
            warehouseItems,
            selectedItemsKeys,
            onSelectionChange,
            onEdit,
            onDelete,
        } = this.props;

        let columns: ColumnsType<any>;
        let tableItems: DocumentItemVm[] | WarehouseItemVm[] | undefined;
        let rowSelection: any;

        if (warehouseItems) {
            columns = this.getWarehouseItemTypeColumns();
            tableItems = warehouseItems;
        } else {
            columns = this.getDocumentItemTypeColumns();
            tableItems = documentItems;
            rowSelection = onSelectionChange
                ? {
                      onChange: (_: any, selectedRows: DocumentItemVm[]) => {
                          onSelectionChange(selectedRows);
                      },
                      getCheckboxProps: (record: DocumentItemVm) => ({
                          disabled: record.name === 'Disabled User', // Column configuration not to be checked
                          name: record.name,
                      }),
                      ...(selectedItemsKeys && { selectedRowKeys: selectedItemsKeys }),
                  }
                : undefined;

            if (onEdit || onDelete) {
                columns.push({
                    title: translations.general.actions,
                    key: 'actions',
                    width: 90,
                    align: 'left',
                    className: 'no-print',
                    render: (record: DocumentItemVm): React.ReactElement => (
                        <>
                            {onEdit && (
                                <Button
                                    shape="circle"
                                    size="small"
                                    type="link"
                                    onClick={() => onEdit(record.id)}
                                    icon={<EditOutlined />}
                                />
                            )}
                            {onDelete && (
                                <Button
                                    shape="circle"
                                    size="small"
                                    type="link"
                                    danger
                                    onClick={() =>
                                        showConfirm(
                                            () => onDelete(record.id),
                                            translations.documents.items.deleteConfirm,
                                            translations.general.delete,
                                            true
                                        )
                                    }
                                    icon={<DeleteOutlined />}
                                />
                            )}
                        </>
                    ),
                });
            }
        }

        return (
            <Table
                locale={getTableLocale()}
                size="small"
                columns={columns}
                rowKey={(record: DocumentItemVm | WarehouseItemVm): string => record.id.toString()}
                rowSelection={rowSelection}
                dataSource={tableItems}
                pagination={defaultTablePagination}
            />
        );
    }
}
