import React from 'react';
import ReplaceStrings from '../../config/replaceStrings';
import Routes from '../../config/routes';
import { DrawerState } from '../../core/models/enum';
import { getNewRoute } from '../../helpers/RoutingHelper';
import { NewsClient, NewsVm } from '../../utils/api';
import { Props } from './index';
import NewsAdmin from './news-admin';
import NewsRegular from './news-regular';

interface State {
    adminMode: boolean;
    selectedNews?: NewsVm;
    drawerState: DrawerState;
}

class News extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            adminMode: this.isAdminMode(props.match.path),
            drawerState: DrawerState.Closed,
        };
    }

    private updateDrawerStateFromPathName = (pathname: string) => {
        switch (pathname) {
            case Routes.ROUTE_NEWS_NEW:
                this.setState({
                    drawerState: DrawerState.Create,
                });
                return;
            case Routes.ROUTE_NEWS_EDIT:
                this.setState({
                    drawerState: DrawerState.Edit,
                });
                return;
            case Routes.ROUTE_NEWS_READ:
            case Routes.ROUTE_NEWS_ADMIN_READ:
                this.setState({
                    drawerState: DrawerState.Read,
                });
                return;
            case Routes.ROUTE_NEWS:
                this.setState({
                    drawerState: DrawerState.Closed,
                });
                return;
            case Routes.ROUTE_NEWS_ADMIN:
                this.setState({
                    drawerState: DrawerState.Closed,
                });
                return;
            default:
                this.setState({
                    drawerState: DrawerState.Closed,
                });
                return;
        }
    };

    private isAdminMode = (pathname: string): boolean => {
        return pathname.includes(Routes.ROUTE_NEWS_ADMIN);
    };

    private handleRouteUpdate = async (pathname: string, newsId?: number) => {
        let selectedNews;
        const client = new NewsClient();

        if (newsId) {
            selectedNews = await client.getById(newsId);
        }

        this.setState(
            {
                selectedNews,
                adminMode: this.isAdminMode(pathname),
            },
            () => this.updateDrawerStateFromPathName(pathname)
        );
    };

    private handleDrawerMode = (drawerState: DrawerState, id?: string) => {
        const { history } = this.props;
        const { adminMode } = this.state;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                adminMode ? Routes.ROUTE_NEWS_ADMIN : Routes.ROUTE_NEWS,
                Routes.ROUTE_NEWS_READ,
                Routes.ROUTE_NEWS_NEW,
                drawerState,
                ReplaceStrings.ENTITY_ID,
                id
            )
        );
    };

    public setSelectedNews = (selectedNews: NewsVm): void => {
        this.setState({ selectedNews: selectedNews });
    };

    public componentDidMount(): void {
        const {
            match: {
                params: { entityId },
                path,
            },
        } = this.props;

        this.handleRouteUpdate(path, +entityId);
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        const {
            match: {
                params: { entityId },
                path,
            },
        } = this.props;
        if (path !== prevProps.match.path) {
            this.handleRouteUpdate(path, +entityId);
        }
    }

    public render(): React.ReactElement {
        const { adminMode, drawerState, selectedNews } = this.state;

        return (
            <>
                {adminMode ? (
                    <NewsAdmin
                        userProfile={this.props.userProfile}
                        handleDrawerMode={this.handleDrawerMode}
                        setSelectedNews={this.setSelectedNews}
                        drawerState={drawerState}
                        selectedNews={selectedNews}
                    />
                ) : (
                    <NewsRegular
                        userProfile={this.props.userProfile}
                        handleDrawerMode={this.handleDrawerMode}
                        selectedNews={selectedNews}
                    />
                )}
            </>
        );
    }
}

export default News;
