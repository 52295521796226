import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Select, Button, Space } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import React from 'react';
import { translations } from '../../config/translations';
import { DropdownOption } from '../../core/models/DropdownOption';

interface Props {
    selectedKeys: React.Key[];
    confirm: (param?: FilterConfirmProps) => void;
    setSelectedKeys: (selectedKeys: React.Key[]) => void;
    clearFilters?: () => void;
    options: DropdownOption[];
    multiple?: boolean;
}
const { Option } = Select;

class SelectFilter extends React.Component<Props> {
    public render = (): React.ReactElement => {
        const { selectedKeys, setSelectedKeys, confirm, clearFilters, options, multiple } =
            this.props;

        return (
            <Space direction="vertical" style={{ padding: 8 }}>
                <Select
                    showSearch
                    placeholder={`${translations.general.choose}...`}
                    mode={multiple ? 'tags' : undefined}
                    value={selectedKeys}
                    onChange={(values: any) => setSelectedKeys(values)}
                    style={{ width: '100%' }}
                >
                    {options.map(
                        (o: DropdownOption): React.ReactElement => (
                            <Option key={o.id} value={o.id}>
                                {o.name}
                            </Option>
                        )
                    )}
                </Select>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {translations.general.search}
                    </Button>
                    <Button
                        onClick={() => (clearFilters ? clearFilters() : undefined)}
                        icon={<CloseOutlined />}
                        size="small"
                    >
                        {translations.general.clear}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                        }}
                    >
                        {translations.general.filter}
                    </Button>
                </Space>
            </Space>
        );
    };
}

export default SelectFilter;
