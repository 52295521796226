import { Profile } from 'oidc-client';
import ProjectItemTable from './ProjectItemTable';
import { ProjectItemExtendedVm } from '../../../../../core/models/ProjectItems';
import { SelectOption } from '../../../../../core/models/SelectOption';
import { Filter } from '../../../../../core/models/Filter';
import { ProjectItemVm, ProjectSituationItemVm } from '../../../../../utils/api';

interface OwnProps {
    projectId: number;
    projectItems?: ProjectItemVm[];
    projectSituationItems?: ProjectSituationItemVm[];
    editingItem?: Partial<ProjectItemExtendedVm>;
    prevEditingItemId?: number;
    forceRenderExpandColumn?: boolean;
    expandedRowKeys?: React.Key[];
    filter?: Filter;
    onAdd: (isGroup: boolean, parentId?: number) => void;
    onEdit: (item: Partial<ProjectItemExtendedVm>) => void;
    onCancel: () => void;
    onSave: (item: Partial<ProjectItemExtendedVm>) => void;
    onDelete: (id: number) => void;
    onExpand: (expand: boolean, item: ProjectItemExtendedVm) => void;
    onFilterChange: (filters: any) => void;
    unitsOfMeasurement: SelectOption[];
    userProfile: Profile;
}

export type Props = OwnProps;

export default ProjectItemTable;
