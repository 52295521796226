import Table, { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { Link } from 'react-router-dom';
import { Props } from '.';
import { defaultTablePagination } from '../../../config/constants';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { getSearchFilter } from '../../../helpers/FilterHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import { WarehouseClient, WarehouseVm } from '../../../utils/api';
import {
    getPagingSortingParams,
    getFiltersParams,
    getFilterParamsString,
    applySearchParams,
} from '../../../helpers/SearchParamsHelper';
import { capitalizeFirstLetter } from '../../../helpers/StringHelper';

interface State {
    warehouses?: WarehouseVm[];
    loading?: boolean;
    totalItems?: number;
}

class WarehouseTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            warehouses: [],
            totalItems: 0
        };
    }

    public componentDidMount = () => {
        this.getWarehouses();
    };

    public componentDidUpdate(prevProps: Props) {
        const {
            location: { search },
        } = this.props;

        if (prevProps.location.search !== search) {
            this.getWarehouses();
        }
    }

    private handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<WarehouseVm> | SorterResult<WarehouseVm>[] = {}
    ) => {
        const {
            history,
            location: { search },
        } = this.props;

        const currSearchParams = new URLSearchParams(search);
        const tableSearchParams = this.createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    private createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<WarehouseVm> | SorterResult<WarehouseVm>[] = {}
    ): URLSearchParams => {
        const filterParamsString = getFilterParamsString(filters);
        const singleSorter = sorter as SorterResult<WarehouseVm>;
        const tableSearchParams = new URLSearchParams(filterParamsString);

        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );
        tableSearchParams.append('sortBy', singleSorter.field?.toString() && singleSorter.order ? singleSorter.field?.toString() : '');
        tableSearchParams.append('sortOrder', singleSorter.order || '');

        return tableSearchParams;
    };

    private getWarehouses = async () => {
        this.setState({
            loading: true,
        });
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { pageIndexParam, pageSizeParam, sortByParam, sortOrderParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        const result = await new WarehouseClient().getPaginated(
            filters.projectId ? parseInt(filters.projectId.toString()) : null,
            filters.project_name ? filters.project_name.toString() : null,
            filters.project_address ? filters.project_address.toString() : null,
            filters.project_city_nameUniversal
                ? filters.project_city_nameUniversal.toString()
                : null,
            filters.project_country_nameUniversal
                ? filters.project_country_nameUniversal.toString()
                : null,
            pageIndexParam && parseInt(pageIndexParam) ? parseInt(pageIndexParam) : 1,
            pageSizeParam && parseInt(pageSizeParam)
                ? parseInt(pageSizeParam)
                : defaultTablePagination.defaultPageSize,
            sortByParam ? capitalizeFirstLetter(sortByParam) : undefined,
            sortOrderParam
        );

        this.setState((prevState: State) => ({
            warehouses: result.pageItems,
            loading: false,
            totalItems: result.totalItems
        }));
    };

    public render(): React.ReactElement {
        const { warehouses, loading, totalItems } = this.state;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam, pageIndexParam, pageSizeParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);
        const pagination: TablePaginationConfig = {
            ...defaultTablePagination,
            current:
                pageIndexParam && parseInt(pageIndexParam)
                    ? parseInt(pageIndexParam)
                    : defaultTablePagination.defaultCurrent,
            pageSize:
                pageSizeParam && parseInt(pageSizeParam)
                    ? parseInt(pageSizeParam)
                    : defaultTablePagination.defaultPageSize,
            total: totalItems
        };

        const columns = [
            {
                title: translations.projects.project,
                dataIndex: 'project_name',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.project_name ? [filters.project_name] : null,
                sortOrder: sortByParam === 'project_name' ? sortOrderParam : null,
                render: (_: string, record: WarehouseVm): React.ReactElement => (
                    <Link
                        to={{
                            pathname: Routes.ROUTE_WAREHOUSE_ITEMS,
                            search: `?projectId=${record.id.toString()}`,
                        }}
                    >
                        {record.name}
                    </Link>
                ),
            },
            {
                title: translations.projects.address,
                dataIndex: 'project_address',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.project_address ? [filters.project_address] : null,
                sortOrder: sortByParam === 'project_address' ? sortOrderParam : null,
                render: (_: string, record: WarehouseVm) => record.address,
            },
            {
                title: translations.projects.city,
                dataIndex: 'project_city_nameUniversal',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.project_city_nameUniversal
                    ? [filters.project_city_nameUniversal]
                    : null,
                sortOrder: sortByParam === 'project_city_nameUniversal' ? sortOrderParam : null,
                render: (_: string, record: WarehouseVm) => record.city,
            },
            {
                title: translations.projects.country,
                dataIndex: 'project_country_nameUniversal',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.project_country_nameUniversal
                    ? [filters.project_country_nameUniversal]
                    : null,
                sortOrder: sortByParam === 'project_country_nameUniversal' ? sortOrderParam : null,
                render: (_: string, record: WarehouseVm) => record.country,
            },
        ];

        return (
            <Table
                locale={getTableLocale()}
                columns={columns}
                dataSource={warehouses}
                loading={loading}
                rowKey={(record: WarehouseVm): string => record.id.toString()}
                pagination={pagination}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default WarehouseTable;
