import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../../../core/models/RouteParams';
import { ProjectCooperatorVm, ProjectSituationVm } from '../../../../../utils/api';
import ProjectSituationDetails from './ProjectSituationDetails';

interface OwnProps {
    projectId: number;
    situation: ProjectSituationVm;
    contractors: ProjectCooperatorVm[];
    userProfile: Profile;
    onContractorChange: (id: number) => void;
    onEdit: () => void;
    onDelete: (id: number) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(ProjectSituationDetails);
