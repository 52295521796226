import React from 'react';
import { Props } from '.';
import replaceStrings from '../../config/replaceStrings';
import Routes from '../../config/routes';
import { DrawerState, HodogramDisplayState } from '../../core/models/enum';
import { getHodogramById } from '../../helpers/HodogramHelper';
import { getNewRoute } from '../../helpers/RoutingHelper';
import { HodogramClient, HodogramVm } from '../../utils/api';
import HodogramRegular from './hodogram-regular';
import HodogramReorder from './hodogram-reorder';

interface State {
    hodogramEntities?: HodogramVm[];
    loading: boolean;
    displayState: HodogramDisplayState;
    drawerState: DrawerState;
    selectedHodogram?: HodogramVm;
}

class Hodogram extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            hodogramEntities: [],
            loading: false,
            displayState: HodogramDisplayState.Regular,
            drawerState: DrawerState.Closed,
        };
    }

    private async getHodogram(callback?: (...params: any) => any, ...params: any) {
        this.setState({
            loading: true,
        });

        let result = await new HodogramClient().getAllHodogram();

        this.setState(
            {
                hodogramEntities: result,
                loading: false,
            },
            () => {
                if (callback) callback(...params);
            }
        );
    }

    private updateHodogramState = (state: any) => {
        this.setState({ ...state });
    };

    public componentDidMount(): void {
        const {
            match: {
                params: { entityId },
                path,
            },
        } = this.props;

        this.getHodogram(this.handleRouteUpdate, path, +entityId);
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        const { displayState, hodogramEntities } = this.state;
        const {
            match: {
                params: { entityId },
                path,
            },
        } = this.props;

        if (path !== prevProps.match.path || entityId !== prevProps.match.params.entityId) {
            this.handleRouteUpdate(path, +entityId);
        }

        if (prevState.hodogramEntities !== hodogramEntities) {
            this.setState({
                hodogramEntities,
            });
        }

        if (prevState.displayState !== displayState) {
            this.setState({
                displayState,
            });
        }
    }

    private updateDrawerStateFromPathName = (pathname: string) => {
        switch (pathname) {
            case Routes.ROUTE_HODOGRAM_EDIT:
                this.setState({
                    drawerState: DrawerState.Edit,
                });
                return;
            case Routes.ROUTE_HODOGRAM_READ:
                this.setState({
                    drawerState: DrawerState.Read,
                });
                return;
            case Routes.ROUTE_HODOGRAM:
                this.setState({
                    drawerState: DrawerState.Closed,
                });
                return;
            default:
                this.setState({
                    drawerState: DrawerState.Closed,
                });
                return;
        }
    };

    private handleRouteUpdate = async (pathname: string, hodogramId?: number) => {
        if (!hodogramId || !this.state.hodogramEntities) return;

        let selectedHodogram = getHodogramById(hodogramId, this.state.hodogramEntities);

        this.setState(
            {
                selectedHodogram: selectedHodogram,
            },
            () => this.updateDrawerStateFromPathName(pathname)
        );
    };

    private handleDrawerMode = (drawerState: DrawerState, id?: string) => {
        const { history } = this.props;

        this.setState({ drawerState: drawerState });

        history.push(
            getNewRoute(
                Routes.ROUTE_HODOGRAM,
                Routes.ROUTE_HODOGRAM_READ,
                Routes.ROUTE_HODOGRAM_EDIT,
                drawerState,
                replaceStrings.ENTITY_ID,
                id
            )
        );
    };

    public render() {
        const { hodogramEntities, displayState, drawerState, selectedHodogram, loading } =
            this.state;

        return (
            <>
                {displayState === HodogramDisplayState.Regular && hodogramEntities && (
                    <HodogramRegular
                        loading={loading}
                        userProfile={this.props.userProfile}
                        drawerState={drawerState}
                        handleDrawerMode={this.handleDrawerMode}
                        updateHodogramState={this.updateHodogramState}
                        hodogramEntities={hodogramEntities}
                        selectedHodogram={selectedHodogram}
                    />
                )}

                {displayState === HodogramDisplayState.Order && hodogramEntities && (
                    <HodogramReorder
                        hodogramEntities={hodogramEntities}
                        updateHodogramState={this.updateHodogramState}
                    />
                )}
            </>
        );
    }
}

export default Hodogram;
