import { PageHeader, Pagination, Input, PaginationProps } from 'antd';
import React from 'react';
import { Props } from '.';
import NewsDetail from '../../../components/news-components/news-detail';
import NewsGrid from '../../../components/news-components/news-grid';
import { defaultNewsPagination } from '../../../config/constants';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { NewsClient, NewsVm } from '../../../utils/api';

const { Search } = Input;

interface State {
    news?: NewsVm[];
    loading?: boolean;
    pagination: PaginationProps;
    filterTitle?: string;
}

class NewsRegular extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            news: [],
            loading: false,
            pagination: defaultNewsPagination,
        };
    }

    public componentDidMount(): void {
        this.getNews();
    }

    private onSearch = (value: string) => {
        this.setState({ filterTitle: value }, () => {
            this.getNews();
        });
    };

    private onPaginationChange = (page: number, pageSize?: number) => {
        this.setState(
            (prevState: State) => ({
                pagination: {
                    ...prevState.pagination,
                    current: page,
                    pageSize: pageSize ?? prevState.pagination.pageSize,
                },
            }),
            () => {
                this.getNews();
            }
        );
    };

    private getNews = async () => {
        this.setState({
            loading: true,
        });

        const { pagination, filterTitle } = this.state;

        const result = await new NewsClient().getPaginated(
            filterTitle,
            null,
            null,
            null,
            false,
            null,
            pagination.current || 1,
            pagination.pageSize || pagination.defaultPageSize,
            'Date',
            'descend'
        );

        this.setState((prevState: State) => ({
            news: result.pageItems,
            pagination: {
                ...prevState.pagination,
                current: result.currentPage,
                total: result.totalItems,
            },
            loading: false,
        }));
    };

    /* NOTE: Even though News.tsx is the component whose main role is to handle routes, NewsRegular
       is checking route directly to make things snappier. */
    private isDetailedMode = (pathname: string): boolean => {
        return pathname.includes(Routes.ROUTE_NEWS_READ);
    };

    public render(): React.ReactElement {
        const { selectedNews } = this.props;
        const { news, pagination, loading } = this.state;

        const detailedMode = this.isDetailedMode(this.props.match.path);

        return (
            <>
                {detailedMode ? (
                    <NewsDetail isAdminView={false} news={selectedNews} />
                ) : (
                    <>
                        <PageHeader
                            title={translations.news.news}
                            extra={
                                <Search
                                    placeholder={translations.general.search}
                                    onSearch={this.onSearch}
                                    style={{ width: 200 }}
                                />
                            }
                        />
                        <NewsGrid news={news} loading={loading} />
                        <Pagination
                            style={{ float: 'right' }}
                            {...pagination}
                            onChange={this.onPaginationChange}
                        />
                    </>
                )}
            </>
        );
    }
}

export default NewsRegular;
