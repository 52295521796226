import { HodogramVm } from '../../../utils/api';
import HodogramForm from './HodogramForm';

interface OwnProps {
    hodogram?: HodogramVm;
    onClose: () => void;
    onSuccess: (hodogramEntities: HodogramVm[]) => void;
    hodogramEntities: HodogramVm[]
}

export type Props = OwnProps;

export default HodogramForm;
