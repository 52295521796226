import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { defaultTablePagination } from '../../../config/constants';
import { defaultFormatWithTime } from '../../../config/constants';
import { translations } from '../../../config/translations';
import { ActionType, ModuleName } from '../../../core/models/enum';
import { authorizeAction } from '../../../helpers/CheckPermissionHelper';
import { formatDate } from '../../../helpers/DateHelper';
import { downloadFile } from '../../../helpers/FileHelper';
import {
    getDateFilter,
    getDatePickerFilter,
    getDefaultFilter,
    getSearchFilter,
} from '../../../helpers/FilterHelper';
import { showConfirm } from '../../../helpers/NotificationHelper';
import { getDateComparer, getDefaultComparer } from '../../../helpers/SortHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import { AttachmentsClient, AttachmentVm } from '../../../utils/api';
import { Props } from './index';

interface State {
    allowUpdate: boolean;
    allowDelete: boolean;
}

class AttachmentTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            allowUpdate: authorizeAction(
                props.userProfile,
                ModuleName.Attachments,
                ActionType.Update,
                props.attachments?.length ? props.attachments[0].projectId : undefined,
                props.attachments?.length  ? props.attachments[0].employeeId : undefined,
                props.attachments?.length  ? props.attachments[0].documentId : undefined
            ),
            allowDelete: authorizeAction(
                props.userProfile,
                ModuleName.Attachments,
                ActionType.Delete,
                props.attachments?.length  ? props.attachments[0].projectId : undefined,
                props.attachments?.length  ? props.attachments[0].employeeId : undefined,
                props.attachments?.length  ? props.attachments[0].documentId : undefined
            ),
        };
    }

    private downloadAttachment = async (id: number) => {
        const client = new AttachmentsClient();
        const result = await client.downloadFile(id);

        downloadFile(result);
    };

    public render(): React.ReactElement {
        const { attachments, onUpdate, onDelete } = this.props;
        const { allowUpdate, allowDelete } = this.state;

        const columns: ColumnsType<AttachmentVm> = [
            {
                title: translations.attachments.fileName,
                dataIndex: 'fileName',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('fileName'),
                sorter: getDefaultComparer('fileName'),
                render: (value: string, item: AttachmentVm): React.ReactElement => {
                    return (
                        <Button
                            type="link"
                            onClick={async () => await this.downloadAttachment(item.id)}
                        >
                            {value}
                        </Button>
                    );
                },
            },
            {
                title: translations.attachments.description,
                dataIndex: 'description',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('description'),
                sorter: getDefaultComparer('description'),
            },
            {
                title: translations.attachments.createdBy,
                key: 'createdBy',
                dataIndex: 'createdBy',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('createdBy'),
                sorter: getDefaultComparer('createdBy'),
            },
            {
                title: translations.attachments.createdDate,
                key: 'createdDate',
                dataIndex: 'createdDate',
                width: 150,
                ...getDatePickerFilter(),
                onFilter: getDateFilter('createdDate'),
                sorter: getDateComparer('createdDate'),
                render: (value: Date) => formatDate(value, defaultFormatWithTime),
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 100,
                align: 'left',
                className: 'no-print',
                render: (value: AttachmentVm): React.ReactElement => (
                    <>
                        {allowUpdate && (
                            <EditOutlined onClick={() => onUpdate(value.id)} className="blue-6" />
                        )}
                        {allowDelete && (
                            <DeleteOutlined
                                onClick={() =>
                                    showConfirm(
                                        () => onDelete(value.id),
                                        translations.attachments.deleteConfirm,
                                        translations.general.delete,
                                        true
                                    )
                                }
                                className="red-5"
                                style={{ marginLeft: 10 }}
                            />
                        )}
                    </>
                ),
            },
        ];

        return (
            <Table
                locale={getTableLocale()}
                columns={columns}
                dataSource={attachments}
                pagination={defaultTablePagination}
                rowKey={(record: AttachmentVm): string => record.id?.toString()}
                bordered
                size="small"
            />
        );
    }
}

export default AttachmentTable;
