import { ProjectVm } from '../../utils/api';
import WorkHourRecordsTable, { TableEmployeeVm } from './WorkHourRecordsTable';

interface OwnProps {
    project?: ProjectVm;
    employees?: TableEmployeeVm[];
}

export type Props = OwnProps;

export default WorkHourRecordsTable;
