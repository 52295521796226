import { DragOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import { Button, PageHeader, Tree } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';
import React, { useCallback, useEffect, useState } from 'react';
import { Props } from '.';
import { translations } from '../../../config/translations';
import { HodogramDisplayState } from '../../../core/models/enum';
import {
    flattenHodogram,
    getHodogramById,
    getParentKeys,
    getTreeData,
} from '../../../helpers/HodogramHelper';
import { showError, showSuccess } from '../../../helpers/NotificationHelper';
import { ApiException, HodogramClient, HodogramVm } from '../../../utils/api';

const recursiveTraverse = (
    nodeArray: DataNode[],
    hodogramLookup: HodogramVm[],
    parentId?: number
): HodogramVm[] => {
    let resultArray: HodogramVm[] = [];

    nodeArray.forEach((elem, index) => {
        let hodogram = hodogramLookup.find((value) => value.id === elem.key);
        if (!hodogram) return;

        hodogram.ordinal = index + 1;
        hodogram.parentId = parentId;

        hodogram.children = [];
        if (elem.children && elem.children.length > 0) {
            hodogram.children = recursiveTraverse(elem.children, hodogramLookup, hodogram.id);
        }

        resultArray.push(hodogram);
    });

    return resultArray;
};

const HodogramReorder: React.FC<Props> = (props: Props) => {
    const [treeData, setTreeData] = useState([] as DataNode[]);
    const [expandedKeys, setExpandedKeys] = useState([] as React.Key[]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    const { hodogramEntities, updateHodogramState } = props;

    useEffect(() => {
        setTreeData(
            getTreeData('', hodogramEntities, (value: any): React.ReactNode => {
                return <DragOutlined />;
            })
        );
        setExpandedKeys(getParentKeys(hodogramEntities));
    }, [hodogramEntities]);

    const adjustTitleIndex = useCallback(
        (namePrefix: string, nodeArray: DataNode[]): DataNode[] => {
            nodeArray.forEach((elem, index) => {
                index = index + 1;

                let prefix = `${namePrefix}${index}.`;
                let value = getHodogramById(+elem.key, hodogramEntities);

                elem.title = (
                    <>
                        <span style={{ marginRight: '10px' }}>
                            {prefix} {value?.name}
                        </span>
                        {<DragOutlined />}
                    </>
                );

                if (elem.children && elem.children.length > 0) {
                    elem.children = adjustTitleIndex(prefix, elem.children);
                }
            });

            return nodeArray;
        },
        [hodogramEntities]
    );

    const handleReorderSave = useCallback(async () => {
        const flattenedHodogram = flattenHodogram(hodogramEntities);
        let reorderedEntites: HodogramVm[] = recursiveTraverse(
            treeData,
            flattenedHodogram,
            undefined
        );

        try {
            const request: any = {
                hodogramEntities: reorderedEntites,
            };
            await new HodogramClient().updateHodogram(request);

            showSuccess(translations.hodogram.successfulReorder);

            updateHodogramState({
                displayState: HodogramDisplayState.Regular,
                hodogramEntities: reorderedEntites,
            });
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    }, [hodogramEntities, treeData, updateHodogramState]);

    const onDrop: TreeProps['onDrop'] = (info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop = (
            data: DataNode[],
            key: React.Key,
            callback: (node: DataNode, i: number, data: DataNode[]) => void
        ) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children!, key, callback);
                }
            }
        };
        const data = [...treeData];

        // Find dragObject
        let dragObj: DataNode;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
            });
        } else if (
            ((info.node as any).props.children || []).length > 0 && // Has children
            (info.node as any).props.expanded && // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
                // in previous version, we use item.children.push(dragObj) to insert the
                // item to the tail of the children
            });
        } else {
            let ar: DataNode[] = [];
            let i: number;
            loop(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i!, 0, dragObj!);
            } else {
                ar.splice(i! + 1, 0, dragObj!);
            }
        }
        setTreeData(adjustTitleIndex('', data));
    };

    const onExpand = (expandedKeysValue: React.Key[]) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    return (
        <>
            <PageHeader
                title={translations.hodogram.hodogram}
                extra={[
                    <Button
                        key="1"
                        type="primary"
                        style={{
                            zIndex: 10,
                            float: 'right',
                        }}
                        onClick={handleReorderSave}
                        className="no-print"
                    >
                        <SaveOutlined />
                        {translations.hodogram.saveReorder}
                    </Button>,
                    <Button
                        key="2"
                        type="default"
                        style={{
                            zIndex: 10,
                            float: 'right',
                            margin: '0px 10px',
                        }}
                        onClick={() =>
                            updateHodogramState({
                                displayState: HodogramDisplayState.Regular,
                            })
                        }
                        className="no-print"
                    >
                        <StopOutlined />
                        {translations.hodogram.abandonReorder}
                    </Button>,
                ]}
            />
            <Tree
                key="reorder-tree"
                autoExpandParent={autoExpandParent}
                className="draggable-tree"
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                draggable
                blockNode
                onDrop={onDrop}
                treeData={treeData}
            />
        </>
    );
};

export default HodogramReorder;
