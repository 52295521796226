import { Card } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { defaultFormatWithTime } from '../../../config/constants';
import replaceStrings from '../../../config/replaceStrings';
import routes from '../../../config/routes';
import { formatDate } from '../../../helpers/DateHelper';
import { Props } from './index';

class NewsShort extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    private stripHtmlTags(htmlString: string) {
        return htmlString.replace(/<[^>]+>/g, ' ').replace(/\s\s+/g, ' ');
    }

    public render() {
        const { news } = this.props;
        const strippedText = this.stripHtmlTags(news.text);

        return (
            <>
                <Link
                    to={routes.ROUTE_NEWS_READ.replace(
                        replaceStrings.ENTITY_ID,
                        news.id.toString()
                    )}
                >
                    <Card
                        title={
                            <div>
                                <h1 style={{ marginBottom: 0 }}>{news.title}</h1>
                            </div>
                        }
                        size="small"
                        extra={
                            <p style={{ marginBottom: 0, fontSize: '14px' }}>
                                {news.author}, {formatDate(news.date, defaultFormatWithTime)}
                            </p>
                        }
                        type="inner"
                        className="notification-overflow-container"
                    >
                        <div className="notification-overflow">{strippedText}</div>
                    </Card>
                </Link>
            </>
        );
    }
}

export default NewsShort;
