import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Select, SelectProps } from 'antd';
import { CitiesClient, CityVm, CreateCityCommandOfCity } from '../../utils/api';
import { useEffect, useState } from 'react';

interface OwnProps {
    onCityAdded: (city: CityVm) => void;
    countryId?: number;
}

const SelectCity = (props: SelectProps & OwnProps) => {
    const { onCityAdded, countryId, options, ...selectProps } = props;

    const [filteredOptions, setFilteredOptions] = useState(options);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        setFilteredOptions(
            searchTerm
                ? options?.filter((o) =>
                      o.label?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                  )
                : options
        );
    }, [options, searchTerm]);

    const addItem = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        const newCity = await new CitiesClient().create(
            CreateCityCommandOfCity.fromJS({
                name: searchTerm,
                nameUniversal: searchTerm,
                countryId,
            })
        );

        onCityAdded(newCity);
    };

    return (
        <Select
            {...selectProps}
            filterOption={false}
            onSearch={(value: string) => setSearchTerm(value)}
            onClear={() => setFilteredOptions(options)}
            options={filteredOptions}
            disabled={!countryId}
            dropdownRender={(menu) => (
                <>
                    {menu}

                    {searchTerm && !filteredOptions?.length && (
                        <>
                            <Divider style={{ margin: '8px 0' }} />
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                Dodaj
                            </Button>
                        </>
                    )}
                </>
            )}
        />
    );
};

export default SelectCity;
