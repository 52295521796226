import React from 'react';
import { NewsClient, NewsVm } from '../../../utils/api';
import NewsGrid from '../news-grid';

interface Props {}

interface State {
    news?: NewsVm[];
    loading?: boolean;
}

class FeaturedNews extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            news: [],
        };
    }

    private getFeaturedNews = async () => {
        this.setState({
            loading: true,
        });

        const result = await new NewsClient().getPaginated(
            null,
            null,
            null,
            null,
            false,
            true,
            null,
            null,
            'Date',
            'descend'
        );

        this.setState({
            loading: false,
            news: result.pageItems,
        });
    };

    public componentDidMount(): void {
        this.getFeaturedNews();
    }

    public render() {
        const { news, loading } = this.state;

        return <NewsGrid news={news} loading={loading} />;
    }
}

export default FeaturedNews;
