import { red, gold } from '@ant-design/colors';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader, Tabs, Tag } from 'antd';
import React from 'react';
import { DRAWER_WIDTH } from '../../../config/constants';
import Routes from '../../../config/routes';
import { translations, documents } from '../../../config/translations';
import { DocumentTypeTranslations } from '../../../core/models/Translations';
import { DrawerState, ModuleName, ActionType, DocumentSubPage } from '../../../core/models/enum';
import { authorizeAction } from '../../../helpers/CheckPermissionHelper';
import {
    getDocumentTypeTagColor,
    getDocumentTypeTranslations,
    getPossibleChildrenTypes,
    initNewDocumentItem,
} from '../../../helpers/DocumentHelper';
import { showConfirm, showError, showSuccess } from '../../../helpers/NotificationHelper';
import { buildRoute, buildSubPageRoute } from '../../../helpers/RoutingHelper';
import { remove } from '../../../helpers/SubmitHelper';
import {
    ApiException,
    AttachmentsClient,
    AttachmentVm,
    CopyDocumentCommand,
    DocumentDetailVm,
    DocumentItemsClient,
    DocumentItemVm,
    DocumentsClient,
    DocumentStatusEnum,
    DocumentTypeEnum,
    DocumentVm,
    RoleEnum,
} from '../../../utils/api';
import AttachmentForm from '../../attachments/attachment-form';
import AttachmentTable from '../../attachments/attachment-table';
import DocumentForm from '../document-form';
import DocumentItemForm from '../document-item-form';
import DocumentItemTable from '../document-item-table';
import DocumentTable from '../document-table';
import DocumentDetails from './document-details';
import { Props } from './index';

const { TabPane } = Tabs;

interface State {
    document?: DocumentDetailVm;
    docTypeTranslations: DocumentTypeTranslations;
    activeSubPage?: DocumentSubPage | keyof typeof DocumentTypeEnum;
    editingDocumentItem?: DocumentItemVm;
    editingAttachment?: AttachmentVm;
    drawerState: DrawerState;
    loading?: boolean;
    allowUpdate: boolean;
    allowDelete: boolean;
    allowAddAttachment: boolean;
}

class DocumentPage extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            docTypeTranslations: documents.documents,
            drawerState: DrawerState.Closed,
            allowUpdate: authorizeAction(
                props.userProfile,
                ModuleName.Documents,
                ActionType.Update
            ),
            allowDelete: authorizeAction(
                props.userProfile,
                ModuleName.Documents,
                ActionType.Delete
            ),
            allowAddAttachment: authorizeAction(
                props.userProfile,
                ModuleName.Attachments,
                ActionType.Create,
                undefined,
                undefined,
                parseInt(props.match.params.entityId)
            ),
        };
    }

    public componentDidMount = () => {
        this.initDocument();
    };

    public componentDidUpdate = (prevProps: Props) => {
        const {
            location: { pathname },
            match: {
                params: { entityId },
            },
        } = this.props;

        if (prevProps.match.params.entityId !== entityId) {
            this.initDocument();
        }

        if (prevProps.location.pathname !== pathname) {
            this.handleRouteChange();
        }
    };

    private initDocument = async () => {
        const {
            match: {
                params: { entityId },
            },
        } = this.props;

        const id = parseInt(entityId);

        if (!id) return;

        this.setState({
            loading: true,
        });

        const document = await new DocumentsClient().getById(id);

        this.setState(
            {
                document,
                docTypeTranslations: getDocumentTypeTranslations(document.documentTypeId),
                loading: false,
            },
            this.handleRouteChange
        );
    };

    private handleRouteChange = () => {
        const {
            match: {
                path,
                params: { subPage, subEntityId },
            },
        } = this.props;
        const { document } = this.state;

        let drawerState;

        switch (path) {
            case Routes.ROUTE_DOCUMENTS_SUBPAGE_NEW:
                drawerState = DrawerState.Create;
                break;
            case Routes.ROUTE_DOCUMENTS_EDIT:
            case Routes.ROUTE_DOCUMENTS_SUBPAGE_EDIT:
                drawerState = DrawerState.Edit;
                break;
            case Routes.ROUTE_DOCUMENTS_READ:
            case Routes.ROUTE_DOCUMENTS_SUBPAGE:
                drawerState = DrawerState.Closed;
                break;
            default:
                drawerState = DrawerState.Closed;
                break;
        }

        // setting activeSubPage so that details tab is active for both details and document items routes
        const activeSubPage =
            subPage === ModuleName.DocumentItems ? DocumentSubPage.Details : subPage;

        this.setState({
            drawerState,
            activeSubPage: activeSubPage
                ? (activeSubPage as DocumentSubPage | keyof typeof DocumentTypeEnum)
                : DocumentSubPage.Details,
            editingDocumentItem:
                subPage === ModuleName.DocumentItems && subEntityId
                    ? document?.documentItems?.find(
                          (i: DocumentItemVm): boolean => i.id.toString() === subEntityId
                      )
                    : undefined,
            editingAttachment:
                subPage === DocumentSubPage.Attachments && subEntityId
                    ? document?.attachments?.find(
                          (a: AttachmentVm): boolean => a.id.toString() === subEntityId
                      )
                    : undefined,
        });
    };

    private handleDocumentSaved = (document: DocumentDetailVm) => {
        const { activeSubPage } = this.state;

        if (!activeSubPage) return;

        this.setState({
            document,
        });

        this.handleTabChange(activeSubPage);
    };

    private handleGenerateDocument = async (typeId: DocumentTypeEnum) => {
        const { history } = this.props;
        const { document } = this.state;

        if (!document) return;

        try {
            const result = await new DocumentsClient().copy(
                new CopyDocumentCommand({
                    sourceDocumentId: document.id,
                    destTypeId: typeId,
                    isStorno: false,
                })
            );

            const destDocTypeTranslations = getDocumentTypeTranslations(typeId);
            showSuccess(destDocTypeTranslations.generateSuccess);

            history.push({
                pathname: buildRoute(
                    Routes.ROUTE_DOCUMENTS,
                    Routes.ROUTE_DOCUMENTS_NEW,
                    Routes.ROUTE_DOCUMENTS_READ,
                    Routes.ROUTE_DOCUMENTS_EDIT,
                    DrawerState.Closed,
                    result.id.toString()
                ),
                search: `?typeId=${typeId}`,
            });
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    };

    private handleItemAdd = () => {
        const {
            history,
            location: { search },
        } = this.props;
        const { document } = this.state;

        if (!document) return;

        history.push({
            pathname: buildSubPageRoute(
                Routes.ROUTE_DOCUMENTS_SUBPAGE,
                Routes.ROUTE_DOCUMENTS_SUBPAGE_NEW,
                Routes.ROUTE_DOCUMENTS_SUBPAGE_EDIT,
                DrawerState.Create,
                document.id.toString(),
                ModuleName.DocumentItems
            ),
            search,
        });
    };

    private handleItemEdit = (id: number) => {
        const {
            history,
            location: { search },
        } = this.props;
        const { document } = this.state;

        if (!document) return;

        history.push({
            pathname: buildSubPageRoute(
                Routes.ROUTE_DOCUMENTS_SUBPAGE,
                Routes.ROUTE_DOCUMENTS_SUBPAGE_NEW,
                Routes.ROUTE_DOCUMENTS_SUBPAGE_EDIT,
                DrawerState.Edit,
                document.id.toString(),
                ModuleName.DocumentItems,
                id.toString()
            ),
            search,
        });
    };

    private handleItemSaved = (item: DocumentItemVm) => {
        const { document, activeSubPage } = this.state;

        if (!document || !activeSubPage) return;

        const index = document.documentItems?.findIndex(
            (i: DocumentItemVm): boolean => i.id === item.id
        );

        if (index !== -1) {
            this.setState({
                document: DocumentDetailVm.fromJS({
                    ...document,
                    documentItems: [
                        ...document.documentItems!.slice(0, index),
                        item,
                        ...document.documentItems!.slice(index! + 1),
                    ],
                }),
            });
        } else {
            this.setState({
                document: DocumentDetailVm.fromJS({
                    ...document,
                    documentItems: [...document.documentItems!, item],
                }),
            });
        }

        this.handleTabChange(activeSubPage);
    };

    private handleItemDeleted = (id: number) => {
        const { document, activeSubPage } = this.state;

        if (!document || !activeSubPage) return;

        this.setState({
            document: DocumentDetailVm.fromJS({
                ...document,
                documentItems: document.documentItems?.filter((a: DocumentItemVm) => a.id !== id),
            }),
        });

        this.handleTabChange(activeSubPage);
    };

    private handleAttachmentAdd = () => {
        const {
            history,
            location: { search },
        } = this.props;
        const { document } = this.state;

        if (!document) return;

        history.push({
            pathname: buildSubPageRoute(
                Routes.ROUTE_DOCUMENTS_SUBPAGE,
                Routes.ROUTE_DOCUMENTS_SUBPAGE_NEW,
                Routes.ROUTE_DOCUMENTS_SUBPAGE_EDIT,
                DrawerState.Create,
                document.id.toString(),
                ModuleName.Attachments
            ),
            search,
        });
    };

    private handleAttachmentEdit = (id: number) => {
        const {
            history,
            location: { search },
        } = this.props;
        const { document } = this.state;

        if (!document) return;

        history.push({
            pathname: buildSubPageRoute(
                Routes.ROUTE_DOCUMENTS_SUBPAGE,
                Routes.ROUTE_DOCUMENTS_SUBPAGE_NEW,
                Routes.ROUTE_DOCUMENTS_SUBPAGE_EDIT,
                DrawerState.Edit,
                document.id.toString(),
                ModuleName.Attachments,
                id.toString()
            ),
            search,
        });
    };

    private handleAttachmentSaved = (attachment: AttachmentVm) => {
        const { document, activeSubPage } = this.state;

        if (!document || !activeSubPage) return;

        const index = document.attachments?.findIndex(
            (a: AttachmentVm): boolean => a.id === attachment.id
        );

        if (index !== -1) {
            this.setState({
                document: DocumentDetailVm.fromJS({
                    ...document,
                    attachments: [
                        ...document.attachments!.slice(0, index),
                        attachment,
                        ...document.attachments!.slice(index! + 1),
                    ],
                }),
            });
        } else {
            this.setState({
                document: DocumentDetailVm.fromJS({
                    ...document,
                    attachments: [...document.attachments!, attachment],
                }),
            });
        }

        this.handleTabChange(activeSubPage);
    };

    private handleAttachmentDeleted = (id: number) => {
        const { document, activeSubPage } = this.state;

        if (!document || !activeSubPage) return;

        this.setState({
            document: DocumentDetailVm.fromJS({
                ...document,
                attachments: document.attachments?.filter((a: AttachmentVm) => a.id !== id),
            }),
        });

        this.handleTabChange(activeSubPage);
    };

    private handleTabChange = (subPage: DocumentSubPage | keyof typeof DocumentTypeEnum) => {
        const {
            history,
            location: { search },
        } = this.props;
        const { document } = this.state;

        if (!document) return;

        history.push({
            pathname:
                subPage === DocumentSubPage.Details
                    ? buildRoute(
                          Routes.ROUTE_DOCUMENTS,
                          Routes.ROUTE_DOCUMENTS_NEW,
                          Routes.ROUTE_DOCUMENTS_READ,
                          Routes.ROUTE_DOCUMENTS_EDIT,
                          DrawerState.Closed,
                          document.id.toString()
                      )
                    : buildSubPageRoute(
                          Routes.ROUTE_DOCUMENTS_SUBPAGE,
                          Routes.ROUTE_DOCUMENTS_SUBPAGE_NEW,
                          Routes.ROUTE_DOCUMENTS_SUBPAGE_EDIT,
                          DrawerState.Closed,
                          document.id.toString(),
                          subPage
                      ),
            search,
        });
    };

    private getDrawerTitle = (): string => {
        const {
            match: {
                params: { subPage },
            },
        } = this.props;
        const { drawerState, docTypeTranslations } = this.state;

        if (!subPage) return docTypeTranslations.edit;

        switch (subPage) {
            case ModuleName.DocumentItems:
                return drawerState === DrawerState.Create
                    ? translations.documents.items.add
                    : translations.documents.items.edit;
            case ModuleName.Attachments:
                return drawerState === DrawerState.Create
                    ? translations.attachments.add
                    : translations.attachments.edit;
            default:
                return '';
        }
    };

    private getDocumentTags = (): React.ReactElement[] | undefined => {
        const { document, docTypeTranslations } = this.state;

        if (!document) return;

        const tags = [
            <Tag
                key={docTypeTranslations.name}
                color={getDocumentTypeTagColor(document.documentTypeId)}
            >
                {docTypeTranslations.name}
            </Tag>,
        ];

        if (!!document.stornedDocumentId) {
            tags.push(
                <Tag key={translations.documents.storno} color={gold.primary}>
                    {translations.documents.storno}
                </Tag>
            );
        } else if (!!document.storningDocuments?.length) {
            tags.push(
                <Tag key={translations.documents.storned} color={red.primary}>
                    {translations.documents.storned}
                </Tag>
            );
        }

        return tags;
    };

    public render() {
        const {
            userProfile,
            match: {
                params: { subPage },
            },
        } = this.props;
        const {
            document,
            activeSubPage,
            editingDocumentItem,
            editingAttachment,
            docTypeTranslations,
            drawerState,
            allowUpdate,
            allowDelete,
            allowAddAttachment,
        } = this.state;

        if (!document) return null;

        const isSiteManager = Number(userProfile['userRoleId']) === RoleEnum.SiteManager;

        const isDraft = document.documentStatusId === DocumentStatusEnum.Draft;
        const isCompleted = document.documentStatusId === DocumentStatusEnum.Completed;
        const isStornoDocument = !!document.stornedDocumentId;

        const canEditDocument = allowUpdate && !isCompleted && (isDraft || !isSiteManager);
        const canDeleteDocument = allowDelete && !isCompleted && (isDraft || !isSiteManager);

        return (
            <>
                <PageHeader title={document.code || ''} tags={this.getDocumentTags()} />

                <Tabs
                    style={{ width: '100%' }}
                    activeKey={activeSubPage}
                    onChange={(key: string) =>
                        this.handleTabChange(key as DocumentSubPage | keyof typeof DocumentTypeEnum)
                    }
                    destroyInactiveTabPane={true}
                >
                    <TabPane tab={translations.general.details} key={DocumentSubPage.Details}>
                        <>
                            <DocumentDetails
                                document={document}
                                userProfile={userProfile}
                                docTypeTranslations={docTypeTranslations}
                                onComplete={this.handleDocumentSaved}
                            />
                            <PageHeader
                                title={translations.documents.items.title}
                                extra={
                                    canEditDocument
                                        ? [
                                              <Button
                                                  key="1"
                                                  type="primary"
                                                  className="no-print"
                                                  style={{
                                                      zIndex: 10,
                                                  }}
                                                  icon={<PlusOutlined />}
                                                  onClick={this.handleItemAdd}
                                              >
                                                  {translations.documents.items.add}
                                              </Button>,
                                          ]
                                        : []
                                }
                            />
                            <DocumentItemTable
                                documentTypeId={document.documentTypeId}
                                documentItems={document.documentItems}
                                onEdit={
                                    canEditDocument
                                        ? (id: number) => this.handleItemEdit(id)
                                        : undefined
                                }
                                onDelete={
                                    canDeleteDocument
                                        ? (id: number) =>
                                              remove(
                                                  new DocumentItemsClient(),
                                                  translations.documents.items.deleteSuccess,
                                                  this.handleItemDeleted,
                                                  id
                                              )
                                        : undefined
                                }
                            />
                        </>
                    </TabPane>

                    {!isStornoDocument &&
                        getPossibleChildrenTypes(document.documentTypeId).map(
                            (typeId: DocumentTypeEnum) => {
                                const childDocTypeTranslations =
                                    getDocumentTypeTranslations(typeId);

                                return (
                                    <TabPane
                                        tab={childDocTypeTranslations.title}
                                        key={DocumentTypeEnum[typeId]}
                                    >
                                        <PageHeader
                                            extra={
                                                allowUpdate && !isDraft
                                                    ? [
                                                          <Button
                                                              key="1"
                                                              type="primary"
                                                              style={{
                                                                  zIndex: 10,
                                                                  float: 'right',
                                                              }}
                                                              icon={<CopyOutlined />}
                                                              onClick={() =>
                                                                  showConfirm(
                                                                      () =>
                                                                          this.handleGenerateDocument(
                                                                              typeId
                                                                          ),
                                                                      childDocTypeTranslations.generateConfirm,
                                                                      translations.general.generate
                                                                  )
                                                              }
                                                          >
                                                              {childDocTypeTranslations.generate}
                                                          </Button>,
                                                      ]
                                                    : []
                                            }
                                        />
                                        <DocumentTable
                                            documents={
                                                document.children?.filter(
                                                    (d: DocumentVm): boolean =>
                                                        d.documentTypeId === typeId
                                                ) || []
                                            }
                                            typeId={typeId}
                                        />
                                    </TabPane>
                                );
                            }
                        )}

                    {!!document.storningDocuments?.length && (
                        <TabPane
                            tab={translations.documents.storningDocuments}
                            key={translations.documents.storningDocuments}
                        >
                            <DocumentTable
                                documents={document.storningDocuments}
                                typeId={document.documentTypeId}
                            />
                        </TabPane>
                    )}

                    <TabPane
                        tab={translations.attachments.attachments}
                        key={DocumentSubPage.Attachments}
                    >
                        <PageHeader
                            title=""
                            extra={
                                allowAddAttachment
                                    ? [
                                          <Button
                                              key="1"
                                              type="primary"
                                              style={{
                                                  zIndex: 10,
                                                  float: 'right',
                                              }}
                                              icon={<PlusOutlined />}
                                              onClick={this.handleAttachmentAdd}
                                          >
                                              {translations.attachments.add}
                                          </Button>,
                                      ]
                                    : []
                            }
                        />

                        <AttachmentTable
                            userProfile={userProfile}
                            attachments={document.attachments}
                            onUpdate={(id: number) => this.handleAttachmentEdit(id)}
                            onDelete={(id: number) =>
                                remove(
                                    new AttachmentsClient(),
                                    translations.attachments.deleteSuccess,
                                    this.handleAttachmentDeleted,
                                    id
                                )
                            }
                        />
                    </TabPane>
                </Tabs>

                <Drawer
                    title={this.getDrawerTitle()}
                    open={!!drawerState}
                    onClose={() => activeSubPage && this.handleTabChange(activeSubPage)}
                    width={DRAWER_WIDTH}
                    destroyOnClose
                >
                    {activeSubPage === DocumentSubPage.Details &&
                        subPage !== ModuleName.DocumentItems && (
                            <DocumentForm
                                typeId={document.documentTypeId}
                                document={document}
                                onClose={() => activeSubPage && this.handleTabChange(activeSubPage)}
                                onSuccess={this.handleDocumentSaved}
                            />
                        )}
                    {activeSubPage === DocumentSubPage.Details &&
                        subPage === ModuleName.DocumentItems && (
                            <DocumentItemForm
                                document={document}
                                item={editingDocumentItem || initNewDocumentItem(document)}
                                onClose={() => activeSubPage && this.handleTabChange(activeSubPage)}
                                onSuccess={this.handleItemSaved}
                            />
                        )}
                    {activeSubPage === DocumentSubPage.Attachments && (
                        <AttachmentForm
                            attachment={editingAttachment}
                            document={document}
                            onClose={() => activeSubPage && this.handleTabChange(activeSubPage)}
                            onSuccess={this.handleAttachmentSaved}
                        />
                    )}
                </Drawer>
            </>
        );
    }
}

export default DocumentPage;
