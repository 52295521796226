import { Col, Row, Skeleton, Space } from 'antd';
import React from 'react';
import { Props } from '.';
import { NewsVm } from '../../../utils/api';
import NewsShort from '../news-short';

class NewsGrid extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    private turnArrayToRows = (array: any[]) => {
        return array.reduce(function (rows, key, index) {
            return (index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows;
        }, []);
    };

    public render(): React.ReactNode {
        const { loading, news } = this.props;
        const newsRows: any[] = news ? this.turnArrayToRows(news) : [];

        return (
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex', margin: '20px 0px' }}
            >
                {loading
                    ? Array.from({ length: 2 }, (_, i) => (
                          <Row key={`skeleton-row-${i}`} gutter={10}>
                              <Col span={12}>
                                  <Skeleton active />
                              </Col>
                              <Col span={12}>
                                  <Skeleton active />
                              </Col>
                          </Row>
                      ))
                    : newsRows.map((row: NewsVm[]) => (
                          <Row key={`row-${newsRows.indexOf(row)}`} gutter={10}>
                              {row.map((news: NewsVm) => (
                                  <Col key={news.id} span={12}>
                                      <NewsShort news={news}></NewsShort>
                                  </Col>
                              ))}
                          </Row>
                      ))}
            </Space>
        );
    }
}

export default NewsGrid;
