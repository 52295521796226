import { Form, Input, Select } from 'antd';
import React from 'react';
import DrawerButtons from '../../../components/drawer-buttons';
import { formItemLayout1410 } from '../../../config/formLayouts';
import { translations } from '../../../config/translations';
import { showError, showSuccess } from '../../../helpers/NotificationHelper';
import {
    ApiException,
    CodebooksClient,
    ExpenseGroupsClient,
    ExpenseGroupVm,
    SelectOptionVm,
} from '../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    expenseTypes: SelectOptionVm[];
}

class ExpenseGroupForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
            expenseTypes: [],
        };
    }

    public componentDidMount = () => {
        this.getExpenseTypes();
    };

    private getExpenseTypes = async () => {
        this.setState({
            expenseTypes: await new CodebooksClient().getByCodebookName('ExpenseType'),
        });
    };

    private handleSubmit = async (values: any) => {
        const { expenseGroup } = this.props;

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: expenseGroup ? expenseGroup.id : undefined,
        };

        try {
            let result;
            if (request.id) {
                result = await new ExpenseGroupsClient().update(request.id, request);
            } else {
                result = await new ExpenseGroupsClient().create(request);
            }

            this.handleSuccess(result);
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (result: ExpenseGroupVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.administration.expenseGroups.savedMessage);

        onSuccess(result);
    };

    public render() {
        const { expenseGroup, onClose } = this.props;
        const { isSaving, expenseTypes } = this.state;

        if (!expenseTypes) return null;

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout1410}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />
                <Form.Item
                    name="name"
                    label={translations.administration.expenseGroups.name}
                    initialValue={expenseGroup?.name}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="expenseTypeId"
                    label={translations.administration.expenseGroups.expenseType}
                    initialValue={expenseGroup?.expenseTypeId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select<number>
                        placeholder={translations.administration.expenseGroups.chooseExpenseType}
                        options={expenseTypes}
                    />
                </Form.Item>
            </Form>
        );
    }
}

export default ExpenseGroupForm;
