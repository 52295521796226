import { DownOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, MenuProps, PageHeader } from 'antd';
import React from 'react';
import { defaultTablePagination, DRAWER_WIDTH, DRAWER_WIDTH_MEDIUM } from '../../config/constants';
import Routes from '../../config/routes';
import { translations } from '../../config/translations';
import { ActionType, DrawerState, ModuleName } from '../../core/models/enum';
import { authorizeAction } from '../../helpers/CheckPermissionHelper';
import {
    getDocumentTypeTranslations,
    getPossibleChildrenTypesForWarehouseItems,
} from '../../helpers/DocumentHelper';
import { buildRoute } from '../../helpers/RoutingHelper';
import {
    DocumentItemVm,
    DocumentTypeEnum,
    DocumentVm,
    WarehouseItemsClient,
    WarehouseItemVm,
} from '../../utils/api';
import DocumentForm from '../documents/document-form';
import WarehouseItemForm from '../warehouse-items/warehouse-item-form';
import WarehouseItemTable from '../warehouse-items/warehouse-item-table';
import { Props } from './index';

interface State {
    allowCreate: boolean;
    drawerState: DrawerState;
    editingWarehouseItem?: WarehouseItemVm;
    selectedItems?: WarehouseItemVm[];
    newTypeId?: DocumentTypeEnum;
}

class WarehouseItems extends React.Component<Props, State> {
    private tableRef: any = React.createRef();

    public constructor(props: Props) {
        super(props);

        const { userProfile } = props;

        this.state = {
            allowCreate: authorizeAction(userProfile, ModuleName.WarehouseItems, ActionType.Create),
            drawerState: DrawerState.Closed,
            selectedItems: [],
        };
    }

    public componentDidMount = () => {
        this.handleRouteChange();
    };

    public componentDidUpdate = (prevProps: Props) => {
        const {
            match: { path },
        } = this.props;

        if (path !== prevProps.match.path) {
            this.handleRouteChange();
        }
    };

    private handleRouteChange = async () => {
        const {
            match: {
                params: { entityId },
                path,
            },
        } = this.props;

        let drawerState, warehouseItemId;

        switch (path) {
            case Routes.ROUTE_WAREHOUSE_ITEMS_NEW:
                drawerState = DrawerState.Create;
                break;
            case Routes.ROUTE_WAREHOUSE_ITEMS_EDIT:
                drawerState = DrawerState.Edit;
                warehouseItemId = entityId;
                break;
            case Routes.ROUTE_WAREHOUSE_ITEMS_READ:
                drawerState = DrawerState.Read;
                warehouseItemId = entityId;
                break;
            case Routes.ROUTE_WAREHOUSE_ITEMS:
                drawerState = DrawerState.Closed;
                break;
            default:
                drawerState = DrawerState.Closed;
                break;
        }

        this.setState({
            drawerState,
            editingWarehouseItem: warehouseItemId
                ? await new WarehouseItemsClient().getById(+warehouseItemId)
                : undefined,
        });
    };

    private handleDrawerClose = () => {
        const { history } = this.props;

        history.push(
            buildRoute(
                Routes.ROUTE_WAREHOUSE_ITEMS,
                Routes.ROUTE_WAREHOUSE_ITEMS_NEW,
                Routes.ROUTE_WAREHOUSE_ITEMS_READ,
                Routes.ROUTE_WAREHOUSE_ITEMS_EDIT,
                DrawerState.Closed
            )
        );
    };

    private handleItemSelectionChange = (items: WarehouseItemVm[]) => {
        this.setState({ selectedItems: items });
    };

    private handleGenerateDocument: MenuProps['onClick'] = ({ key }) => {
        const { history } = this.props;

        this.setState({ newTypeId: parseInt(key, 10) as DocumentTypeEnum });

        history.push({
            pathname: buildRoute(
                Routes.ROUTE_WAREHOUSE_ITEMS,
                Routes.ROUTE_WAREHOUSE_ITEMS_NEW,
                Routes.ROUTE_WAREHOUSE_ITEMS_READ,
                Routes.ROUTE_WAREHOUSE_ITEMS_EDIT,
                DrawerState.Create
            ),
        });
    };

    private handleDocumentSaved = (document: DocumentVm) => {
        const { history } = this.props;

        history.push(
            buildRoute(
                Routes.ROUTE_DOCUMENTS,
                Routes.ROUTE_DOCUMENTS_NEW,
                Routes.ROUTE_DOCUMENTS_READ,
                Routes.ROUTE_DOCUMENTS_EDIT,
                DrawerState.Closed,
                document.id.toString()
            )
        );
    };

    private handleSaveSuccess = async (_: WarehouseItemVm) => {
        this.handleDrawerClose();
        this.tableRef.current.getWarehouseItems(defaultTablePagination);
    };

    private mapToDocumentItems = (warehouseItems?: WarehouseItemVm[]): DocumentItemVm[] => {
        if (!warehouseItems) return [];

        let documentItemArray: DocumentItemVm[] = [];
        warehouseItems.forEach((value) => {
            documentItemArray.push(
                DocumentItemVm.fromJS({ ...value, id: 0, warehouseItemId: value.id })
            );
        });

        return documentItemArray;
    };

    public render(): React.ReactElement {
        const { allowCreate, editingWarehouseItem, drawerState, selectedItems, newTypeId } =
            this.state;

        return (
            <>
                <PageHeader
                    title={translations.warehouseItems.items}
                    extra={
                        allowCreate
                            ? [
                                  <Dropdown
                                      trigger={['click']}
                                      key="generate"
                                      disabled={!selectedItems?.length}
                                      className="no-print"
                                      menu={{
                                          items: getPossibleChildrenTypesForWarehouseItems().map(
                                              (typeId: DocumentTypeEnum) => ({
                                                  label: getDocumentTypeTranslations(typeId)
                                                      .generate,
                                                  key: typeId,
                                                  icon: <PlusOutlined />,
                                              })
                                          ),
                                          onClick: this.handleGenerateDocument,
                                      }}
                                  >
                                      <Button type="primary" icon={<PlusOutlined />}>
                                          {translations.general.generate} <DownOutlined />
                                      </Button>
                                  </Dropdown>,

                                  <Button
                                      key="1"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      onClick={() => window.print()}
                                      className="no-print margin-right-10"
                                  >
                                      <PrinterOutlined />
                                      {translations.general.print}
                                  </Button>,
                              ]
                            : []
                    }
                />

                <WarehouseItemTable
                    onSelectionChange={this.handleItemSelectionChange}
                    wrappedComponentRef={this.tableRef}
                    selectedItemsKeys={selectedItems?.map((value) => value.id.toString())}
                />

                <Drawer
                    title={
                        newTypeId
                            ? getDocumentTypeTranslations(newTypeId).generate
                            : editingWarehouseItem
                            ? translations.warehouseItems.editWarehouseItem
                            : translations.warehouseItems.addWarehouseItem
                    }
                    open={!!drawerState}
                    onClose={this.handleDrawerClose}
                    width={newTypeId ? DRAWER_WIDTH_MEDIUM : DRAWER_WIDTH}
                    destroyOnClose
                >
                    {newTypeId ? (
                        <DocumentForm
                            typeId={newTypeId}
                            warehouseItems={selectedItems}
                            onClose={() => this.handleDrawerClose()}
                            onSuccess={this.handleDocumentSaved}
                        />
                    ) : (
                        <WarehouseItemForm
                            onClose={this.handleDrawerClose}
                            onSuccess={(project: WarehouseItemVm) =>
                                this.handleSaveSuccess(project)
                            }
                            warehouseItem={editingWarehouseItem}
                        />
                    )}
                </Drawer>
            </>
        );
    }
}

export default WarehouseItems;
