import { Select } from 'antd';
import React from 'react';
import { DropdownOption } from '../../core/models/DropdownOption';

const { Option } = Select;

export interface Props {
    getOptions?: (value: string) => Promise<DropdownOption[]>;
    getOptionsFrontend?: (value: string) => DropdownOption[];
    placeholder: React.ReactNode;
    confirmDirty?: (option: DropdownOption[], moduleId?: string, isModule?: boolean) => void;
    style?: React.CSSProperties;
    initialValues?: DropdownOption[];
    disabled?: boolean;
    mode?: 'multiple' | 'tags';
    className?: string;
    optionClassName?: string;
    prefixClassName?: string;
    frontendId?: string;
    isAllowedToClear?: boolean;
    filteredOptions?: string;
    isInitialFocused?: boolean;
    objectDisplayKey?: keyof DropdownOption;
    numberOfCharMinSearch?: number;
    minDropdownWidth?: number;
}

interface State {
    autocompleteOptions: DropdownOption[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timeout: any;
let currentValue: string;

function fetch(
    value: string,
    getOptions: (value: string) => Promise<DropdownOption[]>,
    callback: (data: DropdownOption[]) => void
) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    async function fake() {
        const data = await getOptions(value);
        if (currentValue === value) {
            callback(data);
        }
    }

    timeout = setTimeout(fake, 300);
}

/*
 * Custom Autocomplete Dropdown for cases when antd-form one isn't appropriate
 * and when we want more control over it's behaviour.
 */
class AutocompleteDropdown extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            autocompleteOptions: [],
        };
    }

    public componentDidMount = () => {
        const { initialValues } = this.props;

        this.handleSearch('');
        if (initialValues) {
            this.setState({
                autocompleteOptions: [...initialValues],
            });
        }
    };

    public componentDidUpdate = (prevProps: Props) => {
        const { initialValues } = this.props;
        if (prevProps.initialValues !== initialValues) {
            this.handleSearch('');
            //if (initialValues) {
            //    this.setState({
            //        autocompleteOptions: [...initialValues],
            //    });
            //} else {
            //    this.setState({
            //        autocompleteOptions: [],
            //    });
            //}
        }
    };

    private handleOnChange = (values: string[] | string | number) => {
        const { confirmDirty } = this.props;

        const options: DropdownOption[] = [];

        if (values) {
            // single choice mode
            if (typeof values === 'string' || typeof values === 'number') {
                this.addToOptionsList(values.toString(), options);
                // multiple select mode
            } else {
                values.forEach((value: string) => {
                    this.addToOptionsList(value.toString(), options);
                });
            }
        }

        if (confirmDirty) {
            confirmDirty(options);
        }
    };

    private addToOptionsList = (value: string, options: DropdownOption[]) => {
        const { autocompleteOptions } = this.state;
        const { initialValues, frontendId } = this.props;

        let option = autocompleteOptions.find(
            (d: DropdownOption): boolean => d.id.toString() === value
        );

        if (!option && initialValues) {
            option = initialValues.find((d: DropdownOption): boolean => d.id.toString() === value);
        }

        if (option) {
            options.push({ ...option });
        } else if (frontendId) {
            options.push({
                id: frontendId,
                name: value,
            });
        }
    };

    private handleSearch = (value?: string) => {
        const { getOptions, getOptionsFrontend, numberOfCharMinSearch } = this.props;

        // frontend filtering
        if (getOptionsFrontend) {
            if (numberOfCharMinSearch && value && value.length >= numberOfCharMinSearch) {
                this.setState({
                    autocompleteOptions: getOptionsFrontend(value || ''),
                });
            } else if (!numberOfCharMinSearch) {
                this.setState({
                    autocompleteOptions: getOptionsFrontend(value || ''),
                });
            }
            // BE filtering
        } else if (
            numberOfCharMinSearch &&
            value &&
            value.length >= numberOfCharMinSearch &&
            getOptions
        ) {
            fetch(value, getOptions, (data: DropdownOption[]) =>
                this.setState({
                    autocompleteOptions: data ? [...data] : [],
                })
            );
        } else if (!numberOfCharMinSearch && value && value.length >= 2 && getOptions) {
            fetch(value, getOptions, (data: DropdownOption[]) =>
                this.setState({
                    autocompleteOptions: data ? [...data] : [],
                })
            );
        }
    };

    public render(): React.ReactElement {
        const { autocompleteOptions } = this.state;
        const {
            placeholder,
            style,
            initialValues,
            disabled,
            mode,
            className,
            prefixClassName,
            optionClassName,
            isAllowedToClear,
            filteredOptions,
            isInitialFocused,
            objectDisplayKey,
            minDropdownWidth,
        } = this.props;

        const options = autocompleteOptions
            ? autocompleteOptions.map((d: DropdownOption): React.ReactElement => {
                  const selectedValue =
                      initialValues && initialValues.find((iv): boolean => iv.id === d.id);
                  const disabledValue = selectedValue !== undefined || false;
                  let displayValue = d.name;
                  if (objectDisplayKey && d[objectDisplayKey]) {
                      displayValue = d[objectDisplayKey]!.toString();
                  }
                  return (
                      <Option
                          value={d.id}
                          key={`addProductToGroup-${d.id}`}
                          style={{
                              display: disabledValue ? 'none' : 'block',
                          }}
                          className={optionClassName}
                          disabled={d.disabled}
                          title={d.descriptionalMessage || displayValue}
                      >
                          {displayValue}
                      </Option>
                  );
              })
            : [];

        return (
            <Select
                ref={(input: any): void | false => isInitialFocused && input && input.focus()}
                showSearch
                prefixCls={prefixClassName}
                className={className}
                optionFilterProp="children"
                filterOption={filteredOptions ? undefined : true}
                placeholder={placeholder}
                mode={mode}
                onSearch={this.handleSearch}
                onFocus={() => this.handleSearch('')}
                onChange={this.handleOnChange}
                notFoundContent="There are no selectable options."
                defaultActiveFirstOption={false}
                value={
                    initialValues &&
                    initialValues.map((d: DropdownOption): string => d.id.toString())
                }
                style={style}
                disabled={disabled}
                allowClear={isAllowedToClear || false}
                dropdownStyle={{ minWidth: minDropdownWidth }}
            >
                {options}
            </Select>
        );
    }
}

export default AutocompleteDropdown;
