import { Form, DatePicker, Input} from 'antd';
import { Moment } from 'moment';
import React from 'react';
import { Props } from '.';
import DrawerButtons from '../../../components/drawer-buttons';
import { translations } from '../../../config/translations';
import { showError, showSuccess } from '../../../helpers/NotificationHelper';
import { WorkHourRecordsClient, ApiException, WorkHourRecordVm } from '../../../utils/api';

interface State {
    isSaving: boolean;
}

class WorkHourRecordsCopyForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
        };
    }

    private handleSubmit = async (values: any) => {
        const {projectId} = this.props;

        this.setState({
            isSaving: true,
        });

        const request: any = {
            projectId: projectId,
            originDate: values.originDate,
            destinationDate: values.destinationDate,
        };

        if (request.projectId && request.originDate.format("DD.MM.YYYY.") !== request.destinationDate.format("DD.MM.YYYY.")) {
            const client = new WorkHourRecordsClient();
            try {
                const result = await client.copyBatch(request);

                this.handleSuccess(result);
            } catch (error) {
                if (error instanceof ApiException) {
                    showError(error.response);
                } else {
                    showError(translations.general.errorSavingData);
                }
            }
        } else {
            showError(translations.general.sameDatesInvalid);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (result: WorkHourRecordVm[]) => {
        const { onSuccess } = this.props;

        showSuccess(translations.workHourRecods.successfullyCopied);
        
        onSuccess(result);
    };

    public render(): React.ReactElement {
        const { onClose, hasEditDeadlineExpired, projectName } = this.props;
        const { isSaving } = this.state;

        return (
            <Form onFinish={this.handleSubmit} style={{ textAlign: 'end' }} title={projectName}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />

                <Form.Item label={translations.workHourRecods.form.project}>
                    <Input disabled value={projectName}></Input>
                </Form.Item>

                <Form.Item
                    name="originDate"
                    label={translations.workHourRecods.startingDate}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <DatePicker
                    placeholder={
                            translations.workHourRecods.startingDate
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="destinationDate"
                    label={translations.workHourRecods.destinationDate}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <DatePicker
                    disabledDate={(curr: Moment)=> hasEditDeadlineExpired(curr.month(), curr.year())}
                        placeholder={
                            translations.workHourRecods.destinationDate
                        }
                    />
                </Form.Item>
            </Form>
        );
    }
}

export default WorkHourRecordsCopyForm;
