import { CompareFn } from 'antd/lib/table/interface';
import moment from 'moment';
import { get } from './UtilityHelper';

export const compare = (a?: string | number, b?: string | number): number => {
    if (!a && !b) return 0;
    if (!b) return 1;
    if (!a) return -1;

    return a > b ? 1 : -1;
};

export const compareBoolean = (a?: boolean, b?: boolean): number => {
    if (a === undefined && b === undefined) return 0;
    if (b === undefined) return 1;
    if (a === undefined) return -1;

    return a === b ? 0 : a ? -1 : 1;
};

export const compareDate = (a?: Date, b?: Date): number => {
    if (!a && !b) return 0;
    if (!b) return 1;
    if (!a) return -1;

    return moment(a).isSameOrAfter(b) ? 1 : -1;
};

/**
 * Returns a string compare function.
 *
 * @param path - The path of the property
 * @returns A string compare function for property specified by the path
 */
export const getDefaultComparer = (path: string): CompareFn<any> => {
    return (a: any, b: any) => compare(get(a, path)?.toLowerCase(), get(b, path)?.toLowerCase());
};

/**
 * Returns a number compare function.
 *
 * @param path - The path of the property
 * @returns A number compare function for property specified by the path
 */
export const getNumberComparer = (path: string): CompareFn<any> => {
    return (a: any, b: any) => compare(get(a, path), get(b, path));
};

/**
 * Returns a boolean compare function.
 *
 * @param path - The path of the property
 * @returns A boolean compare function for property specified by the path
 */
export const getBooleanComparer = (path: string): CompareFn<any> => {
    return (a: any, b: any) => compareBoolean(get(a, path), get(b, path));
};

/**
 * Returns a date compare function.
 *
 * @param path - The path of the property
 * @returns A date compare function for property specified by the path
 */
export const getDateComparer = (path: string): CompareFn<any> => {
    return (a: any, b: any) => compareDate(get(a, path), get(b, path));
};
