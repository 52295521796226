import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteParams';
import EmployeeTable from './EmployeeTable';

interface OwnProps {
    wrappedComponentRef?: any;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(EmployeeTable);
