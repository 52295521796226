import { EditOutlined } from '@ant-design/icons';
import { PageHeader, Select, Table, TablePaginationConfig } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { Link } from 'react-router-dom';
import TableActionMenu from '../../../components/table-action-menu/TableActionMenu';
import { defaultTablePagination } from '../../../config/constants';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { TableActionProps } from '../../../core/models/TableAction';
import { getSearchFilter } from '../../../helpers/FilterHelper';
import { formatPrice } from '../../../helpers/PriceHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import {
    WarehouseClient,
    WarehouseItemsClient,
    WarehouseItemVm,
    WarehouseVm,
} from '../../../utils/api';
import { Props } from './index';
import {
    getPagingSortingParams,
    getFiltersParams,
    getFilterParamsString,
    applySearchParams,
} from '../../../helpers/SearchParamsHelper';
import { capitalizeFirstLetter } from '../../../helpers/StringHelper';

export interface WarehouseItemFilter {
    projectId: number;
}

export interface WarehouseItemParams {
    projectId: number;
}

interface State {
    warehouseItems?: WarehouseItemVm[];
    loading?: boolean;
    warehouses?: WarehouseVm[];
    totalItems?: number;
}

const DEFAULT_FILTER: WarehouseItemFilter = {
    projectId: 0,
};

class WarehouseItemTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            warehouseItems: [],
            warehouses: [],
            totalItems: 0,
        }
    }

    public componentDidMount = () => {
        this.getWarehouseItems();
        this.getWarehouses();
    };

    public componentDidUpdate = (prevProps: Props) => {
        const {
            location: { search },
        } = this.props;

        if (prevProps.location.search !== search) {
            this.getWarehouseItems();
            this.getWarehouses();
        }
    };

    private handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<WarehouseItemVm> | SorterResult<WarehouseItemVm>[] = {}
    ) => {
        const {
            history,
            location: { search },
        } = this.props;

        const currSearchParams = new URLSearchParams(search);
        const tableSearchParams = this.createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    private createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<WarehouseItemVm> | SorterResult<WarehouseItemVm>[] = {}
    ): URLSearchParams => {
        const { projectId } = this.formatParamsFromUrl();
        const filterParamsString = getFilterParamsString(filters);
        const singleSorter = sorter as SorterResult<WarehouseItemVm>;
        const tableSearchParams = new URLSearchParams(filterParamsString);

        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );
        tableSearchParams.append('sortBy', singleSorter.field?.toString() && singleSorter.order ? singleSorter.field?.toString() : '');
        tableSearchParams.append('sortOrder', singleSorter.order || '');
        tableSearchParams.append('projectId', projectId.toString());

        return tableSearchParams;
    };

    private formatParamsFromUrl = (): WarehouseItemParams => {
        const {
            location: { search },
        } = this.props;

        const params = new URLSearchParams(search);
        const projectId = params.get('projectId')
            ? params.get('projectId')
            : DEFAULT_FILTER.projectId;

        const documentParams: WarehouseItemParams = {
            projectId: projectId ? Number(projectId) : DEFAULT_FILTER.projectId,
        };
        return documentParams;
    };

    private getWarehouses = async () => {
        this.setState({ warehouses: await new WarehouseClient().getAll() });
    };

    private getWarehouseItems = async () => {
        const { projectId } = this.formatParamsFromUrl();

        this.setState({
            loading: true,
        });
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { pageIndexParam, pageSizeParam, sortByParam, sortOrderParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        const result = await new WarehouseItemsClient().getPaginated(
            projectId ? projectId : null,
            filters.name ? filters.name.toString() : null,
            filters.supplier_name ? filters.supplier_name.toString() : null,
            pageIndexParam && parseInt(pageIndexParam) ? parseInt(pageIndexParam) : 1,
            pageSizeParam && parseInt(pageSizeParam)
                ? parseInt(pageSizeParam)
                : defaultTablePagination.defaultPageSize,
            sortByParam ? capitalizeFirstLetter(sortByParam) : undefined,
            sortOrderParam
        );

        this.setState((prevState: State) => ({
            warehouseItems: result.pageItems,
            loading: false,
            totalItems: result.totalItems
        }));
    };

    private handleWarehouseChange = (warehouseId: number) => {
        const { history, location } = this.props;
        const params = new URLSearchParams(location.search);

        if (params.has('projectId')) {
            params.set('projectId', warehouseId.toString());
        } else {
            params.append('projectId', warehouseId.toString());
        }

        history.push({
            pathname: history.location.pathname,
            search: params.toString(),
        });

        this.getWarehouseItems();
        this.getWarehouses();
    };

    private handleEdit = (warehouseItemId: number) => {
        const { history, onSelectionChange } = this.props;

        if (onSelectionChange) {
            onSelectionChange([]);
        }

        history.push(
            Routes.ROUTE_WAREHOUSE_ITEMS_EDIT.replace(
                ReplaceStrings.ENTITY_ID,
                warehouseItemId.toString()
            )
        );
    };

    public render(): React.ReactElement {
        const { onSelectionChange, selectedItemsKeys, location } = this.props;
        const { loading, warehouseItems, warehouses, totalItems } = this.state;
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam, pageIndexParam, pageSizeParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);
        const { projectId } = this.formatParamsFromUrl();
        const pagination: TablePaginationConfig = {
            ...defaultTablePagination,
            current:
                pageIndexParam && parseInt(pageIndexParam)
                    ? parseInt(pageIndexParam)
                    : defaultTablePagination.defaultCurrent,
            pageSize:
                pageSizeParam && parseInt(pageSizeParam)
                    ? parseInt(pageSizeParam)
                    : defaultTablePagination.defaultPageSize,
            total: totalItems,
        };

        const actionsContent = (value: WarehouseItemVm): TableActionProps[] => [
            {
                id: 1,
                entity: value,
                label: translations.general.edit,
                onClick: () => this.handleEdit(value.id),
                menuItemProps: {
                    icon: <EditOutlined />,
                },
            },
        ];

        const columns: ColumnProps<WarehouseItemVm>[] = [
            {
                title: translations.warehouseItems.item,
                dataIndex: 'name',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.name ? [filters.name] : null,
                sortOrder: sortByParam === 'name' ? sortOrderParam : null,
            },
            {
                title: translations.projects.project,
                dataIndex: 'warehouse.project.name',
                sorter: true,
                sortOrder: sortByParam === 'warehouse.project.name' ? sortOrderParam : null,
                render: (_: string, item: WarehouseItemVm) => item.projectName,
            },
            {
                title: translations.warehouseItems.sourceDocument,
                dataIndex: 'sourceDocumentItemDocumentCode',
                render: (_: string | undefined, record: WarehouseItemVm) =>
                    record.sourceDocumentItemDocumentId ? (
                        <Link
                            to={{
                                pathname: Routes.ROUTE_DOCUMENTS_READ.replace(
                                    ReplaceStrings.ENTITY_ID,
                                    record.sourceDocumentItemDocumentId.toString()
                                ),
                                search: `?typeId=${record.sourceDocumentItemDocumentTypeId}`,
                            }}
                        >
                            {record.sourceDocumentItemDocumentCode}
                        </Link>
                    ) : null,
            },
            {
                title: translations.documents.supplier,
                dataIndex: 'supplier_name',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.supplier_name ? [filters.supplier_name] : null,
                sortOrder: sortByParam === 'supplier_name' ? sortOrderParam : null,
                render: (_: string, item: WarehouseItemVm) => item.supplier,
            },
            {
                title: translations.warehouseItems.unit,
                key: 'unitOfMeasurement',
                render: (item: WarehouseItemVm) => item.unitOfMeasurement?.shortName,
            },
            {
                title: translations.warehouseItems.quantity,
                dataIndex: 'quantity',
                sorter: true,
                sortOrder: sortByParam === 'quantity' ? sortOrderParam : null,
                align: 'right',
                render: (value?: string) => (value ? formatPrice(value) : '0,00'),
            },
            {
                title: translations.warehouseItems.unitPrice,
                dataIndex: 'unitPrice',
                sorter: true,
                sortOrder: sortByParam === 'unitPrice' ? sortOrderParam : null,
                align: 'right',
                render: (value?: string) => (value ? formatPrice(value) : '0,00'),
            },
            {
                title: translations.warehouseItems.totalPrice,
                dataIndex: 'totalPrice',
                sorter: true,
                sortOrder: sortByParam === 'totalPrice' ? sortOrderParam : null,
                align: 'right',
                render: (value?: string) => (value ? formatPrice(value) : '0,00'),
            },
            {
                title: translations.warehouseItems.description,
                dataIndex: 'description',
                sorter: true,
                sortOrder: sortByParam === 'description' ? sortOrderParam : null,
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 100,
                align: 'center',
                className: 'no-print',
                render: (value: WarehouseItemVm): React.ReactElement => (
                    <TableActionMenu tableActions={actionsContent(value)} />
                ),
            },
        ];

        return (
            <>
                <PageHeader
                    title={
                        <Select
                            value={projectId}
                            onChange={this.handleWarehouseChange}
                            style={{ width: 300 }}
                            showSearch
                            optionFilterProp="children"
                        >
                            <Select.Option value={0}>
                                {translations.warehouseItems.allWarehouses}
                            </Select.Option>

                            {warehouses &&
                                warehouses.map((p: WarehouseVm) => (
                                    <Select.Option key={p.id} value={p.id}>
                                        {p.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    }
                />

                <Table
                    locale={getTableLocale()}
                    columns={columns}
                    dataSource={warehouseItems}
                    loading={loading}
                    rowKey={(record: WarehouseItemVm): string => record.id.toString()}
                    rowSelection={
                        onSelectionChange
                            ? {
                                  onChange: (_: any, selectedRows: WarehouseItemVm[]) => {
                                      onSelectionChange(selectedRows);
                                  },
                                  getCheckboxProps: (record: WarehouseItemVm) => ({
                                      disabled: record.name === 'Disabled Warehouse Item', // Column configuration not to be checked
                                      name: record.name,
                                  }),
                                  ...(selectedItemsKeys && { selectedRowKeys: selectedItemsKeys }),
                              }
                            : undefined
                    }
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    bordered
                    size="small"
                />
            </>
        );
    }
}

export default WarehouseItemTable;
