import { SalaryVm } from '../../../utils/api';
import SalariesCopyForm from './SalariesCopyForm';

interface OwnProps {
    selectedMonth: number;
    selectedYear: number;
    selectedCompanyId: number | undefined;
    onClose: () => void;
    onSuccess: (records: SalaryVm[]) => void;
}

export type Props = OwnProps;

export default SalariesCopyForm;
