import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, PageHeader, Skeleton } from 'antd';
import React from 'react';
import { Props } from '.';
import { translations } from '../../../config/translations';
import { ActionType, ModuleName } from '../../../core/models/enum';
import { authorizeAction } from '../../../helpers/CheckPermissionHelper';
import AttachmentTable from '../../../modules/attachments/attachment-table';
import { ProjectHodogramVm } from '../../../utils/api';

interface State {
    allowAddAttachment: boolean;
}

class ProjectHodogramDetail extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            allowAddAttachment: authorizeAction(
                props.userProfile,
                ModuleName.Attachments,
                ActionType.Create,                
                props.selectedHodogram
                    ? (props.selectedHodogram as ProjectHodogramVm).projectId
                    : undefined
            ),
        };
    }

    render(): React.ReactNode {
        const {
            selectedHodogram,
            userProfile,
            onAttachmentUpdate,
            onAttachmentDelete,
            onAttachmentCreate,
            attachments,
        } = this.props;

        const { allowAddAttachment } = this.state;

        return selectedHodogram ? (
            <>
                <div>
                    <div style={{ marginBottom: '3em' }}>
                        <PageHeader style={{ paddingBottom: 0 }} title={selectedHodogram.name} />
                        <p>{selectedHodogram.code}</p>
                    </div>
                    <div>{selectedHodogram.description}</div>
                </div>

                <Divider style={{ margin: '10px 0px' }} />

                <PageHeader
                    title=""
                    extra={
                        allowAddAttachment
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      onClick={onAttachmentCreate}
                                      icon={<PlusOutlined />}
                                      className="no-print"
                                  >
                                      {translations.attachments.add}
                                  </Button>,
                              ]
                            : []
                    }
                />
                <AttachmentTable
                    onUpdate={onAttachmentUpdate}
                    onDelete={onAttachmentDelete}
                    userProfile={userProfile}
                    attachments={attachments}
                />
            </>
        ) : (
            <Skeleton active />
        );
    }
}

export default ProjectHodogramDetail;
