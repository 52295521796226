import { ColumnContentValue } from '../components/column-content-picker';
import { TableEmployeeVm } from '../components/work-hour-records-table/WorkHourRecordsTable';
import { DropdownOption } from '../core/models/DropdownOption';
import { ExcelProjectItemsFields, KeyValueOrder } from '../core/models/ExcelProjectItemsFields';
import { SelectOption } from '../core/models/SelectOption';
import {
    CooperatorsClient,
    CooperatorVm,
    EmployeesClient,
    EmployeeVm,
    ProjectEmployeeVm,
    ProjectItemVm,
    ProjectsClient,
    ProjectVm,
} from '../utils/api';

export interface DropdownEntitiesAndSelected {
    entities: DropdownOption[];
    selectedEntity?: DropdownOption[];
    selectedSecondEntity?: DropdownOption[];
}

export const getEmployeesToDropdownOptions = async (
    employeeId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const employeeClient = new EmployeesClient();
    const employeeVMs = await employeeClient.getAll();

    const employees = employeeVMs.map(
        (e: EmployeeVm): DropdownOption => ({
            id: e && e.id ? e.id.toString() : '-1',
            name: `${e.name} ${e.surname}`,
        })
    );

    const selectedEmployee = employeeId
        ? employees.find((e: DropdownOption): boolean => e.id === employeeId.toString())
        : undefined;

    return {
        entities: employees,
        selectedEntity: selectedEmployee ? [selectedEmployee] : undefined,
    };
};

export const getCooperatorsToDropdownOptions = async (
    cooperatorId?: number,
    associateTypeId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const cooperatorClient = new CooperatorsClient();
    const cooperatorVMs = associateTypeId
        ? await cooperatorClient.getByAssociateType(associateTypeId)
        : await cooperatorClient.getAll();

    const cooperators = cooperatorVMs.map(
        (e: CooperatorVm): DropdownOption => ({
            id: e && e.id ? e.id.toString() : '-1',
            name: `${e.name}`,
        })
    );

    const selectedCooperator = cooperatorId
        ? cooperators.find((e: DropdownOption): boolean => e.id === cooperatorId.toString())
        : undefined;

    return {
        entities: cooperators,
        selectedEntity: selectedCooperator ? [selectedCooperator] : undefined,
    };
};

export const getProjectsToDropdownOptions = async (
    projectId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const projectClient = new ProjectsClient();
    const cooperatorVMs = await projectClient.getAll();

    const projects = cooperatorVMs.map(
        (e: ProjectVm): DropdownOption => ({
            id: e && e.id ? e.id.toString() : '-1',
            name: `${e.name}`,
        })
    );

    const selectedProject = projectId
        ? projects.find((e: DropdownOption): boolean => e.id === projectId.toString())
        : undefined;

    return {
        entities: projects,
        selectedEntity: selectedProject ? [selectedProject] : undefined,
    };
};

export const getExcelProjectItemsFieldsToDropdownOptions = (): DropdownOption[] => {
    return ExcelProjectItemsFields.map(
        (keyValue: KeyValueOrder): DropdownOption => ({
            id: keyValue.key.replace('_', ' '),
            name: keyValue.value.toString(),
        })
    );
};

export const getColumnContentValueForProjectItems = (columnRange: string[]): ColumnContentValue => {
    let updatedValues: ColumnContentValue = { key: '0' };

    const defaultOptions = ExcelProjectItemsFields.filter(
        (kvo: KeyValueOrder): boolean => kvo.order !== undefined
    )
        .sort((x1, x2) => x1.order! - x2.order!)
        .map((keyValue: KeyValueOrder) => keyValue.key);

    columnRange.forEach(function (value, index) {
        updatedValues[value] = defaultOptions[index];
    });

    return updatedValues;
};

export const getColumnContentValueForProjectContractorItems = (
    columnRange: string[]
): ColumnContentValue => {
    let updatedValues: ColumnContentValue = { key: '0' };

    const defaultOptions = ['Quantity', 'UnitPrice'];
    columnRange.forEach(function (value, index) {
        updatedValues[value] = defaultOptions[index];
    });

    return updatedValues;
};

export const getOptionsFromProjectItemsRecursive = (
    projectItems: ProjectItemVm[]
): SelectOption[] => {
    return projectItems.map(
        (item: ProjectItemVm): SelectOption => ({
            label: item.name || '',
            value: item.id,
            children: item.children ? getOptionsFromProjectItemsRecursive(item.children) : [],
        })
    );
};

export const mapProjectEmployeesToTableEmployees = (projectEmployees: ProjectEmployeeVm[]): TableEmployeeVm[] => {
    return projectEmployees.map(
        (pe: ProjectEmployeeVm) => ({
            id: pe.employee.id,
            name: pe.employee.name,
            surname: pe.employee.surname,
            fullName: pe.employee.fullName,
            activeTo: pe.activeTo,
    } as TableEmployeeVm));
};

export const mapEmployeesToTableEmployees = (employees: EmployeeVm[]): TableEmployeeVm[] => {
    return employees.map(
        (em: EmployeeVm) => ({
            id: em.id,
            name: em.name,
            surname: em.surname,
            fullName: em.fullName,
            activeTo: em.activeTo,
    } as TableEmployeeVm));
};
