import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Radio, Space } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import React from 'react';
import { translations } from '../../config/translations';
import { DropdownOption } from '../../core/models/DropdownOption';

interface Props {
    selectedKeys: React.Key[];
    confirm: (param?: FilterConfirmProps) => void;
    setSelectedKeys: (selectedKeys: React.Key[]) => void;
    clearFilters?: () => void;
    options: DropdownOption[];
}

class RadioFilter extends React.Component<Props> {
    public render = (): React.ReactElement => {
        const { selectedKeys, setSelectedKeys, confirm, clearFilters, options } = this.props;

        return (
            <Space direction="vertical" style={{ padding: 8 }}>
                <Radio.Group
                    onChange={(e: RadioChangeEvent) => setSelectedKeys([e.target.value])}
                    value={selectedKeys[0]}
                    className="vertical"
                >
                    {options.map(
                        (o: DropdownOption): React.ReactElement => (
                            <Radio key={o.id} value={o.id}>
                                {o.name}
                            </Radio>
                        )
                    )}
                </Radio.Group>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {translations.general.search}
                    </Button>
                    <Button
                        onClick={() => (clearFilters ? clearFilters() : undefined)}
                        icon={<CloseOutlined />}
                        size="small"
                    >
                        {translations.general.clear}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                        }}
                    >
                        {translations.general.filter}
                    </Button>
                </Space>
            </Space>
        );
    };
}

export default RadioFilter;
