import { Form, Input } from 'antd';
import React from 'react';
import { Props } from '.';
import DrawerButtons from '../../../components/drawer-buttons';
import { formItemLayout724 } from '../../../config/formLayouts';
import { translations } from '../../../config/translations';
import { showSuccess } from '../../../helpers/NotificationHelper';
import { update } from '../../../helpers/SubmitHelper';
import { WarehouseItemsClient, WarehouseItemVm } from '../../../utils/api';

interface State {
    isSaving: boolean;
}

class WarehouseForm extends React.Component<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
        };
    }

    private handleSubmit = (values: any) => {
        const { warehouseItem } = this.props;
        const warehouseItemsClient = new WarehouseItemsClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: warehouseItem?.id,
        };

        if (request.id) {
            update(warehouseItemsClient, request, this.handleSuccess);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (result: WarehouseItemVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.warehouseItems.successfullySaved);

        onSuccess(result);
    };

    public render(): React.ReactElement {
        const { onClose, warehouseItem } = this.props;
        const { isSaving } = this.state;

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout724} ref={this.formRef}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />

                <Form.Item
                    name="name"
                    label={translations.warehouseItems.name}
                    initialValue={warehouseItem ? warehouseItem.name : undefined}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={translations.warehouseItems.description}
                    initialValue={warehouseItem ? warehouseItem.description : undefined}
                >
                    <Input />
                </Form.Item>
            </Form>
        );
    }
}

export default WarehouseForm;
