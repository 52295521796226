import { Profile } from 'oidc-client';
import { ProjectEmployeeVm } from '../../../../../utils/api';
import ProjectEmployeeTable from './ProjectEmployeeTable';

interface OwnProps {
    userProfile: Profile;
    employees?: ProjectEmployeeVm[];
    onUpdate: (id: number) => void;
    onDelete: (id: number) => Promise<void>;
}

export type Props = OwnProps;

export default ProjectEmployeeTable;
