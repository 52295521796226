import { Profile } from 'oidc-client';
import { ActionType, ModuleName } from '../core/models/enum';
import { RoleEnum } from '../utils/api';

export const authorizeAction = (
    userProfile: Profile,
    module: ModuleName,
    action: ActionType,
    projectId?: number,
    employeeId?: number,
    documentId?: number
): boolean => {
    const userRole = parseInt(userProfile['userRoleId']) as RoleEnum;
    const siteManagerProjects = userProfile['siteManagerProjects'] as string[];
    const siteManagerEmployees = userProfile['siteManagerEmployees'] as string[];
    const siteManagerDocuments = userProfile['siteManagerDocuments'] as string[];

    if (!userRole) return false;

    switch (module) {
        case ModuleName.Attachments:
            return authorizeAttachmentsAction(
                userRole,
                action,
                siteManagerProjects,
                siteManagerEmployees,
                siteManagerDocuments,
                projectId,
                employeeId,
                documentId
            );
        case ModuleName.Cooperators:
            return authorizeCooperatorsAction(userRole, action);
        case ModuleName.Dashboard:
            return authorizeDashboardAction(userRole, action);
        case ModuleName.Documents:
            return authorizeDocumentsAction(userRole, action);
        case ModuleName.Employees:
            return authorizeEmployeesAction(userRole, action);
        case ModuleName.ExpenseGroups:
            return authorizeExpenseGroupsAction(userRole, action);
        case ModuleName.ProjectItems:
            return authorizeProjectItemsAction(userRole, action);
        case ModuleName.Projects:
            return authorizeProjectsAction(userRole, action);
        case ModuleName.ProjectEmployees:
            return authorizeProjectEmployeesAction(userRole, action);
        case ModuleName.ProjectCooperators:
            return authorizeProjectCooperatorsAction(userRole, action);
        case ModuleName.ProjectCooperatorItems:
            return authorizeProjectCooperatorItemsAction(userRole, action);
        case ModuleName.ProjectSituations:
            return authorizeProjectSituationsAction(userRole, action);
        case ModuleName.ProjectSituationsItems:
            return authorizeProjectSituationsItemsAction(userRole, action);
        case ModuleName.TimeSheet:
            return authorizeWorkHourRecordsAction(userRole, action);
        case ModuleName.Warehouses:
            return authorizeWarehousesAction(userRole, action);
        case ModuleName.WarehouseItems:
            return checWarehouseItemsAction(userRole, action);
        case ModuleName.NewsRegular:
        case ModuleName.NewsAdmin:
            return authorizeNewsAction(userRole, action);
        case ModuleName.Hodogram:
            return authorizeHodogramAction(userRole, action);
        case ModuleName.ProjectHodogram:
            return authorizeProjectHodogramAction(userRole, action);
        case ModuleName.PeopleSchedule:
            return authorizePeopleScheduleAction(userRole, action);
        case ModuleName.Users:
            return userRole === RoleEnum.SuperAdmin;
        case ModuleName.ProjectItemsOverview:
            return userRole === RoleEnum.SuperAdmin || userRole === RoleEnum.Management;
        case ModuleName.Salaries:
            return (
                process.env.REACT_APP_SHOW_SALARIES === 'true' &&
                (userRole === RoleEnum.Accounting || userRole === RoleEnum.Management)
            );
        case ModuleName.Payrolls:
            return (
                process.env.REACT_APP_SHOW_SALARIES === 'true' &&
                (userRole === RoleEnum.Accounting || userRole === RoleEnum.Management)
            );
        case ModuleName.EmployeeExpenses:
            return (
                process.env.REACT_APP_SHOW_SALARIES === 'true' &&
                (userRole === RoleEnum.Accounting || userRole === RoleEnum.Management)
            );
        default:
            return false;
    }
};

const authorizeHodogramAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeProjectHodogramAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeNewsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeAttachmentsAction = (
    userType: RoleEnum,
    action: ActionType,
    siteManagerProjects: string[],
    siteManagerEmployees: string[],
    siteManagerDocuments: string[],
    projectId?: number,
    employeeId?: number,
    documentId?: number
): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return authorizeEntityAttachmentAction(
                        siteManagerProjects,
                        siteManagerEmployees,
                        siteManagerDocuments,
                        projectId,
                        employeeId,
                        documentId
                    );
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return authorizeEntityAttachmentAction(
                        siteManagerProjects,
                        siteManagerEmployees,
                        siteManagerDocuments,
                        projectId,
                        employeeId,
                        documentId
                    );
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return authorizeEntityAttachmentAction(
                        siteManagerProjects,
                        siteManagerEmployees,
                        siteManagerDocuments,
                        projectId,
                        employeeId,
                        documentId
                    );
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return authorizeEntityAttachmentAction(
                        siteManagerProjects,
                        siteManagerEmployees,
                        siteManagerDocuments,
                        projectId,
                        employeeId,
                        documentId
                    );
                default:
                    return false;
            }
        default:
            return false;
    }
};

export const authorizeEntityAttachmentAction = (
    siteManagerProjects: string[],
    siteManagerEmployees: string[],
    siteManagerDocuments: string[],
    projectId?: number,
    employeeId?: number,
    documentId?: number
): boolean => {
    return true;
};

const authorizeCooperatorsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeDashboardAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            //landing page
            return true;
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeDocumentsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                case RoleEnum.Warehouse:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                case RoleEnum.Warehouse:
                    return true;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                case RoleEnum.Warehouse:
                    return true;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                case RoleEnum.Warehouse:
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeEmployeesAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeExpenseGroupsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeProjectsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeProjectCooperatorsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeProjectEmployeesAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeProjectItemsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeProjectSituationsItemsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeProjectSituationsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeProjectCooperatorItemsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeWorkHourRecordsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizeWarehousesAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                case RoleEnum.Warehouse:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checWarehouseItemsAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Warehouse:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const authorizePeopleScheduleAction = (userType: RoleEnum, action: ActionType): boolean => {
    switch (action) {
        case ActionType.Read:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        case ActionType.Create:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        case ActionType.Update:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        case ActionType.Delete:
            switch (userType) {
                case RoleEnum.SuperAdmin:
                    return true;
                case RoleEnum.Admin:
                    return true;
                case RoleEnum.Accounting:
                    return true;
                case RoleEnum.Management:
                    return true;
                case RoleEnum.SiteManager:
                    return true;
                case RoleEnum.Procurement:
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
};
