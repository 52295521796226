import {
    CarryOutOutlined,
    ContactsOutlined,
    ContainerOutlined,
    HomeOutlined,
    SolutionOutlined,
    TeamOutlined,
    GroupOutlined,
    UnorderedListOutlined,
    DatabaseOutlined,
    SettingOutlined,
    FileTextOutlined,
    ProfileOutlined,
    CheckSquareOutlined,
    ScheduleOutlined,
    FormOutlined,
    ExportOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Routes from '../config/routes';
import { translations, documents } from '../config/translations';
import { ModuleName } from '../core/models/enum';
import { getDocumentTypeTranslations } from '../helpers/DocumentHelper';
import { getRootPathname } from '../helpers/RoutingHelper';
import { DocumentTypeEnum, RoleEnum } from '../utils/api';
import authService from './api-authorization/AuthorizeService';

interface State {
    selectedUserRole?: RoleEnum;
    externalMenuLinks: [MenuLink];
}

interface MenuLink {
    label: string;
    url: string;
}

class SideMenu extends React.Component<RouteComponentProps<{}>, State> {
    _subscription: any;

    constructor(props: RouteComponentProps<{}>) {
        super(props);

        let externalMenuLinks = [];

        if (process.env.REACT_APP_EXTERNAL_MENU_LINKS) {
            externalMenuLinks = JSON.parse(process.env.REACT_APP_EXTERNAL_MENU_LINKS);
        }
        this.state = { externalMenuLinks };
    }

    componentDidMount = async () => {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    };

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateAuthenticationState() {
        var userProfile = await authService.getUserProfile();
        const userRoleId = userProfile['userRoleId'];

        if (userRoleId) {
            this.setState({ selectedUserRole: parseInt(userRoleId, 10) });
        }
    }

    async authenticationChanged() {
        await this.populateAuthenticationState();
    }

    getCurrentModuleKey = () => {
        const { location } = this.props;

        const module = getRootPathname(location.pathname) as ModuleName;

        if (module === ModuleName.Documents) {
            return module + location.search;
        }

        return module;
    };

    render() {
        const { selectedUserRole, externalMenuLinks } = this.state;

        return (
            <Menu
                className="side-menu"
                theme="dark"
                mode="inline"
                selectedKeys={[this.getCurrentModuleKey()]}
            >
                <Menu.Item key={ModuleName.Dashboard} icon={<HomeOutlined />}>
                    <Link to={Routes.ROUTE_DASHBOARD}>{translations.dashboard.dashboard}</Link>
                </Menu.Item>

                <Menu.Item key={ModuleName.NewsRegular} icon={<ProfileOutlined />}>
                    <Link to={Routes.ROUTE_NEWS}>{translations.news.news}</Link>
                </Menu.Item>

                <Menu.Item key={ModuleName.Projects} icon={<ContainerOutlined />}>
                    <Link to={Routes.ROUTE_PROJECTS}>{translations.projects.projects}</Link>
                </Menu.Item>

                <Menu.Item key={ModuleName.Cooperators} icon={<ContactsOutlined />}>
                    <Link to={Routes.ROUTE_COOPERATORS}>
                        {translations.cooperators.cooperators}
                    </Link>
                </Menu.Item>

                <Menu.SubMenu
                    key="documents"
                    icon={<FileTextOutlined />}
                    title={documents.documents.title}
                >
                    <Menu.Item
                        key={ModuleName.Documents + `?typeId=${DocumentTypeEnum.RequisitionOrder}`}
                        icon={<FileTextOutlined />}
                    >
                        <Link
                            to={{
                                pathname: Routes.ROUTE_DOCUMENTS,
                                search: `?typeId=${DocumentTypeEnum.RequisitionOrder}`,
                            }}
                        >
                            {getDocumentTypeTranslations(DocumentTypeEnum.RequisitionOrder).title}
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key={ModuleName.Documents + `?typeId=${DocumentTypeEnum.PurchaseOrder}`}
                        icon={<FileTextOutlined />}
                    >
                        <Link
                            to={{
                                pathname: Routes.ROUTE_DOCUMENTS,
                                search: `?typeId=${DocumentTypeEnum.PurchaseOrder}`,
                            }}
                        >
                            {getDocumentTypeTranslations(DocumentTypeEnum.PurchaseOrder).title}
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key={ModuleName.Documents + `?typeId=${DocumentTypeEnum.Order}`}
                        icon={<FileTextOutlined />}
                    >
                        <Link
                            to={{
                                pathname: Routes.ROUTE_DOCUMENTS,
                                search: `?typeId=${DocumentTypeEnum.Order}`,
                            }}
                        >
                            {getDocumentTypeTranslations(DocumentTypeEnum.Order).title}
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key={
                            ModuleName.Documents +
                            `?typeId=${DocumentTypeEnum.PurchaseReceivedNote}`
                        }
                        icon={<FileTextOutlined />}
                    >
                        <Link
                            to={{
                                pathname: Routes.ROUTE_DOCUMENTS,
                                search: `?typeId=${DocumentTypeEnum.PurchaseReceivedNote}`,
                            }}
                        >
                            {
                                getDocumentTypeTranslations(DocumentTypeEnum.PurchaseReceivedNote)
                                    .title
                            }
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key={ModuleName.Documents + `?typeId=${DocumentTypeEnum.TransferNote}`}
                        icon={<FileTextOutlined />}
                    >
                        <Link
                            to={{
                                pathname: Routes.ROUTE_DOCUMENTS,
                                search: `?typeId=${DocumentTypeEnum.TransferNote}`,
                            }}
                        >
                            {getDocumentTypeTranslations(DocumentTypeEnum.TransferNote).title}
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key={
                            ModuleName.Documents +
                            `?typeId=${DocumentTypeEnum.TransferReceivedNote}`
                        }
                        icon={<FileTextOutlined />}
                    >
                        <Link
                            to={{
                                pathname: Routes.ROUTE_DOCUMENTS,
                                search: `?typeId=${DocumentTypeEnum.TransferReceivedNote}`,
                            }}
                        >
                            {
                                getDocumentTypeTranslations(DocumentTypeEnum.TransferReceivedNote)
                                    .title
                            }
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key={ModuleName.Documents + `?typeId=${DocumentTypeEnum.ConsumptionNote}`}
                        icon={<FileTextOutlined />}
                    >
                        <Link
                            to={{
                                pathname: Routes.ROUTE_DOCUMENTS,
                                search: `?typeId=${DocumentTypeEnum.ConsumptionNote}`,
                            }}
                        >
                            {getDocumentTypeTranslations(DocumentTypeEnum.ConsumptionNote).title}
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key={ModuleName.Documents + `?typeId=${DocumentTypeEnum.WriteOffNote}`}
                        icon={<FileTextOutlined />}
                    >
                        <Link
                            to={{
                                pathname: Routes.ROUTE_DOCUMENTS,
                                search: `?typeId=${DocumentTypeEnum.WriteOffNote}`,
                            }}
                        >
                            {getDocumentTypeTranslations(DocumentTypeEnum.WriteOffNote).title}
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key={ModuleName.Documents + `?typeId=${DocumentTypeEnum.Liquidation}`}
                        icon={<FileTextOutlined />}
                    >
                        <Link
                            to={{
                                pathname: Routes.ROUTE_DOCUMENTS,
                                search: `?typeId=${DocumentTypeEnum.Liquidation}`,
                            }}
                        >
                            {getDocumentTypeTranslations(DocumentTypeEnum.Liquidation).title}
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>

                <Menu.SubMenu
                    key="humanResources"
                    icon={<SolutionOutlined />}
                    title={translations.employees.humanResources}
                >
                    <Menu.Item key={ModuleName.Employees} icon={<UnorderedListOutlined />}>
                        <Link to={Routes.ROUTE_EMPLOYEES}>{translations.employees.employees}</Link>
                    </Menu.Item>

                    <Menu.Item key={ModuleName.TimeSheet} icon={<CarryOutOutlined />}>
                        <Link to={Routes.ROUTE_WORK_HOUR_RECORDS}>
                            {translations.workHourRecods.title}
                        </Link>
                    </Menu.Item>

                    <Menu.Item key={ModuleName.PeopleSchedule} icon={<ScheduleOutlined />}>
                        <Link to={Routes.ROUTE_PEOPLE_SCHEDULE}>
                            {translations.peopleSchedule.title}
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>

                <Menu.SubMenu
                    key="warehousesGroup"
                    icon={<DatabaseOutlined />}
                    title={translations.warehouses.warehouses}
                >
                    <Menu.Item key={ModuleName.Warehouses} icon={<DatabaseOutlined />}>
                        <Link to={Routes.ROUTE_WAREHOUSES}>
                            {translations.warehouses.warehouses}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={ModuleName.WarehouseItems} icon={<DatabaseOutlined />}>
                        <Link to={Routes.ROUTE_WAREHOUSE_ITEMS}>
                            {translations.warehouseItems.items}
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>

                {(selectedUserRole === RoleEnum.SuperAdmin ||
                    selectedUserRole === RoleEnum.Admin ||
                    selectedUserRole === RoleEnum.Management ||
                    selectedUserRole === RoleEnum.Accounting) && (
                    <Menu.SubMenu
                        key="administration"
                        icon={<SettingOutlined />}
                        title={translations.administration.administration}
                    >
                        {selectedUserRole === RoleEnum.SuperAdmin && (
                            <Menu.Item key={ModuleName.Users} icon={<TeamOutlined />}>
                                <Link to={Routes.ROUTE_USERS}>{translations.users.users}</Link>
                            </Menu.Item>
                        )}

                        <Menu.Item key={ModuleName.Hodogram} icon={<CheckSquareOutlined />}>
                            <Link to={Routes.ROUTE_HODOGRAM}>{translations.hodogram.hodogram}</Link>
                        </Menu.Item>

                        <Menu.Item key={ModuleName.NewsAdmin} icon={<ProfileOutlined />}>
                            <Link to={Routes.ROUTE_NEWS_ADMIN}>{translations.news.news}</Link>
                        </Menu.Item>

                        <Menu.Item key={ModuleName.ExpenseGroups} icon={<GroupOutlined />}>
                            <Link to={Routes.ROUTE_EXPENSE_GROUPS}>
                                {translations.administration.expenseGroups.title}
                            </Link>
                        </Menu.Item>

                        {(selectedUserRole === RoleEnum.SuperAdmin ||
                            selectedUserRole === RoleEnum.Management) && (
                            <Menu.Item
                                key={ModuleName.ProjectItemsOverview}
                                icon={<FormOutlined />}
                            >
                                <Link to={Routes.ROUTE_PROJECT_ITEMS_OVERVIEW}>
                                    {translations.administration.projectItems.title}
                                </Link>
                            </Menu.Item>
                        )}

                        {process.env.REACT_APP_SHOW_SALARIES === 'true' && (
                            <>
                                {(selectedUserRole === RoleEnum.Management ||
                                    selectedUserRole === RoleEnum.Accounting) && (
                                    <>
                                        <Menu.Item
                                            key={ModuleName.Salaries}
                                            icon={<FormOutlined />}
                                        >
                                            <Link to={Routes.ROUTE_SALARIES}>
                                                {translations.salaries.salaries}
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item
                                            key={ModuleName.Payrolls}
                                            icon={<FormOutlined />}
                                        >
                                            <Link to={Routes.ROUTE_PAYROLLS}>
                                                {translations.payrolls.payrolls}
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item
                                            key={ModuleName.EmployeeExpenses}
                                            icon={<FormOutlined />}
                                        >
                                            <Link to={Routes.ROUTE_EMPLOYE_EXPENSES}>
                                                {translations.employeeExpenses.employeeExpenses}
                                            </Link>
                                        </Menu.Item>
                                    </>
                                )}
                            </>
                        )}
                    </Menu.SubMenu>
                )}

                {externalMenuLinks.map((link, index) => (
                    <Menu.Item key={index} icon={<ExportOutlined />}>
                        <a href={link.url} target="_blank" rel="noreferrer">
                            {link.label}
                        </a>
                    </Menu.Item>
                ))}
            </Menu>
        );
    }
}

export default withRouter((props: any) => <SideMenu {...props} />);
