import { DatePicker, Form, Input } from 'antd';
import React from 'react';
import moment from 'moment';
import { Props } from '.';
import DrawerButtons from '../../../../../components/drawer-buttons';
import { defaultFormat } from '../../../../../config/constants';
import { formItemLayout724 } from '../../../../../config/formLayouts';
import { translations } from '../../../../../config/translations';
import { showError } from '../../../../../helpers/NotificationHelper';
import {
    ApiException,
    CreateProjectSituationCommandOfProjectSituation,
    ProjectSituationsClient,
    UpdateProjectSituationCommandOfProjectSituation,
} from '../../../../../utils/api';

interface State {
    isSaving: boolean;
}

class ProjectSituationForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
        };
    }

    private handleSubmit = async (values: any) => {
        const { projectId, situation, onSuccess } = this.props;

        this.setState({
            isSaving: true,
        });

        try {
            const result = situation?.id
                ? await new ProjectSituationsClient().update(
                      situation.id,
                      new UpdateProjectSituationCommandOfProjectSituation({
                          ...values,
                          id: situation.id,
                          projectId,
                      })
                  )
                : await new ProjectSituationsClient().create(
                      new CreateProjectSituationCommandOfProjectSituation({
                          ...values,
                          projectId,
                      })
                  );

            onSuccess(result);
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    };

    public render(): React.ReactElement {
        const { situation, onClose } = this.props;
        const { isSaving } = this.state;

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout724}>
                <DrawerButtons isSaving={isSaving} onCancelAction={() => onClose()} />

                <Form.Item
                    name="name"
                    label={translations.projects.situations.name}
                    initialValue={situation ? situation.name : undefined}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="activeFrom"
                    label={translations.projects.situations.startDate}
                    initialValue={situation?.activeFrom ? moment(situation.activeFrom) : null}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="activeTo"
                    label={translations.projects.situations.endDate}
                    initialValue={situation?.activeTo ? moment(situation.activeTo) : null}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if(getFieldValue('activeFrom') <= value){
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(translations.general.fromBeforeToValidationMessage));
                            }
                        })
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={translations.projects.description}
                    initialValue={situation ? situation.description : undefined}
                >
                    <Input.TextArea />
                </Form.Item>
            </Form>
        );
    }
}

export default ProjectSituationForm;
