import Routes from '../config/routes';
import { DrawerState } from '../core/models/enum';

export const getNewRoute = (
    mainRoute: string,
    readRoute: string,
    editRoute: string,
    drawerState: DrawerState,
    stringToReplace?: string,
    id?: string
): string => {
    switch (drawerState) {
        case DrawerState.Closed:
            if (id && stringToReplace) {
                return mainRoute.replace(stringToReplace, id);
            }
            return mainRoute;
        case DrawerState.Read:
            if (id && stringToReplace) {
                return readRoute.replace(stringToReplace, id);
            }
            return mainRoute;
        case DrawerState.Edit:
            if (id && stringToReplace) {
                return editRoute.replace(stringToReplace, id ? id : '0');
            }
            return editRoute;
        default:
            return mainRoute;
    }
};

export const getNewSubRoute = (
    mainRoute: string,
    readRoute: string,
    editRoute: string,
    createRoute: string,
    drawerState: DrawerState,
    firstStringToReplace?: string,
    secondStringToReplace?: string,
    firstId?: string,
    secondId?: string
): string => {
    switch (drawerState) {
        case DrawerState.Closed:
            if (firstId && firstStringToReplace) {
                return mainRoute.replace(firstStringToReplace, firstId);
            }
            return mainRoute;
        case DrawerState.Read:
            if (firstId && secondId && firstStringToReplace && secondStringToReplace) {
                return readRoute
                    .replace(firstStringToReplace, firstId)
                    .replace(secondStringToReplace, secondId);
            }
            return mainRoute;
        case DrawerState.Edit:
            if (firstId && secondId && firstStringToReplace && secondStringToReplace) {
                return editRoute
                    .replace(firstStringToReplace, firstId)
                    .replace(secondStringToReplace, secondId);
            }
            return editRoute;
        case DrawerState.Create:
            if (firstId && firstStringToReplace) {
                return createRoute.replace(firstStringToReplace, firstId);
            }
            return createRoute;
        default:
            return mainRoute;
    }
};

export interface InitialRoutingProperties {
    initialEntityId?: string;
    isInitialEditMode: boolean;
    activeTabKey?: string;
}

export const getInitialProperties = (pathname: string): InitialRoutingProperties => {
    const urlParts = pathname.split('/');
    const lastPart = urlParts.pop();

    const isInitialEditMode = lastPart === 'edit';

    if (lastPart && !isInitialEditMode) {
        urlParts.push(lastPart);
    }

    const initialEntityId = urlParts.length > 2 ? urlParts[2] : undefined;

    return {
        initialEntityId,
        isInitialEditMode,
    };
};

export const getSubPageInitialProperties = (
    pathname: string,
    partPathname: string,
    match: any,
    readRoute: string,
    editRoute: string
): InitialRoutingProperties => {
    const urlParts = pathname.split('/');
    if (
        urlParts.length === 4 &&
        urlParts[1] === partPathname &&
        (urlParts[3] === 'edit' || urlParts[3] === 'new')
    ) {
        urlParts.splice(0, 1);
    } else if (urlParts.length > 3 && urlParts[1] === partPathname) {
        urlParts.splice(0, 3);
    } else {
        urlParts.splice(0, 1);
    }

    const lastPart = urlParts.pop();
    const isInitialEditMode = lastPart === 'edit' || lastPart === 'new';

    if (lastPart && !isInitialEditMode) {
        urlParts.push(lastPart);
    }
    const tabPart = urlParts.length > 0 ? `/${urlParts[0]}` : '';
    const fullTabRoute = tabPart !== `/${partPathname}` ? `${readRoute}${tabPart}` : match.path;

    const initialEntityId = urlParts.length > 1 ? urlParts[1] : undefined;

    const isEditRoute = fullTabRoute === editRoute;

    const activeTabKey = isEditRoute ? readRoute : fullTabRoute;

    return {
        initialEntityId,
        isInitialEditMode,
        activeTabKey,
    };
};

export const getRootPathname = (pathname: string): string => pathname.split('/')[1];

export const buildBasicRoute = (
    path: string,
    entityId: string,
    subPage?: string,
    subEntityId?: string
): string => {
    let route = path.replace(':entityId', entityId);

    if (subPage) {
        route = route.replace(':subPage', subPage);
    }
    if (subEntityId) {
        route = route.replace(':subEntityId', subEntityId!);
    }
    return route;
};

export const buildRoute = (
    mainRoute: string,
    createRoute: string,
    readRoute: string,
    editRoute: string,
    drawerState: DrawerState,
    entityId?: string
): string => {
    switch (drawerState) {
        case DrawerState.Create:
            return createRoute;
        case DrawerState.Read:
            return readRoute.replace(':entityId', entityId!);
        case DrawerState.Edit:
            return editRoute.replace(':entityId', entityId!);
        case DrawerState.Closed:
            if (entityId) {
                return readRoute.replace(':entityId', entityId);
            } else {
                return mainRoute;
            }
        default:
            return mainRoute;
    }
};

export const buildSubPageRoute = (
    mainRoute: string,
    createRoute: string,
    editRoute: string,
    drawerState: DrawerState,
    entityId: string,
    subPage: string,
    subEntityId?: string
): string => {
    switch (drawerState) {
        case DrawerState.Create:
            return createRoute.replace(':entityId', entityId).replace(':subPage', subPage);
        case DrawerState.Edit:
            return editRoute
                .replace(':entityId', entityId)
                .replace(':subPage', subPage)
                .replace(':subEntityId', subEntityId!);
        case DrawerState.Closed:
            return mainRoute.replace(':entityId', entityId).replace(':subPage', subPage);
        default:
            return mainRoute.replace(':entityId', entityId).replace(':subPage', subPage);
    }
};

export const buildSubPageRouteImproved = (
    drawerState: DrawerState,
    entityId: string,
    subPage: string,
    subEntityId?: string
): string => {
    let path;
    switch (drawerState) {
        case DrawerState.Create:
            path = Routes.ROUTE_PROJECT_SUBPAGE_NEW;
            break;
        case DrawerState.Read:
            path = Routes.ROUTE_PROJECT_SUBPAGE_READ;
            break;
        case DrawerState.Edit:
            path = Routes.ROUTE_PROJECT_SUBPAGE_EDIT;
            break;
        case DrawerState.Closed:
            path = Routes.ROUTE_PROJECT_SUBPAGE;
            break;
        default:
            path = Routes.ROUTE_PROJECT_SUBPAGE;
            break;
    }

    path = path.replace(':entityId', entityId).replace(':subPage', subPage);

    if (subEntityId) {
        path = path.replace(':subEntityId', subEntityId);
    }

    return path;
};