import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../../../core/models/RouteParams';
import ProjectItemsExportForm from './ProjectItemsExportForm';

interface OwnProps {
    projectId: number;
    onClose: () => void;
    onSuccess: () => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(ProjectItemsExportForm);
