import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Tooltip, TreeSelect } from 'antd';
import React from 'react';
import { Props } from '.';
import { translations } from '../../../../../config/translations';
import { ActionType, ModuleName } from '../../../../../core/models/enum';
import { authorizeAction } from '../../../../../helpers/CheckPermissionHelper';
import { formatDate } from '../../../../../helpers/DateHelper';
import { showConfirm } from '../../../../../helpers/NotificationHelper';
import { ProjectCooperatorVm } from '../../../../../utils/api';

interface State {
    isSaving: boolean;
    allowUpdate: boolean;
    allowDelete: boolean;
}

const { TreeNode } = TreeSelect;

class ProjectSituationDetails extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
            allowUpdate: false,
            allowDelete: false,
        };
    }

    public componentDidMount = () => {
        this.getPermissions();
    };

    private getPermissions = async () => {
        const { userProfile } = this.props;

        const allowUpdate = await authorizeAction(
            userProfile,
            ModuleName.ProjectSituations,
            ActionType.Update
        );
        const allowDelete = await authorizeAction(
            userProfile,
            ModuleName.ProjectSituations,
            ActionType.Delete
        );

        this.setState({
            allowUpdate,
            allowDelete,
        });
    };

    private renderContractorNodesRecursive = (
        allContractors: ProjectCooperatorVm[],
        currLvlContractors: ProjectCooperatorVm[]
    ): React.ReactElement[] => {
        return currLvlContractors.map((currContractor: ProjectCooperatorVm) => (
            <TreeNode
                key={currContractor.id}
                value={currContractor.id}
                title={currContractor.cooperatorName}
            >
                {this.renderContractorNodesRecursive(
                    allContractors,
                    allContractors.filter(
                        (contractor: ProjectCooperatorVm) =>
                            currContractor.cooperatorId === contractor.clientId
                    )
                )}
            </TreeNode>
        ));
    };

    public render(): React.ReactElement {
        const { situation, contractors, onContractorChange, onEdit, onDelete } = this.props;
        const { allowDelete, allowUpdate } = this.state;

        const activeFrom = formatDate(situation.activeFrom);
        const activeTo = formatDate(situation.activeTo);

        return (
            <Row>
                <Col span={18}>
                    <Form layout="inline">
                        <Row style={{ width: '100%' }}>
                            <Col span={8}>
                                <Form.Item label={translations.projects.contractors.contractor}>
                                    <TreeSelect
                                        onChange={(id) => onContractorChange(id as number)}
                                        defaultValue={-1}
                                        showSearch
                                        treeDefaultExpandAll
                                    >
                                        <TreeNode
                                            value={-1}
                                            title={translations.projects.contractors.allContractors}
                                        />

                                        {this.renderContractorNodesRecursive(
                                            contractors,
                                            contractors.filter(
                                                (contractor: ProjectCooperatorVm) =>
                                                    !contractor.clientId
                                            )
                                        )}
                                    </TreeSelect>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={translations.projects.situations.period}>
                                    <Input readOnly value={`${activeFrom} - ${activeTo}`} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label={translations.projects.description}>
                                    <Tooltip title={situation.description || ''}>
                                        <Input readOnly value={situation.description || ''} />
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>

                <Col span={6}>
                    <Space style={{ float: 'right' }}>
                        {allowUpdate && (
                            <Button onClick={onEdit} icon={<EditOutlined />}>
                                {translations.general.edit}
                            </Button>
                        )}

                        {allowDelete && (
                            <Button
                                onClick={() =>
                                    showConfirm(
                                        () => onDelete(situation.id),
                                        translations.projects.situations.deleteMessage,
                                        translations.general.delete,
                                        true
                                    )
                                }
                                danger
                                icon={<DeleteOutlined />}
                            >
                                {translations.general.delete}
                            </Button>
                        )}
                    </Space>
                </Col>
            </Row>
        );
    }
}

export default ProjectSituationDetails;
