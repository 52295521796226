import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DrawerState } from '../../../core/models/enum';
import { RouteParams } from '../../../core/models/RouteParams';
import { NewsVm } from '../../../utils/api';
import NewsRegular from './NewsRegular';

interface OwnProps {
    userProfile: Profile;
    handleDrawerMode: (drawerState: DrawerState, id?: string) => void;
    selectedNews?: NewsVm;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(NewsRegular);