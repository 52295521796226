import { WorkHourRecordVm } from '../../../utils/api';
import WorkHourRecordsCopyForm from './workHourRecordsCopyForm';

interface OwnProps {
    projectName: string | undefined;
    projectId: number | undefined;
    onClose: () => void;
    onSuccess: (records: WorkHourRecordVm[]) => void;
    hasEditDeadlineExpired: (month: number, year: number) => boolean;
}

export type Props = OwnProps;

export default WorkHourRecordsCopyForm;
