import { Profile } from 'oidc-client';
import { ProjectCooperatorVm } from '../../../../../utils/api';
import ProjectCooperatorsTable from './ProjectCooperatorsTable';

interface OwnProps {
    userProfile: Profile;
    cooperators?: ProjectCooperatorVm[];
    onUpdate: (id: number) => void;
    onDelete: (id: number) => Promise<void>;
}

export type Props = OwnProps;

export default ProjectCooperatorsTable;
