import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React, { ReactElement } from 'react';
import { TableActionProps } from '../../core/models/TableAction';

interface Props {
    tableActions: TableActionProps[];
    className?: string;
}

class TableActionMenu extends React.PureComponent<Props> {
    private getOverlay = (): React.ReactElement => {
        const { tableActions, className } = this.props;

        if (!tableActions || !tableActions.length) return <></>;

        return (
            <Menu className={className}>
                {tableActions.map(
                    (action: TableActionProps): ReactElement => (
                        <Menu.Item
                            key={`actions-${action.id}`}
                            onClick={() => action.onClick(action.entity)}
                            className={`action-button ${
                                action.menuItemProps && action.menuItemProps.className
                            }`}
                            {...action.menuItemProps}
                        >
                            {action.label}
                        </Menu.Item>
                    )
                )}
            </Menu>
        );
    };

    public render = (): ReactElement => {
        return (
            <Dropdown overlay={this.getOverlay()}>
                <Button shape="circle" id="actionsButton" type="text" size="small">
                    <EllipsisOutlined />
                </Button>
            </Dropdown>
        );
    };
}

export default TableActionMenu;
