import { Form, FormInstance, Input, Select, TreeSelect } from 'antd';
import React from 'react';
import DrawerButtons from '../../../components/drawer-buttons';
import InputPrice from '../../../components/input-price';
import { formItemLayout724 } from '../../../config/formLayouts';
import { translations } from '../../../config/translations';
import { SelectOption } from '../../../core/models/SelectOption';
import {
    getItemDisplayName,
    getItemStatusesForDocumentType,
    isOutgoingDocumentType,
    hasHiddenDocumentItemStatus,
    isConsumptionDocumentType,
} from '../../../helpers/DocumentHelper';
import { getOptionsFromProjectItemsRecursive } from '../../../helpers/FetchAndTransformHelpers';
import { showSuccess } from '../../../helpers/NotificationHelper';
import { create, update } from '../../../helpers/SubmitHelper';
import {
    AssociateTypeEnum,
    CodebooksClient,
    CooperatorsClient,
    CooperatorVm,
    CreateDocumentItemCommandOfDocumentItem,
    DocumentItemsClient,
    DocumentItemStatusEnum,
    DocumentItemVm,
    DocumentTypeEnum,
    ExpenseGroupsClient,
    ExpenseGroupVm,
    ProjectsClient,
    ProjectVm,
    SelectOptionVm,
    UnitOfMeasurementsClient,
    UnitOfMeasurementVm,
    UpdateDocumentItemCommandOfDocumentItem,
    WarehouseClient,
    WarehouseItemVm,
} from '../../../utils/api';
import { Props } from './index';

interface State {
    warehouseItems: WarehouseItemVm[];
    expenseGroups: ExpenseGroupVm[];
    suppliers: CooperatorVm[];
    expenseTypes: SelectOptionVm[];
    statuses: SelectOptionVm[];
    unitsOfMeasurement: SelectOption[];
    projectItems: SelectOption[];
    projects: ProjectVm[];
    isSaving?: boolean;
}

const { TextArea } = Input;

class DocumentItemForm extends React.Component<Props, State> {
    formRef = React.createRef<FormInstance>();

    public constructor(props: Props) {
        super(props);

        this.state = {
            projectItems: [],
            projects: [],
            warehouseItems: [],
            expenseGroups: [],
            suppliers: [],
            expenseTypes: [],
            unitsOfMeasurement: [],
            statuses: [],
        };
    }

    public componentDidMount = () => {
        this.getUnitsOfMeasurement();
        this.getProjectItems();
        this.getWarehouseItems();
        this.getExpenseGroups();
        this.getSuppliers();
        this.getProjects();
        this.getExpenseTypes();
        this.getStatuses();
    };

    private getProjects = async () => {
        this.setState({
            projects: await new ProjectsClient().getAll(),
        });
    };

    private getSuppliers = async () => {
        this.setState({
            suppliers: await new CooperatorsClient().getByAssociateType(AssociateTypeEnum.Supplier),
        });
    };

    private getUnitsOfMeasurement = async () => {
        const units = await new UnitOfMeasurementsClient().getAll();

        this.setState({
            unitsOfMeasurement: units.map(
                (unit: UnitOfMeasurementVm): SelectOption => ({
                    label: unit.shortName || unit.name,
                    value: unit.id,
                })
            ),
        });
    };

    private getProjectItems = async () => {
        const projectId = this.formRef.current!.getFieldValue('projectId');

        if (!projectId) return;

        const projectItems = await new ProjectsClient().getProjectItems(projectId);

        this.setState({
            projectItems: getOptionsFromProjectItemsRecursive(projectItems),
        });
    };

    private getWarehouseItems = async () => {
        const projectId = this.formRef.current!.getFieldValue('projectId');

        if (!projectId) return;

        const warehouse = await new WarehouseClient().getByProjectId(projectId);

        this.setState({
            warehouseItems: warehouse.warehouseItems || [],
        });
    };

    private getExpenseGroups = async () => {
        this.setState({
            expenseGroups: await new ExpenseGroupsClient().getAll(null),
        });
    };

    private getExpenseTypes = async () => {
        this.setState({
            expenseTypes: await new CodebooksClient().getByCodebookName('ExpenseType'),
        });
    };

    private getStatuses = async () => {
        this.setState({
            statuses: await new CodebooksClient().getByCodebookName('DocumentItemStatus'),
        });
    };

    private handleExpenseTypeChange = () => {
        this.formRef.current!.setFieldsValue({ expenseGroupId: undefined });
    };

    private handleProjectChange = async (projectId?: number) => {
        if (!projectId) {
            return;
        }

        this.formRef.current!.setFieldsValue({
            warehouseItemId: undefined,
            projectItemId: undefined,
        });
        this.getWarehouseItems();
        this.getProjectItems();
    };

    private handleWarehouseItemChange = async (warehouseItemId?: number) => {
        const { warehouseItems } = this.state;

        if (!warehouseItemId) {
            return;
        }

        const warehouseItem = warehouseItems.find(
            (wi: WarehouseItemVm): boolean => wi.id === warehouseItemId
        );

        if (!warehouseItem) {
            return;
        }

        this.formRef.current!.setFieldsValue({
            name: warehouseItem.name,
            unitOfMeasurementId: warehouseItem.unitOfMeasurementId,
            unitPrice: warehouseItem.unitPrice,
        });
    };

    private handleSubmit = async (values: any) => {
        this.setState({
            isSaving: true,
        });

        if (values.id) {
            await update(
                new DocumentItemsClient(),
                UpdateDocumentItemCommandOfDocumentItem.fromJS(values),
                this.handleSuccess
            );
        } else {
            await create(
                new DocumentItemsClient(),
                CreateDocumentItemCommandOfDocumentItem.fromJS(values),
                this.handleSuccess
            );
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (item: DocumentItemVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.documents.items.saveSuccess);

        onSuccess(item);
    };

    private getDocumentItemTypeFormItems = (): React.ReactElement => {
        const { document, item } = this.props;
        const { projectItems, warehouseItems, expenseGroups, expenseTypes, suppliers, projects } =
            this.state;

        const isOutgoing = isOutgoingDocumentType(document.documentTypeId);
        const isConsumption = isConsumptionDocumentType(document.documentTypeId);
        
        const destinationProject = (
            <Form.Item
                name="destinationProjectId"
                label={translations.documents.destinationProject}
                initialValue={item.destinationProjectId || document.destinationProjectId}
                rules={[
                    {
                        required: true,
                        message: translations.general.requiredField,
                    },
                ]}
            >
                <Select showSearch optionFilterProp="children">
                    {projects.map((p: ProjectVm) => (
                        <Select.Option key={p.id} value={p.id}>
                            {p.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        );

        const supplier = (
            <Form.Item
                name="supplierId"
                label={translations.documents.supplier}
                initialValue={item.supplierId || document.supplierId}
            >
                <Select showSearch optionFilterProp="children">
                    {suppliers.map((p: CooperatorVm) => (
                        <Select.Option key={p.id} value={p.id}>
                            {p.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        );

        const projectItemId = (
            <Form.Item
                name="projectItemId"
                label={translations.documents.items.projectItem}
                initialValue={item.projectItemId}
            >
                <TreeSelect
                    treeNodeFilterProp="label"
                    treeData={projectItems}
                    allowClear
                    showSearch
                />
            </Form.Item>
        );

        const warehouseItemId = (
            <Form.Item
                name="warehouseItemId"
                label={translations.documents.items.warehouseItem}
                initialValue={item.warehouseItemId}
                rules={[
                    {
                        required: isConsumption,
                        message: translations.general.requiredField,
                    },
                ]}
            >
                <Select
                    options={warehouseItems.map(
                        (item: WarehouseItemVm): SelectOption => ({
                            label: getItemDisplayName(item),
                            value: item.id,
                            disabled: !item.quantity,
                        })
                    )}
                    showSearch
                    allowClear={!isConsumption}
                    onChange={(value?: number) => this.handleWarehouseItemChange(value)}
                />
            </Form.Item>
        );

        const expenseGroupId = (
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.expenseTypeId !== currentValues.expenseTypeId
                }
            >
                {({ getFieldValue }) => {
                    const expenseTypeId = getFieldValue('expenseTypeId');

                    // filter options based on selected expenseTypeId
                    const options = expenseGroups
                        .filter(
                            (group: ExpenseGroupVm): boolean =>
                                group.expenseTypeId === expenseTypeId
                        )
                        .map(
                            (group: ExpenseGroupVm): SelectOption => ({
                                label: group.name,
                                value: group.id,
                            })
                        );

                    return (
                        <Form.Item
                            name="expenseGroupId"
                            label={translations.documents.items.expenseGroup}
                            initialValue={item.expenseGroupId}
                        >
                            <Select
                                options={options}
                                showSearch
                                disabled={!expenseTypeId}
                                placeholder={
                                    !expenseTypeId
                                        ? translations.documents.items.chooseExpenseType
                                        : null
                                }
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );

        const expenseTypeId = (
            <Form.Item
                name="expenseTypeId"
                label={translations.documents.items.expenseType}
                initialValue={item.expenseGroup?.expenseTypeId}
            >
                <Select options={expenseTypes} onChange={this.handleExpenseTypeChange} />
            </Form.Item>
        );

        switch (document.documentTypeId) {
            case DocumentTypeEnum.RequisitionOrder:
                return (
                    <>
                        {projectItemId}
                        {supplier}
                        {expenseTypeId}
                        {expenseGroupId}
                    </>
                );
            case DocumentTypeEnum.PurchaseOrder:
                return (
                    <>
                        {projectItemId}
                        {supplier}
                        {expenseTypeId}
                        {expenseGroupId}
                    </>
                );
            case DocumentTypeEnum.PurchaseReceivedNote:
                return (
                    <>
                        {projectItemId}
                        {supplier}
                        {warehouseItemId}
                    </>
                );
            case DocumentTypeEnum.TransferNote:
                return (
                    <>
                        {destinationProject}
                        {projectItemId}
                        {warehouseItemId}
                    </>
                );
            case DocumentTypeEnum.TransferReceivedNote:
                return (
                    <>
                        {destinationProject}
                        {projectItemId}
                        {warehouseItemId}
                    </>
                );
            case DocumentTypeEnum.ConsumptionNote:
                return (
                    <>
                        {projectItemId}
                        {warehouseItemId}
                    </>
                );
            case DocumentTypeEnum.WriteOffNote:
                return (
                    <>
                        {projectItemId}
                        {warehouseItemId}
                    </>
                );
            case DocumentTypeEnum.Liquidation:
                return (
                    <>
                        {projectItemId}
                        {supplier}
                        {warehouseItemId}
                    </>
                );
            case DocumentTypeEnum.Order:
                return (
                    <>
                        {projectItemId}
                        {supplier}
                        {expenseTypeId}
                        {expenseGroupId}
                    </>
                );
            default:
                return <></>;
        }
    };

    public render(): React.ReactElement {
        const { document, item, onClose } = this.props;
        const { projects, unitsOfMeasurement, warehouseItems, statuses, isSaving } = this.state;

        const isOutgoing = isOutgoingDocumentType(document.documentTypeId);
        const hasHiddenStatus = hasHiddenDocumentItemStatus(document.documentTypeId);

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout724} ref={this.formRef}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />

                {hasHiddenStatus ? (
                    <Form.Item name="statusId" initialValue={item.statusId} hidden={true}>
                        <Input />
                    </Form.Item>
                ) : (
                    <Form.Item
                        name="statusId"
                        label={translations.documents.items.status}
                        initialValue={item.statusId || DocumentItemStatusEnum.Draft}
                        rules={[
                            {
                                required: true,
                                message: translations.general.requiredField,
                            },
                        ]}
                        hidden={hasHiddenStatus}
                    >
                        <Select
                            options={getItemStatusesForDocumentType(
                                statuses,
                                document.documentTypeId
                            )}
                        />
                    </Form.Item>
                )}

                <Form.Item
                    name="projectId"
                    label={translations.documents.project}
                    initialValue={item.projectId || document.projectId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={(value: number) => this.handleProjectChange(value)}
                    >
                        {projects.map((p: ProjectVm) => (
                            <Select.Option key={p.id} value={p.id}>
                                {p.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {this.getDocumentItemTypeFormItems()}

                <Form.Item
                    name="name"
                    label={translations.documents.items.name}
                    initialValue={item.name}
                    rules={[{ required: true, message: translations.general.requiredField }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="unitOfMeasurementId"
                    label={translations.documents.items.unit}
                    initialValue={item.unitOfMeasurementId}
                    rules={[{ required: true, message: translations.general.requiredField }]}
                >
                    <Select options={unitsOfMeasurement} />
                </Form.Item>

                <Form.Item
                    name="unitPrice"
                    label={translations.documents.items.unitPrice}
                    initialValue={item.unitPrice}
                    rules={[
                        {
                            required: document.documentTypeId === DocumentTypeEnum.Liquidation,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <InputPrice />
                </Form.Item>

                <Form.Item
                    name="quantity"
                    label={translations.documents.items.quantity}
                    initialValue={item.quantity}
                    rules={[
                        { required: true, message: translations.general.requiredField },
                        {
                            validator: async (_, value) => {
                                const warehouseItem = warehouseItems.find(
                                    (wi: WarehouseItemVm) =>
                                        wi.id ===
                                        this.formRef.current!.getFieldValue('warehouseItemId')
                                );
                                if (
                                    !isOutgoing ||
                                    !warehouseItem ||
                                    warehouseItem.quantity >= value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    `Raspoloživo je ${warehouseItem.quantity} ${warehouseItem.unitOfMeasurement?.shortName}`
                                );
                            },
                        },
                    ]}
                >
                    <InputPrice />
                </Form.Item>

                <Form.Item
                    name="comment"
                    label={translations.documents.items.comment}
                    initialValue={item.comment}
                >
                    <TextArea />
                </Form.Item>

                {/* HIDDEN FIELDS */}

                <Form.Item name="id" initialValue={item.id} hidden={true}>
                    <Input />
                </Form.Item>

                <Form.Item name="documentId" initialValue={item.documentId} hidden={true}>
                    <Input />
                </Form.Item>
            </Form>
        );
    }
}

export default DocumentItemForm;
