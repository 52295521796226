import { ProjectVm, WorkHourRecordVm } from '../../../utils/api';
import { TableEmployeeVm } from '../WorkHourRecordsTable';
import WorkHourRecordForm from './WorkHourRecordForm';

interface OwnProps {
    record: WorkHourRecordVm;
    projects: ProjectVm[];
    employees: TableEmployeeVm[];
    onClose: () => void;
    onSuccess: (result: WorkHourRecordVm) => void;
    onDelete: () => void;
}

export type Props = OwnProps;

export default WorkHourRecordForm;
