import { DocumentItemVm, DocumentTypeEnum, WarehouseItemVm } from '../../../utils/api';
import DocumentItemTable from './DocumentItemTable';

interface OwnProps {
    documentTypeId: DocumentTypeEnum;
    selectedItemsKeys?: React.Key[];
    documentItems?: DocumentItemVm[];
    warehouseItems?: WarehouseItemVm[];
    selectionEnabled?: boolean;
    isSummary?: boolean;
    onSelectionChange?: (items: DocumentItemVm[]) => void;
    onEdit?: (id: number) => void;
    onDelete?: (id: number) => void;
}

export type Props = OwnProps;

export default DocumentItemTable;
