import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Space, Table, TablePaginationConfig } from 'antd';
import React from 'react';
import { defaultTablePagination } from '../../../config/constants';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { ActionType, ModuleName } from '../../../core/models/enum';
import { authorizeAction } from '../../../helpers/CheckPermissionHelper';
import {
    getKeyFilter,
    getCheckboxFilter,
    getDefaultFilter,
    getSearchFilter,
} from '../../../helpers/FilterHelper';
import { showError, showConfirm, showSuccess } from '../../../helpers/NotificationHelper';
import { getDefaultComparer } from '../../../helpers/SortHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import {
    ApiException,
    CodebooksClient,
    ExpenseGroupsClient,
    ExpenseGroupVm,
    SelectOptionVm,
} from '../../../utils/api';
import { Props } from './index';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { applySearchParams, getFilterParamsString, getFiltersParams, getPagingSortingParams } from '../../../helpers/SearchParamsHelper';

interface State {
    expenseGroups?: ExpenseGroupVm[];
    expenseTypes: SelectOptionVm[];
    loading?: boolean;
    allowEdit: boolean;
    allowDelete: boolean;
}

class ExpenseGroupTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            expenseTypes: [],
            allowEdit: authorizeAction(
                props.userProfile,
                ModuleName.ExpenseGroups,
                ActionType.Update
            ),
            allowDelete: authorizeAction(
                props.userProfile,
                ModuleName.ExpenseGroups,
                ActionType.Delete
            ),
        };
    }

    public componentDidMount = () => {
        this.getExpenseGroups();
        this.getExpenseTypes();
    };

    public componentDidUpdate(prevProps: Props) {
        const {
            location: { search },
        } = this.props;

        if (prevProps.location.search !== search) {
            this.getExpenseGroups();
        }
    }

    private handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<ExpenseGroupVm> | SorterResult<ExpenseGroupVm>[] = {}
    ) => {
        const {
            history,
            location: { search },
        } = this.props;

        const currSearchParams = new URLSearchParams(search);
        const tableSearchParams = this.createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    private createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<ExpenseGroupVm> | SorterResult<ExpenseGroupVm>[] = {}
    ): URLSearchParams => {
        const filterParamsString = getFilterParamsString(filters);
        const singleSorter = sorter as SorterResult<ExpenseGroupVm>;
        const tableSearchParams = new URLSearchParams(filterParamsString);

        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );
        tableSearchParams.append('sortBy', singleSorter.field?.toString() && singleSorter.order ? singleSorter.field?.toString() : '');
        tableSearchParams.append('sortOrder', singleSorter.order || '');

        return tableSearchParams;
    };

    private getExpenseGroups = async () => {
        this.setState({
            loading: true,
        });

        this.setState({
            expenseGroups: await new ExpenseGroupsClient().getAll(null),
            loading: false,
        });
    };

    private getExpenseTypes = async () => {
        this.setState({
            expenseTypes: await new CodebooksClient().getByCodebookName('ExpenseType'),
        });
    };

    private handleDelete = async (id: number) => {
        try {
            await new ExpenseGroupsClient().delete(id);

            showSuccess(translations.administration.expenseGroups.deletedMessage);
            this.getExpenseGroups();
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorDeletingData);
            }
        }
    };

    public render(): React.ReactElement {
        const { expenseGroups, allowDelete, allowEdit, expenseTypes, loading } = this.state;
        const { history, location } = this.props;
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam } = getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        const columns = [
            {
                title: translations.administration.expenseGroups.name,
                dataIndex: 'name',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('name'),
                sorter: getDefaultComparer('name'),
                filteredValue: filters.name ? [filters.name] : null,
                sortOrder: sortByParam === 'name' ? sortOrderParam : null,
            },
            {
                title: `${translations.administration.expenseGroups.expenseType}`,
                dataIndex: 'expenseTypeId',
                ...getCheckboxFilter(expenseTypes),
                onFilter: getKeyFilter('expenseTypeId'),
                sorter: getDefaultComparer('expenseType'),
                filteredValue: filters.expenseTypeId ? [filters.expenseTypeId] : null,
                sortOrder: sortByParam === 'expenseTypeId' ? sortOrderParam : null,
                render: (_: number, record: ExpenseGroupVm) => record.expenseType,
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 100,
                align: 'left',
                className: 'no-print',
                render: (value: ExpenseGroupVm): React.ReactElement => (
                    <Space>
                        {allowEdit && (
                            <EditOutlined
                                className="blue-6"
                                onClick={() =>
                                    history.push(
                                        Routes.ROUTE_EXPENSE_GROUP_EDIT.replace(
                                            ReplaceStrings.ENTITY_ID,
                                            value.id.toString()
                                        )
                                    )
                                }
                            />
                        )}
                        {allowDelete && (
                            <DeleteOutlined
                                onClick={() =>
                                    showConfirm(
                                        () => this.handleDelete(value.id),
                                        translations.administration.expenseGroups.deleteConfirm,
                                        translations.general.delete,
                                        true
                                    )
                                }
                                className="red-5"
                            />
                        )}
                    </Space>
                ),
            },
        ];

        return (
            <Table
                locale={getTableLocale()}
                columns={columns}
                dataSource={expenseGroups}
                loading={loading}
                rowKey={(record: ExpenseGroupVm): string => record.id?.toString()}
                pagination={defaultTablePagination}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default ExpenseGroupTable;
