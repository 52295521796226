export const formItemLayout724 = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
};

export const formItemLayout024 = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
};

export const formItemLayout420 = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

export const formItemLayout321 = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
};

export const formItemLayout0816 = {
    labelCol: { lg: 8 },
    wrapperCol: { lg: 16 },
};

export const formItemLayout1410 = {
    labelCol: { lg: 14 },
    wrapperCol: { lg: 10 },
};
