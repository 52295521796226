import { FormProps } from 'antd/lib/form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../core/models/RouteParams';
import { ProjectDetailVm } from '../../../utils/api';
import ProjectForm from './ProjectForm';

interface OwnProps {
    project?: ProjectDetailVm;
    onClose: () => void;
    onSuccess: (project: ProjectDetailVm) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric> & FormProps;

export default withRouter(ProjectForm);
