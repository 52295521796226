import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteParams';
import CooperatorPage from './CooperatorPage';

interface OwnProps {
    userProfile: Profile;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CooperatorPage);
