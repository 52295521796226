import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteParams';
import SalaryTable from './SalaryTable';
import { EmployeeSalaryVm } from '../../../utils/api';

interface OwnProps {
    wrappedComponentRef?: any;
    selectedCompanyId?: number;
    selectedMonth: number;
    selectedYear: number;
    employeeSalaries: EmployeeSalaryVm[];
    loading: boolean;
    fetchEmployeeSalaries: () => void;
    totalCount: number;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(SalaryTable);
