import { HodogramVm } from '../../../utils/api';
import HodogramActions from './HodogramActions';

interface OwnProps {
    item: HodogramVm;
    allowUpdate: boolean;
    allowCreate: boolean;
    allowDelete: boolean;
    onAdd: (parentId?: number) => void;
    onEdit: (item: HodogramVm) => void;
    onDelete: (item: HodogramVm) => void;
    onActivate: (item: HodogramVm) => void;
    onDeactivate: (item: HodogramVm) => void;
}

export type Props = OwnProps;

export default HodogramActions;
