import { Checkbox, DatePicker, Form, Input } from 'antd';
import moment from 'moment';
import React from 'react';
import { Props } from '.';
import DrawerButtons from '../../../../components/drawer-buttons';
import TextEditor from '../../../../components/rich-text-editor/TextEditor';
import { defaultFormatWithTime } from '../../../../config/constants';
import { formItemLayout724 } from '../../../../config/formLayouts';
import { translations } from '../../../../config/translations';
import { DrawerState } from '../../../../core/models/enum';
import { showError, showSuccess } from '../../../../helpers/NotificationHelper';
import { ApiException, NewsClient, NewsVm } from '../../../../utils/api';

interface State {
    isSaving: boolean;
}

class NewsForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
        };
    }

    private handleSubmit = async (values: any) => {
        const { news } = this.props;

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: news ? news.id : undefined,
        };

        try {
            let result;
            if (request.id) {
                result = await new NewsClient().update(request.id, request);
            } else {
                result = await new NewsClient().create(request);
            }

            this.handleSuccess(result);
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }

        this.setState({ isSaving: false });
    };

    private handleSuccess = (result: NewsVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.news.savedMessage);

        onSuccess(result);
    };

    public render() {
        const { isSaving } = this.state;
        const { onClose, news } = this.props;

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout724}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />

                <Form.Item
                    name="title"
                    label={translations.news.title}
                    initialValue={news?.title}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="text"
                    label={translations.news.text}
                    initialValue={news?.text}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    {/* @ts-ignore */}
                    <TextEditor />
                </Form.Item>

                <Form.Item
                    name="date"
                    label={translations.news.date}
                    initialValue={news?.date ? moment(news.date) : null}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormatWithTime}
                        style={{ width: '100%' }}
                        allowClear
                        showTime
                    />
                </Form.Item>

                <Form.Item
                    name="author"
                    label={translations.news.author}
                    initialValue={news?.author}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>

                {this.props.drawerState === DrawerState.Edit && (
                    <Form.Item
                        name="isArchived"
                        label={translations.news.archived}
                        valuePropName="checked"
                        initialValue={news ? news.isArchived : undefined}
                    >
                        <Checkbox />
                    </Form.Item>
                )}

                <Form.Item
                    name="isFeatured"
                    label={translations.news.featured}
                    valuePropName="checked"
                    initialValue={news ? news.isFeatured : undefined}
                >
                    <Checkbox />
                </Form.Item>
            </Form>
        );
    }
}

export default NewsForm;
