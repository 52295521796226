import React from 'react';
import { Props } from './index';
import ProjectItemsOverviewTable from './project-items-overview-table';

class ProjectItemsOverview extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): React.ReactElement {
        return (
            <>
                <ProjectItemsOverviewTable />
            </>
        );
    }
}

export default ProjectItemsOverview;
