import Table, { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { Link } from 'react-router-dom';
import { defaultTablePagination } from '../../../config/constants';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { formatDate } from '../../../helpers/DateHelper';
import { getDatePickerFilter, getSearchFilter } from '../../../helpers/FilterHelper';
import { capitalizeFirstLetter } from '../../../helpers/StringHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import { EmployeesClient, EmployeeVm } from '../../../utils/api';
import { Props } from './index';
import {
    getPagingSortingParams,
    getFiltersParams,
    getFilterParamsString,
    applySearchParams,
} from '../../../helpers/SearchParamsHelper';

interface State {
    employees?: EmployeeVm[];
    loading?: boolean;
    totalItems?: number;
}

class EmployeeTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            employees: [],
            totalItems: 0,
        }
    }

    public componentDidMount = () => {
        this.getEmployees();
    };

    public componentDidUpdate = (prevProps: Props) => {
        const {location } = this.props;

        if (prevProps.location.search !== location.search) {
            this.getEmployees();
        }
    };

    private handleTableChange = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<EmployeeVm> | SorterResult<EmployeeVm>[] = {}
    ) => {
        const {
            history,
            location: { search },
        } = this.props;

        const currSearchParams = new URLSearchParams(search);
        const tableSearchParams = this.createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    private createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<EmployeeVm> | SorterResult<EmployeeVm>[] = {}
    ): URLSearchParams => {
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const filterParamsString = getFilterParamsString(filters);
        const singleSorter = sorter as SorterResult<EmployeeVm>;
        const tableSearchParams = new URLSearchParams(filterParamsString);
        const activeTab = params.get('activeTab') ? params.get('activeTab') : 'active';

        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );
        tableSearchParams.append('sortBy', singleSorter.field?.toString() && singleSorter.order ? singleSorter.field?.toString() : '');
        tableSearchParams.append('sortOrder', singleSorter.order || '');
        tableSearchParams.append('activeTab', activeTab ? activeTab : 'active');

        return tableSearchParams;
    };

    private getEmployees = async () => {
        this.setState({
            loading: true,
        });

        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const activeTab = params.get('activeTab') ? params.get('activeTab') : 'active';
        const { pageIndexParam, pageSizeParam, sortByParam, sortOrderParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        const result = await new EmployeesClient().getPaginated(
            filters.Employee_Name?.toString(),
            filters.oib ? filters.oib.toString() : null,
            filters.birthDate ? new Date(filters.birthDate.toString()) : null,
            filters.address ? filters.address.toString() : null,
            filters.city ? filters.city.toString() : null,
            filters.country ? filters.country.toString() : null,
            activeTab === 'inactive',
            pageIndexParam && parseInt(pageIndexParam) ? parseInt(pageIndexParam) : 1,
            pageSizeParam && parseInt(pageSizeParam)
                ? parseInt(pageSizeParam)
                : defaultTablePagination.defaultPageSize,
            sortByParam ? capitalizeFirstLetter(sortByParam) : undefined,
            sortOrderParam
        );

        this.setState((prevState: State) => ({
            employees: result.pageItems,
            loading: false,
            totalItems: result.totalItems
        }));
    };

    public render(): React.ReactElement {
        const { employees, loading, totalItems } = this.state;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam, pageIndexParam, pageSizeParam } = getPagingSortingParams(params);
        const filters = getFiltersParams(params);
        const pagination: TablePaginationConfig = {
            ...defaultTablePagination,
            current:
                pageIndexParam && parseInt(pageIndexParam)
                    ? parseInt(pageIndexParam)
                    : defaultTablePagination.defaultCurrent,
            pageSize:
                pageSizeParam && parseInt(pageSizeParam)
                    ? parseInt(pageSizeParam)
                    : defaultTablePagination.defaultPageSize,
            total: totalItems
        };

        const columns = [
            {
                title: translations.employees.name,
                dataIndex: 'Employee_Name',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.Employee_Name ? [filters.Employee_Name] : null,
                sortOrder: sortByParam === 'Employee_Name' ? sortOrderParam : null,
                render: (value: string, record: EmployeeVm): React.ReactElement => (
                    <Link
                        to={Routes.ROUTE_EMPLOYEES_READ.replace(
                            ReplaceStrings.ENTITY_ID,
                            record.id.toString()
                        )}
                    >
                        {record.fullName}
                    </Link>
                ),
            },
            {
                title: translations.employees.oib,
                dataIndex: 'oib',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.oib ? [filters.oib] : null,
                sortOrder: sortByParam === 'oib' ? sortOrderParam : null,
            },
            {
                title: translations.employees.birthDate,
                dataIndex: 'birthDate',
                ...getDatePickerFilter(),
                sorter: true,
                filteredValue: filters.birthDate ? [filters.birthDate] : null,
                sortOrder: sortByParam === 'birthDate' ? sortOrderParam : null,
                render: (value: Date) => formatDate(value),
            },
            {
                title: translations.employees.activeFrom,
                dataIndex: 'activeFrom',
                sorter: true,
                sortOrder: sortByParam === 'activeFrom' ? sortOrderParam : null,
                render: (value: Date) => formatDate(value),
            },
            {
                title: translations.employees.activeTo,
                dataIndex: 'activeTo',
                sorter: true,
                sortOrder: sortByParam === 'activeTo' ? sortOrderParam : null,
                render: (value: Date) => formatDate(value),
            },
            {
                title: translations.employees.gender,
                dataIndex: 'gender',
                sorter: true,
                sortOrder: sortByParam === 'gender' ? sortOrderParam : null,
            },
            {
                title: translations.employees.address,
                dataIndex: 'address',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.address ? [filters.address] : null,
                sortOrder: sortByParam === 'address' ? sortOrderParam : null,
            },
            {
                title: translations.employees.city,
                dataIndex: 'city',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.city ? [filters.city] : null,
                sortOrder: sortByParam === 'city' ? sortOrderParam : null,
            },
            {
                title: translations.employees.country,
                dataIndex: 'country',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.country ? [filters.country] : null,
                sortOrder: sortByParam === 'country' ? sortOrderParam : null,
            },
            {
                title: translations.employees.email,
                dataIndex: 'email',
                sorter: true,
                sortOrder: sortByParam === 'email' ? sortOrderParam : null,
            },
            {
                title: translations.employees.phone,
                dataIndex: 'phone',
                sorter: true,
                sortOrder: sortByParam === 'phone' ? sortOrderParam : null,
            },
            {
                title: translations.employees.role,
                dataIndex: 'employeeRole',
                sorter: true,
                sortOrder: sortByParam === 'role' ? sortOrderParam : null,
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 100,
                align: 'left',
                className: 'no-print',
                render: (value: EmployeeVm): React.ReactElement => (
                    <Link
                        to={Routes.ROUTE_EMPLOYEES_READ.replace(
                            ReplaceStrings.ENTITY_ID,
                            value.id.toString()
                        )}
                    >
                        {translations.general.details}
                    </Link>
                ),
            },
        ];

        return (
            <Table
                locale={getTableLocale()}
                columns={columns}
                dataSource={employees}
                loading={loading}
                rowKey={(record: EmployeeVm): string => record.id?.toString()}
                onChange={this.handleTableChange}
                pagination={pagination}
                bordered
                size="small"
            />
        );
    }
}

export default EmployeeTable;
