import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Table from 'antd/lib/table';
import React from 'react';
import { defaultTablePagination } from '../../../../../config/constants';
import { translations } from '../../../../../config/translations';
import { ActionType, ModuleName } from '../../../../../core/models/enum';
import { authorizeAction } from '../../../../../helpers/CheckPermissionHelper';
import { formatDate } from '../../../../../helpers/DateHelper';
import {
    getKeyFilter,
    getDateFilter,
    getDatePickerFilter,
    getDefaultFilter,
    getRadioFilter,
    getSearchFilter,
} from '../../../../../helpers/FilterHelper';
import { showConfirm } from '../../../../../helpers/NotificationHelper';
import {
    getBooleanComparer,
    getDateComparer,
    getDefaultComparer,
} from '../../../../../helpers/SortHelper';
import { getTableLocale } from '../../../../../helpers/TableHelper';
import { ProjectCooperatorVm } from '../../../../../utils/api';
import { Props } from './index';

interface State {
    allowUpdate: boolean;
    allowDelete: boolean;
}

class ProjectCooperatorTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        const { userProfile } = this.props;

        this.state = {
            allowUpdate: authorizeAction(
                userProfile,
                ModuleName.ProjectCooperators,
                ActionType.Update
            ),
            allowDelete: authorizeAction(
                userProfile,
                ModuleName.ProjectCooperators,
                ActionType.Delete
            ),
        };
    }

    public render(): React.ReactElement {
        const { cooperators, onUpdate, onDelete } = this.props;
        const { allowDelete, allowUpdate } = this.state;

        const columns = [
            {
                title: translations.projects.cooperatorName,
                dataIndex: 'cooperatorName',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('cooperatorName'),
                sorter: getDefaultComparer('cooperatorName'),
            },
            {
                title: translations.projects.cooperatorType,
                dataIndex: 'cooperatorType',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('cooperatorType'),
                sorter: getDefaultComparer('cooperatorType'),
            },
            {
                title: translations.projects.client,
                dataIndex: 'clientName',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('clientName'),
                sorter: getDefaultComparer('clientName'),
            },
            {
                title: translations.projects.activeFrom,
                dataIndex: 'activeFrom',
                ...getDatePickerFilter(),
                onFilter: getDateFilter('activeFrom'),
                sorter: getDateComparer('activeFrom'),
                render: (value: Date) => formatDate(value),
            },
            {
                title: translations.projects.activeTo,
                dataIndex: 'activeTo',
                ...getDatePickerFilter(),
                onFilter: getDateFilter('activeTo'),
                sorter: getDateComparer('activeTo'),
                render: (value: Date): string => formatDate(value),
            },
            {
                title: translations.projects.contact,
                dataIndex: 'cooperatorContactSummary',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('cooperatorContactSummary'),
                sorter: getDefaultComparer('cooperatorContactSummary'),
            },
            {
                title: translations.projects.hasItems,
                dataIndex: 'hasBoq',
                align: 'center',
                ...getRadioFilter([
                    { id: 'true', name: translations.projects.hasItems },
                    { id: 'false', name: translations.projects.doesntHaveItems },
                ]),
                onFilter: getKeyFilter('hasBoq'),
                sorter: getBooleanComparer('hasBoq'),
                render: (value: boolean) =>
                    value ? <CheckOutlined style={{ width: '100%' }} /> : null,
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 100,
                align: 'left',
                className: 'no-print',
                render: (value: ProjectCooperatorVm): React.ReactElement => (
                    <>
                        {allowUpdate && (
                            <EditOutlined
                                onClick={() => {
                                    onUpdate(value.id);
                                }}
                                className="blue-6"
                            />
                        )}
                        {allowDelete && (
                            <DeleteOutlined
                                onClick={() =>
                                    showConfirm(
                                        () => onDelete(value.id),
                                        translations.projects.projectCooperatorDeleteConfirm,
                                        translations.general.delete,
                                        true
                                    )
                                }
                                className="red-5"
                                style={{ marginLeft: 10 }}
                            />
                        )}
                    </>
                ),
            },
        ];

        return (
            <Table
                locale={getTableLocale()}
                columns={columns}
                dataSource={cooperators}
                pagination={defaultTablePagination}
                rowKey={(record: ProjectCooperatorVm): string => record.id.toString()}
                bordered
                size="small"
            />
        );
    }
}

export default ProjectCooperatorTable;
