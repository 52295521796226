import { Form, Input } from 'antd';
import React from 'react';
import AutocompleteDropdown from '../../../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../../../components/drawer-buttons';
import { formItemLayout724 } from '../../../../../config/formLayouts';
import { translations } from '../../../../../config/translations';
import { DropdownOption } from '../../../../../core/models/DropdownOption';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
    getCitiesHelper,
    getCountriesHelper,
} from '../../../../../helpers/AutocompleteDropdownHelper';
import { showSuccess } from '../../../../../helpers/NotificationHelper';
import { create, update } from '../../../../../helpers/SubmitHelper';
import { CooperatorContactsClient, CooperatorContactVm } from '../../../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    countries: DropdownOption[];
    cities: DropdownOption[];
    selectedCountry?: DropdownOption[];
    selectedCity?: DropdownOption[];
}

class CooperatorContactForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            isSaving: false,
            countries: [],
            cities: [],
        };
    }

    public componentDidMount = () => {
        const { cooperatorContact } = this.props;

        this.getCountries();
        if (!(cooperatorContact && cooperatorContact.countryId)) {
            this.getCities();
        }
    };

    private confirmCountry = (option: DropdownOption[]) => {
        const selectedCountry = confirmDropdownHelper(option);
        this.setState({
            selectedCountry,
            selectedCity: undefined,
        });
        if (selectedCountry) {
            this.getCities(parseInt(selectedCountry[0].id, 10));
        }
    };

    private getCountries = async () => {
        const { cooperatorContact } = this.props;
        const countryId = cooperatorContact && cooperatorContact.countryId;

        const countryHelper = await getCountriesHelper(countryId);

        if (countryId) {
            this.getCities(countryId);
        }

        this.setState({
            countries: countryHelper.entities,
            selectedCountry: countryHelper.selectedEntity,
        });
    };

    private getCities = async (countryId?: number) => {
        const { cooperatorContact } = this.props;
        const cityId = cooperatorContact && cooperatorContact.cityId;

        const cityHelper = await getCitiesHelper(countryId, cityId);

        this.setState({
            cities: cityHelper.entities,
            selectedCity: cityHelper.selectedEntity,
        });
    };

    private handleSubmit = (values: any) => {
        const { cooperator, cooperatorContact } = this.props;
        const { selectedCountry, selectedCity } = this.state;

        const cooperatorContactClient = new CooperatorContactsClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: cooperatorContact ? cooperatorContact.id : undefined,
            cooperatorId: cooperator ? cooperator.id : undefined,
            countryId:
                selectedCountry && selectedCountry.length > 0
                    ? parseInt(selectedCountry[0].id, 10)
                    : undefined,
            cityId:
                selectedCity && selectedCity.length > 0
                    ? parseInt(selectedCity[0].id, 10)
                    : undefined,
        };

        if (request.id) {
            update(cooperatorContactClient, request, this.handleSuccess);
        } else {
            create(cooperatorContactClient, request, this.handleSuccess);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (result: CooperatorContactVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.cooperators.contacts.successfullySaved);

        onSuccess(result);
    };

    public render(): React.ReactElement {
        const { cooperatorContact, onClose } = this.props;
        const { isSaving, selectedCountry, selectedCity, countries, cities } = this.state;

        return (
            <div>
                <Form onFinish={this.handleSubmit} {...formItemLayout724}>
                    <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />
                    <div>
                        <Form.Item
                            name="name"
                            label={translations.cooperators.name}
                            initialValue={cooperatorContact ? cooperatorContact.name : undefined}
                            rules={[
                                {
                                    required: true,
                                    message: translations.general.requiredField,
                                },
                                { max: 200, message: translations.general.maxLength200 },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="address"
                            label={translations.cooperators.address}
                            initialValue={cooperatorContact ? cooperatorContact.address : undefined}
                            rules={[{ max: 200, message: translations.general.maxLength200 }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="country" label={translations.cooperators.country}>
                            <AutocompleteDropdown
                                placeholder={translations.cooperators.countryPlaceholder}
                                getOptionsFrontend={(value: string): DropdownOption[] =>
                                    filterDropdownOptions(value, countries)
                                }
                                initialValues={selectedCountry}
                                confirmDirty={this.confirmCountry}
                                style={{ width: '100%' }}
                                isAllowedToClear
                            />
                        </Form.Item>
                        <Form.Item name="city" label={translations.cooperators.city}>
                            <AutocompleteDropdown
                                placeholder={translations.cooperators.cityPlaceholder}
                                getOptionsFrontend={(value: string): DropdownOption[] =>
                                    filterDropdownOptions(value, cities)
                                }
                                initialValues={selectedCity}
                                confirmDirty={(options: DropdownOption[]) =>
                                    this.setState({
                                        selectedCity: confirmDropdownHelper(options),
                                    })
                                }
                                style={{ width: '100%' }}
                                isAllowedToClear
                            />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label={translations.cooperators.email}
                            initialValue={cooperatorContact ? cooperatorContact.email : undefined}
                            rules={[
                                { type: 'email', message: translations.general.invalidEmail },
                                { max: 200, message: translations.general.maxLength200 },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            label={translations.cooperators.phone}
                            initialValue={cooperatorContact ? cooperatorContact.phone : undefined}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </Form>
            </div>
        );
    }
}

export default CooperatorContactForm;
