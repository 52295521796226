import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader, Space, Tabs } from 'antd';
import React from 'react';
import { DRAWER_WIDTH } from '../../../config/constants';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { ActionType, DrawerState, EmployeeSubPage, ModuleName } from '../../../core/models/enum';
import { authorizeAction } from '../../../helpers/CheckPermissionHelper';
import { showConfirm } from '../../../helpers/NotificationHelper';
import { buildRoute, buildSubPageRoute, getNewRoute } from '../../../helpers/RoutingHelper';
import { remove } from '../../../helpers/SubmitHelper';
import {
    AttachmentsClient,
    AttachmentVm,
    EmployeeDetailVm,
    EmployeesClient,
} from '../../../utils/api';
import AttachmentForm from '../../attachments/attachment-form';
import AttachmentTable from '../../attachments/attachment-table';
import EmployeeForm from '../employee-form';
import EmployeeGearTable from '../employee-gear/employee-gear-table';
import EmployeeDetails from './employee-details';
import { Props } from './index';

const { TabPane } = Tabs;

interface State {
    employee?: EmployeeDetailVm;
    activeSubPage: EmployeeSubPage;
    drawerState: DrawerState;
    selectedAttachment?: AttachmentVm;
    allowUpdate: boolean;
    allowDelete: boolean;
    allowCreateAttachment: boolean;
}

class EmployeePage extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        const {
            match: {
                params: { subPage },
            },
            userProfile,
        } = props;

        this.state = {
            activeSubPage: subPage ? (subPage as EmployeeSubPage) : EmployeeSubPage.Details,
            allowUpdate: authorizeAction(
                props.userProfile,
                ModuleName.Employees,
                ActionType.Update
            ),
            allowDelete: authorizeAction(
                props.userProfile,
                ModuleName.Employees,
                ActionType.Delete
            ),
            allowCreateAttachment: authorizeAction(
                userProfile,
                ModuleName.Attachments,
                ActionType.Create,
                undefined,
                parseInt(props.match.params.entityId)
            ),
            drawerState: this.getInitDrawerState(),
        };
    }

    public componentDidMount = () => {
        this.getEmployee();
    };

    private getInitDrawerState = (): DrawerState => {
        const {
            match: { path },
        } = this.props;

        switch (path) {
            case Routes.ROUTE_EMPLOYEES_SUBPAGE_NEW:
                return DrawerState.Create;
            case Routes.ROUTE_EMPLOYEES_SUBPAGE_EDIT:
                return DrawerState.Edit;
            case Routes.ROUTE_EMPLOYEES_EDIT:
                return DrawerState.Edit;
            default:
                return DrawerState.Closed;
        }
    };

    private getEmployee = async () => {
        const {
            match: {
                params: { entityId },
            },
        } = this.props;

        if (!entityId) return;

        this.setState({
            employee: await new EmployeesClient().getById(+entityId),
        });
    };

    private handleSuccess = (employee: EmployeeDetailVm) => {
        this.setState({
            employee,
        });
        this.handleDrawerMode(DrawerState.Closed);
    };

    private handleDrawerMode = (drawerState: DrawerState) => {
        const { history } = this.props;
        const { employee } = this.state;

        if (!employee) return;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_EMPLOYEES_READ,
                Routes.ROUTE_EMPLOYEES_READ,
                Routes.ROUTE_EMPLOYEES_EDIT,
                drawerState,
                ReplaceStrings.ENTITY_ID,
                employee.id.toString()
            )
        );
    };

    private handleDelete = () => {
        const { history } = this.props;

        history.push(Routes.ROUTE_EMPLOYEES);
    };

    private handleTabChange = (subPage: EmployeeSubPage) => {
        const { history } = this.props;
        const { employee } = this.state;

        this.setState({
            activeSubPage: subPage,
        });

        if (!employee) {
            this.getEmployee();
        }

        history.push(
            subPage === EmployeeSubPage.Details
                ? buildRoute(
                      Routes.ROUTE_EMPLOYEES,
                      Routes.ROUTE_EMPLOYEES_NEW,
                      Routes.ROUTE_EMPLOYEES_READ,
                      Routes.ROUTE_EMPLOYEES_EDIT,
                      DrawerState.Closed,
                      employee?.id.toString()
                  )
                : buildSubPageRoute(
                      Routes.ROUTE_EMPLOYEES_SUBPAGE,
                      Routes.ROUTE_EMPLOYEES_SUBPAGE_NEW,
                      Routes.ROUTE_EMPLOYEES_SUBPAGE_EDIT,
                      DrawerState.Closed,
                      employee?.id.toString() || '0',
                      subPage
                  )
        );
    };

    private handleAttachmentEdit = (id: number) => {
        const { employee } = this.state;

        if (!id) return;

        const attachments = employee?.attachments || [];
        const document = attachments.find((pd: AttachmentVm): boolean => pd.id === id);

        if (!document) return;

        this.handleDrawerChange(DrawerState.Edit, EmployeeSubPage.Attachments, document);
    };

    private handleAttachmentDeleted = (id: number) => {
        const { employee } = this.state;

        if (!employee) return;

        this.setState({
            employee: EmployeeDetailVm.fromJS({
                ...employee,
                attachments: employee.attachments?.filter((i) => i.id !== id),
            }),
        });
    };

    private handleDrawerChange = (
        drawerState: DrawerState,
        subPage: EmployeeSubPage,
        selectedEntity?: AttachmentVm
    ) => {
        const { history } = this.props;
        const { employee } = this.state;

        const isEdit = drawerState === DrawerState.Edit;

        this.setState({
            drawerState,
            selectedAttachment:
                isEdit && subPage === EmployeeSubPage.Attachments
                    ? (selectedEntity as AttachmentVm)
                    : undefined,
        });

        history.push(
            subPage === EmployeeSubPage.Details
                ? buildRoute(
                      Routes.ROUTE_EMPLOYEES,
                      Routes.ROUTE_EMPLOYEES_NEW,
                      Routes.ROUTE_EMPLOYEES_READ,
                      Routes.ROUTE_EMPLOYEES_EDIT,
                      drawerState,
                      employee?.id.toString()
                  )
                : buildSubPageRoute(
                      Routes.ROUTE_EMPLOYEES_SUBPAGE,
                      Routes.ROUTE_EMPLOYEES_SUBPAGE_NEW,
                      Routes.ROUTE_EMPLOYEES_SUBPAGE_EDIT,
                      drawerState,
                      employee?.id.toString() ?? '0',
                      subPage,
                      selectedEntity?.id.toString()
                  )
        );
    };

    private getDrawerTitle = (): string => {
        const { activeSubPage, selectedAttachment } = this.state;

        switch (activeSubPage) {
            case EmployeeSubPage.Details:
                return translations.employees.editEmployee;
            case EmployeeSubPage.Attachments:
                return selectedAttachment
                    ? translations.attachments.edit
                    : translations.attachments.add;
            default:
                return '';
        }
    };

    private handleAttachmentSaved = (result: AttachmentVm) => {
        const { employee } = this.state;

        if (!employee) return;

        const index = employee.attachments?.findIndex(
            (pe: AttachmentVm): boolean => pe.id === result.id
        );

        if (index !== -1) {
            this.setState({
                employee: EmployeeDetailVm.fromJS({
                    ...employee,
                    attachments: [
                        ...employee.attachments!.slice(0, index),
                        result,
                        ...employee.attachments!.slice(index! + 1),
                    ],
                }),
            });
        } else {
            this.setState({
                employee: EmployeeDetailVm.fromJS({
                    ...employee,
                    attachments: [...employee.attachments!, result],
                }),
            });
        }

        this.handleDrawerChange(DrawerState.Closed, EmployeeSubPage.Attachments);
    };

    public render(): React.ReactElement {
        const {
            employee,
            drawerState,
            activeSubPage,
            selectedAttachment,
            allowUpdate,
            allowDelete,
            allowCreateAttachment,
        } = this.state;
        const { userProfile } = this.props;

        return (
            <>
                <PageHeader title={employee?.name ? `${employee.name} ${employee.surname}` : ''} />

                <Tabs
                    style={{ width: '100%' }}
                    activeKey={activeSubPage}
                    onTabClick={(key: string) => this.handleTabChange(key as EmployeeSubPage)}
                    destroyInactiveTabPane={true}
                >
                    <TabPane tab={translations.general.details} key={EmployeeSubPage.Details}>
                        {employee && (
                            <PageHeader
                                extra={
                                    <Space style={{ float: 'right' }}>
                                        {allowUpdate && (
                                            <Button
                                                icon={<EditOutlined />}
                                                onClick={() =>
                                                    this.handleDrawerMode(DrawerState.Edit)
                                                }
                                            >
                                                {translations.general.edit}
                                            </Button>
                                        )}
                                        {allowDelete && (
                                            <Button
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() =>
                                                    showConfirm(
                                                        () =>
                                                            remove(
                                                                new EmployeesClient(),
                                                                translations.employees
                                                                    .employeeDeleted,
                                                                this.handleDelete,
                                                                employee.id
                                                            ),
                                                        translations.cooperators.deleteMessage,
                                                        translations.general.delete,
                                                        true
                                                    )
                                                }
                                            >
                                                {translations.general.delete}
                                            </Button>
                                        )}
                                    </Space>
                                }
                            />
                        )}

                        {employee && <EmployeeDetails employee={employee} />}
                    </TabPane>

                    <TabPane tab={translations.employees.gear} key={EmployeeSubPage.Gear}>
                        {employee && <EmployeeGearTable employee={employee} />}
                    </TabPane>

                    <TabPane
                        tab={translations.attachments.attachments}
                        key={EmployeeSubPage.Attachments}
                    >
                        <PageHeader
                            title=""
                            extra={
                                allowCreateAttachment
                                    ? [
                                          <Button
                                              key="1"
                                              type="primary"
                                              style={{
                                                  zIndex: 10,
                                                  float: 'right',
                                              }}
                                              onClick={() =>
                                                  this.handleDrawerChange(
                                                      DrawerState.Create,
                                                      EmployeeSubPage.Attachments
                                                  )
                                              }
                                              icon={<PlusOutlined />}
                                          >
                                              {translations.attachments.add}
                                          </Button>,
                                      ]
                                    : []
                            }
                        />
                        {employee && (
                            <AttachmentTable
                                userProfile={userProfile}
                                attachments={employee.attachments}
                                onUpdate={this.handleAttachmentEdit}
                                onDelete={(id: number) =>
                                    remove(
                                        new AttachmentsClient(),
                                        translations.attachments.deleteSuccess,
                                        () => this.handleAttachmentDeleted(id),
                                        id
                                    )
                                }
                            />
                        )}
                    </TabPane>
                </Tabs>

                <Drawer
                    title={this.getDrawerTitle()}
                    open={!!drawerState}
                    onClose={() => this.handleDrawerChange(DrawerState.Closed, activeSubPage)}
                    width={DRAWER_WIDTH}
                    destroyOnClose
                >
                    {activeSubPage === EmployeeSubPage.Details && employee && (
                        <EmployeeForm
                            employee={employee}
                            onClose={() =>
                                this.handleDrawerChange(DrawerState.Closed, EmployeeSubPage.Details)
                            }
                            onSuccess={this.handleSuccess}
                        />
                    )}
                    {activeSubPage === EmployeeSubPage.Attachments && (
                        <AttachmentForm
                            attachment={selectedAttachment}
                            employee={employee}
                            onSuccess={this.handleAttachmentSaved}
                            onClose={() =>
                                this.handleDrawerChange(
                                    DrawerState.Closed,
                                    EmployeeSubPage.Attachments
                                )
                            }
                        />
                    )}
                </Drawer>
            </>
        );
    }
}

export default EmployeePage;
