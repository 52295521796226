import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../../../core/models/RouteParams';
import ProjectItemsImportForm from './ProjectItemsImportForm';

interface OwnProps {
    projectId: number;
    onClose: () => void;
    onSuccess: () => void;
    isEditMode: boolean;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(ProjectItemsImportForm);
