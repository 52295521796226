import { Profile } from 'oidc-client';
import { AttachmentVm, HodogramVm, ProjectHodogramVm } from '../../../utils/api';
import ProjectHodogramDetail from './ProjectHodogramDetail';

interface OwnProps {
    selectedHodogram?: HodogramVm | ProjectHodogramVm;
    userProfile: Profile;
    onAttachmentUpdate: (id: number) => void;
    onAttachmentCreate: () => void;
    onAttachmentDelete: (id: number) => Promise<void>;
    attachments?: AttachmentVm[];
}

export type Props = OwnProps;

export default ProjectHodogramDetail;
