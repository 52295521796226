import moment from 'moment';
import { defaultFormat } from '../config/constants';

export const formatDate = (date?: Date, format: string = defaultFormat): string => {
    if (!date) return '';

    return moment(date)!.format(format);
};

// export const convertUtcToLocal = (date?: Date): moment.Moment | undefined => {
//     if (!date) return;

//     const utcOffset = moment(date).utcOffset();
//     return moment(date).add(utcOffset, 'minutes');
// };

export const getDaysInMonthUTC = (month: number, year: number): Date[] => {
    var date = new Date(Date.UTC(year, month, 1));
    var days = [];
    while (date.getUTCMonth() === month) {
        days.push(new Date(date));
        date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
};

export const getYears = (from: number, to: number): number[] => {
    let years = [];

    for (let i = from; i <= to; i++) {
        years.push(i);
    }

    return years;
};
