import Table, { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { Link } from 'react-router-dom';
import { defaultTablePagination } from '../../../config/constants';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { formatDate } from '../../../helpers/DateHelper';
import { getSearchFilter } from '../../../helpers/FilterHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import { ProjectsClient, ProjectStatusEnum, ProjectVm } from '../../../utils/api';
import { Props } from './index';
import {
    getPagingSortingParams,
    getFiltersParams,
    getFilterParamsString,
    applySearchParams,
} from '../../../helpers/SearchParamsHelper';
import { capitalizeFirstLetter } from '../../../helpers/StringHelper';

interface State {
    projects?: ProjectVm[];
    loading?: boolean;
    totalItems?: number;
}

class ProjectTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);        

        this.state = {
            projects: [],
            totalItems: 0
        };
    }

    public componentDidMount = () => {
        this.getProjects();
    };

    public componentDidUpdate = (prevProps: Props) => {
        //if active tab changed, get new projects
        const {
            location: { search },
        } = this.props;

        if (prevProps.location.search !== search) {
            this.getProjects();
        }
    };

    private handleTableChange = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<ProjectVm> | SorterResult<ProjectVm>[] = {}
    ) => {
        const {
            history,
            location: { search },
        } = this.props;

        const currSearchParams = new URLSearchParams(search);
        const tableSearchParams = this.createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    private createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<ProjectVm> | SorterResult<ProjectVm>[] = {}
    ): URLSearchParams => {
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const filterParamsString = getFilterParamsString(filters);
        const singleSorter = sorter as SorterResult<ProjectVm>;
        const tableSearchParams = new URLSearchParams(filterParamsString);
        const activeTab = params.get('activeTab') ? Number(params.get('activeTab')) : ProjectStatusEnum.Active;

        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );
        tableSearchParams.append('sortBy', singleSorter.field?.toString() && singleSorter.order ? singleSorter.field?.toString() : '');
        tableSearchParams.append('sortOrder', singleSorter.order || '');
        tableSearchParams.append('activeTab', activeTab.toString());

        return tableSearchParams;
    };

    private getProjects = async () => {
        this.setState({
            loading: true,
        });
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const activeTab = params.get('activeTab') ? Number(params.get('activeTab')) : ProjectStatusEnum.Active;
        const { pageIndexParam, pageSizeParam, sortByParam, sortOrderParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        const result = await new ProjectsClient().getPaginated(
            filters.name?.toString(),
            filters.shortName?.toString(),
            filters.address?.toString(),
            filters.country?.toString(),
            filters.city?.toString(),
            filters.project_investor?.toString(),
            activeTab ? [activeTab] : null,
            pageIndexParam && parseInt(pageIndexParam) ? parseInt(pageIndexParam) : 1,
            pageSizeParam && parseInt(pageSizeParam)
                ? parseInt(pageSizeParam)
                : defaultTablePagination.defaultPageSize,
            sortByParam ? capitalizeFirstLetter(sortByParam) : undefined,
            sortOrderParam
        );

        this.setState((prevState: State) => ({
            projects: result.pageItems,
            loading: false,
            totalItems: result.totalItems
        }));
    };

    public render(): React.ReactElement {
        const { loading, totalItems } = this.state;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam, pageIndexParam, pageSizeParam } = getPagingSortingParams(params);
        const filters = getFiltersParams(params);
        const pagination: TablePaginationConfig = {
            ...defaultTablePagination,
            current:
                pageIndexParam && parseInt(pageIndexParam)
                    ? parseInt(pageIndexParam)
                    : defaultTablePagination.defaultCurrent,
            pageSize:
                pageSizeParam && parseInt(pageSizeParam)
                    ? parseInt(pageSizeParam)
                    : defaultTablePagination.defaultPageSize,
            total: totalItems,
        };

        const columns = [
            {
                title: translations.projects.project,
                dataIndex: 'name',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.name ? [filters.name] : null,
                sortOrder: sortByParam === 'name' ? sortOrderParam : null,
                render: (value: string, record: ProjectVm): React.ReactElement => (
                    <Link
                        to={Routes.ROUTE_PROJECTS_READ.replace(
                            ReplaceStrings.ENTITY_ID,
                            record.id.toString()
                        )}
                    >
                        {value}
                    </Link>
                ),
            },
            {
                title: translations.projects.investor,
                dataIndex: 'project_investor',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.project_investor ? [filters.project_investor] : null,
                sortOrder: sortByParam === 'project_investor' ? sortOrderParam : null,
                render: (_: string, record: ProjectVm) => record.investor,
            },
            {
                title: translations.projects.code,
                dataIndex: 'code',
                sorter: true,
                sortOrder: sortByParam === 'code' ? sortOrderParam : null,
            },
            {
                title: translations.projects.startDate,
                dataIndex: 'startDate',
                sorter: true,
                sortOrder: sortByParam === 'startDate' ? sortOrderParam : null,
                render: (value: Date) => formatDate(value),
            },
            {
                title: translations.projects.endDate,
                dataIndex: 'endDate',
                sorter: true,
                sortOrder: sortByParam === 'endDate' ? sortOrderParam : null,
                render: (value: Date) => formatDate(value),
            },
            {
                title: translations.projects.address,
                dataIndex: 'address',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.address ? [filters.address] : null,
                sortOrder: sortByParam === 'address' ? sortOrderParam : null,
            },
            {
                title: translations.projects.country,
                dataIndex: 'country',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.country ? [filters.country] : null,
                sortOrder: sortByParam === 'country' ? sortOrderParam : null,
            },
            {
                title: translations.projects.city,
                dataIndex: 'city',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.city ? [filters.city] : null,
                sortOrder: sortByParam === 'city' ? sortOrderParam : null,
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 100,
                align: 'left',
                className: 'no-print',
                render: (value: ProjectVm): React.ReactElement => (
                    <Link
                        to={Routes.ROUTE_PROJECTS_READ.replace(
                            ReplaceStrings.ENTITY_ID,
                            value.id.toString()
                        )}
                    >
                        {translations.general.details}
                    </Link>
                ),
            },
        ];

        return (
            <Table
                locale={getTableLocale()}
                columns={columns}
                dataSource={this.state.projects || []}
                loading={loading}
                rowKey={(record: ProjectVm): string => record.id.toString()}
                pagination={pagination}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default ProjectTable;
