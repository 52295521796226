import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import React from 'react';
import { translations } from '../../config/translations';

interface Props {
    selectedKeys: React.Key[];
    confirm: (param?: FilterConfirmProps) => void;
    setSelectedKeys: (selectedKeys: React.Key[]) => void;
    clearFilters?: () => void;
}

class SearchFilter extends React.Component<Props> {
    public render = (): React.ReactElement => {
        const { selectedKeys, setSelectedKeys, confirm, clearFilters } = this.props;

        return (
            <Space direction="vertical" style={{ padding: 8 }}>
                <Input
                    placeholder={`${translations.general.search}...`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {translations.general.search}
                    </Button>
                    <Button
                        onClick={() => (clearFilters ? clearFilters() : undefined)}
                        icon={<CloseOutlined />}
                        size="small"
                    >
                        {translations.general.clear}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                        }}
                    >
                        {translations.general.filter}
                    </Button>
                </Space>
            </Space>
        );
    };
}

export default SearchFilter;
