import { Form } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import ColumnContentPicker, {
    ColumnContentValue,
} from '../../../../../components/column-content-picker';
import DrawerButtons from '../../../../../components/drawer-buttons';
import { formItemLayout321 } from '../../../../../config/formLayouts';
import { translations } from '../../../../../config/translations';
import { getColumnsFromExcelRange } from '../../../../../helpers/ExcelHelper';
import {
    getColumnContentValueForProjectItems,
    getExcelProjectItemsFieldsToDropdownOptions,
} from '../../../../../helpers/FetchAndTransformHelpers';
import { downloadFile } from '../../../../../helpers/FileHelper';
import { validateLabelOrder } from '../../../../../helpers/ValidationHelper';
import { ProjectsClient } from '../../../../../utils/api';
import { Props } from './index';

interface State {
    columns: ColumnsType<ColumnContentValue>;
    labelOrderInitial: ColumnContentValue;
}

class ProjectItemsExportForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        let initialColumns = getColumnsFromExcelRange('A:H');
        let labelOrderInitial = {
            A: 'Id',
            ...getColumnContentValueForProjectItems(
                initialColumns.slice(1).map((col) => col.key as string)
            ),
        };

        this.state = {
            columns: initialColumns,
            labelOrderInitial,
        };
    }

    private handleSubmit = async (values: any) => {
        const { projectId } = this.props;
        const { labelOrder } = values;

        const projectClient = new ProjectsClient();
        let result = await projectClient.exportProjectItems(projectId, JSON.stringify(labelOrder));

        downloadFile(result);
    };

    public render(): React.ReactElement {
        const { onClose } = this.props;
        const { columns, labelOrderInitial } = this.state;

        return (
            <div>
                <Form onFinish={this.handleSubmit} {...formItemLayout321}>
                    <DrawerButtons
                        onCancelAction={() => onClose()}
                        submitText={translations.general.export}
                    />
                    <div>
                        <Form.Item
                            name="labelOrder"
                            label={translations.projects.items.labelOrder.title}
                            rules={[
                                {
                                    validator: (_rule: any, value: any, callback: any) => {
                                        let message = validateLabelOrder(value);
                                        callback(message);
                                    },
                                },
                            ]}
                        >
                            <ColumnContentPicker
                                columns={columns}
                                options={getExcelProjectItemsFieldsToDropdownOptions()}
                                initialValue={labelOrderInitial}
                                selectWidth={134}
                                disabledColumns={['A']}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </div>
        );
    }
}

export default ProjectItemsExportForm;
