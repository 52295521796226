import { Profile } from 'oidc-client';
import { Filter } from '../../../../../core/models/Filter';
import { ProjectItemExtendedVm } from '../../../../../core/models/ProjectItems';
import {
    ProjectCooperatorItemVm,
    ProjectCooperatorVm,
    ProjectItemVm,
    ProjectSituationItemVm,
    ProjectSituationVm,
} from '../../../../../utils/api';
import ProjectSituationItemTable from './ProjectSituationItemTable';

interface OwnProps {
    projectId: number;
    situations: ProjectSituationVm[];
    contractors: ProjectCooperatorVm[];
    projectItems?: ProjectItemVm[];
    projectContractorItems?: ProjectCooperatorItemVm[];
    projectSituationItems?: ProjectSituationItemVm[];
    editingSituationItem?: Partial<ProjectSituationItemVm>;
    prevEditingItemId?: number;
    activeSituation: ProjectSituationVm;
    activeContractor?: ProjectCooperatorVm;
    activeSubcontractors?: ProjectCooperatorVm[];
    forceRenderSituationColumns?: boolean;
    forceRenderContractorColumns?: boolean;
    expandedRowKeys?: React.Key[];
    filter?: Filter;
    showAllItems: boolean;
    onAdd: (projectItemId: number, cooperatorItemId: number, situationId: number) => void;
    onEdit: (item: Partial<ProjectSituationItemVm>) => void;
    onCancel: () => void;
    onSave: (item: Partial<ProjectSituationItemVm>) => void;
    onDelete: (id: number) => void;
    onExpand: (expand: boolean, item: ProjectItemExtendedVm) => void;
    onFilterChange: (filters: any) => void;
    userProfile: Profile;
}

export type Props = OwnProps;

export default ProjectSituationItemTable;
