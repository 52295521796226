import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteParams';
import { CooperatorDetailVm } from '../../../utils/api';
import CooperatorForm from './CooperatorForm';

interface OwnProps {
    cooperator?: CooperatorDetailVm;
    onClose: () => void;
    onSuccess: (cooperator: CooperatorDetailVm) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CooperatorForm);
