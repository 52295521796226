import {
    AttachmentVm,
    ProjectDetailVm,
    EmployeeDetailVm,
    DocumentDetailVm,
    ProjectHodogramVm,
} from '../../../utils/api';
import AttachmentForm from './AttachmentForm';

interface OwnProps {
    attachment?: AttachmentVm;
    project?: ProjectDetailVm;
    employee?: EmployeeDetailVm;
    document?: DocumentDetailVm;
    hodogram?: ProjectHodogramVm;
    onClose: () => void;
    onSuccess: (result: AttachmentVm) => void;
}

export type Props = OwnProps;

export default AttachmentForm;
