import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Drawer, PageHeader, Tabs } from 'antd';
import React from 'react';
import WorkHourRecordsTable from '../../../components/work-hour-records-table';
import { DRAWER_WIDTH } from '../../../config/constants';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { ProjectItemExtendedVm } from '../../../core/models/ProjectItems';
import { ActionType, DrawerState, ModuleName, ProjectSubPage } from '../../../core/models/enum';
import { authorizeAction } from '../../../helpers/CheckPermissionHelper';
import { showError, showSuccess } from '../../../helpers/NotificationHelper';
import {
    createContractorItemCreateRequest,
    createContractorItemUpdateRequest,
    createCreateRequest,
    createSituationItemCreateRequest,
    createSituationItemUpdateRequest,
    createUpdateRequest,
    insertItem,
    removeItem,
    updateItem,
    findNextItemRecursively,
    connectContractorItems,
    findNextExtendedItemRecursively,
    connectSituationItems,
} from '../../../helpers/ProjectItemHelper';
import { buildRoute, buildSubPageRoute } from '../../../helpers/RoutingHelper';
import { remove } from '../../../helpers/SubmitHelper';
import {
    ProjectCooperatorItemsClient,
    ProjectCooperatorItemVm,
    ProjectCooperatorsClient,
    ProjectCooperatorVm,
    ProjectDetailVm,
    AttachmentsClient,
    AttachmentVm,
    ProjectEmployeesClient,
    ProjectEmployeeVm,
    ProjectItemsClient,
    ProjectItemVm,
    ProjectsClient,
    ProjectSituationItemsClient,
    ProjectSituationItemVm,
    ProjectSituationVm,
    ApiException,
} from '../../../utils/api';
import AttachmentForm from '../../attachments/attachment-form';
import AttachmentTable from '../../attachments/attachment-table';
import ProjectForm from '../project-form';
import { Props } from './index';
import ProjectContractors from './project-contractors';
import ProjectCooperatorForm from './project-cooperators/project-cooperators-form';
import ProjectCooperatorsTable from './project-cooperators/project-cooperators-table';
import ProjectDetails from './project-details';
import ProjectEmployeeForm from './project-employees/project-employee-form';
import ProjectEmployeeTable from './project-employees/project-employee-table';
import HodogramProjectDisplay from './project-hodogram';
import ProjectItems from './project-items';
import ProjectSituations from './project-situations';
import { mapProjectEmployeesToTableEmployees } from '../../../helpers/FetchAndTransformHelpers';

const { TabPane } = Tabs;

interface State {
    projectId: string;
    project?: ProjectDetailVm;
    projectEmployees?: ProjectEmployeeVm[];
    activeSubPage: ProjectSubPage;
    loading: boolean;
    drawerState: DrawerState;
    allowAddEmployee: boolean;
    allowAddCooperator: boolean;
    allowAddAttachment: boolean;
    selectedEmployee?: ProjectEmployeeVm;
    selectedCooperator?: ProjectCooperatorVm;
    selectedAttachment?: AttachmentVm;
    projectItems?: ProjectItemVm[];
    projectSituationItems?: ProjectSituationItemVm[];
    projectCooperatorItems?: ProjectCooperatorItemVm[];
    editingItem?: Partial<ProjectItemExtendedVm>;
    editingContractorItem?: Partial<ProjectCooperatorItemVm>;
    editingSituationItem?: Partial<ProjectSituationItemVm>;
}

class ProjectPage extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        const {
            userProfile,
            match: {
                params: { entityId, subPage },
            },
        } = props;

        this.state = {
            projectId: entityId,
            activeSubPage: subPage ? (subPage as ProjectSubPage) : ProjectSubPage.Details,
            drawerState: this.getInitDrawerState(),
            allowAddEmployee: authorizeAction(
                userProfile,
                ModuleName.ProjectEmployees,
                ActionType.Create
            ),
            allowAddCooperator: authorizeAction(
                userProfile,
                ModuleName.ProjectCooperators,
                ActionType.Create
            ),
            allowAddAttachment: authorizeAction(
                userProfile,
                ModuleName.Attachments,
                ActionType.Create,
                parseInt(props.match.params.entityId)
            ),
            loading: true,
        };
    }

    public componentDidMount = () => {
        this.getProject();
        this.getProjectEmployees();
        this.getProjectItems();
    };

    public componentDidUpdate = (prevProps: Props) => {
        const {
            location,
            match: {
                params: { subPage },
            },
        } = this.props;

        if (prevProps.location.pathname !== location.pathname) {
            this.setState({
                activeSubPage: subPage ? (subPage as ProjectSubPage) : ProjectSubPage.Details,
            });
        }
    };

    private getInitDrawerState = (): DrawerState => {
        const {
            match: {
                path,
                params: { subPage },
            },
        } = this.props;

        switch (path) {
            case Routes.ROUTE_PROJECT_SUBPAGE_NEW:
                // ProjectSituations component has its own drawer
                return subPage === ProjectSubPage.Situations
                    ? DrawerState.Closed
                    : DrawerState.Create;
            case Routes.ROUTE_PROJECT_SUBPAGE_EDIT:
                // ProjectSituations component has its own drawer
                return subPage === ProjectSubPage.Situations
                    ? DrawerState.Closed
                    : DrawerState.Create;
            case Routes.ROUTE_PROJECTS_EDIT:
                return DrawerState.Edit;
            default:
                return DrawerState.Closed;
        }
    };

    private getProject = async () => {
        const {
            match: {
                params: { entityId, subPage, subEntityId },
            },
        } = this.props;

        this.setState(
            {
                project: await new ProjectsClient().getById(+entityId),
                loading: false,
            },
            () => {
                if (!subEntityId) return;

                switch (subPage) {
                    case ProjectSubPage.Employees:
                        this.handleEmployeeEdit(+subEntityId);
                        break;
                    case ProjectSubPage.Cooperators:
                        this.handleCooperatorEdit(+subEntityId);
                        break;
                    case ProjectSubPage.Attachments:
                        this.handleAttachmentEdit(+subEntityId);
                        break;
                    default:
                        break;
                }
            }
        );
    };

    private getProjectEmployees = async () => {
        const {
            match: {
                params: { entityId },
            },
        } = this.props;

        this.setState({
            projectEmployees: await new ProjectsClient().getProjectEmployees(+entityId),
        });
    };

    private getProjectItems = async () => {
        const {
            match: {
                params: { entityId },
            },
        } = this.props;

        const client = new ProjectsClient();

        const data = await Promise.all([
            client.getProjectItems(+entityId),
            client.getProjectCooperatorItems(+entityId),
            client.getProjectSituationItems(+entityId),
        ]);

        this.setState({
            projectItems: data[0],
            projectCooperatorItems: data[1],
            projectSituationItems: data[2],
        });
    };

    private handleTabChange = (subPage: ProjectSubPage) => {
        const { history } = this.props;
        const { projectId, projectItems } = this.state;

        if (
            !projectItems &&
            (subPage === ProjectSubPage.Items ||
                subPage === ProjectSubPage.Contractors ||
                subPage === ProjectSubPage.Situations)
        ) {
            this.getProjectItems();
        }

        history.push(
            subPage === ProjectSubPage.Details
                ? buildRoute(
                      Routes.ROUTE_PROJECTS,
                      Routes.ROUTE_PROJECTS_NEW,
                      Routes.ROUTE_PROJECTS_READ,
                      Routes.ROUTE_PROJECTS_EDIT,
                      DrawerState.Closed,
                      projectId
                  )
                : buildSubPageRoute(
                      Routes.ROUTE_PROJECT_SUBPAGE,
                      Routes.ROUTE_PROJECT_SUBPAGE_NEW,
                      Routes.ROUTE_PROJECT_SUBPAGE_EDIT,
                      DrawerState.Closed,
                      projectId,
                      subPage
                  )
        );
    };

    private handleDrawerChange = (
        drawerState: DrawerState,
        subPage: ProjectSubPage,
        selectedEntity?: ProjectEmployeeVm | ProjectCooperatorVm | AttachmentVm
    ) => {
        const { history } = this.props;
        const { projectId } = this.state;

        const isEdit = drawerState === DrawerState.Edit;

        this.setState({
            drawerState,
            selectedEmployee:
                isEdit && subPage === ProjectSubPage.Employees
                    ? (selectedEntity as ProjectEmployeeVm)
                    : undefined,
            selectedCooperator:
                isEdit && subPage === ProjectSubPage.Cooperators
                    ? (selectedEntity as ProjectCooperatorVm)
                    : undefined,
            selectedAttachment:
                isEdit && subPage === ProjectSubPage.Attachments
                    ? (selectedEntity as AttachmentVm)
                    : undefined,
        });

        history.push(
            subPage === ProjectSubPage.Details
                ? buildRoute(
                      Routes.ROUTE_PROJECTS,
                      Routes.ROUTE_PROJECTS_NEW,
                      Routes.ROUTE_PROJECTS_READ,
                      Routes.ROUTE_PROJECTS_EDIT,
                      drawerState,
                      projectId
                  )
                : buildSubPageRoute(
                      Routes.ROUTE_PROJECT_SUBPAGE,
                      Routes.ROUTE_PROJECT_SUBPAGE_NEW,
                      Routes.ROUTE_PROJECT_SUBPAGE_EDIT,
                      drawerState,
                      projectId,
                      subPage,
                      selectedEntity?.id.toString()
                  )
        );
    };

    private handleProjectSaved = (project: ProjectDetailVm) => {
        this.setState({
            project,
        });

        this.handleDrawerChange(DrawerState.Closed, ProjectSubPage.Details);
    };

    private handleProjectDeleted = () => {
        const { history } = this.props;

        history.push(Routes.ROUTE_PROJECTS);
    };

    private handleEmployeeEdit = (id: number) => {
        const { projectEmployees } = this.state;

        if (!projectEmployees) return;

        const projectEmployee = projectEmployees?.find(
            (pe: ProjectEmployeeVm): boolean => pe.id === id
        );

        if (!projectEmployee) return;

        this.handleDrawerChange(DrawerState.Edit, ProjectSubPage.Employees, projectEmployee);
    };

    private handleEmployeeSaved = (result: ProjectEmployeeVm) => {
        const { projectEmployees } = this.state;

        const index = projectEmployees?.findIndex(
            (pe: ProjectEmployeeVm): boolean => pe.id === result.id
        );

        if (index !== -1) {
            this.setState({
                projectEmployees: [
                    ...projectEmployees!.slice(0, index),
                    result,
                    ...projectEmployees!.slice(index! + 1),
                ],
            });
        } else {
            this.setState({
                projectEmployees: [...projectEmployees!, result],
            });
        }

        this.handleDrawerChange(DrawerState.Closed, ProjectSubPage.Employees);
    };

    private handleEmployeeDelete = async (id: number) => {
        const { projectEmployees } = this.state;

        try {
            await new ProjectEmployeesClient().delete(id);
            showSuccess(translations.projects.projectEmployeeDeleted);

            if (!projectEmployees) return;

            this.setState({
                projectEmployees: projectEmployees?.filter((i) => i.id !== id),
            });
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorDeletingData);
            }
        }
    };

    private handleCooperatorEdit = (id: number) => {
        const { project } = this.state;

        if (!id) return;

        const projectCooperators = project?.cooperators || [];
        const projectCooperator = projectCooperators.find(
            (pc: ProjectCooperatorVm): boolean => pc.id === id
        );

        if (!projectCooperator) return;

        this.handleDrawerChange(DrawerState.Edit, ProjectSubPage.Cooperators, projectCooperator);
    };

    private handleCooperatorSaved = (result: ProjectCooperatorVm) => {
        const { project } = this.state;

        if (!project) return;

        const index = project.cooperators?.findIndex(
            (pe: ProjectCooperatorVm): boolean => pe.id === result.id
        );

        if (index !== -1) {
            this.setState({
                project: ProjectDetailVm.fromJS({
                    ...project,
                    cooperators: [
                        ...project.cooperators!.slice(0, index),
                        result,
                        ...project.cooperators!.slice(index! + 1),
                    ],
                }),
            });
        } else {
            this.setState({
                project: ProjectDetailVm.fromJS({
                    ...project,
                    cooperators: [...project.cooperators!, result],
                }),
            });
        }

        this.handleDrawerChange(DrawerState.Closed, ProjectSubPage.Cooperators);
    };

    private handleCooperatorDeleted = (id: number) => {
        const { project } = this.state;

        if (!project) return;

        this.setState({
            project: ProjectDetailVm.fromJS({
                ...project,
                cooperators: project.cooperators?.filter((i) => i.id !== id),
            }),
        });
    };

    private handleAttachmentEdit = (id: number) => {
        const { project } = this.state;

        if (!id) return;

        const attachments = project?.attachments || [];
        const attachment = attachments.find((pd: AttachmentVm): boolean => pd.id === id);

        if (!attachment) return;

        this.handleDrawerChange(DrawerState.Edit, ProjectSubPage.Attachments, attachment);
    };

    private handleAttachmentSaved = (result: AttachmentVm) => {
        const { project } = this.state;

        if (!project) return;

        const index = project.attachments?.findIndex(
            (pe: AttachmentVm): boolean => pe.id === result.id
        );

        if (index !== -1) {
            this.setState({
                project: ProjectDetailVm.fromJS({
                    ...project,
                    attachments: [
                        ...project.attachments!.slice(0, index),
                        result,
                        ...project.attachments!.slice(index! + 1),
                    ],
                }),
            });
        } else {
            this.setState({
                project: ProjectDetailVm.fromJS({
                    ...project,
                    attachments: [...project.attachments!, result],
                }),
            });
        }

        this.handleDrawerChange(DrawerState.Closed, ProjectSubPage.Attachments);
    };

    private handleAttachmentDeleted = (id: number) => {
        const { project } = this.state;

        if (!project) return;

        this.setState({
            project: ProjectDetailVm.fromJS({
                ...project,
                attachments: project.attachments?.filter((i) => i.id !== id),
            }),
        });
    };

    private handleSituationSaved = async (result: ProjectSituationVm) => {
        const { project } = this.state;

        if (!project) return;

        const index = project.situations?.findIndex(
            (pe: ProjectSituationVm): boolean => pe.id === result.id
        );

        if (index !== -1) {
            this.setState({
                project: ProjectDetailVm.fromJS({
                    ...project,
                    situations: [
                        ...project.situations!.slice(0, index),
                        result,
                        ...project.situations!.slice(index! + 1),
                    ],
                }),
            });
        } else {
            this.setState({
                project: ProjectDetailVm.fromJS({
                    ...project,
                    situations: [...project.situations!, result],
                }),
            });
        }
    };

    private handleSituationDeleted = async (id: number) => {
        const { project } = this.state;

        if (!project) return;

        this.setState({
            project: ProjectDetailVm.fromJS({
                ...project,
                situations: project.situations?.filter((i) => i.id !== id),
            }),
        });
    };

    private handleItemAdd = (isGroup: boolean, parentId?: number) => {
        const { projectId } = this.state;

        this.setState({
            editingItem: {
                id: 0,
                projectId: +projectId,
                parentId,
                isGroup,
            },
        });
    };

    private handleItemEdit = (item: Partial<ProjectItemExtendedVm>) => {
        this.setState({
            editingItem: item,
        });
    };

    private handleItemCancel = () => {
        this.setState({
            editingItem: undefined,
        });
    };

    private handleItemSave = async (item: Partial<ProjectItemExtendedVm>) => {
        const {
            projectId,
            editingItem,
            projectItems,
            projectCooperatorItems,
            projectSituationItems,
        } = this.state;

        if (!editingItem || !projectItems || !projectCooperatorItems || !projectSituationItems)
            return;

        const request = {
            ...item,
            id: editingItem.id,
            code: editingItem.code,
            projectId: +projectId,
            parentId: editingItem.parentId,
            isGroup: editingItem.isGroup,
        };

        let newProjectItems;
        let nextItem;
        if (request.id) {
            const result = await new ProjectItemsClient().update(
                request.id,
                createUpdateRequest(request)
            );
            newProjectItems = updateItem(projectItems, result);
            nextItem = findNextItemRecursively(projectItems, request.id)[0];
        } else {
            const result = await new ProjectItemsClient().create(createCreateRequest(request));
            newProjectItems = insertItem(projectItems, result);
        }

        this.setState({
            editingItem:
                nextItem === undefined
                    ? undefined
                    : {
                          ...nextItem,
                          children: undefined,
                      },
            projectItems: newProjectItems,
        });
    };

    private handleItemDelete = async (id: number) => {
        const { projectItems, projectCooperatorItems, projectSituationItems } = this.state;

        if (!projectItems || !projectCooperatorItems || !projectSituationItems) return;

        await new ProjectItemsClient().delete(id);

        const newProjectItems = removeItem(projectItems, id);

        this.setState({
            projectItems: newProjectItems,
        });
    };

    private handleContractorItemAdd = (projectItemId: number, projectCooperatorId: number) => {
        this.setState({
            editingContractorItem: {
                id: 0,
                projectItemId,
                projectCooperatorId,
            },
        });
    };

    private handleContractorItemEdit = (item: Partial<ProjectCooperatorItemVm>) => {
        this.setState({
            editingContractorItem: item,
        });
    };

    private handleContractorItemCancel = () => {
        this.setState({
            editingContractorItem: undefined,
        });
    };

    private handleContractorItemSave = async (item: Partial<ProjectCooperatorItemVm>) => {
        const {
            editingContractorItem,
            projectItems,
            projectCooperatorItems,
            projectSituationItems,
        } = this.state;

        if (
            !editingContractorItem ||
            !projectItems ||
            !projectCooperatorItems ||
            !projectSituationItems
        )
            return;

        const request = {
            ...item,
            id: editingContractorItem.id,
            projectItemId: editingContractorItem.projectItemId,
            projectCooperatorId: editingContractorItem.projectCooperatorId,
        };

        let newProjectContractorItems;
        let nextProjectContractorItem;

        if (request.id) {
            const result = await new ProjectCooperatorItemsClient().update(
                request.id,
                createContractorItemUpdateRequest(request)
            );

            const index = projectCooperatorItems.findIndex(
                (ci: ProjectCooperatorItemVm): boolean => ci.id === result.id
            );

            newProjectContractorItems = [
                ...projectCooperatorItems.slice(0, index),
                result,
                ...projectCooperatorItems.slice(index! + 1),
            ];

            const extendedItems = connectContractorItems(
                projectItems,
                projectCooperatorItems,
                result.projectCooperatorId,
                undefined,
                true
            );
            const nextItem = findNextExtendedItemRecursively(
                extendedItems,
                projectCooperatorItems[index].projectItemId,
                result.projectCooperatorId
            )[0];
            nextProjectContractorItem = projectCooperatorItems.find(
                (ci) =>
                    ci.projectItemId === nextItem?.id &&
                    ci.projectCooperatorId === result.projectCooperatorId
            );
        } else {
            const result = await new ProjectCooperatorItemsClient().create(
                createContractorItemCreateRequest(request)
            );
            newProjectContractorItems = [...projectCooperatorItems, result];
        }

        this.setState({
            editingContractorItem: nextProjectContractorItem,
            projectCooperatorItems: newProjectContractorItems,
        });
    };

    private handleContractorItemDelete = async (id: number) => {
        const { projectItems, projectCooperatorItems, projectSituationItems } = this.state;

        if (!projectItems || !projectCooperatorItems || !projectSituationItems) return;

        await new ProjectCooperatorItemsClient().delete(id);

        const newProjectCooperatorItems = projectCooperatorItems.filter(
            (ci: ProjectCooperatorItemVm): boolean => ci.id !== id
        );

        this.setState({
            projectCooperatorItems: newProjectCooperatorItems,
        });
    };

    private handleSituationItemAdd = (
        projectItemId: number,
        cooperatorItemId: number,
        situationId: number
    ) => {
        this.setState({
            editingSituationItem: {
                id: 0,
                projectItemId,
                projectCooperatorItemId: cooperatorItemId,
                projectSituationId: situationId,
            },
        });
    };

    private handleSituationItemEdit = (item: Partial<ProjectSituationItemVm>) => {
        this.setState({
            editingSituationItem: item,
        });
    };

    private handleSituationItemCancel = () => {
        this.setState({
            editingSituationItem: undefined,
        });
    };

    private handleSituationItemSave = async (item: Partial<ProjectCooperatorItemVm>) => {
        const {
            editingSituationItem,
            projectItems,
            projectCooperatorItems,
            projectSituationItems,
            project,
        } = this.state;

        if (
            !editingSituationItem ||
            !projectItems ||
            !projectCooperatorItems ||
            !projectSituationItems
        )
            return;

        const request = {
            ...item,
            id: editingSituationItem.id,
            projectCooperatorItemId: editingSituationItem.projectCooperatorItemId,
            projectSituationId: editingSituationItem.projectSituationId,
        };

        let newProjectSituationItems;
        let nextProjectSituationItem;

        if (request.id) {
            const result = await new ProjectSituationItemsClient().update(
                request.id,
                createSituationItemUpdateRequest(request)
            );

            const index = projectSituationItems.findIndex(
                (si: ProjectSituationItemVm): boolean => si.id === result.id
            );
            newProjectSituationItems = [
                ...projectSituationItems.slice(0, index),
                result,
                ...projectSituationItems.slice(index! + 1),
            ];

            const connectedItems = connectSituationItems(
                projectItems,
                projectCooperatorItems,
                projectSituationItems,
                true,
                project?.situations!,
                project?.cooperators?.filter((c) => c.hasBoq)!,
                result.projectSituationId,
                result.projectCooperatorId,
                undefined
            );

            const nextItem = findNextExtendedItemRecursively(
                connectedItems,
                result.projectItemId,
                result.projectCooperatorId
            )[0];
            nextProjectSituationItem = projectSituationItems.find(
                (si) => si.projectItemId === nextItem?.id
            );
        } else {
            const result = await new ProjectSituationItemsClient().create(
                createSituationItemCreateRequest(request)
            );
            newProjectSituationItems = [...projectSituationItems, result];
        }

        this.setState({
            editingSituationItem: nextProjectSituationItem,
            projectSituationItems: newProjectSituationItems,
        });
    };

    private handleSituationItemDelete = async (id: number) => {
        const { projectItems, projectCooperatorItems, projectSituationItems } = this.state;

        if (!projectItems || !projectCooperatorItems || !projectSituationItems) return;

        await new ProjectSituationItemsClient().delete(id);

        const newProjectSituationItems = projectSituationItems.filter(
            (si: ProjectSituationItemVm): boolean => si.id !== id
        );

        this.setState({
            projectSituationItems: newProjectSituationItems,
        });
    };

    private getDrawerTitle = (): string => {
        const { selectedEmployee, selectedCooperator, selectedAttachment, activeSubPage } =
            this.state;

        switch (activeSubPage) {
            case ProjectSubPage.Details:
                return translations.projects.editProject;
            case ProjectSubPage.Employees:
                return selectedEmployee
                    ? translations.employees.editEmployee
                    : translations.employees.addEmployee;
            case ProjectSubPage.Cooperators:
                return selectedCooperator
                    ? translations.cooperators.editCooperator
                    : translations.cooperators.addCooperator;
            case ProjectSubPage.Attachments:
                return selectedAttachment
                    ? translations.attachments.edit
                    : translations.attachments.add;
            default:
                return '';
        }
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;

        const {
            project,
            drawerState,
            allowAddEmployee,
            allowAddCooperator,
            allowAddAttachment,
            selectedEmployee,
            selectedCooperator,
            selectedAttachment,
            projectEmployees,
            projectItems,
            projectCooperatorItems,
            projectSituationItems,
            editingItem,
            editingContractorItem,
            editingSituationItem,
            loading,
            activeSubPage,
        } = this.state;

        return (
            <>
                <PageHeader title={project?.name || ''} />

                <Tabs
                    style={{ width: '100%' }}
                    activeKey={activeSubPage}
                    onTabClick={(key: string) => this.handleTabChange(key as ProjectSubPage)}
                    destroyInactiveTabPane={true}
                >
                    <TabPane tab={translations.projects.details} key={ProjectSubPage.Details}>
                        {project && (
                            <ProjectDetails
                                userProfile={userProfile}
                                project={project}
                                onUpdate={() =>
                                    this.handleDrawerChange(
                                        DrawerState.Edit,
                                        ProjectSubPage.Details
                                    )
                                }
                                onDelete={() =>
                                    remove(
                                        new ProjectsClient(),
                                        translations.projects.projectDeleted,
                                        this.handleProjectDeleted,
                                        project.id
                                    )
                                }
                            />
                        )}
                    </TabPane>
                    <TabPane tab={translations.hodogram.hodogram} key={ProjectSubPage.Hodogram}>
                        <HodogramProjectDisplay
                            userProfile={userProfile}
                            projectId={+this.state.projectId}
                        />
                    </TabPane>
                    <TabPane
                        tab={translations.projects.cooperators}
                        key={ProjectSubPage.Cooperators}
                    >
                        <PageHeader
                            title=""
                            extra={
                                allowAddCooperator
                                    ? [
                                          <Button
                                              key="1"
                                              type="primary"
                                              style={{
                                                  zIndex: 10,
                                                  float: 'right',
                                              }}
                                              onClick={() =>
                                                  this.handleDrawerChange(
                                                      DrawerState.Create,
                                                      ProjectSubPage.Cooperators
                                                  )
                                              }
                                              icon={<PlusOutlined />}
                                              className="no-print"
                                          >
                                              {translations.cooperators.addCooperator}
                                          </Button>,
                                      ]
                                    : []
                            }
                        />
                        {project && (
                            <ProjectCooperatorsTable
                                userProfile={userProfile}
                                cooperators={project.cooperators || []}
                                onUpdate={this.handleCooperatorEdit}
                                onDelete={(id: number) =>
                                    remove(
                                        new ProjectCooperatorsClient(),
                                        translations.projects.projectCooperatorDeleted,
                                        () => this.handleCooperatorDeleted(id),
                                        id
                                    )
                                }
                            />
                        )}
                    </TabPane>
                    <TabPane tab={translations.projects.employees} key={ProjectSubPage.Employees}>
                        <PageHeader
                            title=""
                            extra={
                                allowAddEmployee
                                    ? [
                                          <Button
                                              key="1"
                                              type="primary"
                                              style={{
                                                  zIndex: 10,
                                                  float: 'right',
                                              }}
                                              onClick={() =>
                                                  this.handleDrawerChange(
                                                      DrawerState.Create,
                                                      ProjectSubPage.Employees
                                                  )
                                              }
                                              icon={<PlusOutlined />}
                                              className="no-print"
                                          >
                                              {translations.employees.addEmployee}
                                          </Button>,
                                      ]
                                    : []
                            }
                        />
                        {projectEmployees && (
                            <ProjectEmployeeTable
                                userProfile={userProfile}
                                employees={projectEmployees || []}
                                onUpdate={this.handleEmployeeEdit}
                                onDelete={(id: number) => this.handleEmployeeDelete(id)}
                            />
                        )}
                    </TabPane>
                    <TabPane
                        tab={translations.workHourRecods.title}
                        key={ProjectSubPage.WorkHourRecords}
                    >
                        {projectEmployees?.length === 0 && (
                            <Alert
                                message={translations.workHourRecods.noEmployeesWarning.message}
                                type="warning"
                                showIcon
                            />
                        )}

                        {project &&
                            !!projectEmployees?.length &&
                            activeSubPage === ProjectSubPage.WorkHourRecords && (
                                <WorkHourRecordsTable
                                    project={project}
                                    employees={mapProjectEmployeesToTableEmployees(
                                        projectEmployees
                                    )}
                                />
                            )}
                    </TabPane>
                    <TabPane tab={translations.projects.items.title} key={ProjectSubPage.Items}>
                        {project && (
                            <ProjectItems
                                projectId={project.id}
                                projectItems={projectItems}
                                projectSituationItems={projectSituationItems}
                                editingItem={editingItem}
                                getProjectItems={this.getProjectItems}
                                onAdd={this.handleItemAdd}
                                onEdit={this.handleItemEdit}
                                onCancel={this.handleItemCancel}
                                onSave={this.handleItemSave}
                                onDelete={this.handleItemDelete}
                                userProfile={userProfile}
                                loading={loading}
                            />
                        )}
                    </TabPane>
                    <TabPane
                        tab={translations.projects.contractors.title}
                        key={ProjectSubPage.Contractors}
                    >
                        {project && (
                            <ProjectContractors
                                userProfile={userProfile}
                                projectId={project.id}
                                projectItems={projectItems}
                                projectCooperatorItems={projectCooperatorItems}
                                contractors={project?.cooperators?.filter((c) => c.hasBoq) || []}
                                editingContractorItem={editingContractorItem}
                                getProjectItems={this.getProjectItems}
                                onAdd={this.handleContractorItemAdd}
                                onEdit={this.handleContractorItemEdit}
                                onCancel={this.handleContractorItemCancel}
                                onSave={this.handleContractorItemSave}
                                onDelete={this.handleContractorItemDelete}
                                loading={loading}
                            />
                        )}
                    </TabPane>
                    <TabPane
                        tab={translations.projects.situations.title}
                        key={ProjectSubPage.Situations}
                    >
                        {project && (
                            <ProjectSituations
                                userProfile={userProfile}
                                projectId={project.id}
                                projectItems={projectItems}
                                projectContractorItems={projectCooperatorItems!}
                                projectSituationItems={projectSituationItems}
                                situations={project.situations!}
                                contractors={project.cooperators?.filter((c) => c.hasBoq) || []}
                                editingSituationItem={editingSituationItem}
                                onSituationSaved={this.handleSituationSaved}
                                onSituationDeleted={this.handleSituationDeleted}
                                onItemAdd={this.handleSituationItemAdd}
                                onItemEdit={this.handleSituationItemEdit}
                                onItemCancel={this.handleSituationItemCancel}
                                onItemSave={this.handleSituationItemSave}
                                onItemDelete={this.handleSituationItemDelete}
                            />
                        )}
                    </TabPane>
                    <TabPane
                        tab={translations.attachments.attachments}
                        key={ProjectSubPage.Attachments}
                    >
                        <PageHeader
                            title=""
                            extra={
                                allowAddAttachment
                                    ? [
                                          <Button
                                              key="1"
                                              type="primary"
                                              style={{
                                                  zIndex: 10,
                                                  float: 'right',
                                              }}
                                              onClick={() =>
                                                  this.handleDrawerChange(
                                                      DrawerState.Create,
                                                      ProjectSubPage.Attachments
                                                  )
                                              }
                                              icon={<PlusOutlined />}
                                              className="no-print"
                                          >
                                              {translations.attachments.add}
                                          </Button>,
                                      ]
                                    : []
                            }
                        />
                        {project && (
                            <AttachmentTable
                                userProfile={userProfile}
                                attachments={project.attachments}
                                onUpdate={this.handleAttachmentEdit}
                                onDelete={(id: number) =>
                                    remove(
                                        new AttachmentsClient(),
                                        translations.attachments.deleteSuccess,
                                        () => this.handleAttachmentDeleted(id),
                                        id
                                    )
                                }
                            />
                        )}
                    </TabPane>
                </Tabs>

                {project && (
                    <Drawer
                        title={this.getDrawerTitle()}
                        open={!!drawerState}
                        onClose={() => this.handleDrawerChange(DrawerState.Closed, activeSubPage)}
                        width={DRAWER_WIDTH}
                        destroyOnClose
                    >
                        {activeSubPage === ProjectSubPage.Details && (
                            <ProjectForm
                                project={project}
                                onSuccess={this.handleProjectSaved}
                                onClose={() =>
                                    this.handleDrawerChange(
                                        DrawerState.Closed,
                                        ProjectSubPage.Details
                                    )
                                }
                            />
                        )}
                        {activeSubPage === ProjectSubPage.Employees && (
                            <ProjectEmployeeForm
                                project={project}
                                projectEmployee={selectedEmployee}
                                onSuccess={this.handleEmployeeSaved}
                                onClose={() =>
                                    this.handleDrawerChange(
                                        DrawerState.Closed,
                                        ProjectSubPage.Employees
                                    )
                                }
                            />
                        )}
                        {activeSubPage === ProjectSubPage.Cooperators && (
                            <ProjectCooperatorForm
                                project={project}
                                projectCooperator={selectedCooperator}
                                onSuccess={this.handleCooperatorSaved}
                                onClose={() =>
                                    this.handleDrawerChange(
                                        DrawerState.Closed,
                                        ProjectSubPage.Cooperators
                                    )
                                }
                            />
                        )}
                        {activeSubPage === ProjectSubPage.Attachments && (
                            <AttachmentForm
                                attachment={selectedAttachment}
                                project={project}
                                onSuccess={this.handleAttachmentSaved}
                                onClose={() =>
                                    this.handleDrawerChange(
                                        DrawerState.Closed,
                                        ProjectSubPage.Attachments
                                    )
                                }
                            />
                        )}
                    </Drawer>
                )}
            </>
        );
    }
}

export default ProjectPage;
