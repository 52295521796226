import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../../core/models/RouteParams';
import {
    ProjectCooperatorItemVm,
    ProjectCooperatorVm,
    ProjectItemVm,
    ProjectSituationItemVm,
    ProjectSituationVm,
} from '../../../../utils/api';
import ProjectSituations from './ProjectSituations';

interface OwnProps {
    projectId: number;
    projectItems?: ProjectItemVm[];
    projectContractorItems?: ProjectCooperatorItemVm[];
    projectSituationItems?: ProjectSituationItemVm[];
    situations: ProjectSituationVm[];
    contractors: ProjectCooperatorVm[];
    editingSituationItem?: Partial<ProjectSituationItemVm>;
    onSituationDeleted: (id: number) => void;
    onSituationSaved: (result: ProjectSituationVm) => void;
    onItemAdd: (projectItemId: number, cooperatorItemId: number, situationId: number) => void;
    onItemEdit: (item: Partial<ProjectSituationItemVm>) => void;
    onItemCancel: () => void;
    onItemDelete: (id: number) => void;
    onItemSave: (item: Partial<ProjectSituationItemVm>) => void;
    userProfile: Profile;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(ProjectSituations);
