import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../core/models/RouteParams';
import EmployeePage from './EmployeePage';

interface OwnProps {
    userProfile: Profile;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(EmployeePage);
