import { SaveOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import React from 'react';
import { translations } from '../../config/translations';
import { Props } from './index';

class DrawerButtons extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): React.ReactElement {
        const {
            isSaving,
            isFormDirty,
            onCancelAction,
            onSubmitAction,
            submitText,
            loadingText,
            cancelText,
        } = this.props;

        return (
            <Row style={{ height: 50, display: 'flow-root' }}>
                <div style={{ float: 'right' }}>
                    <Button
                        type="default"
                        style={{ width: 100 }}
                        disabled={isSaving}
                        onClick={onCancelAction}>
                        {cancelText || translations.general.cancel}
                    </Button>
                    <Button
                        type="primary"
                        style={{
                            marginLeft: 10,
                            width: 100,
                        }}
                        htmlType="submit"
                        disabled={isSaving || (isFormDirty !== undefined && !isFormDirty)}
                        onClick={onSubmitAction}
                    >
                        {isSaving ? <SyncOutlined spin /> : <SaveOutlined />}
                        {isSaving
                            ? loadingText || submitText || translations.general.save
                            : submitText || translations.general.save}
                    </Button>
                </div>
            </Row>
        );
    }
}

export default DrawerButtons;
