import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../../core/models/RouteParams';
import { DocumentTypeTranslations } from '../../../../core/models/Translations';
import { DocumentDetailVm } from '../../../../utils/api';
import DocumentDetails from './DocumentDetails';

interface OwnProps {
    document: DocumentDetailVm;
    docTypeTranslations: DocumentTypeTranslations;
    userProfile: Profile;
    onComplete: (item: DocumentDetailVm) => void;
}
export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(DocumentDetails);
