import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader } from 'antd';
import React from 'react';
import { Props } from '.';
import NewsDetail from '../../../components/news-components/news-detail';
import { DRAWER_WIDTH } from '../../../config/constants';
import { translations } from '../../../config/translations';
import { ActionType, DrawerState, ModuleName } from '../../../core/models/enum';
import { authorizeAction } from '../../../helpers/CheckPermissionHelper';
import { NewsVm } from '../../../utils/api';
import NewsForm from './news-form';
import NewsTable from './news-table';

interface State {
    allowCreate: boolean;
}

class NewsAdmin extends React.Component<Props, State> {
    private tableRef: any = React.createRef();

    public constructor(props: Props) {
        super(props);

        this.state = {
            allowCreate: authorizeAction(props.userProfile, ModuleName.Users, ActionType.Create),
        };
    }

    private handleTemplateCreate = (selectedNews: NewsVm) => {
        this.props.setSelectedNews(selectedNews);
        this.props.handleDrawerMode(DrawerState.Create);
    };

    private handleSuccessCreate = (_: NewsVm) => {
        this.props.handleDrawerMode(DrawerState.Closed);
        this.tableRef.current.getNews();
    };

    public render(): React.ReactElement {
        const { userProfile, drawerState, selectedNews } = this.props;
        const { allowCreate } = this.state;

        return (
            <>
                <PageHeader
                    title={translations.news.news}
                    extra={
                        allowCreate
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      onClick={() => this.props.handleDrawerMode(DrawerState.Edit)}
                                      className="no-print"
                                  >
                                      <PlusOutlined />
                                      {translations.news.addNews}
                                  </Button>,
                              ]
                            : []
                    }
                />
                <NewsTable
                    wrappedComponentRef={this.tableRef}
                    userProfile={userProfile}
                    handleTemplateCreate={this.handleTemplateCreate}
                />
                <Drawer
                    title={
                        drawerState === DrawerState.Create
                            ? translations.news.addNews
                            : drawerState === DrawerState.Edit
                            ? translations.news.editNews
                            : translations.news.detailNews
                    }
                    open={!!drawerState}
                    onClose={() => this.props.handleDrawerMode(DrawerState.Closed)}
                    width={DRAWER_WIDTH}
                    destroyOnClose
                >
                    {drawerState === DrawerState.Read ? (
                        <NewsDetail isAdminView={true} news={selectedNews} />
                    ) : (
                        <NewsForm
                            onClose={() => this.props.handleDrawerMode(DrawerState.Closed)}
                            onSuccess={this.handleSuccessCreate}
                            news={selectedNews}
                            drawerState={drawerState}
                        ></NewsForm>
                    )}
                </Drawer>
            </>
        );
    }
}

export default NewsAdmin;
