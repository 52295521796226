import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../../../core/models/RouteParams';
import { ProjectDetailVm, ProjectEmployeeVm } from '../../../../../utils/api';
import ProjectEmployeeForm from './ProjectEmployeeForm';

interface OwnProps {
    project?: ProjectDetailVm;
    projectEmployee?: ProjectEmployeeVm;
    onClose: () => void;
    onSuccess: (result: ProjectEmployeeVm) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(ProjectEmployeeForm);
