import ProjectItems from './ProjectItems';
import { Profile } from 'oidc-client';
import { ProjectItemExtendedVm } from '../../../../core/models/ProjectItems';
import { ProjectItemVm, ProjectSituationItemVm } from '../../../../utils/api';

interface OwnProps {
    projectId: number;
    projectItems?: ProjectItemVm[];
    projectSituationItems?: ProjectSituationItemVm[];
    mergedItems?: ProjectItemExtendedVm[];
    editingItem?: Partial<ProjectItemExtendedVm>;
    getProjectItems: () => void;
    onAdd: (isGroup: boolean, parentId?: number) => void;
    onEdit: (item: Partial<ProjectItemExtendedVm>) => void;
    onCancel: () => void;
    onDelete: (id: number) => void;
    onSave: (item: Partial<ProjectItemExtendedVm>) => void;
    userProfile: Profile;
    loading: boolean;
}

export type Props = OwnProps;

export default ProjectItems;
