import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Space, Checkbox } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import React from 'react';
import { translations } from '../../config/translations';
import { SelectOptionVm } from '../../utils/api';

interface Props {
    selectedKeys: React.Key[];
    confirm: (param?: FilterConfirmProps) => void;
    setSelectedKeys: (selectedKeys: React.Key[]) => void;
    clearFilters?: () => void;
    options: SelectOptionVm[];
}

class CheckboxFilter extends React.Component<Props> {
    public render = (): React.ReactElement => {
        const { selectedKeys, setSelectedKeys, confirm, clearFilters, options } = this.props;

        return (
            <Space direction="vertical" style={{ padding: 8 }}>
                <Checkbox.Group
                    value={selectedKeys}
                    onChange={(values: any) => setSelectedKeys(values)}
                    options={options}
                    className="vertical"
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {translations.general.search}
                    </Button>
                    <Button
                        onClick={() => (clearFilters ? clearFilters() : undefined)}
                        icon={<CloseOutlined />}
                        size="small"
                    >
                        {translations.general.clear}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                        }}
                    >
                        {translations.general.filter}
                    </Button>
                </Space>
            </Space>
        );
    };
}

export default CheckboxFilter;
