import { Profile } from "oidc-client";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RouteParamsGeneric } from "../../../../core/models/RouteParams";
import { NewsVm } from "../../../../utils/api";
import NewsTable from "./NewsTable"

interface OwnProps {
    userProfile: Profile;
    wrappedComponentRef?: any;
    handleTemplateCreate: (selectedNews: NewsVm) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>

export default withRouter(NewsTable)