import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, Space } from 'antd';
import React from 'react';
import { DRAWER_WIDTH } from '../../../../config/constants';
import { formItemLayout724 } from '../../../../config/formLayouts';
import ReplaceStrings from '../../../../config/replaceStrings';
import Routes from '../../../../config/routes';
import { translations } from '../../../../config/translations';
import { ActionType, DrawerState, ModuleName } from '../../../../core/models/enum';
import { authorizeAction } from '../../../../helpers/CheckPermissionHelper';
import { showConfirm } from '../../../../helpers/NotificationHelper';
import { getNewRoute } from '../../../../helpers/RoutingHelper';
import { remove } from '../../../../helpers/SubmitHelper';
import { CooperatorDetailVm, CooperatorsClient } from '../../../../utils/api';
import CooperatorForm from '../../cooperator-form';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    loading: boolean;
    allowUpdate: boolean;
    allowDelete: boolean;
}

class CooperatorDetails extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            drawerState: props.isInitialEdit ? DrawerState.Edit : DrawerState.Closed,
            loading: true,
            allowUpdate: authorizeAction(
                props.userProfile,
                ModuleName.Cooperators,
                ActionType.Update
            ),
            allowDelete: authorizeAction(
                props.userProfile,
                ModuleName.Cooperators,
                ActionType.Delete
            ),
        };
    }

    private handleDrawerMode = (drawerState: DrawerState, id?: string) => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_COOPERATORS,
                Routes.ROUTE_COOPERATORS_READ,
                Routes.ROUTE_COOPERATORS_EDIT,
                drawerState,
                ReplaceStrings.COOPERATOR_ID,
                id
            )
        );
    };

    private openForm = () => {
        const { cooperator } = this.props;

        this.handleDrawerMode(DrawerState.Edit, cooperator.id.toString());
    };

    private handleSuccess = (cooperator: CooperatorDetailVm) => {
        const { onSuccess } = this.props;

        this.handleDrawerMode(DrawerState.Read, cooperator.id.toString());
        onSuccess(cooperator);
    };

    private handleDelete = () => {
        const { history } = this.props;

        history.push(Routes.ROUTE_COOPERATORS);
    };

    public render(): React.ReactElement {
        const { cooperator } = this.props;
        const { drawerState, allowUpdate, allowDelete } = this.state;

        return (
            <>
                <Row>
                    <Col span={3} offset={21}>
                        <Space style={{ float: 'right', marginBottom: 15 }}>
                            {allowUpdate && (
                                <Button icon={<EditOutlined />} onClick={() => this.openForm()}>
                                    {translations.general.edit}
                                </Button>
                            )}
                            {allowDelete && (
                                <Button
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                        showConfirm(
                                            () =>
                                                remove(
                                                    new CooperatorsClient(),
                                                    translations.cooperators.cooperatorDeleted,
                                                    this.handleDelete,
                                                    cooperator.id
                                                ),
                                            translations.cooperators.deleteMessage,
                                            translations.general.delete,
                                            true
                                        )
                                    }
                                >
                                    {translations.general.delete}
                                </Button>
                            )}
                        </Space>
                    </Col>

                    <Col span={12}>
                        <Form size="small" {...formItemLayout724}>
                            <Form.Item label={translations.cooperators.name}>
                                <Input readOnly value={cooperator.name} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.personType}>
                                <Input readOnly value={cooperator.personType} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.associateType}>
                                <Input readOnly value={cooperator.associateType} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.oib}>
                                <Input readOnly value={cooperator.oib} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.mbg}>
                                <Input readOnly value={cooperator.mbg} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.mb}>
                                <Input readOnly value={cooperator.mb} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.iban}>
                                <Input readOnly value={cooperator.iban} />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form size="small" {...formItemLayout724}>
                            <Form.Item label={translations.cooperators.address}>
                                <Input readOnly value={cooperator.address} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.zip}>
                                <Input readOnly value={cooperator.zip} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.city}>
                                <Input readOnly value={cooperator.city} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.country}>
                                <Input readOnly value={cooperator.country} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.email}>
                                <Input readOnly value={cooperator.email} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.phone}>
                                <Input readOnly value={cooperator.phone} />
                            </Form.Item>
                            <Form.Item label={translations.cooperators.web}>
                                <Input readOnly value={cooperator.web} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Drawer
                    title={translations.cooperators.editCooperator}
                    open={drawerState === DrawerState.Edit}
                    onClose={() =>
                        this.handleDrawerMode(DrawerState.Read, cooperator.id.toString())
                    }
                    width={DRAWER_WIDTH}
                    destroyOnClose
                >
                    <CooperatorForm
                        onClose={() =>
                            this.handleDrawerMode(DrawerState.Read, cooperator.id.toString())
                        }
                        onSuccess={this.handleSuccess}
                        cooperator={cooperator}
                    />
                </Drawer>
            </>
        );
    }
}

export default CooperatorDetails;
