import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Space } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import moment from 'moment';
import React from 'react';
import { defaultFormat } from '../../config/constants';
import { translations } from '../../config/translations';

interface Props {
    selectedKeys: React.Key[];
    confirm: (param?: FilterConfirmProps) => void;
    setSelectedKeys: (selectedKeys: React.Key[]) => void;
    clearFilters?: () => void;
}

class DatePickerFilter extends React.Component<Props> {
    public render = (): React.ReactElement => {
        const { selectedKeys, setSelectedKeys, confirm, clearFilters } = this.props;

        return (
            <Space direction="vertical" style={{ padding: 8 }}>
                <DatePicker
                    placeholder={`${translations.general.chooseDate}...`}
                    value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
                    onChange={(e) => setSelectedKeys(e ? [e.toString()] : [])}
                    format={defaultFormat}
                    style={{ width: '100%' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {translations.general.search}
                    </Button>
                    <Button
                        onClick={() => (clearFilters ? clearFilters() : undefined)}
                        icon={<CloseOutlined />}
                        size="small"
                    >
                        {translations.general.clear}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                        }}
                    >
                        {translations.general.filter}
                    </Button>
                </Space>
            </Space>
        );
    };
}

export default DatePickerFilter;
