import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteParams';
import { ExpenseGroupVm } from '../../../utils/api';
import ExpenseGroupTable from './ExpenseGroupTable';

interface OwnProps {
    expenseGroups?: ExpenseGroupVm[];
    userProfile: Profile;
    wrappedComponentRef?: any;
    expenseGroupsFromProps?: ExpenseGroupVm[];
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(ExpenseGroupTable);
