import { FilterValue } from 'antd/lib/table/interface';
import { ParamsEnum } from '../core/models/enum';

interface pageSortParams {
    pageIndexParam: string | undefined | null;
    pageSizeParam: string | undefined | null;
    sortByParam: string | undefined | null;
    sortOrderParam: string | undefined | null;
}
export const getPagingSortingParams = (params: URLSearchParams): pageSortParams => {
    const pageIndexParam = params.get(ParamsEnum.PageIndex);
    const pageSizeParam = params.get(ParamsEnum.PageSize);
    const sortByParam = params.get(ParamsEnum.SortBy);
    const sortOrderParam = params.get(ParamsEnum.SortOrder);

    return {
        pageIndexParam,
        pageSizeParam,
        sortByParam,
        sortOrderParam,
    };
};

export const getFiltersParams = (params: URLSearchParams): Record<string, FilterValue | null> => {
    const filters: Record<string, FilterValue | null> = {};
    const FILTER = 'filter.';

    params.forEach((value, key) => {
        if (key.startsWith(FILTER)) {
            const trimmedKey = key.substring(FILTER.length);
            filters[trimmedKey] = (value as unknown as FilterValue)
                ? (value as unknown as FilterValue)
                : null;
        }
    });

    return filters;
};

export const getFilterParamsString = (filters: Record<string, FilterValue | null> = {}): string => {
    let filterParamsString = '';
    for (const key in filters) {
        if (filters[key] !== null) {
            filterParamsString += '&filter.' + key + '=' + filters[key];
        }
    }
    return filterParamsString;
};

export const applySearchParams = (
    currSearchParams: URLSearchParams,
    tableSearchParams: URLSearchParams
): URLSearchParams => {
    const searchParams = new URLSearchParams(currSearchParams);

    tableSearchParams.forEach((value, key) => {
        if (currSearchParams.has(key)) {
            searchParams.set(key, value);
        } else {
            searchParams.append(key, value);
        }
    });

    currSearchParams.forEach((value, key) => {
        if(!tableSearchParams.has(key)) {
            searchParams.delete(key);
        }
    });

    return searchParams;
};
