import { RouteComponentProps, withRouter } from 'react-router-dom';
import WarehouseTable from './WarehouseTable';
import { RouteParams } from '../../../core/models/RouteParams';

interface OwnProps {
    wrappedComponentRef?: any;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(WarehouseTable);
