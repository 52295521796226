import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../core/models/RouteParams';
import { NewsVm } from '../../../utils/api';
import NewsDetail from './NewsDetail'

interface OwnProps {
    news?: NewsVm;
    isAdminView: boolean;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(NewsDetail);