import Table, { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { Link } from 'react-router-dom';
import { defaultTablePagination } from '../../../config/constants';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { getCheckboxFilter, getKeyFilter, getSearchFilter } from '../../../helpers/FilterHelper';
import { getDefaultComparer } from '../../../helpers/SortHelper';
import { capitalizeFirstLetter } from '../../../helpers/StringHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import {
    CodebooksClient,
    CooperatorsClient,
    CooperatorVm,
    SelectOptionVm,
} from '../../../utils/api';
import { Props } from './index';
import {
    getPagingSortingParams,
    getFiltersParams,
    getFilterParamsString,
    applySearchParams,
} from '../../../helpers/SearchParamsHelper';

interface State {
    cooperators?: CooperatorVm[];
    associateTypes: SelectOptionVm[];
    personTypes: SelectOptionVm[];
    loading?: boolean;
    totalItems?: number
}

class CooperatorTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            associateTypes: [],
            personTypes: [],
            totalItems: 0
        };
    }

    public componentDidMount = () => {
        this.getCooperators();
        this.getAssociateTypes();
        this.getPersonTypes();
    };

    public componentDidUpdate(prevProps: Props) {
        const {
            location: { search },
        } = this.props;

        if (prevProps.location.search !== search) {
            this.getCooperators();
        }
    }

    private handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<CooperatorVm> | SorterResult<CooperatorVm>[] = {}
    ) => {
        const {
            history,
            location: { search },
        } = this.props;

        const currSearchParams = new URLSearchParams(search);
        const tableSearchParams = this.createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    private createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<CooperatorVm> | SorterResult<CooperatorVm>[] = {}
    ): URLSearchParams => {
        const filterParamsString = getFilterParamsString(filters);
        const singleSorter = sorter as SorterResult<CooperatorVm>;
        const tableSearchParams = new URLSearchParams(filterParamsString);

        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );
        tableSearchParams.append('sortBy', singleSorter.field?.toString() && singleSorter.order ? singleSorter.field?.toString() : '');
        tableSearchParams.append('sortOrder', singleSorter.order || '');

        return tableSearchParams;
    };

    private getCooperators = async () => {
        this.setState({
            loading: true,
        });
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { pageIndexParam, pageSizeParam, sortByParam, sortOrderParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        const result = await new CooperatorsClient().getPaginated(
            filters.name?.toString(),
            filters.oib?.toString(),
            filters.mbg?.toString(),
            filters.mb?.toString(),
            filters.address?.toString(),
            filters.zip?.toString(),
            filters.city?.toString(),
            filters.country ? filters.country?.toString() : null,
            filters.personTypeId
                ? ((filters.personTypeId as unknown as string).split(',') as unknown as number[])
                : null,
            filters.associateTypeId
                ? ((filters.associateTypeId as unknown as string).split(',') as unknown as number[])
                : null,
            pageIndexParam && parseInt(pageIndexParam) ? parseInt(pageIndexParam) : 1,
            pageSizeParam && parseInt(pageSizeParam)
                ? parseInt(pageSizeParam)
                : defaultTablePagination.defaultPageSize,
            sortByParam ? capitalizeFirstLetter(sortByParam) : undefined,
            sortOrderParam
        );

        this.setState((prevState: State) => ({
            cooperators: result.pageItems,
            loading: false,
            totalItems: result.totalItems
        }));
    };

    private getAssociateTypes = async () => {
        this.setState({
            associateTypes: await new CodebooksClient().getByCodebookName('AssociateType'),
        });
    };

    private getPersonTypes = async () => {
        this.setState({
            personTypes: await new CodebooksClient().getByCodebookName('PersonType'),
        });
    };

    public render(): React.ReactElement {
        const { cooperators, personTypes, associateTypes, loading, totalItems } = this.state;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam, pageIndexParam, pageSizeParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);
        const pagination: TablePaginationConfig = {
            ...defaultTablePagination,
            current:
                pageIndexParam && parseInt(pageIndexParam)
                    ? parseInt(pageIndexParam)
                    : defaultTablePagination.defaultCurrent,
            pageSize:
                pageSizeParam && parseInt(pageSizeParam)
                    ? parseInt(pageSizeParam)
                    : defaultTablePagination.defaultPageSize,
            total: totalItems,
        };

        const columns = [
            {
                title: translations.cooperators.name,
                dataIndex: 'name',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.name ? [filters.name] : null,
                sortOrder: sortByParam === 'name' ? sortOrderParam : null,
                render: (value: string, record: CooperatorVm): React.ReactElement => (
                    <Link
                        to={Routes.ROUTE_COOPERATORS_READ.replace(
                            ReplaceStrings.COOPERATOR_ID,
                            record.id.toString()
                        )}
                    >
                        {value}
                    </Link>
                ),
            },
            {
                title: translations.cooperators.personType,
                dataIndex: 'personTypeId',
                ...getCheckboxFilter(personTypes),
                onFilter: getKeyFilter('personTypeId'),
                sorter: getDefaultComparer('personType'),
                filteredValue: filters.personTypeId
                    ? (filters.personTypeId as unknown as string).split(',')
                    : null,
                sortOrder: sortByParam === 'personTypeId' ? sortOrderParam : null,
                render: (_: number, record: CooperatorVm) => record.personType,
            },
            {
                title: translations.cooperators.associateType,
                dataIndex: 'associateTypeId',
                ...getCheckboxFilter(associateTypes),
                onFilter: getKeyFilter('associateTypeId'),
                sorter: getDefaultComparer('associateType'),
                filteredValue: filters.associateTypeId ? [filters.associateTypeId] : null,
                sortOrder: sortByParam === 'associateTypeId' ? sortOrderParam : null,
                render: (_: number, record: CooperatorVm) => record.associateType,
            },
            {
                title: translations.cooperators.oib,
                dataIndex: 'oib',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.oib ? [filters.oib] : null,
                sortOrder: sortByParam === 'oib' ? sortOrderParam : null,
            },
            {
                title: translations.cooperators.address,
                dataIndex: 'address',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.address ? [filters.address] : null,
                sortOrder: sortByParam === 'address' ? sortOrderParam : null,
            },
            {
                title: translations.cooperators.zip,
                dataIndex: 'zip',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.zip ? [filters.zip] : null,
                sortOrder: sortByParam === 'zip' ? sortOrderParam : null,
            },
            {
                title: translations.cooperators.city,
                dataIndex: 'city',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.city ? [filters.city] : null,
                sortOrder: sortByParam === 'city' ? sortOrderParam : null,
            },
            {
                title: translations.cooperators.country,
                dataIndex: 'country',
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.country ? [filters.country] : null,
                sortOrder: sortByParam === 'country' ? sortOrderParam : null,
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 100,
                align: 'left',
                className: 'no-print',
                render: (value: CooperatorVm): React.ReactElement => (
                    <Link
                        to={Routes.ROUTE_COOPERATORS_READ.replace(
                            ReplaceStrings.COOPERATOR_ID,
                            value.id.toString()
                        )}
                    >
                        {translations.general.details}
                    </Link>
                ),
            },
        ];

        return (
            <Table
                locale={getTableLocale()}
                columns={columns}
                dataSource={cooperators}
                loading={loading}
                rowKey={(record: CooperatorVm): string => record.id?.toString()}
                onChange={this.handleTableChange}
                pagination={pagination}
                bordered
                size="small"
            />
        );
    }
}

export default CooperatorTable;
