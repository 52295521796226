import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Upload } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { UploadFile } from 'antd/lib/upload/interface';
import React from 'react';
import ColumnContentPicker, {
    ColumnContentValue,
} from '../../../../../components/column-content-picker';
import DrawerButtons from '../../../../../components/drawer-buttons';
import { xlsxType } from '../../../../../config/constants';
import { formItemLayout420 } from '../../../../../config/formLayouts';
import { translations } from '../../../../../config/translations';
import { getColumnsFromExcelRange } from '../../../../../helpers/ExcelHelper';
import {
    getColumnContentValueForProjectItems,
    getExcelProjectItemsFieldsToDropdownOptions,
} from '../../../../../helpers/FetchAndTransformHelpers';
import { showError, showConfirm, showSuccess } from '../../../../../helpers/NotificationHelper';
import { validateExcelRange, validateLabelOrder } from '../../../../../helpers/ValidationHelper';
import { ApiException, ProjectsClient } from '../../../../../utils/api';
import { Props } from './index';

interface State {
    fileList: UploadFile[];
    isSaving: boolean;
    columns: ColumnsType<ColumnContentValue>;
    labelOrderInitial: ColumnContentValue;
    excelRange?: string;
    sheetName?: string;
}

class ProjectItemsImportForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        const initialColumns = getColumnsFromExcelRange('B:H', 'A');

        this.state = {
            isSaving: false,
            fileList: [],
            columns: initialColumns,
            labelOrderInitial: {
                A: 'Id',
                ...getColumnContentValueForProjectItems(
                    initialColumns.slice(1).map((col) => col.key as string)
                ),
            },
        };
    }

    private handleSubmit = async (values: any) => {
        const { isEditMode } = this.props;

        if (isEditMode) {
            showConfirm(
                () => this.handleSave(values),
                translations.projects.items.confirmImport,
                translations.general.import
            );
        } else {
            this.handleSave(values);
        }
    };

    private handleSave = async (values: any) => {
        const { projectId, onSuccess } = this.props;
        const { fileList } = this.state;
        const { excelRange, sheetName, labelOrder } = values;

        this.setState({
            isSaving: true,
        });

        try {
            await new ProjectsClient().importProjectItems(
                projectId,
                sheetName,
                excelRange,
                JSON.stringify(labelOrder),
                { data: fileList[0], fileName: fileList[0].name }
            );

            showSuccess(translations.projects.items.importSuccess);

            onSuccess();
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }

        this.setState({
            isSaving: false,
        });
    };

    handleExcelRangeChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            excelRange: event.target.value,
        });

        if (!event.target.value || !!validateExcelRange(event.target.value)) {
            return;
        }

        const newColumns = getColumnsFromExcelRange(event.target.value, 'A');

        this.setState({
            columns: newColumns,
            labelOrderInitial: {
                A: 'Id',
                ...getColumnContentValueForProjectItems(
                    newColumns.slice(1).map((col) => col.key as string)
                ),
            },
        });
    }

    public render(): React.ReactElement {
        const { onClose } = this.props;
        const { isSaving, fileList, columns, excelRange, labelOrderInitial } = this.state;

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout420}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={() => onClose()}
                    submitText={translations.general.import}
                />
                <Form.Item
                    name="file"
                    label={translations.general.xlsxFile}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Upload
                        fileList={fileList}
                        onRemove={() => {
                            this.setState({
                                fileList: [],
                            });
                        }}
                        beforeUpload={(file) => {
                            if (file.type !== xlsxType) {
                                showError(translations.general.wrongFileType);
                            } else {
                                this.setState({
                                    fileList: [file],
                                });
                            }
                            return false;
                        }}
                        accept={xlsxType}
                    >
                        <Button type="primary">
                            <UploadOutlined />
                            {translations.general.upload}
                        </Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    name="excelRange"
                    label={translations.projects.items.excelRange.title}
                    rules={[
                        { max: 21, message: translations.general.maxLength21 },
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        {
                            validator: (_rule: any, value: any, callback: any) => {
                                let message = validateExcelRange(value);
                                if (!!message) {
                                    callback(message);
                                } else {
                                    callback();
                                }
                            },
                        },
                    ]}
                >
                    <Input
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            this.handleExcelRangeChange(event)
                        }
                        placeholder="A5:G300"
                    />
                </Form.Item>

                {columns && (
                    <Form.Item
                        name="labelOrder"
                        label={translations.projects.items.labelOrder.title}
                        rules={[
                            {
                                validator: (_rule: any, value: any, callback: any) => {
                                    let message = validateLabelOrder(value, ['Ordinal', 'Name']);
                                    if (!!message) {
                                        callback(message);
                                    } else {
                                        callback();
                                    }
                                },
                            },
                        ]}
                    >
                        <ColumnContentPicker
                            columns={columns}
                            options={getExcelProjectItemsFieldsToDropdownOptions()}
                            disabled={!excelRange || !!validateExcelRange(excelRange)}
                            initialValue={labelOrderInitial}
                            selectWidth={134}
                            disabledColumns={['A']}
                        />
                    </Form.Item>
                )}

                <Form.Item
                    name="sheetName"
                    label={translations.general.sheetName}
                    rules={[
                        { max: 200, message: translations.general.maxLength200 },
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Input placeholder="Sheet1" />
                </Form.Item>
            </Form>
        );
    }
}

export default ProjectItemsImportForm;
