import { HodogramVm } from "../../../utils/api";
import HodogramReorder from "./HodogramReorder";

interface OwnProps {
    hodogramEntities: HodogramVm[];
    updateHodogramState: (state: any) => void;
}

export type Props = OwnProps;

export default HodogramReorder;