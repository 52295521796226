import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { ColumnType } from 'antd/lib/table/interface';
import React from 'react';
import { Link } from 'react-router-dom';
import { defaultTablePagination } from '../../../config/constants';
import { defaultFormatWithTime } from '../../../config/constants';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { formatDate } from '../../../helpers/DateHelper';
import { getDocumentStatusBadge } from '../../../helpers/DocumentHelper';
import {
    getKeyFilter,
    getCheckboxFilter,
    getDateFilter,
    getDatePickerFilter,
    getDefaultFilter,
    getNumberFilter,
    getSearchFilter,
} from '../../../helpers/FilterHelper';
import { formatPrice } from '../../../helpers/PriceHelper';
import { sumPropValues } from '../../../helpers/ProjectItemHelper';
import {
    getDateComparer,
    getDefaultComparer,
    getNumberComparer,
} from '../../../helpers/SortHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import {
    CodebooksClient,
    DocumentStatusEnum,
    DocumentTypeEnum,
    DocumentVm,
    SelectOptionVm,
} from '../../../utils/api';
import DocumentItemTable from '../document-item-table';
import { Props } from './index';
import { getPagingSortingParams, getFiltersParams } from '../../../helpers/SearchParamsHelper';

interface State {
    documentStatuses: SelectOptionVm[];
}

export default class DocumentTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            documentStatuses: []
        };
    }

    public componentDidMount = () => {
        this.getDocumentStatuses();
    };

    private getDocumentStatuses = async () => {
        this.setState({
            documentStatuses: await new CodebooksClient().getByCodebookName('DocumentStatus'),
        });
    };

    private renderTableSummary = (
        data: readonly DocumentVm[],
        columns: ColumnsType<DocumentVm>
    ): React.ReactNode => {
        const totalPrice = sumPropValues(data, 'totalPrice');
        const totalPriceIndex = columns.findIndex(
            (col: ColumnType<DocumentVm>): boolean => col.key === 'totalPrice'
        );

        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={totalPriceIndex + 1} align="right">
                        <strong style={{ textTransform: 'uppercase' }}>
                            {translations.general.total}
                        </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right">
                        <strong>{totalPrice ? formatPrice(totalPrice) : '0,00'}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                        index={totalPriceIndex}
                        colSpan={columns.length - totalPriceIndex}
                        align="right"
                    />
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    private renderNestedTable = (record: DocumentVm): React.ReactNode => (
        <DocumentItemTable
            documentTypeId={record.documentTypeId}
            documentItems={record.documentItems}
        />
    );

    private getDocumentTypeColumns = (typeId?: DocumentTypeEnum): ColumnsType<DocumentVm> => {
        const { documentStatuses } = this.state;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam } = getPagingSortingParams(params);
        const filters = getFiltersParams(params);
        
        // Generic columns start
        const code: ColumnType<DocumentVm> = {
            title: translations.documents.code,
            dataIndex: 'code',
            width: 150,
            ...getSearchFilter(),
            onFilter: getDefaultFilter('code'),
            sorter: getDefaultComparer('code'),
            filteredValue: filters.code ? [filters.code] : null,
            sortOrder: sortByParam === 'code' ? sortOrderParam : null,
            render: (value: string, record: DocumentVm): React.ReactElement => (
                <Link
                    to={{
                        pathname: Routes.ROUTE_DOCUMENTS_READ.replace(
                            ReplaceStrings.ENTITY_ID,
                            record.id.toString()
                        ),
                        search: `?typeId=${record.documentTypeId}`,
                    }}
                >
                    {value}
                </Link>
            ),
        };
        const project: ColumnType<DocumentVm> = {
            title: translations.documents.project,
            dataIndex: 'project',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('project'),
            sorter: getDefaultComparer('project'),
            filteredValue: filters.project ? [filters.project] : null,
            sortOrder: sortByParam === 'project' ? sortOrderParam : null,
        };
        const totalPrice: ColumnType<DocumentVm> = {
            title: translations.documents.totalPrice,
            key: 'totalPrice',
            dataIndex: 'totalPrice',
            align: 'right',
            ...getSearchFilter(),
            onFilter: getNumberFilter('totalPrice'),
            sorter: getNumberComparer('totalPrice'),
            filteredValue: filters.totalPrice ? [filters.totalPrice] : null,
            sortOrder: sortByParam === 'totalPrice' ? sortOrderParam : null,
            render: (value: number): string => {
                return value ? formatPrice(value) : '0,00';
            },
        };
        const createdBy: ColumnType<DocumentVm> = {
            title: translations.documents.createdBy,
            dataIndex: 'createdBy',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('createdBy'),
            sorter: getDefaultComparer('createdBy'),
            filteredValue: filters.createdBy ? [filters.createdBy] : null,
            sortOrder: sortByParam === 'createdBy' ? sortOrderParam : null,
        };
        const createdDate: ColumnType<DocumentVm> = {
            title: translations.documents.createdDate,
            dataIndex: 'createdDate',
            width: 150,
            ...getDatePickerFilter(),
            onFilter: getDateFilter('createdDate'),
            sorter: getDateComparer('createdDate'),
            render: (value: Date) => formatDate(value, defaultFormatWithTime),
            filteredValue: filters.createdDate ? [filters.createdDate] : null,
            sortOrder: sortByParam === 'createdDate' ? sortOrderParam : null,
        };
        const documentStatus: ColumnType<DocumentVm> = {
            title: translations.documents.status,
            dataIndex: 'documentStatusId',
            width: 120,
            ...getCheckboxFilter(documentStatuses),
            onFilter: getKeyFilter('documentStatusId'),
            sorter: getDefaultComparer('documentStatus'),
            filteredValue: filters.documentStatusId ? (filters.documentStatusId as unknown as string).split(',') : null,
            sortOrder: sortByParam === 'documentStatusId' ? sortOrderParam : null,
            render: (value: DocumentStatusEnum, record: DocumentVm): React.ReactElement =>
                getDocumentStatusBadge(value, record.documentStatus),
        };

        const actions: ColumnType<DocumentVm> = {
            title: translations.general.actions,
            key: 'actions',
            width: 90,
            align: 'left',
            className: 'no-print',
            render: (record: DocumentVm): React.ReactElement => (
                <Link
                    to={{
                        pathname: Routes.ROUTE_DOCUMENTS_READ.replace(
                            ReplaceStrings.ENTITY_ID,
                            record.id.toString()
                        ),
                        search: `?typeId=${record.documentTypeId}`,
                    }}
                >
                    {translations.general.details}
                </Link>
            ),
        };

        // Generic columns end

        // Type specific columns start

        // const warehouse: ColumnType<DocumentVm> = {
        //     title: isTransfer
        //         ? translations.documents.sourceWarehouse
        //         : translations.documents.warehouse,
        //     dataIndex: 'warehouse',
        //     ...getSearchFilter(),
        //     onFilter: getDefaultFilter('warehouse'),
        //     sorter: getDefaultComparer('warehouse'),
        //     render: (_: string | undefined, record: DocumentVm): React.ReactElement =>
        //         record.documentTypeId !== DocumentTypeEnum.Liquidation && !record.warehouseId ? (
        //             <Tooltip title={translations.documents.chooseProject}>
        //                 <ExclamationCircleOutlined style={{ color: gold.primary }} />
        //             </Tooltip>
        //         ) : (
        //             <>{record.warehouse}</>
        //         ),
        // };
        const destinationProject: ColumnType<DocumentVm> = {
            title: translations.documents.destinationProject,
            dataIndex: 'destinationProject',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('destinationProject'),
            sorter: getDefaultComparer('destinationProject'),
            filteredValue: filters.destinationProject ? [filters.destinationProject] : null,
            sortOrder: sortByParam === 'destinationProject' ? sortOrderParam : null,
        };
        const parentCode: ColumnType<DocumentVm> = {
            title: translations.documents.parentDocument,
            dataIndex: 'parentCode',
            width: 150,
            ...getSearchFilter(),
            onFilter: getDefaultFilter('parentCode'),
            sorter: getDefaultComparer('parentCode'),
            filteredValue: filters.parentCode ? [filters.parentCode] : null,
            sortOrder: sortByParam === 'parentCode' ? sortOrderParam : null,
            render: (_: string | undefined, record: DocumentVm) =>
                record.parentCode && record.parentId ? (
                    <Link
                        to={{
                            pathname: Routes.ROUTE_DOCUMENTS_READ.replace(
                                ReplaceStrings.ENTITY_ID,
                                record.parentId.toString()
                            ),
                            search: `?typeId=${record.parentTypeId}`,
                        }}
                    >
                        {record.parentCode}
                    </Link>
                ) : null,
        };
        const supplier: ColumnType<DocumentVm> = {
            title: translations.documents.supplier,
            dataIndex: 'supplier',
            ...getSearchFilter(),
            onFilter: getDefaultFilter('supplier'),
            sorter: getDefaultComparer('supplier'),
            filteredValue: filters.supplier ? [filters.supplier] : null,
            sortOrder: sortByParam === 'supplier' ? sortOrderParam : null,
        };
        const deadlineDate: ColumnType<DocumentVm> = {
            title: translations.documents.deadlineDate,
            dataIndex: 'deadlineDate',
            width: 120,
            ...getDatePickerFilter(),
            onFilter: getDateFilter('deadlineDate'),
            sorter: getDateComparer('deadlineDate'),
            filteredValue: filters.deadlineDate ? [filters.deadlineDate] : null,
            sortOrder: sortByParam === 'deadlineDate' ? sortOrderParam : null,
            render: (value: Date) => formatDate(value),
        };
        const receiptCode: ColumnType<DocumentVm> = {
            title: translations.documents.receiptCode,
            dataIndex: 'receiptCode',
            width: 150,
            ...getSearchFilter(),
            onFilter: getDefaultFilter('receiptCode'),
            sorter: getDefaultComparer('receiptCode'),
            filteredValue: filters.receiptCode ? [filters.receiptCode] : null,
            sortOrder: sortByParam === 'receiptCode' ? sortOrderParam : null,
        };
        const receiptAmount: ColumnType<DocumentVm> = {
            title: translations.documents.receiptAmount,
            key: 'receiptAmount',
            dataIndex: 'receiptAmount',
            align: 'right',
            ...getSearchFilter(),
            onFilter: getNumberFilter('receiptAmount'),
            sorter: getNumberComparer('receiptAmount'),
            filteredValue: filters.receiptAmount ? [filters.receiptAmount] : null,
            sortOrder: sortByParam === 'receiptAmount' ? sortOrderParam : null,
            render: (value: number): string => {
                return value ? formatPrice(value) : '0,00';
            },
        };

        // Type specific columns end

        switch (typeId) {
            case DocumentTypeEnum.RequisitionOrder:
                return [
                    code,
                    project,
                    supplier,
                    deadlineDate,
                    totalPrice,
                    createdBy,
                    createdDate,
                    documentStatus,
                    actions,
                ];
            case DocumentTypeEnum.PurchaseOrder:
                return [
                    code,
                    project,
                    parentCode,
                    supplier,
                    deadlineDate,
                    totalPrice,
                    createdBy,
                    createdDate,
                    documentStatus,
                    actions,
                ];
            case DocumentTypeEnum.PurchaseReceivedNote:
                return [
                    code,
                    project,
                    parentCode,
                    supplier,
                    totalPrice,
                    createdBy,
                    createdDate,
                    documentStatus,
                    actions,
                ];
            case DocumentTypeEnum.TransferNote:
                return [
                    code,
                    project,
                    destinationProject,
                    deadlineDate,
                    totalPrice,
                    createdBy,
                    createdDate,
                    documentStatus,
                    actions,
                ];
            case DocumentTypeEnum.TransferReceivedNote:
                return [
                    code,
                    project,
                    parentCode,
                    destinationProject,
                    totalPrice,
                    createdBy,
                    createdDate,
                    documentStatus,
                    actions,
                ];
            case DocumentTypeEnum.ConsumptionNote:
                return [code, project, totalPrice, createdBy, createdDate, documentStatus, actions];
            case DocumentTypeEnum.WriteOffNote:
                return [
                    code,
                    project,
                    parentCode,
                    totalPrice,
                    createdBy,
                    createdDate,
                    documentStatus,
                    actions,
                ];
            case DocumentTypeEnum.Liquidation:
                return [
                    code,
                    project,
                    parentCode,
                    supplier,
                    receiptCode,
                    receiptAmount,
                    totalPrice,
                    createdBy,
                    createdDate,
                    documentStatus,
                    actions,
                ];
            case DocumentTypeEnum.Order:
                return [
                    code,
                    project,
                    parentCode,
                    supplier,
                    deadlineDate,
                    totalPrice,
                    createdBy,
                    createdDate,
                    documentStatus,
                    actions,
                ];
            default:
                return [];
        }
    };

    public render(): React.ReactElement {
        const { documents, typeId, loading, onHandleTableChange } = this.props;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { pageIndexParam, pageSizeParam } = getPagingSortingParams(params);

        const pagination: TablePaginationConfig  = {
            ...defaultTablePagination,
            current:
                pageIndexParam && parseInt(pageIndexParam)
                    ? parseInt(pageIndexParam)
                    : defaultTablePagination.defaultCurrent,
            pageSize:
                pageSizeParam && parseInt(pageSizeParam)
                    ? parseInt(pageSizeParam)
                    : defaultTablePagination.defaultPageSize,
        };

        const columns = this.getDocumentTypeColumns(typeId);

        return (
            <Table
                locale={getTableLocale()}
                columns={columns}
                loading={loading}
                dataSource={documents}
                pagination={pagination}
                onChange={onHandleTableChange}
                size="small"
                rowKey={(record: DocumentVm): string => record.id.toString()}
                summary={(data: readonly DocumentVm[]) => this.renderTableSummary(data, columns)}
                expandable={{ expandedRowRender: this.renderNestedTable }}
            />
        );
    }
}
