import { Select } from 'antd';
import { Props } from '.';

const SelectMonth = (props: Props) => {
    const { selectedMonth, onMonthSelected } = props;

    const handleMonthChange = (value: string) => {
        const month = parseInt(value, 10);
        onMonthSelected(month);
    };

    const monthNames = [
        'Siječanj',
        'Veljača',
        'Ožujak',
        'Travanj',
        'Svibanj',
        'Lipanj',
        'Srpanj',
        'Kolovoz',
        'Rujan',
        'Listopad',
        'Studeni',
        'Prosinac',
    ];

    return (
        <Select
            value={selectedMonth?.toString()}
            onChange={handleMonthChange}
            placeholder="Odaberite mjesec"
            style={{ width: props.width || 120, textAlign: 'left' }}
        >
            {monthNames.map((monthName, index) => (
                <Select.Option key={index + 1} value={`${index + 1}`}>
                    {monthName}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectMonth;
