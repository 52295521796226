import { message, Modal } from 'antd';
import log from 'loglevel';
import { ReactNode } from 'react';
import { translations } from '../config/translations';

const { confirm } = Modal;

export const showSuccess = (msg: string) => {
    message.success(msg);
};

export const showError = (msg: any) => {
    if (!msg) {
        log.error('Error with passing arguments, no message to be logged!');
    }
    log.error(msg);
    if (typeof msg === 'string') {
        message.error(msg);
    } else if (msg.message) {
        message.error(msg.message);
    } else {
        message.error('Unknown error occured. Please contact developers.');
    }
};

export const showConfirm = (
    onConfirm: () => void,
    title?: string,
    confirmButtonText?: string,
    isDanger?: boolean,
    content?: ReactNode
) => {
    confirm({
        title: title || translations.general.confirmMessage,
        onOk: () => onConfirm(),
        okText:
            confirmButtonText ||
            (isDanger ? translations.general.delete : translations.general.confirm),
        okButtonProps: { danger: isDanger },
        cancelText: translations.general.cancel,
        content,
    });
};
