import { ColumnContentValue } from '../components/column-content-picker';
import { translations } from '../config/translations';
import { DropdownOption } from '../core/models/DropdownOption';
import { ExcelProjectItemsFields } from '../core/models/ExcelProjectItemsFields';
import { getNumericValueFromColumnString } from './ExcelHelper';

export const arrayValidator = (
    _rule: any,
    value: any,
    callback: any,
    message: string,
    entityToValidate?: DropdownOption[]
) => {
    if (!entityToValidate || entityToValidate.length === 0) {
        callback(message);
    } else {
        callback();
    }
};

export const numberRangeValidator = (
    _rule: any,
    minValue: any,
    maxValue: any,
    callback: any,
    message: string,
    entityToValidate?: number
) => {
    if (!entityToValidate || (entityToValidate <= maxValue && entityToValidate >= minValue)) {
        callback();
    }
    callback(message);
};

export const dateRangeValidator = (
    callback: any,
    message: string,
    startDate?: Date,
    endDate?: Date
) => {
    if (!startDate || !endDate || (startDate && endDate && startDate <= endDate)) {
        callback();
    }

    callback(message);
};

export const oibValidator = (
    _rule: any,
    value: any,
    callback: any,
    message: string,
    entityToValidate?: string
) => {
    if (!value || value === '') return callback();
    if (value.length !== 11) return callback(message);

    if (isNaN(Number(value))) return callback(message);

    let a = 10;
    for (let i = 0; i < 10; i++) {
        a = a + +value.substr(i, 1);
        a = a % 10;
        if (a === 0) a = 10;
        a *= 2;
        a = a % 11;
    }
    let checkNumber = 11 - a;
    if (checkNumber === 10) checkNumber = 0;

    if (checkNumber === +value.substr(10, 1)) {
        callback();
    } else {
        callback(message);
    }
};

export const mbValidator = (
    _rule: any,
    value: any,
    callback: any,
    message: string,
    entityToValidate?: string
) => {
    if (!value || value === '') return callback();

    if (value.length !== 8) callback(message);

    if (isNaN(Number(entityToValidate))) callback(message);

    let a;
    let sum = 0;

    for (let i = 7; i > 0; i--) {
        a = +value.substr(7 - i, 1);
        sum += a * (i + 1);
    }

    const res = sum % 11;

    //ako je ostatak 0(OST = 0 tj.broj ZZZ je djeljiv s 11 bez ostatka) mb je pogrešan
    if (res === 0) {
        callback(message);
    }

    //ako je ostatak 1(OST = 1, RAZ = 10) kontrolna znamenka K je 0(K = 0)
    let checkNumber = 11 - res;
    if (checkNumber === 10) checkNumber = 0;

    if (checkNumber === +value.substr(10, 1)) {
        callback();
    } else {
        callback(message);
    }
};

export const mbgValidator = (
    _rule: any,
    value: any,
    callback: any,
    message: string,
    entityToValidate?: string
) => {
    if (!value || value === '') return callback();
    if (value.length !== 13) callback(message);

    if (isNaN(Number(value))) callback(message);

    let a;
    let sum = 0;

    for (let i = 7; i > 1; i--) {
        a = +value.substr(12 - (5 + i), 1);
        sum += a * i;
    }
    for (let i = 7; i > 1; i--) {
        a = +value.substr(12 - (i - 1), 1);
        sum += a * i;
    }

    let res = sum % 11;

    //ako je ostatak 0(OST = 0 tj.broj ZZZ je djeljiv s 11 bez ostatka) mbg je pogrešan
    if (res === 0) {
        callback(message);
    }

    //ako je ostatak 1(OST = 1, RAZ = 10) kontrolna znamenka K je 0(K = 0)
    let checkNumber = 11 - res;
    if (checkNumber === 10) checkNumber = 0;

    //ako je ostatak broj između 1 i 11 (1<OST<11) kontrolna znamenka K je razlika između 11 i ostatka (K=RAZ)
    if (checkNumber === +value.substr(10, 1)) {
        callback();
    } else {
        callback(message);
    }
};

export const validateLabelOrder = (
    columnContentValue?: ColumnContentValue,
    obligatoryValues?: string[]
): string | undefined => {
    let usedValues: string[];
    usedValues = [];

    let notUniqueValues = false;

    Object.entries(columnContentValue!).forEach(([key, value]) => {
        if (!!value) {
            if (usedValues.includes(value)) {
                notUniqueValues = true;
            } else {
                usedValues.push(value);
            }
        }
    });

    if (notUniqueValues) {
        return translations.projects.items.labelOrder.notUniqueValues;
    }

    if (!!obligatoryValues?.length) {
        for (let i = 0; i < obligatoryValues.length; i++) {
            if (!usedValues.includes(obligatoryValues[i])) {
                return (
                    translations.projects.items.labelOrder.missingValue +
                    ExcelProjectItemsFields.find((x) => x.key === obligatoryValues[i])?.value
                );
            }
        }
    }

    return;
};

export const validateExcelRange = (excelRange?: string): string | undefined => {
    try {
        //check if string is empty
        if (!excelRange) {
            return;
        }

        let excelRangeParts = excelRange.split(':');

        //check if exactly 2 parts of range
        if (excelRangeParts.length !== 2) {
            return translations.projects.items.excelRange.formatWrong;
        }

        // check if range parts have correct number of letters followed by digits
        if (
            !excelRangeParts[0].match(/^[A-Z]{1,3}[1-9]+[0-9]{0,6}$/) ||
            !excelRangeParts[1].match(/^[A-Z]{1,3}[1-9]+[0-9]{0,6}$/)
        ) {
            return translations.projects.items.excelRange.formatWrong;
        }

        // all strings with only letters
        let columnStrings = excelRange.match(/[A-Z]+/g);
        // check if column string larger than max
        if (
            (columnStrings![0].length > 2 && columnStrings![0] > 'XFD') ||
            (columnStrings![1].length > 2 && columnStrings![1] > 'XFD')
        ) {
            return translations.projects.items.excelRange.formatWrong;
        }

        // check if too many columns or column order not ok
        if (
            getNumericValueFromColumnString(columnStrings![1]) -
                getNumericValueFromColumnString(columnStrings![0]) >
            15
        ) {
            return translations.projects.items.excelRange.tooLarge;
        }

        // all strings with only numbers
        let numberStrings = excelRange.match(/[0-9]+/g);
        // check if row number larger than max
        if (
            (numberStrings![0].length > 6 && numberStrings![0] > '1048576') ||
            (numberStrings![1].length > 6 && numberStrings![1] > '1048576')
        ) {
            return translations.projects.items.excelRange.formatWrong;
        }

        // check if second row is larger than first row
        if (
            numberStrings![0].length > numberStrings![1].length ||
            (numberStrings![0].length === numberStrings![1].length &&
                numberStrings![0] > numberStrings![1])
        ) {
            return translations.projects.items.excelRange.formatWrong;
        }
    } catch {
        return translations.projects.items.excelRange.formatWrong;
    }

    return;
};
