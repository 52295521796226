import { DatePicker, Form, Input, InputNumber, Select, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import DrawerButtons from '../../../components/drawer-buttons';
import { defaultFormat } from '../../../config/constants';
import { formItemLayout724 } from '../../../config/formLayouts';
import { translations } from '../../../config/translations';
import { showConfirm, showSuccess } from '../../../helpers/NotificationHelper';
import { create, update } from '../../../helpers/SubmitHelper';
import { numberRangeValidator, dateRangeValidator } from '../../../helpers/ValidationHelper';
import {
    CityVm,
    CodebooksClient,
    CountriesClient,
    CountryVm,
    ProjectDetailVm,
    ProjectsClient,
    ProjectStatusEnum,
    RoleEnum,
    SelectOptionVm,
    WarehouseItemsClient,
} from '../../../utils/api';
import { Props } from './index';
import authService from '../../../components/api-authorization/AuthorizeService';
import { SelectOption } from '../../../core/models/SelectOption';
import SelectCity from '../../../components/select-city/SelectCity';

interface State {
    isSaving: boolean;
    countries: SelectOption[];
    cities: SelectOption[];
    projectStatuses: SelectOptionVm[];
    hasWarehouseItems: boolean;
    userProfile?: any;
}

class ProjectForm extends React.Component<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
            countries: [],
            cities: [],
            projectStatuses: [],
            hasWarehouseItems: false,
        };
    }

    public componentDidMount = () => {
        const { project } = this.props;

        this.getProjectStatuses();
        this.getCountries();
        this.getUserProfile();

        if (project) {
            this.checkWarehouseItems(project.id);
        }
    };

    private getProjectStatuses = async () => {
        this.setState({
            projectStatuses: await new CodebooksClient().getByCodebookName('ProjectStatus'),
        });
    };

    private getCountries = async () => {
        const { project } = this.props;

        if (project?.countryId) {
            this.getCountryCities(project?.countryId);
        }

        this.setState({
            countries: (await new CountriesClient().getAll()).map(
                (unit: CountryVm): SelectOption => ({
                    label: unit.name,
                    value: unit.id,
                })
            ),
        });
    };

    private getCountryCities = async (countryId: number) => {
        this.setState({
            cities: (await new CountriesClient().getById(countryId)).cities.map(
                (unit: CityVm): SelectOption => ({
                    label: unit.name,
                    value: unit.id,
                })
            ),
        });
    };

    async getUserProfile() {
        this.setState({ userProfile: await authService.getUserProfile() });
    }

    private checkWarehouseItems = async (projectId: number) => {
        let result = await new WarehouseItemsClient().projectHasWarehouseItems(projectId);
        this.setState({ hasWarehouseItems: result });
    };

    private handleCityAdded = () => {
        const countryId = this.formRef?.current?.getFieldValue('countryId');

        this.getCountryCities(countryId);
    };

    private handleSubmit = (values: any) => {
        const { project } = this.props;

        const projectClient = new ProjectsClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: project?.id,
        };

        if (request.id) {
            update(projectClient, request, this.handleSuccess);
        } else {
            create(projectClient, request, this.handleSuccess);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (result: ProjectDetailVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.projects.successfullySaved);

        onSuccess(result);
    };

    public render(): React.ReactElement {
        const { project, onClose } = this.props;
        const { isSaving, countries, cities, projectStatuses, hasWarehouseItems, userProfile } =
            this.state;

        const role = userProfile ? Number(userProfile['userRoleId']) : undefined;

        const projectStatus = this.formRef?.current?.getFieldValue('projectStatus');
        const countryId = this.formRef?.current?.getFieldValue('countryId');

        const updateGeneratesConsumptionNote =
            project?.id &&
            project.projectStatusId !== ProjectStatusEnum.Completed &&
            projectStatus === ProjectStatusEnum.Completed &&
            hasWarehouseItems;

        return (
            <Form
                ref={this.formRef}
                onFinish={(values: any) => {
                    updateGeneratesConsumptionNote
                        ? showConfirm(
                              () => this.handleSubmit(values),
                              translations.documents.confirmStatus,
                              translations.general.confirm,
                              true
                          )
                        : this.handleSubmit(values);
                }}
                {...formItemLayout724}
            >
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />

                <Form.Item
                    name="name"
                    label={translations.projects.name}
                    initialValue={project?.name}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="projectStatusId"
                    label={translations.projects.status}
                    initialValue={project?.projectStatusId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select<number>
                        placeholder={translations.projects.statusPlaceholder}
                        options={projectStatuses}
                    />
                </Form.Item>

                <Form.Item
                    name="shortName"
                    label={translations.projects.shortName}
                    initialValue={project ? project.shortName : undefined}
                    rules={[{ max: 200, message: translations.general.maxLength200 }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="code"
                    label={translations.projects.code}
                    initialValue={project ? project.code : undefined}
                    rules={[{ max: 200, message: translations.general.maxLength200 }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="startDate"
                    label={translations.projects.startDate}
                    initialValue={project?.startDate ? moment(project.startDate) : null}
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                dateRangeValidator(
                                    callback,
                                    translations.general.startBeforeEndValidationMessage,
                                    value,
                                    this.formRef?.current?.getFieldValue('endDate')
                                ),
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="endDate"
                    label={translations.projects.endDate}
                    initialValue={project?.endDate ? moment(project.endDate) : null}
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                dateRangeValidator(
                                    callback,
                                    translations.general.startBeforeEndValidationMessage,
                                    this.formRef?.current?.getFieldValue('startDate'),
                                    value
                                ),
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="countryId"
                    label={translations.projects.country}
                    initialValue={project?.countryId}
                >
                    <Select<number>
                        showSearch
                        allowClear
                        placeholder={translations.projects.countryPlaceholder}
                        options={countries}
                        onSelect={(value) => this.getCountryCities(value)}
                    />
                </Form.Item>
                <Form.Item
                    name="cityId"
                    label={translations.projects.city}
                    initialValue={project?.cityId}
                >
                    <SelectCity
                        showSearch
                        allowClear
                        placeholder={translations.cooperators.cityPlaceholder}
                        options={cities}
                        countryId={countryId}
                        onCityAdded={this.handleCityAdded}
                    />
                </Form.Item>
                <Form.Item
                    name="address"
                    label={translations.projects.address}
                    initialValue={project ? project.address : undefined}
                    rules={[{ max: 200, message: translations.general.maxLength200 }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="longitude"
                    label={translations.projects.longitude}
                    initialValue={project?.longitude}
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                numberRangeValidator(
                                    _rule,
                                    -180,
                                    180,
                                    callback,
                                    translations.projects.longitudeMessage,
                                    value
                                ),
                            message: translations.projects.longitudeMessage,
                        },
                    ]}
                >
                    <InputNumber precision={8} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    name="latitude"
                    label={translations.projects.latitude}
                    initialValue={project?.latitude}
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                numberRangeValidator(
                                    _rule,
                                    -90,
                                    90,
                                    callback,
                                    translations.general.requiredField,
                                    value
                                ),
                            message: translations.projects.latitudeMessage,
                        },
                    ]}
                >
                    <InputNumber precision={8} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    name="bankGuarantees"
                    label={translations.projects.bankGuarantees}
                    initialValue={project ? project.bankGuarantees : undefined}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="paymentMethod"
                    label={translations.projects.paymentMethod}
                    initialValue={project ? project.paymentMethod : undefined}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="paymentTerms"
                    label={translations.projects.paymentTerms}
                    initialValue={project ? project.paymentTerms : undefined}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={translations.projects.description}
                    initialValue={project ? project.description : undefined}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="workHourEntryDeadlineExtensionInDays"
                    label={
                        <Tooltip title={translations.projects.WorkHourEntryDeadlineExtensionInDays}>
                            {translations.projects.WorkHourEntryDeadline}
                        </Tooltip>
                    }
                    initialValue={
                        project ? project.workHourEntryDeadlineExtensionInDays : undefined
                    }
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                numberRangeValidator(
                                    _rule,
                                    0,
                                    31,
                                    callback,
                                    translations.general.requiredField,
                                    value
                                ),
                            message: translations.projects.extensionDaysMessage,
                        },
                    ]}
                >
                    <InputNumber
                        precision={0}
                        style={{ width: '100%' }}
                        disabled={!role || (role !== RoleEnum.SuperAdmin && role !== RoleEnum.Admin)}
                    />
                </Form.Item>
            </Form>
        );
    }
}

export default ProjectForm;
