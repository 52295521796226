import { ImportOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Drawer, PageHeader, Switch, Tabs } from 'antd';
import React from 'react';
import { Props } from '.';
import { DRAWER_WIDTH } from '../../../../config/constants';
import Routes from '../../../../config/routes';
import { translations } from '../../../../config/translations';
import { Filter } from '../../../../core/models/Filter';
import { ProjectItemExtendedVm } from '../../../../core/models/ProjectItems';
import { DrawerState, ProjectSubPage } from '../../../../core/models/enum';
import {
    getItemIds,
    getItemsExpandLevel,
    getItemsMaxDepth,
} from '../../../../helpers/ProjectItemHelper';
import { buildFilterRequest } from '../../../../helpers/RecursionHelper';
import { buildBasicRoute } from '../../../../helpers/RoutingHelper';
import { ProjectCooperatorItemVm, ProjectCooperatorVm } from '../../../../utils/api';
import ProjectContractorItemTable from './project-contractor-item-table';
import ProjectContractorItemsImportForm from './project-contractor-items-import-form';

const { TabPane } = Tabs;

interface State {
    drawerState: DrawerState;
    activeContractor?: ProjectCooperatorVm;
    activeSubcontractors?: ProjectCooperatorVm[];
    expandedRowKeys?: React.Key[];
    expandedLevel: number;
    prevEditingItemId?: number;
    filter?: Filter;
    forceRenderContractorColumns?: boolean;
    showAllItems: boolean;
}

class ProjectContractors extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            drawerState: DrawerState.Closed,
            expandedLevel: 0,
            showAllItems: false,
        };
    }

    public componentDidMount = () => {
        this.initActiveTab();
        this.initExpandedRows();
    };

    public componentDidUpdate = (prevProps: Props, prevState: State) => {
        const { projectItems } = this.props;

        if (!prevProps.projectItems && projectItems) {
            this.initExpandedRows();
        }
    };

    private initActiveTab = () => {
        const {
            contractors,
            match: {
                params: { subEntityId },
            },
        } = this.props;

        let contractor = subEntityId
            ? contractors.find((s: ProjectCooperatorVm): boolean => s.id === +subEntityId)
            : undefined;

        if (!contractor && contractors.length) {
            contractor = contractors[contractors.length - 1];
        }

        this.setState(
            {
                activeContractor: contractor,
                activeSubcontractors: contractors.filter(
                    (c: ProjectCooperatorVm) => c.clientId === contractor?.cooperatorId
                ),
            },
            this.updateHistory
        );
    };

    private initExpandedRows = () => {
        const { projectItems } = this.props;

        if (!projectItems) return;

        const maxParentDepth = getItemsMaxDepth(projectItems) - 1;

        this.setState({
            expandedRowKeys: getItemIds(projectItems, maxParentDepth),
            expandedLevel: maxParentDepth,
        });
    };

    private handleTabChange = (id: number) => {
        const { contractors } = this.props;

        this.handleCancel();

        const activeContractor = contractors.find((s: ProjectCooperatorVm) => s.id === +id);

        if (!activeContractor) return;

        this.setState(
            {
                activeContractor,
                activeSubcontractors: contractors.filter(
                    (c: ProjectCooperatorVm) => c.clientId === activeContractor.cooperatorId
                ),
                forceRenderContractorColumns: true,
            },
            this.updateHistory
        );
    };

    private updateHistory = () => {
        const { projectId, history } = this.props;
        const { activeContractor } = this.state;

        if (!activeContractor) {
            return;
        }

        history.push(
            buildBasicRoute(
                Routes.ROUTE_PROJECT_SUBPAGE_READ,
                projectId.toString(),
                ProjectSubPage.Contractors,
                activeContractor.id.toString()
            )
        );
    };

    private handleImport = () => {
        this.setState({
            drawerState: DrawerState.Create,
        });
    };

    private handleCloseDrawer = () => {
        this.setState({
            drawerState: DrawerState.Closed,
        });
    };

    private handleAdd = (projectItemId: number, projectCooperatorId: number) => {
        const { editingContractorItem, onAdd, onCancel } = this.props;

        if (editingContractorItem) {
            onCancel();
        }

        this.setState(
            {
                prevEditingItemId: editingContractorItem?.projectItemId,
                forceRenderContractorColumns: false,
            },
            () => onAdd(projectItemId, projectCooperatorId)
        );
    };

    private handleEdit = (item: Partial<ProjectCooperatorItemVm>) => {
        const { editingContractorItem, onEdit } = this.props;

        onEdit(item);

        this.setState({
            prevEditingItemId: editingContractorItem?.projectItemId,
            forceRenderContractorColumns: false,
        });
    };

    private handleDelete = (item: ProjectItemExtendedVm) => {
        const { onDelete } = this.props;

        onDelete(item.contractorItem!.id);

        this.setState({
            prevEditingItemId: item.id,
            forceRenderContractorColumns: false,
        });
    };

    private handleCancel = () => {
        const { editingContractorItem, onCancel } = this.props;

        onCancel();

        this.setState({
            prevEditingItemId: editingContractorItem?.projectItemId,
        });
    };

    private handleSave = async (values: Partial<ProjectCooperatorItemVm>) => {
        const { editingContractorItem, onSave } = this.props;

        onSave(values);

        this.setState({
            prevEditingItemId: editingContractorItem?.projectItemId,
        });
    };

    private handleFilterChange = (filters: any) => {
        const filter: Filter = {
            filterRequest: buildFilterRequest(filters),
        };

        this.setState({
            filter: filter,
        });
    };

    private handleExpand = (expand: boolean, item: ProjectItemExtendedVm) => {
        const { projectItems } = this.props;
        const { expandedRowKeys } = this.state;

        const newExpandedRowKeys = expand
            ? [...(expandedRowKeys || []), item.id]
            : expandedRowKeys?.filter((k) => k !== item.id);

        this.setState({
            expandedRowKeys: newExpandedRowKeys,
            expandedLevel: getItemsExpandLevel(projectItems || [], newExpandedRowKeys || []),
            forceRenderContractorColumns: false,
        });
    };

    private handleToggleLevelExpand = (expand: boolean) => {
        const { projectItems } = this.props;
        const { expandedLevel } = this.state;

        if (!projectItems) return;

        const maxDepth = getItemsMaxDepth(projectItems) - 1;
        let newExpandedLevel = expand ? expandedLevel + 1 : expandedLevel - 1;

        if (newExpandedLevel > maxDepth) {
            newExpandedLevel = maxDepth;
        }

        let newExpandedRowKeys: React.Key[] = [];
        if (newExpandedLevel > -1) {
            newExpandedRowKeys = getItemIds(projectItems, newExpandedLevel);
        }

        this.setState({
            expandedRowKeys: newExpandedRowKeys,
            expandedLevel: newExpandedLevel,
            forceRenderContractorColumns: false,
        });
    };

    private handleToggleShowAllItems = () => {
        this.setState((prevState: State) => ({
            showAllItems: !prevState.showAllItems,
        }));
    };

    public render(): React.ReactElement {
        const {
            projectId,
            projectItems,
            editingContractorItem,
            contractors,
            getProjectItems,
            userProfile,
            loading,
            projectCooperatorItems,
        } = this.props;
        const {
            drawerState,
            expandedRowKeys,
            expandedLevel,
            prevEditingItemId,
            filter,
            activeContractor,
            activeSubcontractors,
            forceRenderContractorColumns,
            showAllItems,
        } = this.state;

        return (
            <>
                {contractors.length === 0 && (
                    <Alert
                        message={translations.projects.contractors.noContractorsWarning.message}
                        description={
                            translations.projects.contractors.noContractorsWarning.description
                        }
                        type="warning"
                        showIcon
                    />
                )}

                <Tabs
                    type="editable-card"
                    activeKey={activeContractor?.id.toString()}
                    onTabClick={(key: string) => this.handleTabChange(+key)}
                    hideAdd
                    destroyInactiveTabPane={true}
                >
                    {contractors.map(
                        (c: ProjectCooperatorVm): React.ReactElement => (
                            <TabPane
                                tab={c.cooperatorName}
                                key={c.id.toString()}
                                closable={false}
                            />
                        )
                    )}
                </Tabs>

                {activeContractor && (
                    <PageHeader
                        title={
                            <>
                                <Button
                                    size="small"
                                    onClick={() => this.handleToggleLevelExpand(true)}
                                    icon={<PlusOutlined />}
                                />
                                <Button
                                    size="small"
                                    onClick={() =>
                                        expandedLevel > -1
                                            ? this.handleToggleLevelExpand(false)
                                            : null
                                    }
                                    icon={<MinusOutlined />}
                                />
                                <Divider type="vertical" />
                                <Switch
                                    checked={showAllItems}
                                    onChange={this.handleToggleShowAllItems}
                                    checkedChildren={translations.projects.items.allItems}
                                    unCheckedChildren={translations.projects.items.allItems}
                                />
                            </>
                        }
                        extra={
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => this.handleImport()}
                                    disabled={loading}
                                    icon={<ImportOutlined />}
                                >
                                    {translations.general.import}
                                </Button>
                            </>
                        }
                    />
                )}

                {activeContractor && (
                    <ProjectContractorItemTable
                        projectId={projectId}
                        projectItems={projectItems}
                        contractors={contractors}
                        projectContractorItems={projectCooperatorItems}
                        activeContractor={activeContractor}
                        activeSubcontractors={activeSubcontractors}
                        forceRenderContractorColumns={forceRenderContractorColumns}
                        editingContractorItem={editingContractorItem}
                        prevEditingItemId={prevEditingItemId}
                        expandedRowKeys={expandedRowKeys}
                        filter={filter}
                        showAllItems={showAllItems}
                        onAdd={this.handleAdd}
                        onEdit={this.handleEdit}
                        onCancel={this.handleCancel}
                        onSave={this.handleSave}
                        onDelete={this.handleDelete}
                        onExpand={this.handleExpand}
                        onFilterChange={this.handleFilterChange}
                        userProfile={userProfile}
                    />
                )}

                {activeContractor && (
                    <Drawer
                        title={translations.projects.contractors.import}
                        open={!!drawerState}
                        onClose={() => this.handleCloseDrawer()}
                        width={DRAWER_WIDTH}
                        destroyOnClose
                    >
                        <ProjectContractorItemsImportForm
                            projectId={projectId}
                            contractorId={activeContractor.cooperatorId}
                            onClose={() => this.handleCloseDrawer()}
                            onSuccess={() => {
                                this.handleCloseDrawer();
                                getProjectItems();
                            }}
                            isEditMode={!!projectCooperatorItems?.length}
                        />
                    </Drawer>
                )}
            </>
        );
    }
}

export default ProjectContractors;
