import DrawerButtons from './DrawerButtons';

interface OwnProps {
    isSaving?: boolean;
    isFormDirty?: boolean;
    onSubmitAction?: () => void;
    onCancelAction: () => void;
    loadingText?: string;
    submitText?: string;
    cancelText?: string;
}

export type Props = OwnProps;

export default DrawerButtons;
