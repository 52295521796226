//const THOUSAND_SEPARATOR_PATTERN = /\B(?=(\d{3})+(?!\d))/g;
const INVALID_NUMBER_CHARACTERS = /[^0-9,,,-]+/g;

export const formatPrice = (value: string | number | undefined, precision: number = 2): string =>
        value
        ? new Intl.NumberFormat('hr-HR', { maximumFractionDigits: precision, minimumFractionDigits: 2 }).format(+value)
        : '';

export const parsePrice = (value: string | undefined): string =>
    `${value}`.replace(INVALID_NUMBER_CHARACTERS, '').replace(',', '.');
