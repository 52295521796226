import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../../../core/models/RouteParams';
import { ProjectSituationVm } from '../../../../../utils/api';
import ProjectSituationForm from './ProjectSituationForm';

interface OwnProps {
    situation?: ProjectSituationVm;
    projectId: number;
    onClose: () => void;
    onSuccess: (result: ProjectSituationVm) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(ProjectSituationForm);
