import { ConfigProvider } from 'antd';
import hrHR from 'antd/lib/locale/hr_HR';
import moment from 'moment';
import momentHr from 'moment/locale/hr';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string | undefined;
const rootElement = document.getElementById('root');
moment.updateLocale('language', momentHr);
moment.locale('hr');

ReactDOM.render(
    <ConfigProvider locale={hrHR}>
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
    </ConfigProvider>,

    rootElement
);
