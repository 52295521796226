import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteParams';
import CooperatorTable from './CooperatorTable';

interface OwnProps {
    wrappedComponentRef?: any;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CooperatorTable);
