import { LeftOutlined } from '@ant-design/icons';
import { Button, PageHeader, Skeleton } from 'antd';
import React from 'react';
import { defaultFormatWithTime } from '../../../config/constants';
import { translations } from '../../../config/translations';
import { formatDate } from '../../../helpers/DateHelper';
import SanitizedDiv from '../../sanitize-html/SanitizedDiv';
import { Props } from './index';

class NewsDetail extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const { news, isAdminView } = this.props;
        return (
            <>
                {news ? (
                    <div>
                        <div style={{ marginBottom: '3em' }}>
                            <PageHeader
                                style={{ paddingBottom: 0 }}
                                title={news.title}
                                extra={
                                    !isAdminView && (
                                        <Button
                                            onClick={() => {
                                                this.props.history.go(-1);
                                            }}
                                        >
                                            <LeftOutlined />
                                            {translations.general.back}
                                        </Button>
                                    )
                                }
                            />
                            <p>
                                {news.author}, {formatDate(news.date, defaultFormatWithTime)}
                            </p>
                        </div>
                        <SanitizedDiv dirtyHtml={news.text} />
                    </div>
                ) : (
                    <Skeleton active />
                )}
            </>
        );
    }
}

export default NewsDetail;
