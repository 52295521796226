import { DownOutlined, FolderOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import React from 'react';
import scrollIntoView from 'scroll-into-view';
import InputPrice from '../../../../../components/input-price';
import ProjectItemActions from '../../../../../components/project-item-actions';
import { translations } from '../../../../../config/translations';
import { ProjectItemExtendedVm } from '../../../../../core/models/ProjectItems';
import { ActionType, ModuleName } from '../../../../../core/models/enum';
import { authorizeAction } from '../../../../../helpers/CheckPermissionHelper';
import { getRadioFilter, getSearchFilter } from '../../../../../helpers/FilterHelper';
import { formatPrice } from '../../../../../helpers/PriceHelper';
import {
    insertItem,
    sumPropValues,
    isVisible,
    connectItems,
} from '../../../../../helpers/ProjectItemHelper';
import { statusFilterRangeOptions } from '../../../../../helpers/ProjectItemHelper';
import { recursiveFilterAndSort } from '../../../../../helpers/RecursionHelper';
import { getTableLocale } from '../../../../../helpers/TableHelper';
import { ProjectItemVm } from '../../../../../utils/api';
import { Props } from './index';

const NON_TABLE_CONTENT_HEIGHT = 330;
const EDITING_ROW_CLASS = 'editing-row';
const SCROLLABLE_CONTAINER_CLASS = 'ant-table-body';

interface State {
    allowUpdate: boolean;
    allowCreate: boolean;
    allowDelete: boolean;
}

class ProjectItemTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            allowUpdate: false,
            allowCreate: false,
            allowDelete: false,
        };
    }

    public componentDidMount = () => {
        document.addEventListener('keydown', this.handleKeyDown);
        this.getPermissions();
    };

    public componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
    };

    public componentDidUpdate = (prevProps: Props) => {
        const { editingItem } = this.props;

        if (editingItem && prevProps.editingItem !== editingItem) {
            const editingRow = document.querySelector<HTMLElement>(`.${EDITING_ROW_CLASS}`);
            const container = document.querySelector<HTMLElement>(`.${SCROLLABLE_CONTAINER_CLASS}`);

            if (!editingRow) return;

            if (isVisible(editingRow, container)) return;

            scrollIntoView(editingRow, {
                align: {
                    top: 1,
                },
            });
        }
    };

    private getPermissions = async () => {
        const { userProfile } = this.props;

        const allowUpdate = await authorizeAction(
            userProfile,
            ModuleName.ProjectItems,
            ActionType.Update
        );
        const allowDelete = await authorizeAction(
            userProfile,
            ModuleName.ProjectItems,
            ActionType.Delete
        );
        const allowCreate = await authorizeAction(
            userProfile,
            ModuleName.ProjectItems,
            ActionType.Create
        );

        this.setState({
            allowUpdate,
            allowCreate,
            allowDelete,
        });
    };

    private handleKeyDown: { (e: KeyboardEvent): void } = (e: KeyboardEvent) => {
        const { onCancel } = this.props;

        if (e.key === 'Escape') {
            onCancel();
        }
    };

    private getTableColumns = (): ColumnProps<ProjectItemExtendedVm>[] => {
        const {
            editingItem,
            unitsOfMeasurement,
            prevEditingItemId,
            forceRenderExpandColumn,
            onAdd,
            onEdit,
            onCancel,
            onDelete,
            filter,
        } = this.props;
        const { allowDelete, allowUpdate, allowCreate } = this.state;

        return [
            {
                title: translations.projects.ordinal,
                dataIndex: 'ordinalDisplay',
                width: 80,
                fixed: 'left',
                shouldCellUpdate: (item: ProjectItemExtendedVm) => {
                    return editingItem?.id === item.id;
                },
            },
            // {
            //     title: translations.projects.codeItem,
            //     key: 'code',
            //     width: 100,
            //     ellipsis: true,
            //     ...getSearchFilter(),
            //     render: (item: ProjectItemExtendedVm): React.ReactElement =>
            //         editingItem?.id === item.id ? (
            //             <Form.Item
            //                 name="code"
            //                 initialValue={editingItem.code}
            //                 rules={[{ max: 100, message: translations.general.maxLength100 }]}
            //             >
            //                 <Input
            //                     placeholder={translations.projects.codeItem}
            //                     onClick={(e: React.MouseEvent) => e.stopPropagation()}
            //                 />
            //             </Form.Item>
            //         ) : (
            //             <Tooltip title={item.code}>{item.code}</Tooltip>
            //         ),
            //     shouldCellUpdate: (item: ProjectItemExtendedVm) => {
            //         return editingItem?.id === item.id || prevEditingItemId === item.id;
            //     },
            // },
            {
                title: translations.projects.item,
                key: 'name',
                width: 200,
                fixed: 'left',
                ellipsis: true,
                ...getSearchFilter(),
                filteredValue: filter?.filterRequest?.name || null,
                render: (item: ProjectItemExtendedVm): React.ReactElement =>
                    editingItem?.id === item.id ? (
                        <Form.Item
                            name="name"
                            initialValue={editingItem.name}
                            rules={[
                                { required: true, message: translations.general.requiredField },
                            ]}
                        >
                            <Input
                                placeholder={translations.projects.item}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                            />
                        </Form.Item>
                    ) : (
                        <>
                            {item.isGroup && (
                                <FolderOutlined style={{ marginLeft: 4, marginRight: 4 }} />
                            )}
                            <Tooltip title={item.name}>{item.name}</Tooltip>
                        </>
                    ),
                shouldCellUpdate: (item: ProjectItemExtendedVm) => {
                    return (
                        editingItem?.id === item.id ||
                        prevEditingItemId === item.id ||
                        forceRenderExpandColumn
                    );
                },
            },
            {
                title: translations.projects.unit,
                key: 'unitOfMeasurement',
                className: 'border-right',
                width: 80,
                fixed: 'left',
                render: (item: ProjectItemExtendedVm) => {
                    if (item.isGroup) return null;

                    return editingItem?.id === item.id ? (
                        <Form.Item
                            name="unitOfMeasurementId"
                            initialValue={editingItem.unitOfMeasurementId}
                            rules={[
                                { required: true, message: translations.general.requiredField },
                            ]}
                        >
                            <Select
                                placeholder={translations.projects.unit}
                                options={unitsOfMeasurement}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                            />
                        </Form.Item>
                    ) : (
                        <>{item.unitOfMeasurement?.shortName}</>
                    );
                },
                shouldCellUpdate: (item: ProjectItemExtendedVm) => {
                    return (
                        editingItem?.id === item.id ||
                        prevEditingItemId === item.id ||
                        forceRenderExpandColumn
                    );
                },
            },
            {
                title: translations.projects.quantity,
                key: 'quantity',
                width: 80,
                align: 'right',
                render: (item: ProjectItemExtendedVm) => {
                    if (item.isGroup) return null;

                    if (editingItem?.id === item.id) {
                        return (
                            <Form.Item
                                name="quantity"
                                initialValue={editingItem.quantity}
                                rules={[
                                    { required: true, message: translations.general.requiredField },
                                ]}
                            >
                                <InputPrice
                                    placeholder={translations.projects.quantity}
                                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                    onChange={(value: string | number | null | undefined) => {
                                        onEdit({
                                            ...editingItem,
                                            quantity: value == null ? undefined : +value,
                                        });
                                    }}
                                    precision={6}
                                />
                            </Form.Item>
                        );
                    }
                    return item.quantity ? formatPrice(item.quantity, 6) : '0,00';
                },
                shouldCellUpdate: (item: ProjectItemExtendedVm) => {
                    return editingItem?.id === item.id || prevEditingItemId === item.id;
                },
            },
            {
                title: translations.projects.unitPrice,
                key: 'unitPrice',
                width: 110,
                align: 'right',
                render: (item: ProjectItemExtendedVm) => {
                    if (item.isGroup) return null;

                    if (editingItem?.id === item.id) {
                        return (
                            <Form.Item
                                name="unitPrice"
                                initialValue={editingItem.unitPrice}
                                rules={[
                                    { required: true, message: translations.general.requiredField },
                                ]}
                            >
                                <InputPrice
                                    placeholder={translations.projects.unitPrice}
                                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                    onChange={(value: string | number | null | undefined) => {
                                        onEdit({
                                            ...editingItem,
                                            unitPrice: value == null ? undefined : +value,
                                        });
                                    }}
                                />
                            </Form.Item>
                        );
                    }
                    return item.unitPrice ? formatPrice(item.unitPrice) : '0,00';
                },
                shouldCellUpdate: (item: ProjectItemExtendedVm) => {
                    return editingItem?.id === item.id || prevEditingItemId === item.id;
                },
            },
            {
                title: translations.projects.totalPrice,
                key: 'totalPrice',
                width: 110,
                align: 'right',
                className: 'border-right',
                render: (item: ProjectItemExtendedVm) => {
                    if (editingItem?.id === item.id) {
                        return formatPrice(
                            (editingItem?.quantity || 0) * (editingItem?.unitPrice || 0)
                        );
                    }
                    return item.totalPrice ? formatPrice(item.totalPrice) : '0,00';
                },
                shouldCellUpdate: (
                    item: ProjectItemExtendedVm,
                    prevItem: ProjectItemExtendedVm
                ) => {
                    return (
                        editingItem?.id === item.id ||
                        prevEditingItemId === item.id ||
                        item.totalPrice !== prevItem.totalPrice
                    );
                },
            },
            {
                title: translations.projects.completedQuantity,
                key: 'completedQuantity',
                width: 80,
                align: 'right',
                render: (item: ProjectItemExtendedVm) => {
                    if (item.isGroup) return null;

                    return item.completedQuantity ? formatPrice(item.completedQuantity, 6) : '0,00';
                },
                shouldCellUpdate: () => false,
            },
            {
                title: translations.projects.completedPrice,
                key: 'completedPrice',
                width: 110,
                align: 'right',
                className: 'border-right',
                render: (item: ProjectItemExtendedVm) =>
                    item.completedPrice ? formatPrice(item.completedPrice) : '0,00',
                shouldCellUpdate: () => false,
            },
            {
                title: translations.projects.remainingQuantity,
                key: 'remainingQuantity',
                width: 80,
                align: 'right',
                render: (item: ProjectItemExtendedVm) => {
                    if (item.isGroup) return null;

                    return item.remainingQuantity ? formatPrice(item.remainingQuantity, 6) : '0,00';
                },

                shouldCellUpdate: (item, prevItem) =>
                    item.remainingQuantity !== prevItem.remainingQuantity,
            },
            {
                title: translations.projects.remainingPrice,
                key: 'remainingPrice',
                width: 110,
                align: 'right',
                className: 'border-right',
                render: (item: ProjectItemExtendedVm) =>
                    item.remainingPrice ? formatPrice(item.remainingPrice) : '0,00',
                shouldCellUpdate: (item, prevItem) =>
                    item.remainingPrice !== prevItem.remainingPrice,
            },
            {
                title: translations.projects.completedPercent,
                key: 'completedPercent',
                width: 80,
                align: 'right',
                ...getRadioFilter(statusFilterRangeOptions),
                filteredValue: filter?.filterRequest?.completedPercent || null,
                render: (item: ProjectItemExtendedVm): React.ReactElement =>
                    this.renderItemCompletedPercentage(item.completedPercent),
                shouldCellUpdate: (item, prevItem) =>
                    item.completedPercent !== prevItem.completedPercent,
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 70,
                align: 'center',
                render: (item: ProjectItemExtendedVm): React.ReactElement => (
                    <ProjectItemActions
                        item={item}
                        editingItem={editingItem}
                        allowCreate={allowCreate}
                        allowDelete={allowDelete}
                        allowUpdate={allowUpdate}
                        onCancel={onCancel}
                        onAdd={onAdd}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                ),
            },
        ];
    };

    private getTableExpandableConfig = (): ExpandableConfig<ProjectItemExtendedVm> => {
        const { editingItem, expandedRowKeys, onExpand } = this.props;

        return {
            expandedRowKeys: expandedRowKeys,
            onExpand: (expanded: boolean, item: ProjectItemExtendedVm) => onExpand(expanded, item),
            expandIconColumnIndex: 1,
            expandIcon: (iconProps: RenderExpandIconProps<ProjectItemExtendedVm>) => {
                const { expanded, record, onExpand } = iconProps;

                if (!record.children?.length || editingItem?.id === record.id) {
                    return null;
                }

                return (
                    <Button
                        shape="circle"
                        type="text"
                        size="small"
                        onClick={(e) => {
                            onExpand(record, e);
                        }}
                        icon={expanded ? <DownOutlined /> : <RightOutlined />}
                    />
                );
            },
        };
    };

    private getTableSummary = (data: readonly ProjectItemExtendedVm[]): React.ReactNode => {
        const totalPrice = sumPropValues(data, 'totalPrice');
        const totalCompletedPrice = sumPropValues(data, 'completedPrice');
        const totalRemainingPrice = totalPrice - totalCompletedPrice;
        const totalCompletedPercentage = sumPropValues(data, 'completedPercent') / data.length;

        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell
                        index={0}
                        colSpan={3}
                        align="right"
                        className="border-right"
                    >
                        <strong style={{ textTransform: 'uppercase' }}>
                            {translations.general.total}
                        </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                        index={4}
                        colSpan={3}
                        align="right"
                        className="border-right"
                    >
                        <strong>{totalPrice ? formatPrice(totalPrice) : '0,00'}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                        index={7}
                        colSpan={2}
                        align="right"
                        className="border-right"
                    >
                        <strong>
                            {totalCompletedPrice ? formatPrice(totalCompletedPrice) : '0,00'}
                        </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                        index={9}
                        colSpan={2}
                        align="right"
                        className="border-right"
                    >
                        <strong>
                            {totalRemainingPrice ? formatPrice(totalRemainingPrice) : '0,00'}
                        </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="right">
                        <strong>
                            {this.renderItemCompletedPercentage(totalCompletedPercentage)}
                        </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11}></Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    private renderItemCompletedPercentage = (completedPercent?: number): React.ReactElement => {
        let className = 'red-5';

        if (!completedPercent) {
            className = '';
        } else if (completedPercent > 0 && completedPercent < 1) {
            className = 'blue-6';
        } else if (completedPercent === 1) {
            className = 'green-6';
        }

        return <div className={className}>{((completedPercent || 0) * 100).toFixed(2)}%</div>;
    };

    public render(): React.ReactElement {
        const {
            projectItems,
            projectSituationItems,
            editingItem,
            filter,
            onSave,
            onEdit,
            onFilterChange,
        } = this.props;

        if (!projectItems || !projectSituationItems) {
            return (
                <Table
                    locale={getTableLocale()}
                    key="loading-table"
                    loading
                    pagination={false}
                    size="small"
                    className="ant-table-slim"
                    columns={this.getTableColumns()}
                />
            );
        }

        const mergedItems = connectItems(projectItems, projectSituationItems);

        const items =
            editingItem && !editingItem.id
                ? (insertItem(mergedItems, editingItem as ProjectItemVm) as ProjectItemExtendedVm[])
                : mergedItems;

        const filteredItems =
            filter?.filterRequest && !editingItem
                ? recursiveFilterAndSort(items, filter).pageItems
                : items;

        return (
            <Form onFinish={onSave} preserve={false}>
                <Table
                    locale={getTableLocale()}
                    key="data-table"
                    pagination={false}
                    size="small"
                    className="ant-table-slim"
                    rowClassName={(record: ProjectItemExtendedVm) =>
                        editingItem?.id === record.id ? EDITING_ROW_CLASS : ''
                    }
                    onRow={(record: ProjectItemExtendedVm) => {
                        return {
                            onDoubleClick: () => onEdit(record),
                        };
                    }}
                    scroll={{ y: `calc(100vh - ${NON_TABLE_CONTENT_HEIGHT}px)` }}
                    rowKey={(record: ProjectItemExtendedVm) => record.id}
                    dataSource={filteredItems}
                    columns={this.getTableColumns()}
                    onChange={(_: any, filters: any) => onFilterChange(filters)}
                    expandable={this.getTableExpandableConfig()}
                    summary={(data: readonly ProjectItemExtendedVm[]): React.ReactNode =>
                        this.getTableSummary(data)
                    }
                />
            </Form>
        );
    }
}

export default ProjectItemTable;
