import { DropdownOption } from '../core/models/DropdownOption';
import {
    CitiesClient,
    CityVm,
    CodebooksClient,
    CooperatorContactVm,
    CooperatorsClient,
    CountriesClient,
    CountryVm,
    SelectOptionVm,
} from '../utils/api';

export interface EntitiesSelectedEntityHelperModel {
    entities: DropdownOption[];
    selectedEntity: DropdownOption[];
}

export const getCountriesHelper = async (
    countryId?: number
): Promise<EntitiesSelectedEntityHelperModel> => {
    const countryClient = new CountriesClient();
    const countriesVMs = await countryClient.getAll();

    const countries = countriesVMs.map(
        (c: CountryVm): DropdownOption => ({
            id: c && c.id ? c.id.toString() : '-1',
            name: c.nameUniversal || c.name || '',
        })
    );
    const selectedCountry = countryId
        ? countries.find((c: DropdownOption): boolean => c.id === countryId.toString())
        : undefined;

    return {
        entities: countries,
        selectedEntity: selectedCountry ? [selectedCountry] : [],
    };
};

export const getCitiesHelper = async (
    countryId?: number,
    cityId?: number
): Promise<EntitiesSelectedEntityHelperModel> => {
    if (countryId) {
        const countryClient = new CountriesClient();
        const countryDetailVMs = await countryClient.getById(countryId);

        if (countryDetailVMs && countryDetailVMs.cities) {
            const cities = countryDetailVMs.cities.map(
                (c: CityVm): DropdownOption => ({
                    id: c && c.id ? c.id.toString() : '-1',
                    name: c.nameUniversal || c.name || '',
                })
            );
            const selectedCity = cityId
                ? cities.find((c: DropdownOption): boolean => c.id === cityId.toString())
                : undefined;

            return {
                entities: cities,
                selectedEntity: selectedCity ? [selectedCity] : [],
            };
        }
        return {
            entities: [],
            selectedEntity: [],
        };
    } else {
        const cityClient = new CitiesClient();

        const citiesVMs = await cityClient.getAll();

        const cities = citiesVMs.map(
            (c: CityVm): DropdownOption => ({
                id: c && c.id ? c.id.toString() : '-1',
                name: c.nameUniversal || c.name || '',
            })
        );

        const selectedCity = cityId
            ? cities.find((c: DropdownOption): boolean => c.id === cityId.toString())
            : undefined;

        return {
            entities: cities,
            selectedEntity: selectedCity ? [selectedCity] : [],
        };
    }
};

export const getCooperatorContactsHelper = async (
    cooperatorId?: number,
    cooperatorContactId?: number
): Promise<EntitiesSelectedEntityHelperModel> => {
    if (!cooperatorId) {
        return {
            entities: [],
            selectedEntity: [],
        };
    }
    const cooperatorsClient = new CooperatorsClient();
    const cooperatorContactVms = await cooperatorsClient.getCooperatorContacts(cooperatorId);

    if (!cooperatorContactVms) {
        return {
            entities: [],
            selectedEntity: [],
        };
    }
    const cooperatorContacts = cooperatorContactVms.map(
        (c: CooperatorContactVm): DropdownOption => ({
            id: c.id.toString(),
            name: c.name || '',
        })
    );
    const selectedContact = cooperatorContactId
        ? cooperatorContacts.find(
              (c: DropdownOption): boolean => c.id === cooperatorContactId.toString()
          )
        : undefined;

    return {
        entities: cooperatorContacts,
        selectedEntity: selectedContact ? [selectedContact] : [],
    };
};

export const getProjectStatusesHelper = async (
    projectStatusId?: number
): Promise<EntitiesSelectedEntityHelperModel> => {
    const types = await new CodebooksClient().getByCodebookName('ProjectStatus');

    const projectStatuses = types.map(
        (c: SelectOptionVm): DropdownOption => ({
            id: c && c.value ? c.value.toString() : '-1',
            name: c.label || c.name || '',
        })
    );
    const selectedProjectStatus = projectStatusId
        ? projectStatuses.find((c: DropdownOption): boolean => c.id === projectStatusId.toString())
        : undefined;

    return {
        entities: projectStatuses,
        selectedEntity: selectedProjectStatus ? [selectedProjectStatus] : [],
    };
};

export const filterDropdownOptions = (
    value: string,
    entitiesToSearch: DropdownOption[]
): DropdownOption[] => {
    const options: DropdownOption[] = [];

    entitiesToSearch.forEach((entity: DropdownOption) => {
        if (entity.name.toLowerCase().includes(value.toLowerCase())) {
            options.push(entity);
        }
    });

    return options;
};

export const confirmDropdownHelper = (option: DropdownOption[]): DropdownOption[] | undefined => {
    return option && option.length > 0 ? option : undefined;
};
