import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteParams';
import { EmployeeDetailVm, EmployeeVm } from '../../../utils/api';
import EmployeeForm from './EmployeeForm';

interface OwnProps {
    employee?: EmployeeVm;
    onClose: () => void;
    onSuccess: (employee: EmployeeDetailVm) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(EmployeeForm);
