import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { Props } from '.';
import DrawerButtons from '../../../components/drawer-buttons';
import { defaultFormat, defaultFormatWithTime } from '../../../config/constants';
import { formItemLayout724 } from '../../../config/formLayouts';
import { translations } from '../../../config/translations';
import { SelectOption } from '../../../core/models/SelectOption';
import { formatDate } from '../../../helpers/DateHelper';
import {
    ApiException,
    AssociateTypeEnum,
    CodebooksClient,
    CooperatorsClient,
    CooperatorVm,
    CreateWorkHourRecordCommand,
    ProjectVm,
    SelectOptionVm,
    UpdateWorkHourRecordCommand,
    WorkHourRecordsClient,
    WorkRecordCoefficientsClient,
    WorkRecordCoefficientVm,
} from '../../../utils/api';
import InputPrice from '../../input-price';
import { TableEmployeeVm } from '../WorkHourRecordsTable';
import { showError } from '../../../helpers/NotificationHelper';

interface State {
    workHourTypes?: SelectOptionVm[];
    coefficients?: SelectOption[];
    accommodationSuppliers?: SelectOption[];
    foodSuppliers?: SelectOption[];
    saving?: boolean;
}
class WorkHourRecordForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {};
    }

    public componentDidMount = () => {
        this.getWorkHourTypes();
        this.getWorkRecordCoefficients();
        this.getSuppliers();
    };

    private getWorkHourTypes = async () => {
        this.setState({
            workHourTypes: await new CodebooksClient().getByCodebookName('WorkHourType'),
        });
    };

    private getWorkRecordCoefficients = async () => {
        this.setState({
            coefficients: (await new WorkRecordCoefficientsClient().getAll()).map(
                (unit: WorkRecordCoefficientVm): SelectOption => ({
                    label: unit.name,
                    value: unit.id,
                })
            ),
        });
    };

    private getSuppliers = async () => {
        this.setState({
            accommodationSuppliers: (
                await new CooperatorsClient().getByAssociateType(
                    AssociateTypeEnum.AccommodationSupplier
                )
            ).map(
                (unit: CooperatorVm): SelectOption => ({
                    label: unit.name,
                    value: unit.id,
                })
            ),
        });

        this.setState({
            foodSuppliers: (
                await new CooperatorsClient().getByAssociateType(AssociateTypeEnum.FoodSupplier)
            ).map(
                (unit: CooperatorVm): SelectOption => ({
                    label: unit.name,
                    value: unit.id,
                })
            ),
        });
    };

    private handleSubmit = async (values: any) => {
        const { record, onSuccess } = this.props;
        this.setState({
            saving: true,
        });

        for (const item of values.gear) {
            values[item] = true;
        }

        try {
            if (record.id) {
                const result = await new WorkHourRecordsClient().update(
                    record.id,
                    UpdateWorkHourRecordCommand.fromJS(values)
                );
                onSuccess(result);
            } else {
                const result = await new WorkHourRecordsClient().create(
                    CreateWorkHourRecordCommand.fromJS(values)
                );
                onSuccess(result);
            }
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }

        this.setState({
            saving: false,
        });
    };

    public render() {
        const { record, projects, employees, onClose, onDelete } = this.props;
        const { workHourTypes, coefficients, accommodationSuppliers, foodSuppliers, saving } =
            this.state;

        if (!workHourTypes) return null;

        const initialGearValues: string[] = [];

        if (record.shoes) initialGearValues.push('shoes');
        if (record.shoesPro) initialGearValues.push('shoesPro');
        if (record.pants) initialGearValues.push('pants');
        if (record.overalls) initialGearValues.push('overalls');
        if (record.workJacket) initialGearValues.push('workJacket');
        if (record.winterJacket) initialGearValues.push('winterJacket');
        if (record.fleeseJacket) initialGearValues.push('fleeseJacket');
        if (record.helmet) initialGearValues.push('helmet');
        if (record.vest) initialGearValues.push('vest');
        if (record.protectionGloves) initialGearValues.push('protectionGloves');
        if (record.goggles) initialGearValues.push('goggles');
        if (record.hydrodemolitionSuit) initialGearValues.push('hydrodemolitionSuit');

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout724}>
                <DrawerButtons isSaving={saving} onCancelAction={() => onClose()} />

                <Form.Item
                    name="projectId"
                    label={translations.workHourRecods.form.project}
                    initialValue={record.projectId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select showSearch optionFilterProp="children">
                        {projects.map((p: ProjectVm) => (
                            <Select.Option key={p.id} value={p.id}>
                                {p.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="employeeId"
                    label={translations.workHourRecods.form.employee}
                    initialValue={record.employeeId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select showSearch optionFilterProp="children">
                        {employees.map((e: TableEmployeeVm) => (
                            <Select.Option key={e.id} value={e.id}>
                                {`${e.name} ${e.surname}`}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="date"
                    label={translations.workHourRecods.form.date}
                    initialValue={record?.date ? moment(record.date) : null}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    name="hours"
                    label={translations.workHourRecods.form.hours}
                    initialValue={record.hours}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <InputPrice min={0} />
                </Form.Item>
                <Form.Item
                    name="workHourTypeId"
                    label={translations.workHourRecods.form.type}
                    initialValue={record.workHourTypeId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select<number> options={workHourTypes} />
                </Form.Item>

                <Form.Item
                    name="isFieldWork"
                    valuePropName="checked"
                    label={translations.workHourRecods.form.fieldWork}
                    initialValue={record.isFieldWork}
                >
                    <Checkbox />
                </Form.Item>

                <Form.Item
                    name="workRecordCoefficientId"
                    label={translations.workHourRecods.form.coefficient}
                    initialValue={record.workRecordCoefficientId}
                    shouldUpdate
                >
                    <Select allowClear options={coefficients} />
                </Form.Item>

                <Divider plain>{translations.workHourRecods.form.accomodation}</Divider>

                <Form.Item
                    name="accommodationSupplierId"
                    label={translations.workHourRecods.form.accomodationSupplier}
                    initialValue={record.accommodationSupplierId}
                >
                    <Select allowClear options={accommodationSuppliers} />
                </Form.Item>

                <Divider plain>{translations.workHourRecods.form.food}</Divider>

                <Form.Item
                    name="foodSupplierId"
                    label={translations.workHourRecods.form.foodSupplier}
                    initialValue={record.foodSupplierId}
                >
                    <Select allowClear options={foodSuppliers} />
                </Form.Item>

                <Form.Item
                    name="numberOfMeals"
                    label={translations.workHourRecods.form.numberOfMeals}
                    initialValue={record.numberOfMeals}
                >
                    <InputNumber min={0} max={3} />
                </Form.Item>

                <Divider plain>{translations.workHourRecods.form.gear}</Divider>

                <Form.Item
                    name="gear"
                    label={translations.gear.gearSupplied}
                    initialValue={initialGearValues}
                >
                    <Checkbox.Group>
                        <Row>
                            <Col span={8}>
                                <Checkbox value="shoes" style={{ lineHeight: '32px' }}>
                                    {translations.gear.shoes}
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="shoesPro" style={{ lineHeight: '32px' }}>
                                    {translations.gear.shoesPro}
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="pants" style={{ lineHeight: '32px' }}>
                                    {translations.gear.pants}
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="overalls" style={{ lineHeight: '32px' }}>
                                    {translations.gear.overalls}
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="workJacket" style={{ lineHeight: '32px' }}>
                                    {translations.gear.workJacket}
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="winterJacket" style={{ lineHeight: '32px' }}>
                                    {translations.gear.winterJacket}
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="fleeseJacket" style={{ lineHeight: '32px' }}>
                                    {translations.gear.fleeseJacket}
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="helmet" style={{ lineHeight: '32px' }}>
                                    {translations.gear.helmet}
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="vest" style={{ lineHeight: '32px' }}>
                                    {translations.gear.vest}
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="protectionGloves" style={{ lineHeight: '32px' }}>
                                    {translations.gear.protectionGloves}
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="goggles" style={{ lineHeight: '32px' }}>
                                    {translations.gear.goggles}
                                </Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox
                                    value="hydrodemolitionSuit"
                                    style={{ lineHeight: '32px' }}
                                >
                                    {translations.gear.hydrodemolitionSuit}
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>

                <Form.Item
                    name="description"
                    label={translations.workHourRecods.form.description}
                    initialValue={record.description}
                >
                    <Input />
                </Form.Item>

                {record.id && (
                    <>
                        <Divider />
                        <Row gutter={24} justify="space-around">
                            <Col span={10}>
                                <p>{`${translations.workHourRecods.createdBy}: ${record.createdBy}`}</p>
                            </Col>
                            <Col span={10}>
                                <p>{`${translations.workHourRecods.lastModifiedBy}: ${record.lastModifiedBy}`}</p>
                            </Col>
                        </Row>
                        <Row gutter={24} justify="space-around">
                            <Col span={10}>
                                <p>{`${translations.workHourRecods.createdDate}: ${
                                    record.createdDate
                                        ? formatDate(record.createdDate, defaultFormatWithTime)
                                        : ''
                                }`}</p>
                            </Col>
                            <Col span={10}>
                                <p>{`${translations.workHourRecods.lastModifiedDate}: ${
                                    record.lastModifiedDate
                                        ? formatDate(record.lastModifiedDate, defaultFormatWithTime)
                                        : ''
                                }`}</p>
                            </Col>
                        </Row>
                        <Row gutter={24} justify="space-around">
                            <Col span={10}>
                                <Button
                                    type="link"
                                    style={{ padding: 0 }}
                                    danger
                                    htmlType="button"
                                    onClick={onDelete}
                                >
                                    {translations.workHourRecods.deleteRecord}
                                </Button>
                            </Col>
                            <Col span={10} />
                        </Row>
                    </>
                )}
            </Form>
        );
    }
}

export default WorkHourRecordForm;
