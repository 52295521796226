import { NewsVm } from "../../../utils/api";
import NewsGrid from './NewsGrid';

interface OwnProps {
    news?: NewsVm[]
    loading?: boolean
}

export type Props = OwnProps;

export default NewsGrid;