import { DocumentTranslations } from '../core/models/Translations';

export const translations = {
    dashboard: {
        dashboard: 'Naslovna',
        title: 'Upravljanje građevinskim projektima',
    },
    users: {
        role: 'Uloga',
        email: 'Email',
        chooseRole: 'Odaberite ulogu',
        employee: 'Zaposlenik',
        chooseEmployee: 'Odaberite zaposlenika',
        emailConfirmed: 'Email potvrđen',
        deleteConfirmation: 'Jeste li sigurni da želite izbrisati ovog korisnika?',
        users: 'Korisnici',
        addUser: 'Dodaj korisnika',
        editUser: 'Uredi korisnika',
        savedMessage: 'Korisnik uspješno spremljen',
        deletedMessage: 'Korisnik uspješno izbrisan',
        name: 'Ime',
        surname: 'Prezime',
    },
    attachments: {
        attachments: 'Datoteke',
        attachment: 'Datoteka',
        add: 'Dodaj datoteku',
        edit: 'Uredi datoteku',
        fileName: 'Naziv datoteke',
        url: 'URL',
        description: 'Opis',
        createdBy: 'Kreirao',
        createdDate: 'Datum kreiranja',
        deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu datoteku?',
        deleteSuccess: 'Datoteka uspješno izbrisana',
        saveSuccess: 'Datoteka uspješno spremljena',
    },
    hodogram: {
        hodogram: 'Hodogram',
        editHodogram: 'Uredi',
        reorderHodogram: 'Razmjesti hodogram',
        saveReorder: 'Spremi razmještaj',
        successfulReorder: 'Razmještaj uspješno spremljen',
        title: 'Naslov',
        description: 'Opis',
        saveHodogram: 'Spremi',
        abandonEdit: 'Odustani',
        successfulEdit: 'Promjene uspješno spremljene',
        deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj korak hodograma?',
        abandonReorder: 'Odustani',
        noEntities: 'Nisu pronađeni koraci hodograma za ovaj centar odgovornosti',
        successfulUpdate: 'Uspješno postavljen status',
        name: 'Naziv',
        code: 'Kod',
        deleteHodogram: 'Izbriši',
        addHodogram: 'Dodaj korak',
        deactivateHodogram: 'Deaktiviraj korak',
        activateHodogram: 'Aktiviraj korak',
    },
    peopleSchedule: {
        title: 'Raspored ljudi',
    },
    news: {
        news: 'Obavijesti',
        addNews: 'Dodaj obavijest',
        editNews: 'Uredi obavijest',
        detailNews: 'Prikaz obavijesti',
        title: 'Naslov',
        text: 'Tekst',
        date: 'Datum',
        archived: 'Arhivirano',
        featured: 'Istaknuto',
        author: 'Autor',
        savedMessage: 'Obavijest uspješno spremljena',
        deletedMessage: 'Obavijest uspješno izbrisana',
        noSelected: 'Nije odabrana valjana obavijest',
        deleteConfirmation: 'Jeste li sigurni da želite izbrisati ovu obavijest?',
    },
    gear: {
        shoes: 'Cipele',
        shoesPro: 'Cipele PRO',
        pants: 'Hlače',
        overalls: 'Kombinezon',
        workJacket: 'Radna jakna',
        winterJacket: 'Zimska jakna',
        fleeseJacket: 'Flis jakna',
        helmet: 'Kaciga',
        vest: 'Prsluk',
        protectionGloves: 'Zaštitne rukavice',
        goggles: 'Zaštitne naočale',
        hydrodemolitionSuit: 'Odijelo za hidrodemoliranje',
        gearSupplied: 'Oprema dodijeljena',
        gearNotSupplied: 'Oprema nije dodijeljena',
    },
    projects: {
        project: 'Centar odgovornosti',
        projects: 'Centri odgovornosti',
        all: 'Svi centri odgovornosti',
        addProject: 'Dodaj centar odgovornosti',
        editProject: 'Uredi centar odgovornosti',
        name: 'Naziv',
        shortName: 'Skraćeni naziv',
        code: 'Oznaka',
        startDate: 'Datum početka',
        endDate: 'Datum završetka',
        address: 'Adresa',
        country: 'Država',
        city: 'Grad',
        bankGuarantees: 'Bankovne garancije',
        paymentMethod: 'Metoda plaćanja',
        paymentTerms: 'Uvjeti plaćanja',
        longitude: 'Geografska dužina',
        latitude: 'Geografska širina',
        description: 'Opis',
        deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj centar odgovornosti?',
        longitudeMessage: 'Vrijednost mora biti manja od 180, a veća od -180',
        latitudeMessage: 'Vrijednost mora biti manja od 90, a veća od -90',
        extensionDaysMessage: 'Vrijednost mora biti veća od 0 i manja od 31',
        countryPlaceholder: 'Odaberite državu',
        cityPlaceholder: 'Odaberite grad',
        successfullySaved: 'Centar odgovornosti uspješno spremljen',
        projectDeleted: 'Centar odgovornosti uspješno izbrisan',
        details: 'Detalji',
        employees: 'Zaposlenici',
        employee: 'Zaposlenik',
        employeeName: 'Ime i prezime',
        role: 'Uloga',
        activeFrom: 'Aktivan od',
        activeTo: 'Aktivan do',
        chooseRole: 'Odaberite ulogu',
        projectEmployeeDeleteConfirm:
            'Jeste li sigurni da želite izbrisati ovog zaposlenika s centra odgovornosti?',
        projectEmployeeDeleted: 'Zaposlenik uspješno izbrisan s centra odgovornosti',
        WorkHourEntryDeadlineExtensionInDays: 'Produljenje roka unosa evidencije sati (dani)',
        WorkHourEntryDeadline: 'Rok unosa evidencije sati',
        cooperatorName: 'Naziv sudionika',
        cooperatorType: 'Tip sudionika',
        hasItems: 'Ima troškovnik',
        doesntHaveItems: 'Nema troškovnik',
        projectCooperatorDeleteConfirm:
            'Jeste li sigurni da želite izbrisati ovog sudionika s centra odgovornosti?',
        projectCooperatorDeleted: 'Sudionik uspješno izbrisan s centra odgovornosti',
        chooseCooperatorType: 'Odaberite tip sudionika',
        chooseCooperator: 'Odaberite sudionika',
        chooseCooperatorClient: 'Odaberite naručitelja',
        cooperator: 'Sudionik',
        client: 'Naručitelj',
        cooperators: 'Sudionici',
        investor: 'Investitor',
        contact: 'Kontakt',
        ordinal: 'Redni broj',
        codeItem: 'Oznaka',
        unit: 'Jed. mjera',
        quantity: 'Količina',
        unitPrice: 'Jed. cijena',
        totalPrice: 'Uk. cijena',
        completedQuantity: 'Završena količina',
        completedPrice: 'Završena cijena',
        remainingQuantity: 'Preostala količina',
        remainingPrice: 'Preostala cijena',
        addGroup: 'Dodaj grupu',
        addItem: 'Dodaj stavku',
        editItem: 'Uredi',
        deleteItem: 'Izbriši',
        deleteItemConfirm: 'Jeste li sigurni da želite izbrisati ovu stavku?',
        completedPercent: 'Status',
        item: 'Opis',
        status: 'Status',
        statusPlaceholder: 'Odaberite status',
        items: {
            title: 'Osnovni troškovnik',
            import: 'Uvoz osnovnog troškovnika',
            importSuccess: 'Osnovni troškovnik uspješno uvezen',
            confirmImport:
                'Uvoz troškovnika će pregaziti postojeći troškovnik, jeste li sigurni da želite nastaviti?',
            export: 'Izvoz osnovnog troškovnika',
            excelRange: {
                title: 'Raspon podatkovnih ćelija',
                formatWrong: 'Raspon podatkovnih ćelija je pogrešnog formata',
                tooLarge: 'Preveliki raspon podatkovnih ćelija',
            },
            labelOrder: {
                title: 'Podatkovni stupci',
                notUniqueValues: 'Podatkovni stupci nemaju jedinstvene vrijednosti',
                missingValue:
                    'Nedostaju vrijednosti, jedan od stupaca mora imati odabranu vrijednost: ',
            },
            contracted: 'Ugovoreno',
            subcontracted: 'Podugovoreno',
            allItems: 'Sve stavke',
        },
        situations: {
            title: 'Situacije',
            situation: 'Situacija',
            addSituation: 'Dodaj situaciju',
            editSituation: 'Uredi situaciju',
            chooseSituation: 'Odaberite situaciju',
            name: 'Naziv situacije',
            startDate: 'Datum početka',
            endDate: 'Datum završetka',
            successfullySaved: 'Situacija uspješno spremljena',
            deleteMessage: 'Jeste li sigurni da želite izbrisati ovu situaciju?',
            deleted: 'Situacija uspješno izbrisana',
            period: 'Razdoblje',
            cumulativeQuantity: 'Kum. količina',
            cumulativePrice: 'Kum. cijena',
            noSituationsWarning: {
                message: 'Nije pronađena nijedna situacija',
            },
        },
        contractors: {
            title: 'Troškovnik izvođača',
            import: 'Uvoz troškovnika izvođača',
            importSuccess: 'Troškovnik izvođača uspješno uvezen',
            confirmImport:
                'Uvoz troškovnika će pregaziti postojeći troškovnik, jeste li sigurni da želite nastaviti?',
            contractor: 'Izvođač',
            allContractors: 'Svi izvođači',
            chooseContractor: 'Odaberite izvođača',
            noContractorsWarning: {
                message: 'Nije pronađen nijedan izvođač centra odgovornosti',
                description:
                    'Sudionici koji su u troškovniku će se automatski prikazati kao izvođači centra odgovornosti',
            },
            columnAExcelRangeError: 'Kolona A je rezervirana',
        },
    },
    employees: {
        humanResources: 'Ljudski resursi',
        employees: 'Zaposlenici',
        addEmployee: 'Dodaj zaposlenika',
        editEmployee: 'Uredi zaposlenika',
        successfullySaved: 'Zaposlenik uspješno spremljen',
        countryPlaceholder: 'Odaberite državu',
        cityPlaceholder: 'Odaberite grad',
        oibInvalid: 'Neispravan OIB',
        employeeDeleted: 'Zaposlenik uspješno izbrisan',
        deleteMessage: 'Jeste li sigurni da želite izbrisati ovog zaposlenika?',
        chooseGender: 'Odaberite spol',
        generalInfo: 'Opći podaci',
        name: 'Ime',
        surname: 'Prezime',
        oib: 'OIB',
        birthDate: 'Datum rođenja',
        activeFrom: 'Aktivan od',
        activeTo: 'Aktivan do',
        vacationDays: 'Broj dana godišnjeg odmora',
        gender: 'Spol',
        residenceAddress: 'Adresa prebivališta',
        inhabitancyAddress: 'Adresa boravišta',
        address: 'Adresa',
        city: 'Grad',
        country: 'Država',
        email: 'Email',
        phone: 'Telefon',
        positionInfo: 'Podaci o radnom mjestu',
        position: 'Radno mjesto',
        company: 'Tvrtka',
        company2: 'Druga tvrtka',
        workSafety: 'Zaštita na radu',
        workSafetyInfo: 'Podaci vezani za zaštitu na radu',
        workUnderSupervision: 'Odluka za rad pod nadzorom',
        workUnderSupervisionExpiryDate: 'Datum isteka rada pod nadzorom',
        competenceForWorkInSafeManner: 'Osposobljenost za rad na siguran način',
        competenceForFireProtectionMeasures: 'Osposobljenost za provođenje mjera zaštite od požara',
        competenceForFirstAid: 'Osposobljenost za provođenje mjera prve pomoći',
        workSafetyAuthorizee: 'Ovlaštenik zaštite na radu',
        workSafetyAuthorizationExpiryDate: 'Datum isteka ovlaštenja za zaštitu na radu',
        medicalExamination: 'Liječnički pregled',
        medicalExaminationRequired: 'Potreban liječnički pregled',
        medicalExaminationExpiryDate: 'Datum isteka liječničkog pregleda',
        medicalExaminationScheduled: 'Zakazan liječnički pregled',
        medicalExaminationNotes: 'Napomene liječničkog pregleda',
        medicalExaminationSections: 'Popis točaka liječničkog pregleda',
        medicalExaminationInfo: 'Podaci vezani za liječnički pregled',
        toxicologyInfo: 'Podaci vezani za toksikologiju',
        toxicologyCompetence: 'Osposobljenost za poslove s opasnim kemikalijama',
        responsibleForToxicology: 'Odgovorna osoba za rad s opasnim kemikalijama',
        executantForToxicology: 'Neposredni izvršitelj poslova s opasnim kemikalijama',
        toxicologyExpiryDate: 'Datum isteka toksikologije',
        workShoesSize: 'Veličina radne obuće',
        workPantsSize: 'Veličina radnih hlača',
        workJacketSize: 'Veličina radne jakne',
        workPermitInfo: 'Podaci vezani za radnu dozvolu',
        workPermit: 'Radna dozvola',
        workPermitExpiryDate: 'Datum isteka radne dozvole',
        workPermitExtensionRequestDeadlineDate:
            'Rok za predaju zahtjeva za prođuženje radne dozvole',
        workPermitExtensionRequestSubmitDate: 'Datum predaje zahtjeva za produženje radne dozvole',
        showInactive: 'Prikaži neaktivne',
        hideInactive: 'Sakrij neaktivne',
        gear: 'HTZ Oprema',
        employmentAgency: 'Agencija za zapošljavanje',
        role: 'Uloga',
        chooseRole: 'Odaberite ulogu',
        usedBonusAmountBeforeEmployment:
            'Iznos iskorištene nagrade pri zaposlenju u godini zaposlenja',
        usedBonus: 'Iznos iskorištene nagrade',
        identificationNumber: 'Matični broj',
        companyPlaceholder: 'Odaberite tvrtku',
    },
    administration: {
        administration: 'Administracija',
        expenseGroups: {
            title: 'Grupe troškova',
            add: 'Dodaj grupu',
            name: 'Naziv',
            expenseType: 'Vrsta troška',
            deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu grupu?',
            edit: 'Uredi grupu',
            savedMessage: 'Grupa uspješno spremljena',
            deletedMessage: 'Grupa uspješno izbrisana',
            successfullySaved: 'Grupa uspješno spremljena',
            chooseExpenseType: 'Odaberite vrstu troška',
        },
        projectItems: {
            title: 'Stavke troškovnika',
            itemIndex: 'Redni broj',
            item: 'Stavka',
            unit: 'Mjerna jedinica',
            quantity: 'Količina',
            oneItemPrice: 'Jedinična cijena',
            totalPrice: 'Ukupna cijena',
            conctractor: 'Naručitelj',
            cooperator: 'Izvođač',
            project: 'Centar odgovornosti',
            projectCode: 'Oznaka centra odgovornosti',
            year: 'Godina',
            export: 'Export',
        },
    },
    workHourRecods: {
        title: 'Evidencija sati',
        noEmployeesWarning: {
            message: 'Nije pronađen nijedan zaposlenik',
        },
        noProjectsWarning: {
            message: 'Nije pronađen nijedan centar odgovornosti',
        },
        workHoursTotal: 'Redovan rad ',
        fieldWorkDays: 'Terenski rad (dani)',
        vacationHoursTotal: 'Godišnji odmor',
        sickLeaveHoursTotal: 'Bolovanje',
        sickLeaveInjuryHoursTotal: 'Bolovanje (ozljeda na radu)',
        dayOffHoursTotal: 'Slobodan dan',
        nonWorkingTotal: 'Neradni dan',
        showSummary: 'Prikaži sumarno',
        today: 'Danas',
        deleteRecord: 'Izbriši unos',
        deleteConfirmMessege: 'Jeste li sigurni da želite izbrisati ovaj unos?',
        deadlineExpiredMessage: 'Evidencija sati je zaključana za odabrani mjesec',
        unauthorizedProject: 'Evidencija sati je za centar odgovornosti za koji niste autorizirani',
        createdBy: 'Kreirao',
        createdDate: 'Datum kreiranja',
        lastModifiedBy: 'Zadnje izmijenio',
        lastModifiedDate: 'Datum zadnje izmjene',
        form: {
            title: 'Uredi unos',
            project: 'Centar odgovornosti',
            employee: 'Zaposlenik',
            date: 'Datum',
            hours: 'Broj sati',
            days: 'Broj dana',
            type: 'Vrsta rada',
            fieldWork: 'Terenski rad',
            coefficient: 'Koeficijent',
            description: 'Opis',
            accomodation: 'Smještaj',
            accomodationSupplier: 'Dobavljač smještaja',
            food: 'Prehrana',
            foodSupplier: 'Dobavljač prehrane',
            gear: 'HTZ oprema',
            numberOfMeals: 'Broj obroka',
        },
        copyRecords: 'Kopiraj zapise',
        showActive: 'Prikaži aktivne',
        showInactive: 'Prikaži neaktivne',
        startingDate: 'Izvorišni datum',
        destinationDate: 'Odredišni datum',
        successfullyCopied: 'Zapisi uspješno kopirani',
    },
    cooperators: {
        cooperators: 'Suradnici',
        addCooperator: 'Dodaj suradnika',
        editCooperator: 'Uredi suradnika',
        name: 'Naziv',
        oib: 'OIB',
        personType: 'Vrsta osobe',
        associateType: 'Vrsta suradnika',
        mbg: 'MBG',
        mb: 'MB',
        iban: 'IBAN',
        address: 'Adresa',
        zip: 'ZIP',
        city: 'Grad',
        country: 'Država',
        email: 'Email',
        phone: 'Telefon',
        web: 'Web',
        successfullySaved: 'Suradnik uspješno spremljen',
        projectDeleted: 'Suradnik uspješno izbrisan',
        choosePersonType: 'Odaberite vrstu osobe',
        chooseAssociateType: 'Odabreite vrstu suradnika',
        countryPlaceholder: 'Odaberite državu',
        cityPlaceholder: 'Odaberite grad',
        oibLength: 'OIB mora imati 11 znamenaka',
        cooperatorDeleted: 'Suradnik uspješno izbrisan',
        deleteMessage: 'Jeste li sigurni da želite izbrisati ovog suradnika?',
        oibInvalid: 'Neispravan OIB',
        mbInvalid: 'Neispravan MB',
        mbgInvalid: 'Neispravan MBG',
        ibanTooLong: 'Maksimalno 32 znaka',
        contacts: {
            title: 'Kontakti',
            chooseContact: 'Odaberite kontakt',
            successfullySaved: 'Kontakt uspješno spremljen',
            deleted: 'Kontakt uspješno izbrisan',
            deleteConfirm: 'Jeste li sigurni da želite izbrisati ovaj kontakt?',
            add: 'Dodaj kontakt',
            edit: 'Uredi kontakt',
        },
    },
    account: {
        title: 'Korisnički račun',
        profile: 'Profil',
        login: 'Prijava',
        logout: 'Odjava',
    },
    warehouses: {
        warehouses: 'Skladišta',
    },
    warehouseItems: {
        item: 'Stavka',
        items: 'Stavke skladišta',
        allWarehouses: 'Svi centri odgovornosti',
        name: 'Naziv',
        code: 'Oznaka',
        sourceDocument: 'Izvorni dokument',
        unit: 'Jed. mjera',
        unitPrice: 'Jed. cijena',
        quantity: 'Količina',
        totalPrice: 'Uk. cijena',
        description: 'Opis',
        successfullySaved: 'Stavka uspješno spremljena',
        addWarehouseItem: 'Dodaj stavku',
        editWarehouseItem: 'Uredi stavku',
    },
    documents: {
        complete: 'Završi',
        add: 'Dodaj dokument',
        code: 'Oznaka',
        project: 'Centar odgovornosti',
        createdBy: 'Kreirao',
        createdDate: 'Datum kreiranja',
        status: 'Status',
        totalPrice: 'Uk. cijena',
        parentDocument: 'Izvorni dokument',
        storned: 'Stornirano',
        storno: 'Storno',
        stornedDocumentCode: 'Stornirani dokument',
        storningDocuments: 'Stornirajući dokumenti',
        warehouse: 'Centar odgovornosti',
        sourceWarehouse: 'Izvorni centar odgovornosti',
        destinationProject: 'Odredišni centar odgovornosti',
        supplier: 'Dobavljač',
        deadlineDate: 'Rok isporuke',
        receiptCode: 'Oznaka računa',
        receiptAmount: 'Iznos računa',
        comment: 'Napomena',
        sendToProcessing: 'Pošalji na obradu',
        sendToProcessingConfirm: 'Jeste li sigurni da želite poslati dokument na obradu?',
        sendToProcessingSuccess: 'Dokument uspješno poslan na obradu',
        exportSuccess: 'Dokument uspješno izvezen',
        confirmStatus:
            'Promjenom statusa centra odgovornosti u "Završeno" se automatski generira utrošnica sa preostalim stavkama u skladištu. Jeste li sigurni da želite promijeniti status u "Završeno"?',
        items: {
            title: 'Stavke',
            view: 'Pregled stavaka',
            add: 'Dodaj stavku',
            edit: 'Uredi stavku',
            deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu stavku?',
            saveSuccess: 'Stavka uspješno spremljena',
            deleteSuccess: 'Stavka uspješno izbrisana',
            code: 'Oznaka',
            name: 'Naziv stavke',
            unit: 'Jed. mjera',
            quantity: 'Količina',
            unitPrice: 'Jed. cijena',
            totalPrice: 'Uk. cijena',
            comment: 'Napomena',
            parentItem: 'Oznaka roditelja',
            warehouseItem: 'Stavka skladišta',
            projectItem: 'Stavka troškovnika',
            expenseType: 'Vrsta troška',
            expenseGroup: 'Grupa troška',
            status: 'Status',
            chooseExpenseType: 'Odaberite vrstu troška',
            chooseDestinationWarehouse: 'Potrebno odabrati odredišni centar odgovornosti',
            chooseWarehouseItem: 'Potrebno odabrati stavku skladišta',
            finished: 'Prikaži završeno',
        },
    },
    sidebar: {
        toolsAndEquipment: 'Alati i oprema',
        vehicles: 'Vozila',
    },
    general: {
        details: 'Detalji',
        maxLength21: 'Maksimalno 21 znak',
        maxLength50: 'Maksimalno 21 znak',
        maxLength100: 'Maksimalno 100 znakova',
        maxLength200: 'Maksimalno 200 znakova',
        maxLength20: 'Maksimalno 20 znakova',
        errorDeletingData: 'Greška prilikom brisanja',
        errorSavingData: 'Greška prilikom spremanja',
        wrongFileType: 'Pogrešan tip datoteke',
        invalidEmail: 'Neispravan email',
        edit: 'Uredi',
        add: 'Dodaj',
        cancel: 'Odustani',
        save: 'Spremi',
        delete: 'Izbriši',
        confirm: 'Potvrdi',
        import: 'Uvezi',
        confirmMessage: 'Jeste li sigurni?',
        total: 'Ukupno',
        chooseDate: 'Odaberite datum',
        requiredField: 'Obavezno polje',
        noData: 'Nema podataka',
        startBeforeEndValidationMessage:
            'Datum početka mora biti manji ili jednak datumu završetka',
        sameDatesInvalid: 'datumi ne smiju biti isti',
        fromBeforeToValidationMessage: 'Datum od mora biti manji ili jednak datumu do',
        cancelSort: 'Otkaži sortiranje',
        triggerSortAsc: 'Klikni za uzlazno sortiranje',
        triggerSortDesc: 'Klikni za silazno sortiranje',
        upload: 'Odaberite datoteku',
        xlsxFile: 'Datoteka (.xlsx)',
        sheetName: 'Naziv lista',
        dateFrom: 'Datum od',
        dateTo: 'Datum do',
        actions: 'Akcije',
        search: 'Pretraži',
        filter: 'Filtriraj',
        clear: 'Očisti',
        choose: 'Odaberite',
        generate: 'Generiraj',
        storno: 'Storniraj',
        readMore: 'Pročitaj više',
        back: 'Natrag',
        date: 'Datum',
        export: 'Izvezi',
        exportPdf: 'Izvezi u PDF',
        exportXlsx: 'Izvezi u Excel',
        exportXlsxByDay: 'Izvezi u Excel po danu',
        exportXlsxByProject: 'Izvezi u Excel po centru odgovornosti',
        print: 'Ispiši',
        printPdf: 'Ispiši u PDF',
        createXlsx: 'Kreiraj XLSX',
        copyFrom: 'Kopiraj iz',
        copyTo: 'Kopiraj u',
    },
    salaries: {
        salaries: 'Plaće',
        month: 'Mjesec',
        monthPlaceholder: 'Odaberite mjesec',
        year: 'Godina',
        yearPlaceholder: 'Odaberite godinu',
        employee: 'Zaposlenik',
        contractedAmount: 'Ugovoreni iznos',
        netAmount: 'Neto iznos',
        agreedAmount: 'Dogovoreni iznos',
        hourlyRate: 'Satnica',
        additionRecurring: 'Dodatak (ponavljajući)',
        additionOneTime: 'Dodatak (jednokratni)',
        note: 'Napomena',
        actions: 'Akcije',
        copyLastMonth: 'Kopiraj iz prethodnog mjeseca',
        copySalaries: 'Kopiraj plaće',
        export: 'Izvezi',
        createSuccessMessage: 'Plaća uspješno spremljena',
        createErrorMessage: 'Greška prilikom spremanja plaće',
        updateSuccessMessage: 'Plaća uspješno ažurirana',
        updateErrorMessage: 'Greška prilikom ažuriranja plaće',
        company: 'Tvrtka',
        exportSalaries: 'Izvezi plaće u excel',
        successfullyCopied: 'Plaće uspješno kopirane',
        failedCopy: 'Greška prilikom kopiranja plaća',
        copyFromMonth: 'Kopiraj plaće iz mjeseca',
        copyToMonth: 'Kopiraj plaće iz godine',
        copyFromYear: 'U mjesec',
        copyToYear: 'U godinu',
        copy: 'Kopiraj',
        deleteSuccessMessage: 'Plaća uspješno izbrisana',
        deleteErrorMessage: 'Greška prilikom brisanja plaće',
        confirmDeleteTitle: 'Obriši plaću',
        bothFieldsCannotBeFilled: 'Dozvoljen je unos ili Dogovorenog iznosa ili Satnice',
        confirmDeleteContent: 'Jeste li sigurni da želite izbrisati ovu plaću?',
        validationMessage:
            'Ispunite sva obavezna polja, pa onda spremite plaću. Obavezno morate unijeti: ugovoreni iznos, neto iznos i (dogovoreni iznos ili satnicu).',
        agreedAmountOrHourlyRateRequired: 'Obavezno unesi ili dogovoreni iznos ili satnicu',
    },
    payrolls: {
        payrolls: 'Obračuni',
        month: 'Mjesec',
        monthPlaceholder: 'Odaberite mjesec',
        year: 'Godina',
        yearPlaceholder: 'Odaberite godinu',
        employee: 'Zaposlenik',
        accounting: 'Obračun',
        administration: 'Računovodstveno',
        employeeID: 'Matični broj',
        hourlyRateBOL: 'Satnica BOL',
        hourlyRateONR: 'Satnica ONR',
        hourlyRateGO: 'Satnica GO',
        regularWorkHours: 'Sati redovnog rada',
        hoursBOL: 'Sati BOL',
        hoursONR: 'Sati ONR',
        hoursGO: 'Sati GO',
        fieldDays: 'Dani terena',
        totalRegularWork: 'Ukupno redovan rad',
        totalBOL: 'Ukupno BOL',
        totalONR: 'Ukupno ONR',
        totalGO: 'Ukupno GO',
        totalFieldWork: 'Ukupno terenski',
        additions: 'Dodaci',
        total: 'Ukupno',
        netAmount1: 'Neto iznos (1)',
        workingDaysInMonth: 'Broj radnih dana u mjesecu',
        fieldWorkFundDays: 'Terenski fond dana',
        fieldWorkFundDaysEmployee: 'Terenski fond dana zaposlenika',
        fieldWorkDaysForPayment: 'Broj dana terenskog za isplatu',
        fieldWorkAddition2: 'Terenski dodatak (2)',
        reward3: 'Nagrada (3)',
        stimulations4: 'Stimulacije (4)',
        generate: 'Generiraj',
        regenerate: 'Ponovno generiraj',
        lock: 'Zaključaj',
        unlock: 'Otključaj',
        exportPayroll: 'Izvezi obračun',
        exportPayrollHours: 'Izvezi računovodstvenu satnicu',
        company: 'Tvrtka',
        successUpdate: 'Uspješno ažuriran obračun!',
        failedMessage: 'Greška prilikom spremanja obračuna!',
        accountingTotal: 'Ukupno',
        hourlyRateDividerBOL: 'Djeljitelj satnice BOL',
        hourlyRateDividerBOLWorkInjury: 'Djeljitelj satnice BOL (ozljeda na radu)',
        hourlyRateDividerGO: 'Djeljitelj satnice GO',
        amountOfFieldAllowance: 'Iznos terenskog dodatka',
        maxAmountOfAnnualBonus: 'Max. iznos godišnje nagrade',
        maxAmountOfFieldAllowance: 'Max. iznos terenskog dodatka',
        lockMessage: 'Jeste li sigurni da želite zaključati obračun?',
        unlockMessage: 'Jeste li sigurni da želite otključati obračun?',
    },
    employeeExpenses: {
        employeeExpenses: 'Troškovi',
        employee: 'Zaposlenici',
        total: 'Ukupno',
        company: 'Tvrtka',
        month: 'Mjesec',
        year: 'Godina',
        importSuccess: 'Troškovi uspješno uvezeni',
        importError: 'Greška prilikom uvoza troškova',
    },
};

export const documents: DocumentTranslations = {
    documents: {
        title: 'Dokumenti',
        name: 'Dokument',
        add: 'Dodaj dokument',
        edit: 'Uredi dokument',
        generate: 'Generiraj dokument',
        generateSuccess: 'Dokument uspješno generiran',
        completeSuccess: 'Dokument uspješno završen',
        saveSuccess: 'Dokument uspješno spremljen',
        deleteSuccess: 'Dokument uspješno izbrisan',
        stornoSuccess: 'Dokument uspješno storniran',
        stornoConfirm: 'Jeste li sigurni da želite stornirati ovaj dokument?',
        generateConfirm: 'Jeste li sigurni da želite generirati dokument?',
        deleteConfirm: 'Jeste li sigurni da želite izbrisati ovaj dokument?',
        completeConfirm: 'Jeste li sigurni da želite završiti uređivanje dokumente?',
        parent: '',
        parentItem: '',
    },
    requisitionOrder: {
        title: 'Trebovnice',
        name: 'Trebovnica',
        add: 'Dodaj trebovnicu',
        edit: 'Uredi trebovnicu',
        generate: 'Generiraj trebovnicu',
        generateSuccess: 'Trebovnica uspješno generirana',
        completeSuccess: 'Trebovnica uspješno završena',
        saveSuccess: 'Trebovnica uspješno spremljena',
        deleteSuccess: 'Trebovnica uspješno izbrisana',
        stornoSuccess: 'Trebovnica uspješno stornirana',
        stornoConfirm: 'Jeste li sigurni da želite stornirati ovu trebovnicu?',
        generateConfirm: 'Jeste li sigurni da želite generirati trebovnicu?',
        deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu trebovnicu?',
        completeConfirm: 'Jeste li sigurni da želite završiti uređivanje trebovnice?',
        parent: '',
        parentItem: '',
    },
    purchaseOrder: {
        title: 'Narudžbe',
        name: 'Narudžba',
        add: 'Dodaj narudžbu',
        edit: 'Uredi narudžbu',
        generate: 'Generiraj narudžbu',
        generateSuccess: 'Narudžba uspješno generirana',
        completeSuccess: 'Narudžba uspješno završena',
        saveSuccess: 'Narudžba uspješno spremljena',
        deleteSuccess: 'Narudžba uspješno izbrisana',
        stornoSuccess: 'Narudžba uspješno stornirana',
        stornoConfirm: 'Jeste li sigurni da želite stornirati ovu narudžbu?',
        generateConfirm: 'Jeste li sigurni da želite generirati narudžbu?',
        deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu narudžbu?',
        completeConfirm: 'Jeste li sigurni da želite završiti uređivanje narudžbe?',
        parent: 'Trebovnica',
        parentItem: 'Stavka trebovnice',
    },
    purchaseReceivedNote: {
        title: 'Primke',
        name: 'Primka',
        add: 'Dodaj primku',
        edit: 'Uredi primku',
        generate: 'Generiraj primku',
        generateSuccess: 'Primka uspješno generirana',
        completeSuccess: 'Primka uspješno završena',
        saveSuccess: 'Primka uspješno spremljena',
        deleteSuccess: 'Primka uspješno izbrisana',
        stornoSuccess: 'Primka uspješno stornirana',
        stornoConfirm: 'Jeste li sigurni da želite stornirati ovu primku?',
        generateConfirm: 'Jeste li sigurni da želite generirati primku?',
        deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu primku?',
        completeConfirm: 'Jeste li sigurni da želite završiti uređivanje primke?',
        parent: 'Narudžba',
        parentItem: 'Stavka narudžbe',
    },
    transferNote: {
        title: 'Izlazne međuskladišnice',
        name: 'Izlazna međuskladišnica',
        add: 'Dodaj izlaznu međuskladišnicu',
        edit: 'Uredi izlaznu međuskladišnicu',
        generate: 'Generiraj izlaznu međuskladišnicu',
        generateSuccess: 'Izlazna međuskladišnica uspješno generirana',
        completeSuccess: 'Izlazna međuskladišnica uspješno završena',
        saveSuccess: 'Izlazna međuskladišnica uspješno spremljena',
        deleteSuccess: 'Izlazna međuskladišnica uspješno izbrisana',
        stornoSuccess: 'Izlazna međuskladišnica uspješno stornirana',
        stornoConfirm: 'Jeste li sigurni da želite stornirati ovu izlaznu međuskladišnicu?',
        generateConfirm: 'Jeste li sigurni da želite generirati izlaznu međuskladišnicu?',
        deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu izlaznu međuskladišnicu?',
        completeConfirm: 'Jeste li sigurni da želite završiti uređivanje izlazne međuskladišnice?',
        parent: 'Trebovnica',
        parentItem: 'Stavka trebovnice',
    },
    transferReceivedNote: {
        title: 'Ulazne međuskladišnice',
        name: 'Ulazna međuskladišnica',
        add: 'Dodaj ulaznu međuskladišnicu',
        edit: 'Uredi ulaznu međuskladišnicu',
        generate: 'Generiraj ulaznu međuskladišnicu',
        generateSuccess: 'Ulazna međuskladišnica uspješno generirana',
        completeSuccess: 'Ulazna međuskladišnica uspješno završena',
        saveSuccess: 'Ulazna međuskladišnica uspješno spremljena',
        deleteSuccess: 'Ulazna međuskladišnica uspješno izbrisana',
        stornoSuccess: 'Ulazna međuskladišnica uspješno stornirana',
        stornoConfirm: 'Jeste li sigurni da želite stornirati ovu ulaznu međuskladišnicu?',
        generateConfirm: 'Jeste li sigurni da želite generirati ulaznu međuskladišnicu?',
        deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu ulaznu međuskladišnicu?',
        completeConfirm: 'Jeste li sigurni da želite završiti uređivanje ulazne međuskladišnice?',
        parent: 'Izlaz. međuskladišnica',
        parentItem: 'Stavka izlaz. međuskladišnice',
    },
    consumptionNote: {
        title: 'Utrošnice',
        name: 'Utrošnica',
        add: 'Dodaj utrošnicu',
        edit: 'Uredi utrošnicu',
        generate: 'Generiraj utrošnicu',
        generateSuccess: 'Utrošnica uspješno generirana',
        completeSuccess: 'Utrošnica uspješno završena',
        saveSuccess: 'Utrošnica uspješno spremljena',
        deleteSuccess: 'Utrošnica uspješno izbrisana',
        stornoSuccess: 'Utrošnica uspješno stornirana',
        stornoConfirm: 'Jeste li sigurni da želite stornirati ovu utrošnicu?',
        generateConfirm: 'Jeste li sigurni da želite generirati utrošnicu?',
        deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu utrošnicu?',
        completeConfirm: 'Jeste li sigurni da želite završiti uređivanje utrošnice?',
        parent: '',
        parentItem: '',
    },
    writeOffNote: {
        title: 'Otpisnice',
        name: 'Otpisnica',
        add: 'Dodaj otpisnicu',
        edit: 'Uredi otpisnicu',
        generate: 'Generiraj otpisnicu',
        generateSuccess: 'Otpisnica uspješno generirana',
        completeSuccess: 'Otpisnica uspješno završena',
        saveSuccess: 'Otpisnica uspješno spremljena',
        deleteSuccess: 'Otpisnica uspješno izbrisana',
        stornoSuccess: 'Otpisnica uspješno stornirana',
        stornoConfirm: 'Jeste li sigurni da želite stornirati ovu otpisnicu?',
        generateConfirm: 'Jeste li sigurni da želite generirati otpisnicu?',
        deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu otpisnicu?',
        completeConfirm: 'Jeste li sigurni da želite završiti uređivanje otpisnice?',
        parent: 'Izlaz. međuskladišnica',
        parentItem: 'Stavka izlaz. međuskladišnice',
    },
    liquidation: {
        title: 'Likvidature',
        name: 'Likvidatura',
        add: 'Dodaj likvidaturu',
        edit: 'Uredi likvidaturu',
        generate: 'Generiraj likvidaturu',
        generateSuccess: 'Likvidatura uspješno generirana',
        completeSuccess: 'Likvidatura uspješno završena',
        saveSuccess: 'Likvidatura uspješno spremljena',
        deleteSuccess: 'Likvidatura uspješno izbrisana',
        stornoSuccess: 'Likvidatura uspješno stornirana',
        stornoConfirm: 'Jeste li sigurni da želite stornirati ovu likvidaturu?',
        generateConfirm: 'Jeste li sigurni da želite generirati likvidaturu?',
        deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu likvidaturu?',
        completeConfirm: 'Jeste li sigurni da želite završiti uređivanje likvidature?',
        parent: 'Primka',
        parentItem: 'Stavka primke',
    },
    order: {
        title: 'Narudžbenice',
        name: 'Narudžbenica',
        add: 'Dodaj narudžbenicu',
        edit: 'Uredi narudžbenicu',
        generate: 'Generiraj narudžbenicu',
        generateSuccess: 'Narudžbenica uspješno generirana',
        completeSuccess: 'Narudžbenica uspješno završena',
        saveSuccess: 'Narudžbenica uspješno spremljena',
        deleteSuccess: 'Narudžbenica uspješno izbrisana',
        stornoSuccess: 'Narudžbenica uspješno stornirana',
        stornoConfirm: 'Jeste li sigurni da želite stornirati ovu narudžbenicu?',
        generateConfirm: 'Jeste li sigurni da želite generirati narudžbenicu?',
        deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu narudžbenicu?',
        completeConfirm: 'Jeste li sigurni da želite završiti uređivanje narudžbenice?',
        parent: 'Narudžba',
        parentItem: 'Stavka narudžbe',
    },
};
