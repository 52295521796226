import { FormProps } from 'antd/lib/form';
import {
    DocumentDetailVm,
    DocumentItemVm,
    DocumentTypeEnum,
    WarehouseItemVm,
} from '../../../utils/api';
import DocumentForm from './DocumentForm';

interface OwnProps {
    document?: DocumentDetailVm;
    typeId: DocumentTypeEnum;
    documentItems?: DocumentItemVm[];
    warehouseItems?: WarehouseItemVm[];
    onClose: () => void;
    onSuccess: (document: DocumentDetailVm) => void;
}

export type Props = OwnProps & FormProps;

export default DocumentForm;
