import Table, { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { defaultTablePagination } from '../../../config/constants';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { getSearchFilter } from '../../../helpers/FilterHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import { EmployeeSalaryVm, ISalaryVm, SalariesClient } from '../../../utils/api';
import {
    getPagingSortingParams,
    applySearchParams,
    getFiltersParams,
} from '../../../helpers/SearchParamsHelper';
import { isVisible } from '../../../helpers/ProjectItemHelper';
import scrollIntoView from 'scroll-into-view';
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, FormInstance, message, Space, Modal } from 'antd';
import { Props } from '.';
import { formatPrice } from '../../../helpers/PriceHelper';
import { showConfirm } from '../../../helpers/NotificationHelper';

const { confirm } = Modal;

const EDITING_ROW_CLASS = 'editing-row';
const SCROLLABLE_CONTAINER_CLASS = 'ant-table-body';

const SalaryTable: React.FC<Props> = (props: Props) => {
    const [editingItem, setEditingItem] = useState<EmployeeSalaryVm | undefined>(undefined);
    const formRef = useRef<FormInstance>(null);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const editingRow = document.querySelector<HTMLElement>(`.${EDITING_ROW_CLASS}`);
        const container = document.querySelector<HTMLElement>(`.${SCROLLABLE_CONTAINER_CLASS}`);

        if (editingItem && editingRow && container && !isVisible(editingRow, container)) {
            scrollIntoView(editingRow, {
                align: {
                    top: 1,
                },
            });
        }
    }, [editingItem]);

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<EmployeeSalaryVm> | SorterResult<EmployeeSalaryVm>[] = {}
    ) => {
        const currSearchParams = new URLSearchParams(location.search);
        const tableSearchParams = createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    const createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<EmployeeSalaryVm> | SorterResult<EmployeeSalaryVm>[] = {}
    ): URLSearchParams => {
        const tableSearchParams = new URLSearchParams();

        // Handle pagination
        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );

        // Handle sorting
        const singleSorter = sorter as SorterResult<EmployeeSalaryVm>;
        let sortBy;
        if (
            Array.isArray(singleSorter.field) &&
            singleSorter.field[0] === 'employee' &&
            singleSorter.field[1] === 'fullName'
        ) {
            sortBy = 'Employee_Name';
        } else {
            sortBy = singleSorter.field?.toString() || undefined;
        }

        if (sortBy && singleSorter.order) {
            tableSearchParams.set('sortBy', sortBy);
            tableSearchParams.set('sortOrder', singleSorter.order);
        } else {
            tableSearchParams.delete('sortBy');
            tableSearchParams.delete('sortOrder');
        }

        // Handle filtering
        const employeeFullNameFilter = filters['employee.fullName'];
        if (employeeFullNameFilter) {
            const filterValue = employeeFullNameFilter.toString();
            tableSearchParams.set('filter.Employee_Name', filterValue || '');
        }

        // Append the selected company, month, and year to the URL
        tableSearchParams.set('companyId', props.selectedCompanyId?.toString() || '');
        tableSearchParams.set('month', props.selectedMonth?.toString() || '');
        tableSearchParams.set('year', props.selectedYear?.toString() || '');

        return tableSearchParams;
    };

    const handleEdit = (item: Partial<EmployeeSalaryVm>) => {
        setEditingItem(item as EmployeeSalaryVm);
    };

    const handleCancelEdit = () => {
        setEditingItem(undefined);
    };

    const handleSaveEdit = () => {
        if (formRef.current) {
            const { agreedAmount, hourlyRate } = formRef.current.getFieldsValue();

            if (agreedAmount && hourlyRate) {
                formRef.current.validateFields(['agreedAmount', 'hourlyRate']);
                message.error(translations.salaries.bothFieldsCannotBeFilled);
                return;
            }

            formRef.current.validateFields().then(() => formRef.current?.submit());
        }
    };

    const handleFormSubmit = async (values: any) => {
        if (!editingItem) return;

        try {
            if (editingItem && !editingItem.employee) return;

            const newSalary = {
                ...values,
                employeeId: editingItem.employee?.id,
                month: props.selectedMonth,
                year: props.selectedYear,
                companyId: props.selectedCompanyId,
            };

            const existingSalary = props.employeeSalaries.find(
                (salary) =>
                    salary.employee?.id === newSalary.employeeId &&
                    salary.salary?.month === newSalary.month &&
                    salary.salary?.year === newSalary.year
            );

            if (existingSalary && existingSalary.salary) {
                await new SalariesClient().update(existingSalary.salary.id, newSalary);
                message.success(translations.salaries.updateSuccessMessage);
            } else {
                await new SalariesClient().create(newSalary);
                message.success(translations.salaries.createSuccessMessage);
            }

            setEditingItem(undefined);
            props.fetchEmployeeSalaries();
        } catch (error) {
            console.error(error);
            message.error(translations.salaries.createErrorMessage);
        }
    };

    const confirmDelete = (employeeId: number | undefined) => {
        /*confirm({
            title: translations.salaries.confirmDeleteTitle,
            content: translations.salaries.confirmDeleteContent,
            onOk: () => handleDelete(employeeId),
            onCancel() {},
        });*/
        showConfirm(
            () => handleDelete(employeeId),
            translations.salaries.confirmDeleteContent,
            translations.general.confirm,
            true
        );
    };

    const handleDelete = async (employeeId: number | undefined) => {
        if (!employeeId) return;

        try {
            const salaryToDelete = props.employeeSalaries.find(
                (salary) =>
                    salary.employee?.id === employeeId &&
                    salary.salary?.month === props.selectedMonth &&
                    salary.salary?.year === props.selectedYear
            );

            if (salaryToDelete && salaryToDelete.salary) {
                await new SalariesClient().delete(salaryToDelete.salary.id);
                message.success(translations.salaries.deleteSuccessMessage);
            }

            props.fetchEmployeeSalaries();
        } catch (error) {
            console.error(error);
            message.error(translations.salaries.deleteErrorMessage);
        }
    };

    const formatNumber = (
        num: number | string | undefined,
        salary: ISalaryVm | undefined
    ): string => {
        if (salary && Object.keys(salary).length > 0) {
            return num ? formatPrice(num) : '0,00';
        }
        return '';
    };

    const validateAgreedAmountAndHourlyRate = (_: any, value: any, callback: Function) => {
        const { agreedAmount, hourlyRate } = formRef.current?.getFieldsValue() || {};

        if (agreedAmount && hourlyRate) {
            callback(translations.salaries.bothFieldsCannotBeFilled);
        } else if (!agreedAmount && !hourlyRate) {
            callback(translations.salaries.agreedAmountOrHourlyRateRequired);
        } else {
            callback();
        }
    };

    const getTableColumns = (): ColumnProps<EmployeeSalaryVm>[] => {
        const { location } = props;
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam } = getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        return [
            {
                title: translations.salaries.employee,
                dataIndex: ['employee', 'fullName'],
                ...getSearchFilter(),
                sorter: true,
                filteredValue: filters.Employee_Name ? [filters.Employee_Name] : null,
                sortOrder: sortByParam === 'Employee_Name' ? sortOrderParam : null,
                width: 250,
                render: (text: string, record: EmployeeSalaryVm): React.ReactElement => (
                    <Link
                        to={Routes.ROUTE_EMPLOYEES_READ.replace(
                            ReplaceStrings.ENTITY_ID,
                            record.employee?.id?.toString() || '0'
                        )}
                    >
                        {text}
                    </Link>
                ),
            },
            {
                title: translations.salaries.contractedAmount,
                dataIndex: ['salary', 'contractedAmount'],
                align: 'right',
                render: (text: string, record: EmployeeSalaryVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="contractedAmount"
                            initialValue={editingItem?.salary?.contractedAmount}
                            rules={[
                                { required: true, message: translations.general.requiredField },
                            ]}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.salaries.contractedAmount}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                            />
                        </Form.Item>
                    ) : (
                        <span>{formatNumber(text, record.salary)}</span>
                    ),
            },
            {
                title: translations.salaries.netAmount,
                dataIndex: ['salary', 'netAmount'],
                align: 'right',
                render: (text: string, record: EmployeeSalaryVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="netAmount"
                            initialValue={editingItem?.salary?.netAmount}
                            rules={[
                                { required: true, message: translations.general.requiredField },
                            ]}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.salaries.netAmount}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                            />
                        </Form.Item>
                    ) : (
                        <span>{formatNumber(text, record.salary)}</span>
                    ),
            },
            {
                title: translations.salaries.agreedAmount,
                dataIndex: ['salary', 'agreedAmount'],
                align: 'right',
                render: (text: string, record: EmployeeSalaryVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="agreedAmount"
                            initialValue={editingItem?.salary?.agreedAmount}
                            rules={[{ validator: validateAgreedAmountAndHourlyRate }]}
                            validateTrigger={['onChange', 'onBlur']}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.salaries.agreedAmount}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                                onChange={() =>
                                    formRef.current?.validateFields(['agreedAmount', 'hourlyRate'])
                                }
                            />
                        </Form.Item>
                    ) : (
                        <span>{formatNumber(text, record.salary)}</span>
                    ),
            },
            {
                title: translations.salaries.hourlyRate,
                dataIndex: ['salary', 'hourlyRate'],
                align: 'right',
                render: (text: string, record: EmployeeSalaryVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="hourlyRate"
                            initialValue={editingItem?.salary?.hourlyRate}
                            rules={[{ validator: validateAgreedAmountAndHourlyRate }]}
                            validateTrigger={['onChange', 'onBlur']}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.salaries.hourlyRate}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                                onChange={() =>
                                    formRef.current?.validateFields(['agreedAmount', 'hourlyRate'])
                                }
                            />
                        </Form.Item>
                    ) : (
                        <span>{formatNumber(text, record.salary)}</span>
                    ),
            },
            {
                title: translations.salaries.additionRecurring,
                dataIndex: ['salary', 'additionRecurring'],
                align: 'right',
                render: (text: string, record: EmployeeSalaryVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="additionRecurring"
                            initialValue={editingItem?.salary?.additionRecurring}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.salaries.additionRecurring}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                            />
                        </Form.Item>
                    ) : (
                        <span>{formatNumber(text, record.salary)}</span>
                    ),
            },
            {
                title: translations.salaries.additionOneTime,
                dataIndex: ['salary', 'additionOneTime'],
                align: 'right',
                render: (text: string, record: EmployeeSalaryVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="additionOneTime"
                            initialValue={editingItem?.salary?.additionOneTime}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                placeholder={translations.salaries.additionOneTime}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                style={{ width: '100%' }}
                                precision={2}
                                decimalSeparator=","
                            />
                        </Form.Item>
                    ) : (
                        <span>{formatNumber(text, record.salary)}</span>
                    ),
            },
            {
                title: translations.salaries.note,
                dataIndex: ['salary', 'note'],
                width: 200,
                render: (text: string, record: EmployeeSalaryVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Form.Item
                            name="note"
                            initialValue={editingItem?.salary?.note}
                            style={{ margin: 0 }}
                        >
                            <Input
                                placeholder={translations.salaries.note}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                            />
                        </Form.Item>
                    ) : (
                        <span>{text}</span>
                    ),
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 100,
                align: 'center',
                render: (record: EmployeeSalaryVm): React.ReactElement =>
                    editingItem?.employee?.id === record.employee?.id ? (
                        <Space>
                            <Button
                                shape="circle"
                                type="primary"
                                size="small"
                                onClick={handleSaveEdit}
                                icon={<CheckOutlined />}
                            />
                            <Button
                                shape="circle"
                                size="small"
                                onClick={handleCancelEdit}
                                icon={<CloseOutlined />}
                            />
                        </Space>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                type="link"
                                onClick={() => handleEdit(record)}
                                style={{ padding: 0, marginRight: 8 }}
                            >
                                <EditOutlined />
                            </Button>
                            {record.salary && Object.keys(record.salary).length > 0 ? (
                                <Button
                                    icon={<DeleteOutlined className="red-5" />}
                                    onClick={() => confirmDelete(record.employee?.id)}
                                    type="link"
                                    style={{ padding: 0 }}
                                />
                            ) : (
                                <div style={{ width: 24 }} />
                            )}
                        </div>
                    ),
            },
        ];
    };

    const params = new URLSearchParams(location.search);
    const { pageIndexParam, pageSizeParam } = getPagingSortingParams(params);
    const pagination: TablePaginationConfig = {
        ...defaultTablePagination,
        current:
            pageIndexParam && parseInt(pageIndexParam)
                ? parseInt(pageIndexParam)
                : defaultTablePagination.defaultCurrent,
        pageSize:
            pageSizeParam && parseInt(pageSizeParam)
                ? parseInt(pageSizeParam)
                : defaultTablePagination.defaultPageSize,
        total: props.totalCount,
    };

    return (
        <Form
            ref={formRef}
            onFinish={handleFormSubmit}
            preserve={false}
            key={editingItem?.employee?.id}
        >
            <Table
                locale={getTableLocale()}
                columns={getTableColumns()}
                className="ant-table-slim salary-table"
                dataSource={props.employeeSalaries}
                loading={props.loading}
                rowKey={(record: EmployeeSalaryVm): string =>
                    record.employee?.id?.toString() || '0'
                }
                rowClassName={(record: EmployeeSalaryVm) =>
                    editingItem?.employee?.id === record?.employee?.id ? EDITING_ROW_CLASS : ''
                }
                onRow={(record: EmployeeSalaryVm) => ({ onDoubleClick: () => handleEdit(record) })}
                onChange={handleTableChange}
                pagination={pagination}
                bordered
                size="small"
            />
        </Form>
    );
};

export default SalaryTable;
