import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Table from 'antd/lib/table';
import React from 'react';
import { Link } from 'react-router-dom';
import { defaultTablePagination } from '../../../../../config/constants';
import ReplaceStrings from '../../../../../config/replaceStrings';
import Routes from '../../../../../config/routes';
import { translations } from '../../../../../config/translations';
import { ActionType, ModuleName } from '../../../../../core/models/enum';
import { authorizeAction } from '../../../../../helpers/CheckPermissionHelper';
import { formatDate } from '../../../../../helpers/DateHelper';
import {
    getDateFilter,
    getDatePickerFilter,
    getDefaultFilter,
    getSearchFilter,
} from '../../../../../helpers/FilterHelper';
import { showConfirm } from '../../../../../helpers/NotificationHelper';
import { getDateComparer, getDefaultComparer } from '../../../../../helpers/SortHelper';
import { getTableLocale } from '../../../../../helpers/TableHelper';
import { ProjectEmployeeVm } from '../../../../../utils/api';
import { Props } from './index';

interface State {
    allowUpdate: boolean;
    allowDelete: boolean;
}

class ProjectEmployeeTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        const { userProfile } = this.props;

        this.state = {
            allowUpdate: authorizeAction(
                userProfile,
                ModuleName.ProjectEmployees,
                ActionType.Update
            ),
            allowDelete: authorizeAction(
                userProfile,
                ModuleName.ProjectEmployees,
                ActionType.Delete
            ),
        };
    }

    public render(): React.ReactElement {
        const { employees, onUpdate, onDelete } = this.props;
        const { allowUpdate, allowDelete } = this.state;

        const columns = [
            {
                title: translations.projects.employeeName,
                dataIndex: 'employeeName',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('employeeName'),
                sorter: getDefaultComparer('employeeName'),
                render: (value: string, record: ProjectEmployeeVm): React.ReactElement => (
                    <Link
                        to={Routes.ROUTE_EMPLOYEES_READ.replace(
                            ReplaceStrings.ENTITY_ID,
                            record.employeeId.toString()
                        )}
                    >
                        {value}
                    </Link>
                ),
            },
            {
                title: translations.projects.role,
                dataIndex: ['employee', 'employeeRole'],
                ...getSearchFilter(),
                onFilter: getDefaultFilter('employeeRole'),
                sorter: getDefaultComparer('employeeRole'),
            },
            {
                title: translations.projects.activeFrom,
                dataIndex: 'activeFrom',
                ...getDatePickerFilter(),
                onFilter: getDateFilter('activeFrom'),
                sorter: getDateComparer('activeFrom'),
                render: (value: Date) => formatDate(value),
            },
            {
                title: translations.projects.activeTo,
                dataIndex: 'activeTo',
                ...getDatePickerFilter(),
                onFilter: getDateFilter('activeTo'),
                sorter: getDateComparer('activeTo'),
                render: (value: Date): string => formatDate(value),
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 100,
                align: 'left',
                className: 'no-print',
                render: (value: ProjectEmployeeVm): React.ReactElement => (
                    <>
                        {allowUpdate && (
                            <EditOutlined
                                onClick={() => {
                                    onUpdate(value.id);
                                }}
                                className="blue-6"
                            />
                        )}
                        {allowDelete && (
                            <DeleteOutlined
                                onClick={() =>
                                    showConfirm(
                                        () => onDelete(value.id),
                                        translations.projects.projectEmployeeDeleteConfirm,
                                        translations.general.delete,
                                        true
                                    )
                                }
                                className="red-5"
                                style={{ marginLeft: 10 }}
                            />
                        )}
                    </>
                ),
            },
        ];

        return (
            <Table
                locale={getTableLocale()}
                columns={columns}
                dataSource={employees}
                rowKey={(record: ProjectEmployeeVm): string => record.id?.toString()}
                pagination={defaultTablePagination}
                bordered
                size="small"
            />
        );
    }
}

export default ProjectEmployeeTable;
