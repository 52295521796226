import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader } from 'antd';
import React from 'react';
import { DRAWER_WIDTH } from '../../config/constants';
import ReplaceStrings from '../../config/replaceStrings';
import Routes from '../../config/routes';
import { translations } from '../../config/translations';
import { ActionType, DrawerState, ModuleName } from '../../core/models/enum';
import { authorizeAction } from '../../helpers/CheckPermissionHelper';
import { getNewRoute } from '../../helpers/RoutingHelper';
import { UserVm, UsersClient } from '../../utils/api';
import { Props } from './index';
import UserForm from './user-form';
import UserTable from './user-table';

interface State {
    drawerState: DrawerState;
    selectedUser?: UserVm;
    allowCreate: boolean;
}

class Users extends React.Component<Props, State> {
    private tableRef: any = React.createRef();

    public constructor(props: Props) {
        super(props);

        this.state = {
            drawerState: DrawerState.Closed,
            allowCreate: authorizeAction(props.userProfile, ModuleName.Users, ActionType.Create),
        };
    }

    public componentDidMount = () => {
        const {
            match: {
                params: { userId },
                path,
            },
        } = this.props;

        this.handleRouteUpdate(path, userId);
    };

    public componentDidUpdate = (prevProps: Props) => {
        const {
            match: {
                params: { userId },
                path,
            },
        } = this.props;
        if (path !== prevProps.match.path) {
            this.handleRouteUpdate(path, userId);
        }
    };

    private updateDrawerStateFromPathName = (pathname: string) => {
        switch (pathname) {
            case Routes.ROUTE_USERS_NEW:
                this.setState({
                    drawerState: DrawerState.Create,
                });
                return;
            case Routes.ROUTE_USERS_EDIT:
                this.setState({
                    drawerState: DrawerState.Edit,
                });
                return;
            case Routes.ROUTE_USERS_READ:
                this.setState({
                    drawerState: DrawerState.Read,
                });
                return;
            case Routes.ROUTE_USERS:
                this.setState({
                    drawerState: DrawerState.Closed,
                });
                return;
            default:
                this.setState({
                    drawerState: DrawerState.Closed,
                });
                return;
        }
    };

    private handleRouteUpdate = async (pathname: string, userId?: string) => {
        let selectedUser;
        const client = new UsersClient();

        if (userId) {
            selectedUser = await client.getById(userId);
        }

        this.setState(
            {
                selectedUser,
            },
            () => this.updateDrawerStateFromPathName(pathname)
        );
    };

    private handleDrawerMode = (drawerState: DrawerState, id?: string) => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_USERS,
                Routes.ROUTE_USERS_READ,
                Routes.ROUTE_USERS_NEW,
                drawerState,
                ReplaceStrings.USER_ID,
                id
            )
        );
    };

    private handleSuccessCreate = (_: UserVm) => {
        this.handleDrawerMode(DrawerState.Closed);
        this.tableRef.current.getUsers();
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const { drawerState, selectedUser, allowCreate } = this.state;

        return (
            <>
                <PageHeader
                    title={translations.users.users}
                    extra={
                        allowCreate
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      onClick={() => this.handleDrawerMode(DrawerState.Edit)}
                                      className="no-print"
                                  >
                                      <PlusOutlined />
                                      {translations.users.addUser}
                                  </Button>,
                              ]
                            : []
                    }
                />

                <UserTable userProfile={userProfile} wrappedComponentRef={this.tableRef} />

                <Drawer
                    title={
                        drawerState === DrawerState.Create
                            ? translations.users.addUser
                            : translations.users.editUser
                    }
                    open={!!drawerState}
                    onClose={() => this.handleDrawerMode(DrawerState.Closed)}
                    width={DRAWER_WIDTH}
                    destroyOnClose
                >
                    <UserForm
                        onClose={() => this.handleDrawerMode(DrawerState.Closed)}
                        onSuccess={this.handleSuccessCreate}
                        user={selectedUser}
                    />
                </Drawer>
            </>
        );
    }
}

export default Users;
