import { Checkbox, DatePicker, Form, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import React from 'react';
import AutocompleteDropdown from '../../../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../../../components/drawer-buttons';
import { defaultFormat } from '../../../../../config/constants';
import { formItemLayout724 } from '../../../../../config/formLayouts';
import { translations } from '../../../../../config/translations';
import { DropdownOption } from '../../../../../core/models/DropdownOption';
import { SelectOption } from '../../../../../core/models/SelectOption';
import {
    filterDropdownOptions,
    confirmDropdownHelper,
    getCooperatorContactsHelper,
} from '../../../../../helpers/AutocompleteDropdownHelper';
import { getCooperatorsToDropdownOptions } from '../../../../../helpers/FetchAndTransformHelpers';
import { showSuccess } from '../../../../../helpers/NotificationHelper';
import { create, update } from '../../../../../helpers/SubmitHelper';
import { arrayValidator, dateRangeValidator } from '../../../../../helpers/ValidationHelper';
import {
    AssociateTypeEnum,
    CodebooksClient,
    ProjectCooperatorsClient,
    ProjectCooperatorVm,
    ProjectsClient,
    SelectOptionVm,
} from '../../../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    cooperators: DropdownOption[];
    selectedCooperator?: DropdownOption[];
    contacts: DropdownOption[];
    selectedContact?: DropdownOption[];
    cooperatorTypes?: SelectOptionVm[];
    projectCooperators?: ProjectCooperatorVm[];
    hasBoq: boolean;
}

class ProjectCooperatorForm extends React.Component<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        const { projectCooperator } = this.props;

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
            cooperators: [],
            contacts: [],
            projectCooperators: [],
            hasBoq: projectCooperator ? projectCooperator.hasBoq : false,
        };
    }

    public componentDidMount = () => {
        const { projectCooperator } = this.props;

        this.getCooperators();
        this.getCooperatorTypes();
        this.getProjectCooperators();

        if (!(projectCooperator && projectCooperator.cooperatorId)) {
            this.clearContacts();
        } else {
            this.getContacts(projectCooperator.cooperatorId);
        }
    };

    private setCooperatorAndDropdownContacts = (options: DropdownOption[]) => {
        const selectedCooperator = confirmDropdownHelper(options);
        this.setState({
            selectedCooperator,
            selectedContact: undefined,
        });
        if (selectedCooperator) {
            this.getContacts(+selectedCooperator[0].id);
        }
    };

    private getCooperatorTypes = async () => {
        this.setState({
            cooperatorTypes: await new CodebooksClient().getByCodebookName('CooperatorType'),
        });
    };

    private getCooperators = async () => {
        const { projectCooperator } = this.props;

        const cooperatorId = projectCooperator?.cooperatorId;

        if (cooperatorId) {
            this.getContacts(cooperatorId);
        }

        const results = await getCooperatorsToDropdownOptions(
            cooperatorId,
            AssociateTypeEnum.ProjectAssociate
        );
        this.setState({
            cooperators: results.entities,
            selectedCooperator: results.selectedEntity,
        });
    };

    private getProjectCooperators = async () => {
        const { project } = this.props;

        if (!project) return;

        this.setState({
            projectCooperators: await new ProjectsClient().getProjectCooperators(project.id),
        });
    };

    private getContacts = async (cooperatorId: number) => {
        const { projectCooperator } = this.props;

        const contactId = projectCooperator?.cooperatorContactId;
        const cooperatorContactsHelper = await getCooperatorContactsHelper(cooperatorId, contactId);

        this.setState({
            contacts: cooperatorContactsHelper.entities,
            selectedContact: cooperatorContactsHelper.selectedEntity,
        });
    };

    private clearContacts = () => {
        this.setState({
            contacts: [],
            selectedContact: [],
        });
    };

    private onHasBoqChange = (e: CheckboxChangeEvent) => {
        this.setState({
            hasBoq: e.target.checked,
        });
    };

    private handleSubmit = (values: any) => {
        const { selectedCooperator, selectedContact, hasBoq } = this.state;
        const { projectCooperator, project } = this.props;

        const projectCooperatorsClient = new ProjectCooperatorsClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: projectCooperator ? projectCooperator.id : undefined,
            projectId: project ? project.id : undefined,
            cooperatorId:
                selectedCooperator && selectedCooperator.length > 0
                    ? +selectedCooperator[0].id
                    : undefined,
            cooperatorContactId:
                selectedContact && selectedContact.length > 0 ? +selectedContact[0].id : undefined,
            hasBoq,
        };

        if (request.id) {
            update(projectCooperatorsClient, request, this.handleSuccess);
        } else {
            create(projectCooperatorsClient, request, this.handleSuccess);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (result: ProjectCooperatorVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.cooperators.successfullySaved);

        onSuccess(result);
    };

    public render() {
        const { projectCooperator, onClose } = this.props;
        const {
            isSaving,
            selectedCooperator,
            cooperators,
            cooperatorTypes,
            selectedContact,
            projectCooperators,
            contacts,
            hasBoq,
        } = this.state;

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout724} ref={this.formRef}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />

                <Form.Item
                    name="cooperatorId"
                    label={translations.projects.cooperator}
                    rules={[
                        {
                            required: true,
                            validator: (_rule: any, value: any, callback: any) =>
                                arrayValidator(
                                    _rule,
                                    value,
                                    callback,
                                    translations.general.requiredField,
                                    selectedCooperator
                                ),
                        },
                    ]}
                >
                    <AutocompleteDropdown
                        placeholder={translations.projects.chooseCooperator}
                        getOptionsFrontend={(value: string): DropdownOption[] =>
                            filterDropdownOptions(value, cooperators)
                        }
                        initialValues={selectedCooperator}
                        confirmDirty={this.setCooperatorAndDropdownContacts}
                        isAllowedToClear
                        disabled={!!projectCooperator}
                    />
                </Form.Item>
                <Form.Item
                    name="cooperatorTypeId"
                    label={translations.projects.cooperatorType}
                    initialValue={projectCooperator?.cooperatorTypeId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select<number>
                        placeholder={translations.projects.chooseCooperatorType}
                        options={cooperatorTypes}
                        loading={!cooperatorTypes}
                    />
                </Form.Item>
                <Form.Item
                    name="hasBoq"
                    label={translations.projects.hasItems}
                    initialValue={projectCooperator ? projectCooperator.hasBoq : undefined}
                >
                    <Checkbox checked={hasBoq} onChange={this.onHasBoqChange} />
                </Form.Item>
                <Form.Item
                    name="clientId"
                    label={translations.projects.client}
                    initialValue={projectCooperator?.clientId || undefined}
                >
                    <Select<number>
                        placeholder={translations.projects.chooseCooperatorClient}
                        allowClear
                        options={projectCooperators?.map(
                            (pc: ProjectCooperatorVm): SelectOption => ({
                                label: pc.cooperatorName!,
                                value: pc.cooperatorId,
                                disabled:
                                    selectedCooperator &&
                                    selectedCooperator[0] &&
                                    pc.cooperatorId.toString() === selectedCooperator[0].id,
                            })
                        )}
                    />
                </Form.Item>
                <Form.Item
                    name="activeFrom"
                    label={translations.projects.activeFrom}
                    initialValue={
                        projectCooperator?.activeFrom ? moment(projectCooperator.activeFrom) : null
                    }
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                dateRangeValidator(
                                    callback,
                                    translations.general.fromBeforeToValidationMessage,
                                    value,
                                    this.formRef?.current?.getFieldValue('activeTo')
                                ),
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="activeTo"
                    label={translations.projects.activeTo}
                    initialValue={
                        projectCooperator?.activeTo ? moment(projectCooperator.activeTo) : null
                    }
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                dateRangeValidator(
                                    callback,
                                    translations.general.fromBeforeToValidationMessage,
                                    this.formRef?.current?.getFieldValue('activeFrom'),
                                    value
                                ),
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item name="cooperatorContactId" label={translations.projects.contact}>
                    <AutocompleteDropdown
                        placeholder={translations.cooperators.contacts.chooseContact}
                        getOptionsFrontend={(value: string): DropdownOption[] =>
                            filterDropdownOptions(value, contacts)
                        }
                        initialValues={selectedContact}
                        confirmDirty={(options: DropdownOption[]) =>
                            this.setState({
                                selectedContact: confirmDropdownHelper(options),
                            })
                        }
                        isAllowedToClear
                        disabled={!selectedCooperator}
                    />
                </Form.Item>
            </Form>
        );
    }
}

export default ProjectCooperatorForm;
