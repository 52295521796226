import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteParams';
import UserTable from './UserTable';

interface OwnProps {
    userProfile: Profile;
    wrappedComponentRef?: any;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(UserTable);
