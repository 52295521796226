import ProjectItemActions from './ProjectItemActions';

interface OwnProps {
    item: any;
    editingItem?: any;
    allowUpdate: boolean;
    allowCreate: boolean;
    allowDelete: boolean;
    onCancel: () => void;
    onAdd: (isGroup: boolean, parentId?: number) => void;
    onEdit: (item: any) => void;
    onDelete: (id: number) => void;
}

export type Props = OwnProps;

export default ProjectItemActions;
