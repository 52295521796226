import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader, Tabs } from 'antd';
import React from 'react';
import { defaultTablePagination, DRAWER_WIDTH } from '../../config/constants';
import Routes from '../../config/routes';
import { translations } from '../../config/translations';
import { ActionType, DrawerState, ModuleName } from '../../core/models/enum';
import { authorizeAction } from '../../helpers/CheckPermissionHelper';
import { buildRoute } from '../../helpers/RoutingHelper';
import { ProjectStatusEnum, ProjectVm } from '../../utils/api';
import { Props } from './index';
import ProjectForm from './project-form';
import ProjectTable from './project-table';

const { TabPane } = Tabs;

interface State {
    allowCreate: boolean;
    drawerState: DrawerState;
}

class Projects extends React.Component<Props, State> {
    private tableRef: any = React.createRef();

    public constructor(props: Props) {
        super(props);

        const {
            userProfile,
            match: { path }
        } = this.props;

        this.state = {
            allowCreate: authorizeAction(userProfile, ModuleName.Projects, ActionType.Create),
            drawerState:
                path === Routes.ROUTE_PROJECTS_NEW ? DrawerState.Create : DrawerState.Closed,
        };
    }

    private handleDrawerChange = (drawerState: DrawerState) => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            buildRoute(
                Routes.ROUTE_PROJECTS,
                Routes.ROUTE_PROJECTS_NEW,
                Routes.ROUTE_PROJECTS_READ,
                Routes.ROUTE_PROJECTS_EDIT,
                drawerState
            )
        );
    };

    private handleSuccessCreate = (_: ProjectVm) => {
        this.handleDrawerChange(DrawerState.Closed);
        this.tableRef.current.getProjects(defaultTablePagination);
    };

    private handleTabChange = (key: string) => {
        const { history, location } = this.props;
        const params = new URLSearchParams(location.search);
        const activeTab = parseInt(key) as ProjectStatusEnum;

        if (params.has('activeTab')) {
            params.set('activeTab', activeTab.toString());
        } else {
            params.append('activeTab', activeTab.toString());
        }

        history.push({
            pathname: history.location.pathname,
            search: params.toString(),
        });
    };

    public render(): React.ReactElement {
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { allowCreate, drawerState } = this.state;
        const activeTab = params.get('activeTab') ? params.get('activeTab') : ProjectStatusEnum.Active;

        return (
            <>
                <PageHeader
                    title={translations.projects.projects}
                    extra={
                        allowCreate
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      onClick={() => this.handleDrawerChange(DrawerState.Create)}
                                      className="no-print"
                                  >
                                      <PlusOutlined />
                                      {translations.projects.addProject}
                                  </Button>,
                              ]
                            : []
                    }
                />

                <Tabs
                    type="editable-card"
                    activeKey={activeTab?.toString()}
                    onTabClick={(key: string) => this.handleTabChange(key)}
                    hideAdd
                    destroyInactiveTabPane={true}
                >
                    <TabPane tab={'Aktivni'} key={ProjectStatusEnum.Active} closable={false} />
                    <TabPane tab={'Neaktivni'} key={ProjectStatusEnum.Inactive} closable={false} />
                    <TabPane tab={'Završeni'} key={ProjectStatusEnum.Completed} closable={false} />
                </Tabs>

                <ProjectTable wrappedComponentRef={this.tableRef} />

                <Drawer
                    title={translations.projects.addProject}
                    open={!!drawerState}
                    onClose={() => this.handleDrawerChange(DrawerState.Closed)}
                    width={DRAWER_WIDTH}
                    destroyOnClose
                >
                    <ProjectForm
                        onClose={() => this.handleDrawerChange(DrawerState.Closed)}
                        onSuccess={(project: ProjectVm) => this.handleSuccessCreate(project)}
                    />
                </Drawer>
            </>
        );
    }
}

export default Projects;
