import { DatePicker, Form } from 'antd';
import React from 'react';
import moment from 'moment';
import AutocompleteDropdown from '../../../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../../../components/drawer-buttons';
import { defaultFormat } from '../../../../../config/constants';
import { formItemLayout724 } from '../../../../../config/formLayouts';
import { translations } from '../../../../../config/translations';
import { DropdownOption } from '../../../../../core/models/DropdownOption';
import {
    filterDropdownOptions,
    confirmDropdownHelper,
} from '../../../../../helpers/AutocompleteDropdownHelper';
import { getEmployeesToDropdownOptions } from '../../../../../helpers/FetchAndTransformHelpers';
import { showSuccess } from '../../../../../helpers/NotificationHelper';
import { create, update } from '../../../../../helpers/SubmitHelper';
import { arrayValidator, dateRangeValidator } from '../../../../../helpers/ValidationHelper';
import {
    CodebooksClient,
    ProjectEmployeesClient,
    ProjectEmployeeVm,
    SelectOptionVm,
} from '../../../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    employees: DropdownOption[];
    selectedEmployee?: DropdownOption[];
    roles?: SelectOptionVm[];
    selectedRole?: DropdownOption[];
}

class ProjectEmployeeForm extends React.Component<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
            employees: [],
        };
    }

    public componentDidMount = () => {
        this.getEmployees();
        this.getRoles();
    };

    private getRoles = async () => {
        this.setState({
            roles: await new CodebooksClient().getByCodebookName('EmployeeRole'),
        });
    };

    private getEmployees = async () => {
        const { projectEmployee } = this.props;

        const results = await getEmployeesToDropdownOptions(projectEmployee?.employeeId);
        this.setState({
            employees: results.entities,
            selectedEmployee: results.selectedEntity,
        });
    };

    private handleSubmit = (values: any) => {
        const { selectedEmployee } = this.state;
        const { projectEmployee, project } = this.props;

        if (!project) return;

        const projectEmployeesClient = new ProjectEmployeesClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: projectEmployee?.id,
            projectId: project.id,
            employeeId:
                selectedEmployee && selectedEmployee.length > 0
                    ? parseInt(selectedEmployee[0].id, 10)
                    : undefined,
        };

        if (request.id) {
            update(projectEmployeesClient, request, this.handleSuccess);
        } else {
            create(projectEmployeesClient, request, this.handleSuccess);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (result: ProjectEmployeeVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.employees.successfullySaved);

        onSuccess(result);
    };

    public render() {
        const { projectEmployee, onClose } = this.props;
        const { isSaving, selectedEmployee, employees, roles } = this.state;

        if (!roles) return null;

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout724} ref={this.formRef}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />

                <Form.Item
                    name="employeeId"
                    label={translations.projects.employee}
                    rules={[
                        {
                            required: true,
                            validator: (_rule: any, value: any, callback: any) =>
                                arrayValidator(
                                    _rule,
                                    value,
                                    callback,
                                    translations.general.requiredField,
                                    selectedEmployee
                                ),
                        },
                    ]}
                >
                    <AutocompleteDropdown
                        placeholder={translations.users.chooseEmployee}
                        getOptionsFrontend={(value: string): DropdownOption[] =>
                            filterDropdownOptions(value, employees)
                        }
                        initialValues={selectedEmployee}
                        confirmDirty={(options: DropdownOption[]) =>
                            this.setState({
                                selectedEmployee: confirmDropdownHelper(options),
                            })
                        }
                        isAllowedToClear
                        disabled={!!projectEmployee}
                    />
                </Form.Item>
                <Form.Item
                    name="activeFrom"
                    label={translations.projects.activeFrom}
                    initialValue={
                        projectEmployee?.activeFrom ? moment(projectEmployee.activeFrom) : null
                    }
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                dateRangeValidator(
                                    callback,
                                    translations.general.fromBeforeToValidationMessage,
                                    value,
                                    this.formRef?.current?.getFieldValue('activeTo')
                                ),
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="activeTo"
                    label={translations.projects.activeTo}
                    initialValue={
                        projectEmployee?.activeTo ? moment(projectEmployee.activeTo) : null
                    }
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                dateRangeValidator(
                                    callback,
                                    translations.general.fromBeforeToValidationMessage,
                                    this.formRef?.current?.getFieldValue('activeFrom'),
                                    value
                                ),
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={translations.general.chooseDate}
                        format={defaultFormat}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </Form.Item>
            </Form>
        );
    }
}

export default ProjectEmployeeForm;
