import sanitizeHtml from 'sanitize-html';
import {Props} from './index';
import React from 'react';

class SanitizedDiv extends React.Component<Props> {
    public render() {
        let clean = sanitizeHtml(this.props.dirtyHtml);
        return <div dangerouslySetInnerHTML={{ __html: clean }} />
    }
}

export default SanitizedDiv;