import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader, Tabs } from 'antd';
import React from 'react';
import { DRAWER_WIDTH } from '../../../config/constants';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { translations } from '../../../config/translations';
import { ProjectSubPage, DrawerState, ModuleName, ActionType } from '../../../core/models/enum';
import { authorizeAction } from '../../../helpers/CheckPermissionHelper';
import { showSuccess } from '../../../helpers/NotificationHelper';
import { getSubPageInitialProperties } from '../../../helpers/RoutingHelper';
import { remove } from '../../../helpers/SubmitHelper';
import {
    CooperatorContactsClient,
    CooperatorContactVm,
    CooperatorDetailVm,
    CooperatorsClient,
} from '../../../utils/api';
import CooperatorContactForm from './cooperator-contacts/cooperator-contacts-form';
import CooperatorContactsTable from './cooperator-contacts/cooperator-contacts-table';
import CooperatorDetails from './cooperator-details';
import { Props } from './index';

const { TabPane } = Tabs;

interface State {
    cooperator?: CooperatorDetailVm;
    activeTabKey: string;
    isEditMode: boolean;
    id?: string;
    loading: boolean;
    drawerState: DrawerState;
    selectedContact?: CooperatorContactVm;
    allowCreate: boolean;
}

class CooperatorPage extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            ProjectSubPage.Cooperators,
            props.match,
            Routes.ROUTE_COOPERATORS_READ,
            Routes.ROUTE_COOPERATORS_EDIT
        );

        this.state = {
            isEditMode: initialProperties.isInitialEditMode,
            id: initialProperties.initialEntityId,
            loading: false,
            drawerState:
                initialProperties.activeTabKey &&
                initialProperties.activeTabKey !== Routes.ROUTE_COOPERATORS_READ &&
                initialProperties.isInitialEditMode
                    ? DrawerState.Edit
                    : DrawerState.Closed,
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_COOPERATORS_READ,
            allowCreate: authorizeAction(
                props.userProfile,
                ModuleName.Cooperators,
                ActionType.Create
            ),
        };
    }

    public componentDidMount = () => {
        this.getCooperatorDetails();
    };

    private handleSuccess = (cooperator: CooperatorDetailVm) => {
        this.setState({
            cooperator,
        });
    };

    private getCooperatorDetails = async () => {
        const {
            match: {
                params: { cooperatorId },
            },
        } = this.props;

        if (cooperatorId) {
            const cooperatorClient = new CooperatorsClient();
            const cooperatorDetails = await cooperatorClient.getById(parseInt(cooperatorId, 10));

            this.setState({
                cooperator: cooperatorDetails,
            });
        }

        this.setState({
            loading: false,
        });
    };

    private handleContactEdit = (id: number) => {
        const { cooperator } = this.state;

        if (!cooperator) return;

        const cooperatorContact = cooperator.contacts?.find(
            (cc: CooperatorContactVm): boolean => cc.id === id
        );

        if (!cooperatorContact) return;

        this.handleDrawerChange(DrawerState.Edit, cooperatorContact);
    };

    private handleContactSaved = (result: CooperatorContactVm) => {
        const { cooperator } = this.state;

        if (!cooperator) return;

        const index = cooperator.contacts!.findIndex(
            (cc: CooperatorContactVm): boolean => cc.id === result.id
        );

        const contacts = cooperator.contacts?.slice();

        if (index !== -1) {
            contacts?.splice(index, 1, result);
        } else {
            contacts?.push(result);
        }

        this.setState({
            cooperator: {
                ...cooperator,
                contacts: contacts,
            } as CooperatorDetailVm,
        });

        this.handleDrawerChange(DrawerState.Closed);
    };

    private handleContactDeleted = (id: number) => {
        const { cooperator } = this.state;

        if (!cooperator) return;

        // find index to remove from state
        const index = cooperator.contacts!.findIndex(
            (cc: CooperatorContactVm): boolean => cc.id === id
        );

        if (index === -1) return;

        // remove index from state
        const contacts = cooperator.contacts?.slice();
        contacts?.splice(index, 1);

        this.setState({
            cooperator: {
                ...cooperator,
                contacts,
            } as CooperatorDetailVm,
        });

        showSuccess(translations.cooperators.contacts.deleted);
    };

    private handleTabClick = (route: string) => {
        const {
            history,
            match: {
                params: { cooperatorId },
            },
        } = this.props;

        this.setState({
            activeTabKey: route,
            isEditMode: false,
        });

        const newRoute = route.replace(ReplaceStrings.COOPERATOR_ID, cooperatorId);

        history.push(newRoute);
    };

    private handleDrawerChange = (
        drawerState: DrawerState,
        selectedEntity?: CooperatorContactVm
    ) => {
        this.setState({
            drawerState,
            selectedContact:
                drawerState === DrawerState.Closed
                    ? undefined
                    : (selectedEntity as CooperatorContactVm),
        });
    };

    private getDrawerTitle = (): string => {
        const { activeTabKey, selectedContact } = this.state;

        if (activeTabKey === Routes.ROUTE_COOPERATORS_READ) {
            return translations.cooperators.editCooperator;
        } else {
            return selectedContact
                ? translations.cooperators.contacts.edit
                : translations.cooperators.contacts.add;
        }
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const { cooperator, activeTabKey, isEditMode, drawerState, allowCreate } = this.state;

        return (
            <>
                <PageHeader title={cooperator?.name || ''} />

                <Tabs
                    style={{ width: '100%' }}
                    activeKey={activeTabKey}
                    onTabClick={(key: string) => this.handleTabClick(key)}
                    destroyInactiveTabPane={true}
                >
                    <TabPane tab={translations.general.details} key={Routes.ROUTE_COOPERATORS_READ}>
                        {cooperator && (
                            <CooperatorDetails
                                userProfile={userProfile}
                                isInitialEdit={isEditMode}
                                cooperator={cooperator}
                                onSuccess={this.handleSuccess}
                            />
                        )}
                    </TabPane>
                    <TabPane
                        tab={translations.cooperators.contacts.title}
                        key={Routes.ROUTE_COOPERATORS_CONTACTS}
                    >
                        <PageHeader
                            title=""
                            extra={
                                allowCreate
                                    ? [
                                          <Button
                                              key="1"
                                              type="primary"
                                              style={{
                                                  zIndex: 10,
                                                  float: 'right',
                                              }}
                                              onClick={() =>
                                                  this.handleDrawerChange(DrawerState.Edit)
                                              }
                                          >
                                              <PlusOutlined />
                                              {translations.cooperators.contacts.add}
                                          </Button>,
                                      ]
                                    : []
                            }
                        />
                        <div>
                            {cooperator && (
                                <CooperatorContactsTable
                                    userProfile={userProfile}
                                    cooperatorContacts={cooperator.contacts}
                                    onUpdate={this.handleContactEdit}
                                    onDelete={(id: number) =>
                                        remove(
                                            new CooperatorContactsClient(),
                                            translations.cooperators.contacts.deleted,
                                            () => this.handleContactDeleted(id),
                                            id
                                        )
                                    }
                                />
                            )}
                        </div>
                    </TabPane>
                </Tabs>

                <Drawer
                    title={this.getDrawerTitle()}
                    open={!!drawerState}
                    onClose={() => this.handleDrawerChange(DrawerState.Closed)}
                    width={DRAWER_WIDTH}
                    destroyOnClose
                >
                    {activeTabKey === Routes.ROUTE_COOPERATORS_CONTACTS && cooperator && (
                        <CooperatorContactForm
                            cooperator={this.state.cooperator}
                            cooperatorContact={this.state.selectedContact}
                            onClose={() => this.handleDrawerChange(DrawerState.Closed)}
                            onSuccess={this.handleContactSaved}
                            refreshAfterSave={this.getCooperatorDetails}
                        />
                    )}
                </Drawer>
            </>
        );
    }
}

export default CooperatorPage;
