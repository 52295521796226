import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../core/models/RouteParams';
import { WarehouseItemVm } from '../../../utils/api';
import WarehouseItemTable from './WarehouseItemTable';

interface OwnProps {
    wrappedComponentRef?: any;
    selectedItemsKeys?: React.Key[];
    onSelectionChange?: (items: WarehouseItemVm[]) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(WarehouseItemTable);
