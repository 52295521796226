import { FileResponse } from '../utils/api';
import { showError } from './NotificationHelper';

interface UploadFileHelperModel {
    file: File;
    fileBase64?: string;
}

export const onUploadFileChangeHelper = async (
    file: File
): Promise<UploadFileHelperModel | undefined> => {
    const fileBase64 = await convertFileToBase64(file);
    const isTooBigResolution = await checkImageResolution(fileBase64);

    if (isTooBigResolution) {
        showError('Image resolution is too big. Max resolution is 256x256.');
        return;
    }

    const fileBase64Array = fileBase64 ? fileBase64.split(',') : [];

    return {
        file: file,
        fileBase64: fileBase64Array.length > 1 ? fileBase64Array[1] : undefined,
    };
};

export const convertFileToBase64 = async (file: File): Promise<string | undefined> => {
    return file ? await readFileDataAsBase64(file) : undefined;
};

const readFileDataAsBase64 = (file: File): Promise<any> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
            if (event && event.target && event.target.result) {
                resolve(event.target.result);
            }
        };

        reader.onerror = (err) => {
            reject(err);
        };
    });
};

const checkImageResolution = async (imageBase64?: string): Promise<boolean> => {
    return imageBase64 ? await checkImageResolutionHelper(imageBase64) : false;
};

const checkImageResolutionHelper = (file: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = file;

        image.onload = () => {
            resolve(image.width > 256 || image.height > 256 ? true : false);
        };

        image.onerror = (err) => {
            reject(err);
        };
    });
};

export const downloadFile = (file: FileResponse) => {
    // 1. Create link element
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(file.data);
    link.setAttribute('download', file.fileName || '');

    // 2. Append to body
    document.body.appendChild(link);

    // 3. Trigger download
    link.click();

    // 4. Clean up and remove the link
    link.parentNode?.removeChild(link);
};
