import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React from 'react';
import DrawerButtons from '../../../components/drawer-buttons';
import { formItemLayout724 } from '../../../config/formLayouts';
import { translations } from '../../../config/translations';
import { showSuccess } from '../../../helpers/NotificationHelper';
import { AttachmentsClient, AttachmentVm } from '../../../utils/api';
import { Props } from './index';

const { TextArea } = Input;

interface State {
    isSaving: boolean;
    fileList: UploadFile[];
}

class AttachmentForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            isSaving: false,
            fileList: [],
        };
    }

    private onSubmit = async (values: any) => {
        const { attachment, project, employee, document, hodogram } = this.props;
        const { fileList } = this.state;

        this.setState({
            isSaving: true,
        });

        let result;
        if (attachment?.id) {
            result = await new AttachmentsClient().update(
                attachment?.id,
                attachment?.id,
                undefined,
                values.description,
                project?.id,
                employee?.id,
                document?.id,
                hodogram?.id
            );
        } else {
            result = await new AttachmentsClient().create(
                { data: fileList[0], fileName: fileList[0].name },
                values.description,
                project?.id,
                employee?.id,
                document?.id,
                hodogram?.id
            );
        }

        this.handleSuccess(result);

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (result: AttachmentVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.attachments.saveSuccess);

        onSuccess(result);
    };

    public render(): React.ReactElement {
        const { attachment, onClose } = this.props;
        const { isSaving, fileList } = this.state;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />
                {!attachment?.id && (
                    <Form.Item
                        name="file"
                        label={translations.attachments.attachment}
                        rules={[
                            {
                                required: true,
                                message: translations.general.requiredField,
                            },
                        ]}
                    >
                        <Upload
                            fileList={fileList}
                            onRemove={() => {
                                this.setState({
                                    fileList: [],
                                });
                            }}
                            beforeUpload={(file) => {
                                this.setState({
                                    fileList: [file],
                                });
                                return false;
                            }}
                        >
                            <Button type="primary">
                                <UploadOutlined />
                                {translations.general.upload}
                            </Button>
                        </Upload>
                    </Form.Item>
                )}
                <Form.Item
                    name="description"
                    label={translations.attachments.description}
                    initialValue={attachment?.description}
                >
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        );
    }
}

export default AttachmentForm;
