import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../../core/models/RouteParams';
import { CooperatorDetailVm } from '../../../../utils/api';
import CooperatorDetails from './CooperatorDetails';

interface OwnProps {
    userProfile: Profile;
    isInitialEdit: boolean;
    cooperator: CooperatorDetailVm;
    onSuccess: (employee: CooperatorDetailVm) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CooperatorDetails);
