import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../core/models/RouteParams';
import WarehouseItems from './WarehouseItems';

interface OwnProps {
    userProfile: Profile;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(WarehouseItems);
