import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../../../core/models/RouteParams';
import { CooperatorContactVm } from '../../../../../utils/api';
import CooperatorContactsTable from './CooperatorContactsTable';

interface OwnProps {
    userProfile: Profile;
    cooperatorContacts?: CooperatorContactVm[];
    onUpdate: (id: number) => void;
    onDelete: (id: number) => Promise<void>;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CooperatorContactsTable);
