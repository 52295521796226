import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../core/models/RouteParams';
import ExpenseGroups from './ExpenseGroups';

interface OwnProps {
    userProfile: Profile;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(ExpenseGroups);
