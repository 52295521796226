import moment from 'moment';
import { defaultTablePagination } from '../config/constants';
import { Filter, FilterRequest } from '../core/models/Filter';
import { PaginatedListOfAnyVm, Pagination } from '../core/models/Pagination';
import { SortDirectionEnum } from '../core/models/enum';
import { compare, compareBoolean, compareDate } from './SortHelper';

// BE ADVISED
//
// this helper is only used for front-end sorting and filtering of following tree view tables:
//
// 'ProjectItemTable'
// 'ProjectCooperatorItemTable'
// 'ProjectSituationItemTable'
//
// for sorting and filtering anything else please use 'SortHelper' and 'FilterHelper'

export const buildFilterRequest = (filters: any): Partial<FilterRequest> | undefined => {
    let filterRequest = { ...filters };

    Object.keys(filters)
        .filter((key: string): boolean => filters[key] === null || filters[key].length < 1)
        .forEach((key: string): boolean => delete filterRequest[key]);

    Object.keys(filterRequest).forEach((key: string) => {
        if (singleValueProps.includes(key)) {
            [filterRequest[key]] = [...filterRequest[key]];
        } else if (dateRangeProps.includes(key)) {
            const [[start, end]] = filterRequest[key];
            filterRequest[key] = { start, end };
        } else {
            return;
        }
    });

    return Object.keys(filterRequest).length !== 0 ? filterRequest as FilterRequest : undefined;
};

export const recursiveFilterAndSort = (entities: any[], filter: Filter): PaginatedListOfAnyVm => {
    let allPageItems = recursiveFilter(JSON.parse(JSON.stringify(entities)), filter);

    if (filter.orderByKey && filter.orderByValue) {
        allPageItems = sort(filter.orderByKey, filter.orderByValue, allPageItems);
    }

    const paginationSettings = calcPagination(filter, allPageItems?.length || 0);

    const pageItems = allPageItems?.slice(
        paginationSettings.startIndex,
        paginationSettings.endIndex
    );

    return {
        pageItems: pageItems,
        currentPage: paginationSettings.currentPage,
        pageSize: paginationSettings.pageSize,
        totalPages: paginationSettings.totalPages,
        totalItems: paginationSettings.totalItems,
    };
};

const recursiveFilter = (entities: any[], filter: Filter): any[] | undefined => {
    let items: any[] = [];
    const filterRequest = filter.filterRequest as any;

    entities.forEach((entity: any) => {
        if (filterRequest && Object.keys(filterRequest).length !== 0) {
            const keys = Object.keys(filterRequest);
            let isValid = true;

            keys.forEach((k: string) => {
                if (regularCompareKeys.find((rck: string): boolean => rck === k)) {
                    if (!entity[k]?.toLowerCase().includes(filterRequest[k].toLowerCase())) {
                        isValid = false;
                    }
                } else if (dateCompareKeys.find((dck: string): boolean => dck === k)) {
                    if (!moment(filterRequest[k]).isSame(entity[k], 'day')) {
                        isValid = false;
                        return;
                    }
                } else if (booleanCompareKeys.find((bck: string): boolean => bck === k)) {
                    if (!(entity[k]?.toString() === filterRequest[k]?.toString())) {
                        isValid = false;
                        return;
                    }
                } else if (specialRadioCompareKeys.find((src: string): boolean => src === k)) {
                    if (k === 'completedPercent') {
                        const val = entity.completedPercent;

                        if (filter.filterRequest && filter.filterRequest.completedPercent) {
                            const splitted = filter.filterRequest.completedPercent[0].split('-');
                            if (
                                !(
                                    splitted[0] !== '%' &&
                                    +splitted[0] <= +val &&
                                    splitted[1] !== '%' &&
                                    +splitted[1] >= +val
                                ) &&
                                !(splitted[0] === '%' && +splitted[1] === +val) &&
                                !(splitted[1] === '%' && +splitted[0] <= +val)
                            ) {
                                isValid = false;
                                return;
                            }
                        }
                    }
                }

                let children: any[] | undefined;

                if (entity.children?.length) {
                    children = recursiveFilter(entity.children, filter);
                    entity.children = children;
                }

                if (isValid || children?.length) {
                    items.push(entity);
                }
            });
        } else {
            items.push(entity);
        }
    });

    return items.length ? items : undefined;
};

const sort = (orderByKey: string, orderByValue: SortDirectionEnum, unsortedList?: any[]): any[] => {
    if (!unsortedList) return [];

    const result = [...unsortedList];

    if (regularCompareKeys.find((rck: string): boolean => rck === orderByKey)) {
        result.sort((a: any, b: any): number =>
            orderByValue === SortDirectionEnum.ASC
                ? compare(a[orderByKey]?.toLowerCase(), b[orderByKey]?.toLowerCase())
                : compare(b[orderByKey]?.toLowerCase(), a[orderByKey]?.toLowerCase())
        );
    } else if (dateCompareKeys.find((dck: string): boolean => dck === orderByKey)) {
        result.sort((a: any, b: any): number =>
            orderByValue === SortDirectionEnum.ASC
                ? compareDate(a[orderByKey], b[orderByKey])
                : compareDate(b[orderByKey], a[orderByKey])
        );
    } else if (booleanCompareKeys.find((bck: string): boolean => bck === orderByKey)) {
        result.sort((a: any, b: any): number =>
            orderByValue === SortDirectionEnum.ASC
                ? compareBoolean(a[orderByKey], b[orderByKey])
                : compareBoolean(b[orderByKey], a[orderByKey])
        );
    } else if (selectCompareKeys.find((sck: string): boolean => sck === orderByKey)) {
        result.sort((a: any, b: any): number =>
            orderByValue === SortDirectionEnum.ASC
                ? compare(a[orderByKey]?.toLowerCase(), b[orderByKey]?.toLowerCase())
                : compare(b[orderByKey]?.toLowerCase(), a[orderByKey]?.toLowerCase())
        );
    }

    return result;
};

const calcPagination = (filter: Filter, length: number): Pagination => {
    const currentPage = filter.pageIndex || defaultTablePagination.defaultCurrent!;
    const pageSize = filter.pageSize || defaultTablePagination.defaultPageSize!;

    const totalItems = length;
    const totalPages = Math.ceil(totalItems / pageSize);

    const startIndex = pageSize * (currentPage - 1);
    const endIndex = pageSize * currentPage;

    return {
        currentPage,
        pageSize,
        totalPages,
        totalItems,
        startIndex,
        endIndex,
    };
};

export const singleValueProps: string[] = [
    'activeFrom',
    'activeTo',
    'category',
    'city',
    'cityOfBirth',
    'country',
    'countryOfBirth',
    'email',
    'emailConfirmed',
    'endDate',
    'name',
    'nameUniversal',
    'person',
    'shortName',
    'startDate',
    'status',
    'surname',
    'type',
    'fileName',
    'description',
    'cooperatorName',
    'cooperatorType',
    'cooperatorContactSummary',
    'employeeName',
    'employeeRole',
    'oib',
    'address',
    'role',
    'fullName',
    'code',
    'deadlineDate',
];

export const dateRangeProps: string[] = ['dateRange', 'activeFrom', 'activeTo', 'birthDate'];

export const regularCompareKeys = [
    'category',
    'city',
    'cityName',
    'cityOfBirth',
    'country',
    'countryName',
    'countryOfBirth',
    'email',
    'name',
    'nameUniversal',
    'person',
    'shortName',
    'status',
    'surname',
    'type',
    'address',
    'phone',
    'fileName',
    'description',
    'cooperatorName',
    'cooperatorType',
    'cooperatorContactSummary',
    'employeeName',
    'employeeRole',
    'oib',
    'gender',
    'address',
    'role',
    'fullName',
    'code',
    'project',
    'totalPrice',
    'createdBy',
    'documentStatus',
    'warehouse',
    'destinationWarehouse',
    'parentCode',
    'supplier',
    'receiptCode',
];

export const dateCompareKeys = [
    'activeFrom',
    'activeTo',
    'endDate',
    'startDate',
    'birthDate',
    'lastModifiedDate',
    'deadlineDate',
];

export const selectCompareKeys = [
    'expenseTypeId',
    'projectStatusId',
    'expenseTypeId',
    'documentStatusId',
];

export const booleanCompareKeys = ['emailConfirmed'];

export const specialRadioCompareKeys = ['completedPercent'];
