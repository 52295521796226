import SelectMonth from "./SelectMonth";


interface OwnProps {
    onMonthSelected: (month: number) => void;
    selectedMonth?: number;
    width?: number | string;
}

export type Props = OwnProps;

export default SelectMonth;