import { Profile } from 'oidc-client';
import { AttachmentVm } from '../../../utils/api';
import AttachmentTable from './AttachmentTable';

interface OwnProps {
    userProfile: Profile;
    attachments?: AttachmentVm[];
    onUpdate: (id: number) => void;
    onDelete: (id: number) => Promise<void>;
}

export type Props = OwnProps;

export default AttachmentTable;
