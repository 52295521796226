import { PageHeader, Typography } from 'antd';
import React from 'react';
import { translations } from '../config/translations';
import FeaturedNews from './news-components/news-featured/FeaturedNews';

const { Link } = Typography;

export class Home extends React.PureComponent {
    render() {
        let isDev = process.env.NODE_ENV.toUpperCase() === 'DEVELOPMENT';

        return (
            <div>
                <PageHeader title={translations.dashboard.title} />

                {isDev && (
                    <>
                        <Link href="https://localhost:44339/swagger" target="_blank">
                            OpenAPI Specification
                        </Link>
                        <br></br>
                    </>
                )}

                <FeaturedNews />
            </div>
        );
    }
}
