import { PaginationProps } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { ProjectVm } from '../utils/api';

export const defaultTablePagination: TablePaginationConfig = {
    showSizeChanger: true,
    hideOnSinglePage: false,
    pageSizeOptions: ['5', '10', '25', '50'],
    defaultCurrent: 1,
    defaultPageSize: 50,
};

export const defaultProjectSorter: SorterResult<ProjectVm> = {
    column: {
        dataIndex: 'code',
        sorter: true,
        title: 'Oznaka',
    },
    field: 'code',
    order: 'descend',
};

export const defaultNewsPagination: PaginationProps = {
    ...defaultTablePagination,
    defaultPageSize: 6,
    pageSizeOptions: ['6', '12', '24', '48'],
};

export const defaultFormat = 'DD.MM.YYYY.';
export const universalFormat = 'YYYY-MM-DD';
export const defaultFormatWithTime = 'DD.MM.YYYY. HH:mm';

export const xlsxType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const DRAWER_WIDTH = 730;
export const DRAWER_WIDTH_SMALL = 500;
export const DRAWER_WIDTH_MEDIUM = 1100;
export const DRAWER_WIDTH_LARGE = 1350;
export const DRAWER_WIDTH_EXTRA_LARGE = 1440;

export const NEWS_MAX_SHORT_DESC_CHAR = 330;
