import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    EllipsisOutlined,
    FolderOutlined,
    RightOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Form, Menu, Space, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import React from 'react';
import InputPrice from '../../../../../components/input-price';
import { translations } from '../../../../../config/translations';
import { ProjectItemExtendedVm } from '../../../../../core/models/ProjectItems';
import { ActionType, ModuleName } from '../../../../../core/models/enum';
import { authorizeAction } from '../../../../../helpers/CheckPermissionHelper';
import { getRadioFilter, getSearchFilter } from '../../../../../helpers/FilterHelper';
import { showConfirm } from '../../../../../helpers/NotificationHelper';
import { formatPrice } from '../../../../../helpers/PriceHelper';
import { connectSituationItems, sumPropValues } from '../../../../../helpers/ProjectItemHelper';
import { statusFilterRangeOptions } from '../../../../../helpers/ProjectItemHelper';
import { recursiveFilterAndSort } from '../../../../../helpers/RecursionHelper';
import { getTableLocale } from '../../../../../helpers/TableHelper';
import { ProjectCooperatorVm, UnitOfMeasurementVm } from '../../../../../utils/api';
import { Props } from './index';

const NON_TABLE_CONTENT_HEIGHT = 430;

interface State {
    allowUpdate: boolean;
    allowCreate: boolean;
    allowDelete: boolean;
}

class ProjectSituationItemTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            allowUpdate: false,
            allowCreate: false,
            allowDelete: false,
        };
    }

    public componentDidMount = () => {
        document.addEventListener('keydown', this.handleKeyDown);
        this.getPermissions();
    };

    public componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
    };

    private getPermissions = async () => {
        const { userProfile } = this.props;

        const allowUpdate = await authorizeAction(
            userProfile,
            ModuleName.ProjectSituationsItems,
            ActionType.Update
        );
        const allowDelete = await authorizeAction(
            userProfile,
            ModuleName.ProjectSituationsItems,
            ActionType.Delete
        );
        const allowCreate = await authorizeAction(
            userProfile,
            ModuleName.ProjectSituationsItems,
            ActionType.Create
        );

        this.setState({
            allowUpdate,
            allowCreate,
            allowDelete,
        });
    };

    private handleKeyDown: { (e: KeyboardEvent): void } = (e: KeyboardEvent) => {
        const { onCancel } = this.props;

        if (e.key === 'Escape') {
            onCancel();
        }
    };

    private getTableColumns = (): ColumnProps<ProjectItemExtendedVm>[] => {
        const {
            editingSituationItem,
            activeSituation,
            activeContractor,
            activeSubcontractors,
            prevEditingItemId,
            forceRenderSituationColumns,
            forceRenderContractorColumns,
            onAdd,
            onEdit,
            onCancel,
            onDelete,
        } = this.props;
        const { allowDelete, allowUpdate, allowCreate } = this.state;

        const incomeColumns = [
            {
                title: 'Prihod - ' + activeSituation.name,
                key: 'situation-income-group',
                className: 'border-right',
                children: [
                    {
                        title: translations.projects.quantity,
                        dataIndex: 'situationIncomeQuantity',
                        width: 110,
                        align: 'right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;
                            return value ? formatPrice(value, 6) : null;
                        },
                        shouldCellUpdate: (
                            item: ProjectItemExtendedVm,
                            prevItem: ProjectItemExtendedVm
                        ) => {
                            return (
                                editingSituationItem?.projectItemId === item.id ||
                                prevEditingItemId === item.id ||
                                forceRenderSituationColumns ||
                                item.situationIncomeQuantity !== prevItem.situationIncomeQuantity
                            );
                        },
                    },
                    {
                        title: translations.projects.totalPrice,
                        dataIndex: 'situationIncomeTotalPrice',
                        width: 110,
                        align: 'right',
                        className: 'border-right',
                        render: (value: number | undefined) => {
                            return value ? formatPrice(value) : null;
                        },
                        shouldCellUpdate: (
                            item: ProjectItemExtendedVm,
                            prevItem: ProjectItemExtendedVm
                        ) => {
                            return (
                                editingSituationItem?.projectItemId === item.id ||
                                prevEditingItemId === item.id ||
                                forceRenderSituationColumns ||
                                item.situationIncomeTotalPrice !==
                                    prevItem.situationIncomeTotalPrice
                            );
                        },
                    },
                ],
            },
            {
                title: 'Prihod - ' + activeSituation.name + ' (Kum.)',
                key: 'situation-income-cumulative-group',
                className: 'border-right',
                children: [
                    {
                        title: translations.projects.situations.cumulativeQuantity,
                        dataIndex: 'situationIncomeCumulativeQuantity',
                        width: 110,
                        align: 'right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;
                            return value ? formatPrice(value, 6) : null;
                        },
                        shouldCellUpdate: (
                            item: ProjectItemExtendedVm,
                            prevItem: ProjectItemExtendedVm
                        ) => {
                            return (
                                editingSituationItem?.projectItemId === item.id ||
                                prevEditingItemId === item.id ||
                                forceRenderSituationColumns ||
                                item.situationIncomeCumulativeQuantity !==
                                    prevItem.situationIncomeCumulativeQuantity
                            );
                        },
                    },
                    {
                        title: translations.projects.situations.cumulativePrice,
                        dataIndex: 'situationIncomeCumulativeTotalPrice',
                        width: 110,
                        align: 'right',
                        className: 'border-right',
                        render: (value: number | undefined) => {
                            return value ? formatPrice(value) : null;
                        },
                        shouldCellUpdate: (
                            item: ProjectItemExtendedVm,
                            prevItem: ProjectItemExtendedVm
                        ) => {
                            return (
                                editingSituationItem?.projectItemId === item.id ||
                                prevEditingItemId === item.id ||
                                forceRenderSituationColumns ||
                                item.situationIncomeCumulativeTotalPrice !==
                                    prevItem.situationIncomeCumulativeTotalPrice
                            );
                        },
                    },
                ],
            },
        ];
        const expenseColumns = [
            {
                title: 'Rashod - ' + activeSituation.name,
                key: 'situation-expense-group',
                className: 'border-right',
                children: [
                    {
                        title: translations.projects.quantity,
                        dataIndex: 'situationExpenseQuantity',
                        width: 110,
                        align: 'right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;
                            return value ? formatPrice(value, 6) : null;
                        },
                        shouldCellUpdate: (
                            item: ProjectItemExtendedVm,
                            prevItem: ProjectItemExtendedVm
                        ) => {
                            return (
                                editingSituationItem?.projectItemId === item.id ||
                                prevEditingItemId === item.id ||
                                forceRenderSituationColumns ||
                                item.situationExpenseQuantity !== prevItem.situationExpenseQuantity
                            );
                        },
                    },
                    {
                        title: translations.projects.totalPrice,
                        dataIndex: 'situationExpenseTotalPrice',
                        width: 110,
                        align: 'right',
                        className: 'border-right',
                        render: (value: number | undefined) => {
                            return value ? formatPrice(value) : null;
                        },
                        shouldCellUpdate: (
                            item: ProjectItemExtendedVm,
                            prevItem: ProjectItemExtendedVm
                        ) => {
                            return (
                                editingSituationItem?.projectItemId === item.id ||
                                prevEditingItemId === item.id ||
                                forceRenderSituationColumns ||
                                item.situationExpenseTotalPrice !==
                                    prevItem.situationExpenseTotalPrice
                            );
                        },
                    },
                ],
            },
            {
                title: 'Rashod - ' + activeSituation.name + ' (Kum.)',
                key: 'situation-expense-cumulative-group',
                className: 'border-right',
                children: [
                    {
                        title: translations.projects.situations.cumulativeQuantity,
                        dataIndex: 'situationExpenseCumulativeQuantity',
                        width: 110,
                        align: 'right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;
                            return value ? formatPrice(value, 6) : null;
                        },
                        shouldCellUpdate: (
                            item: ProjectItemExtendedVm,
                            prevItem: ProjectItemExtendedVm
                        ) => {
                            return (
                                editingSituationItem?.projectItemId === item.id ||
                                prevEditingItemId === item.id ||
                                forceRenderSituationColumns ||
                                item.situationExpenseCumulativeQuantity !==
                                    prevItem.situationExpenseCumulativeQuantity
                            );
                        },
                    },
                    {
                        title: translations.projects.situations.cumulativePrice,
                        dataIndex: 'situationExpenseCumulativeTotalPrice',
                        width: 110,
                        align: 'right',
                        className: 'border-right',
                        render: (value: number | undefined) => {
                            return value ? formatPrice(value) : null;
                        },
                        shouldCellUpdate: (
                            item: ProjectItemExtendedVm,
                            prevItem: ProjectItemExtendedVm
                        ) => {
                            return (
                                editingSituationItem?.projectItemId === item.id ||
                                prevEditingItemId === item.id ||
                                forceRenderSituationColumns ||
                                item.situationExpenseCumulativeTotalPrice !==
                                    prevItem.situationExpenseCumulativeTotalPrice
                            );
                        },
                    },
                ],
            },
        ];

        return [
            {
                title: translations.projects.ordinal,
                dataIndex: 'ordinalDisplay',
                width: 80,
                fixed: 'left',
            },
            {
                title: translations.projects.item,
                dataIndex: 'name',
                width: 200,
                fixed: 'left',
                ellipsis: true,
                ...getSearchFilter(),
                render: (value: string | undefined, item: ProjectItemExtendedVm) => (
                    <>
                        {item.isGroup && (
                            <FolderOutlined style={{ marginLeft: 4, marginRight: 4 }} />
                        )}
                        <Tooltip title={value}>{value}</Tooltip>
                    </>
                ),
            },
            {
                title: translations.projects.unit,
                dataIndex: 'unitOfMeasurement',
                className: 'border-right',
                width: 80,
                fixed: 'left',
                render: (value: UnitOfMeasurementVm | undefined, item: ProjectItemExtendedVm) =>
                    item.isGroup ? null : value?.shortName,
            },
            {
                title: `${translations.projects.items.contracted} ${
                    activeContractor
                        ? activeContractor.clientId
                            ? `(${activeContractor.clientName})`
                            : ''
                        : `(${translations.projects.contractors.allContractors})`
                }`,
                key: 'contractor-group',
                className: 'border-right',
                children: [
                    {
                        title: translations.projects.quantity,
                        dataIndex: 'contractorQuantity',
                        width: 110,
                        align: 'right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;
                            return value ? formatPrice(value, 6) : null;
                        },
                        shouldCellUpdate: (item: ProjectItemExtendedVm) => {
                            return !item.isGroup && forceRenderContractorColumns;
                        },
                    },
                    {
                        title: translations.projects.unitPrice,
                        dataIndex: 'contractorUnitPrice',
                        width: 110,
                        align: 'right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;
                            return value ? formatPrice(value) : null;
                        },
                        shouldCellUpdate: (item: ProjectItemExtendedVm) => {
                            return !item.isGroup && forceRenderContractorColumns;
                        },
                    },
                    {
                        title: translations.projects.totalPrice,
                        dataIndex: 'contractorTotalPrice',
                        width: 110,
                        align: 'right',
                        className: 'border-right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            return value ? formatPrice(value) : null;
                        },
                        shouldCellUpdate: () => {
                            return forceRenderContractorColumns;
                        },
                    },
                ],
            },
            {
                title: activeSituation.name,
                key: 'situation-group',
                className: 'border-right',
                children: [
                    {
                        title: translations.projects.quantity,
                        dataIndex: 'situationQuantity',
                        width: 110,
                        align: 'right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;

                            if (editingSituationItem?.projectItemId === item.id) {
                                return (
                                    <Form.Item
                                        name="quantity"
                                        initialValue={editingSituationItem.quantity}
                                        rules={[
                                            {
                                                required: true,
                                                message: translations.general.requiredField,
                                            },
                                        ]}
                                    >
                                        <InputPrice
                                            placeholder={translations.projects.quantity}
                                            onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                            onChange={(
                                                value: string | number | null | undefined
                                            ) => {
                                                onEdit({
                                                    ...editingSituationItem,
                                                    quantity: value == null ? undefined : +value,
                                                });
                                            }}
                                            precision={6}
                                        />
                                    </Form.Item>
                                );
                            }
                            return value ? formatPrice(value, 6) : null;
                        },
                        shouldCellUpdate: (item: ProjectItemExtendedVm) => {
                            return (
                                !item.isGroup &&
                                (editingSituationItem?.projectItemId === item.id ||
                                    prevEditingItemId === item.id ||
                                    forceRenderSituationColumns)
                            );
                        },
                    },
                    {
                        title: translations.projects.totalPrice,
                        dataIndex: 'situationTotalPrice',
                        width: 110,
                        align: 'right',
                        className: 'border-right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (editingSituationItem?.projectItemId === item.id) {
                                return formatPrice(
                                    (editingSituationItem?.quantity || 0) *
                                        (item.contractorUnitPrice || 0)
                                );
                            }

                            return value ? formatPrice(value) : null;
                        },
                        shouldCellUpdate: (item: ProjectItemExtendedVm) => {
                            return (
                                !item.isGroup &&
                                (editingSituationItem?.projectItemId === item.id ||
                                    prevEditingItemId === item.id ||
                                    forceRenderSituationColumns)
                            );
                        },
                    },
                ],
            },
            {
                title: activeSituation.name + ' (Kum.)',
                key: 'situation-cumulative-group',
                className: 'border-right',
                children: [
                    {
                        title: translations.projects.situations.cumulativeQuantity,
                        dataIndex: 'situationCumulativeQuantity',
                        width: 110,
                        align: 'right',
                        render: (value: number | undefined, item: ProjectItemExtendedVm) => {
                            if (item.isGroup) return null;
                            return value ? formatPrice(value, 6) : null;
                        },
                        shouldCellUpdate: (
                            item: ProjectItemExtendedVm,
                            prevItem: ProjectItemExtendedVm
                        ) => {
                            return (
                                editingSituationItem?.projectItemId === item.id ||
                                prevEditingItemId === item.id ||
                                forceRenderSituationColumns ||
                                item.contractorTotalPrice !== prevItem.contractorTotalPrice
                            );
                        },
                    },
                    {
                        title: translations.projects.situations.cumulativePrice,
                        dataIndex: 'situationCumulativePrice',
                        width: 110,
                        align: 'right',
                        className: 'border-right',
                        render: (value: number | undefined) => {
                            return value ? formatPrice(value) : null;
                        },
                        shouldCellUpdate: (
                            item: ProjectItemExtendedVm,
                            prevItem: ProjectItemExtendedVm
                        ) => {
                            return (
                                editingSituationItem?.projectItemId === item.id ||
                                prevEditingItemId === item.id ||
                                forceRenderSituationColumns ||
                                item.contractorTotalPrice !== prevItem.contractorTotalPrice
                            );
                        },
                    },
                ],
            },
            ...(activeSubcontractors?.length ? incomeColumns : []),
            ...(activeSubcontractors?.length ? expenseColumns : []),
            {
                title: translations.projects.completedPercent,
                key: 'completedPercent',
                width: 80,
                align: 'right',
                ...getRadioFilter(statusFilterRangeOptions),
                render: (item: ProjectItemExtendedVm) => {
                    if (activeContractor && !item.contractorItem && !item.contractorTotalPrice) {
                        return null;
                    }

                    return this.renderItemCompletedPercentage(item.completedPercent);
                },
                shouldCellUpdate: (item, prevItem) =>
                    item.completedPercent !== prevItem.completedPercent ||
                    forceRenderSituationColumns,
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 70,
                align: 'center',
                render: (item: ProjectItemExtendedVm) => {
                    if (item.isGroup || !item.contractorItem) return null;

                    if (editingSituationItem?.projectItemId === item.id) {
                        return (
                            <Space>
                                <Button
                                    shape="circle"
                                    type="primary"
                                    size="small"
                                    htmlType="submit"
                                    onClick={(e) => e.stopPropagation()}
                                    icon={<CheckOutlined />}
                                />
                                <Button
                                    shape="circle"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onCancel();
                                    }}
                                    icon={<CloseOutlined />}
                                />
                            </Space>
                        );
                    }

                    return (
                        <>
                            {(allowUpdate || allowDelete || allowCreate) && (
                                <Dropdown
                                    trigger={['click']}
                                    overlay={
                                        <Menu>
                                            {item.situationItem ? (
                                                <>
                                                    {allowUpdate && (
                                                        <Menu.Item
                                                            key="edit"
                                                            onClick={(e) => {
                                                                e.domEvent.stopPropagation();
                                                                onEdit(item.situationItem!);
                                                            }}
                                                            icon={<EditOutlined />}
                                                        >
                                                            {translations.projects.editItem}
                                                        </Menu.Item>
                                                    )}
                                                    {allowDelete && (
                                                        <Menu.Item
                                                            key="delete"
                                                            onClick={(e) => {
                                                                e.domEvent.stopPropagation();
                                                                showConfirm(
                                                                    () =>
                                                                        onDelete(
                                                                            item.situationItem!.id
                                                                        ),
                                                                    translations.projects
                                                                        .deleteItemConfirm,
                                                                    translations.general.delete,
                                                                    true
                                                                );
                                                            }}
                                                            icon={<DeleteOutlined />}
                                                            danger
                                                        >
                                                            {translations.projects.deleteItem}
                                                        </Menu.Item>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {allowCreate && (
                                                        <Menu.Item
                                                            key="add"
                                                            onClick={(e) => {
                                                                e.domEvent.stopPropagation();
                                                                onAdd(
                                                                    item.id,
                                                                    item.contractorItem!.id,
                                                                    activeSituation.id
                                                                );
                                                            }}
                                                            icon={<EditOutlined />}
                                                        >
                                                            {translations.projects.editItem}
                                                        </Menu.Item>
                                                    )}
                                                </>
                                            )}
                                        </Menu>
                                    }
                                >
                                    <Button
                                        shape="circle"
                                        type="text"
                                        size="small"
                                        onClick={(e) => e.stopPropagation()}
                                        icon={<EllipsisOutlined />}
                                    />
                                </Dropdown>
                            )}
                        </>
                    );
                },
            },
        ];
    };

    private renderItemCompletedPercentage = (completedPercent?: number): React.ReactElement => {
        let className = 'red-5';

        if (!completedPercent) {
            className = '';
        } else if (completedPercent > 0 && completedPercent < 1) {
            className = 'blue-6';
        } else if (completedPercent === 1) {
            className = 'green-6';
        }

        return <div className={className}>{((completedPercent || 0) * 100).toFixed(2)}%</div>;
    };

    private getTableExpandableConfig = (): ExpandableConfig<ProjectItemExtendedVm> => {
        const { editingSituationItem, expandedRowKeys, onExpand } = this.props;

        return {
            expandedRowKeys: expandedRowKeys,
            onExpand: (expanded: boolean, item: ProjectItemExtendedVm) => onExpand(expanded, item),
            expandIconColumnIndex: 1,
            expandIcon: (iconProps: RenderExpandIconProps<ProjectItemExtendedVm>) => {
                const { expanded, record, onExpand } = iconProps;

                if (!record.children?.length || editingSituationItem?.projectItemId === record.id) {
                    return null;
                }

                return (
                    <Button
                        shape="circle"
                        type="text"
                        size="small"
                        onClick={(e) => {
                            onExpand(record, e);
                        }}
                        icon={expanded ? <DownOutlined /> : <RightOutlined />}
                    />
                );
            },
        };
    };

    private getTableSummary = (data: readonly ProjectItemExtendedVm[]): React.ReactNode => {
        const { activeSubcontractors } = this.props;
        const contractorTotalPrice = sumPropValues(data, 'contractorTotalPrice');
        const situationTotalPrice = sumPropValues(data, 'situationTotalPrice');
        const situationCumulativePrice = sumPropValues(data, 'situationCumulativePrice');

        const situationExpenseTotalPrice = sumPropValues(data, 'situationExpenseTotalPrice');
        const situationExpenseCumulativeTotalPrice = sumPropValues(
            data,
            'situationExpenseCumulativeTotalPrice'
        );
        const situationIncomeTotalPrice = sumPropValues(data, 'situationIncomeTotalPrice');
        const situationIncomeCumulativeTotalPrice = sumPropValues(
            data,
            'situationIncomeCumulativeTotalPrice'
        );

        const totalCompletedPercentage = sumPropValues(data, 'completedPercent') / data.length;

        const showIncomeAndExpenseSummary = !!activeSubcontractors?.length;

        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell
                        index={0}
                        colSpan={3}
                        align="right"
                        className="border-right"
                    >
                        <strong style={{ textTransform: 'uppercase' }}>
                            {translations.general.total}
                        </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                        index={6}
                        colSpan={3}
                        align="right"
                        className="border-right"
                    >
                        <strong>
                            {contractorTotalPrice ? formatPrice(contractorTotalPrice) : '0,00'}
                        </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                        index={9}
                        colSpan={2}
                        align="right"
                        className="border-right"
                    >
                        <strong>
                            {situationTotalPrice ? formatPrice(situationTotalPrice) : '0,00'}
                        </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                        index={11}
                        colSpan={2}
                        align="right"
                        className="border-right"
                    >
                        <strong>
                            {situationCumulativePrice
                                ? formatPrice(situationCumulativePrice)
                                : '0,00'}
                        </strong>
                    </Table.Summary.Cell>
                    {showIncomeAndExpenseSummary && (
                        <Table.Summary.Cell index={13} colSpan={2} align="right">
                            <strong>
                                {situationIncomeTotalPrice
                                    ? formatPrice(situationIncomeTotalPrice)
                                    : '0,00'}
                            </strong>
                        </Table.Summary.Cell>
                    )}
                    {showIncomeAndExpenseSummary && (
                        <Table.Summary.Cell index={15} colSpan={2} align="right">
                            <strong>
                                {situationIncomeCumulativeTotalPrice
                                    ? formatPrice(situationIncomeCumulativeTotalPrice)
                                    : '0,00'}
                            </strong>
                        </Table.Summary.Cell>
                    )}
                    {showIncomeAndExpenseSummary && (
                        <Table.Summary.Cell index={17} colSpan={2} align="right">
                            <strong>
                                {situationExpenseTotalPrice
                                    ? formatPrice(situationExpenseTotalPrice)
                                    : '0,00'}
                            </strong>
                        </Table.Summary.Cell>
                    )}
                    {showIncomeAndExpenseSummary && (
                        <Table.Summary.Cell index={19} colSpan={2} align="right">
                            <strong>
                                {situationExpenseCumulativeTotalPrice
                                    ? formatPrice(situationExpenseCumulativeTotalPrice)
                                    : '0,00'}
                            </strong>
                        </Table.Summary.Cell>
                    )}

                    <Table.Summary.Cell index={21} align="right">
                        <strong>
                            {this.renderItemCompletedPercentage(totalCompletedPercentage)}
                        </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={22}></Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    public render(): React.ReactElement {
        const {
            situations,
            contractors,
            activeSituation,
            activeContractor,
            activeSubcontractors,
            projectItems,
            projectContractorItems,
            projectSituationItems,
            filter,
            showAllItems,
            onAdd,
            onEdit,
            onSave,
            onFilterChange,
        } = this.props;

        if (!projectItems || !projectContractorItems || !projectSituationItems) {
            return (
                <Table
                    locale={getTableLocale()}
                    key="loading-table"
                    loading
                    pagination={false}
                    size="small"
                    className="ant-table-slim"
                    columns={this.getTableColumns()}
                />
            );
        }

        const mergedItems = connectSituationItems(
            projectItems,
            projectContractorItems,
            projectSituationItems,
            !showAllItems,
            situations,
            contractors,
            activeSituation.id,
            activeContractor?.id,
            activeSubcontractors?.map((s: ProjectCooperatorVm) => s.id)
        );

        const filteredItems = filter
            ? recursiveFilterAndSort(mergedItems, filter).pageItems
            : mergedItems;

        return (
            <Form onFinish={onSave} preserve={false}>
                <Table
                    locale={getTableLocale()}
                    key="data-table"
                    pagination={false}
                    size="small"
                    className="ant-table-slim"
                    scroll={{ y: `calc(100vh - ${NON_TABLE_CONTENT_HEIGHT}px)` }}
                    rowKey={(record: ProjectItemExtendedVm) => record.id}
                    onRow={(record: ProjectItemExtendedVm) => {
                        if (record.isGroup || !activeContractor) return {};

                        return {
                            onDoubleClick: () => {
                                if (record.situationItem) {
                                    onEdit(record.situationItem);
                                } else if (record.contractorItem) {
                                    onAdd(record.id, record.contractorItem.id, activeSituation.id);
                                }
                            },
                        };
                    }}
                    dataSource={filteredItems}
                    columns={this.getTableColumns()}
                    onChange={(_: any, filters: any) => onFilterChange(filters)}
                    expandable={this.getTableExpandableConfig()}
                    summary={(data: readonly ProjectItemExtendedVm[]): React.ReactNode =>
                        this.getTableSummary(data)
                    }
                />
            </Form>
        );
    }
}

export default ProjectSituationItemTable;
