import 'antd-button-color/dist/css/style.css';
import 'antd/dist/antd.css';
import React from 'react';
import { Route } from 'react-router';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { Home } from './components/Home';
import Layout from './components/Layout';
import { AuthorizationRoutes } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import NotFoundPage from './components/non-existing-page';
import Routes from './config/routes';
import { translations } from './config/translations';
import { ModuleName } from './core/models/enum';
import './custom.css';
import Cooperators from './modules/cooperators';
import CooperatorsPage from './modules/cooperators/cooperator-page';
import Documents from './modules/documents';
import DocumentPage from './modules/documents/document-page';
import Employees from './modules/employees';
import EmployeePage from './modules/employees/employee-page';
import ExpenseGroups from './modules/expense-groups';
import Hodogram from './modules/hodogram';
import News from './modules/news';
import Projects from './modules/projects';
import ProjectPage from './modules/projects/project-page';
import Users from './modules/users';
import WarehouseItems from './modules/warehouse-items';
import Warehouses from './modules/warehouses';
import WorkHourRecords from './modules/work-hour-records';
import PeopleSchedule from './modules/people-schedule';
import CostItems from './modules/project-items-overview';
import Salaries from './modules/salaries';
import Payrolls from './modules/payrolls';
import EmployeeExpenses from './modules/employee-expenses';

type Props = RouteComponentProps<{}>;

class App extends React.Component<Props> {
    componentDidMount() {
        document.title = translations.dashboard.title;
    }

    render() {
        return (
            <Layout>
                <Switch>
                    <AuthorizeRoute
                        exact
                        path={Routes.ROUTE_DASHBOARD}
                        component={Home}
                        moduleName={ModuleName.Dashboard}
                    />
                    <Route path={Routes.ROUTE_NOT_FOUND} component={NotFoundPage} />
                    <Route
                        path={AuthorizationRoutes.ApiAuthorizationPrefix}
                        component={ApiAuthorizationRoutes}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_USERS_NEW}
                        component={Users}
                        moduleName={ModuleName.Users}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_USERS_EDIT}
                        component={Users}
                        moduleName={ModuleName.Users}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_USERS_READ}
                        component={Users}
                        moduleName={ModuleName.Users}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_USERS}
                        component={Users}
                        moduleName={ModuleName.Users}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_NEWS_EDIT}
                        component={News}
                        moduleName={ModuleName.NewsAdmin}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_NEWS_NEW}
                        component={News}
                        moduleName={ModuleName.NewsAdmin}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_NEWS_ADMIN_READ}
                        component={News}
                        moduleName={ModuleName.NewsAdmin}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_NEWS_ADMIN}
                        component={News}
                        moduleName={ModuleName.NewsAdmin}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_NEWS_READ}
                        component={News}
                        moduleName={ModuleName.NewsRegular}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_NEWS}
                        component={News}
                        moduleName={ModuleName.NewsRegular}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_PROJECT_SUBPAGE_EDIT}
                        component={ProjectPage}
                        moduleName={ModuleName.Projects}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PROJECT_SUBPAGE_NEW}
                        component={ProjectPage}
                        moduleName={ModuleName.Projects}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PROJECT_SUBPAGE_READ}
                        component={ProjectPage}
                        moduleName={ModuleName.Projects}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_PROJECT_SUBPAGE_IMPORT}
                        component={ProjectPage}
                        moduleName={ModuleName.Projects}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PROJECTS_EDIT}
                        component={ProjectPage}
                        moduleName={ModuleName.Projects}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PROJECT_SUBPAGE}
                        component={ProjectPage}
                        moduleName={ModuleName.Projects}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PROJECTS_NEW}
                        component={Projects}
                        moduleName={ModuleName.Projects}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PROJECTS_READ}
                        component={ProjectPage}
                        moduleName={ModuleName.Projects}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PROJECTS}
                        component={Projects}
                        moduleName={ModuleName.Projects}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_HODOGRAM_EDIT}
                        component={Hodogram}
                        moduleName={ModuleName.Hodogram}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_HODOGRAM_READ}
                        component={Hodogram}
                        moduleName={ModuleName.Hodogram}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_HODOGRAM}
                        component={Hodogram}
                        moduleName={ModuleName.Hodogram}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_EMPLOYEES_ATTACHMENTS_EDIT}
                        component={EmployeePage}
                        moduleName={ModuleName.Attachments}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EMPLOYEES_ATTACHMENTS_NEW}
                        component={EmployeePage}
                        moduleName={ModuleName.Attachments}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EMPLOYEES_SUBPAGE_EDIT}
                        component={EmployeePage}
                        moduleName={ModuleName.Employees}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EMPLOYEES_SUBPAGE_NEW}
                        component={EmployeePage}
                        moduleName={ModuleName.Employees}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EMPLOYEES_SUBPAGE_READ}
                        component={EmployeePage}
                        moduleName={ModuleName.Employees}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_EMPLOYEES_EDIT}
                        component={EmployeePage}
                        moduleName={ModuleName.Employees}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EMPLOYEES_SUBPAGE}
                        component={EmployeePage}
                        moduleName={ModuleName.Projects}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EMPLOYEES_NEW}
                        component={Employees}
                        moduleName={ModuleName.Employees}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EMPLOYEES_READ}
                        component={EmployeePage}
                        moduleName={ModuleName.Employees}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EMPLOYEES}
                        component={Employees}
                        moduleName={ModuleName.Employees}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_WORK_HOUR_RECORDS}
                        component={WorkHourRecords}
                        moduleName={ModuleName.TimeSheet}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_COOPERATORS_NEW}
                        component={Cooperators}
                        moduleName={ModuleName.Cooperators}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COOPERATORS_EDIT}
                        component={CooperatorsPage}
                        moduleName={ModuleName.Cooperators}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COOPERATORS_READ}
                        component={CooperatorsPage}
                        moduleName={ModuleName.Cooperators}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COOPERATORS}
                        component={Cooperators}
                        moduleName={ModuleName.Cooperators}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_WAREHOUSES_EDIT}
                        component={Warehouses}
                        moduleName={ModuleName.Warehouses}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_WAREHOUSES_NEW}
                        component={Warehouses}
                        moduleName={ModuleName.Warehouses}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_WAREHOUSES_READ}
                        component={Warehouses}
                        moduleName={ModuleName.Warehouses}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_WAREHOUSES}
                        component={Warehouses}
                        moduleName={ModuleName.Warehouses}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_WAREHOUSE_ITEMS_EDIT}
                        component={WarehouseItems}
                        moduleName={ModuleName.WarehouseItems}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_WAREHOUSE_ITEMS_NEW}
                        component={WarehouseItems}
                        moduleName={ModuleName.WarehouseItems}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_WAREHOUSE_ITEMS_READ}
                        component={WarehouseItems}
                        moduleName={ModuleName.WarehouseItems}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_WAREHOUSE_ITEMS}
                        component={WarehouseItems}
                        moduleName={ModuleName.WarehouseItems}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_EXPENSE_GROUP_NEW}
                        component={ExpenseGroups}
                        moduleName={ModuleName.ExpenseGroups}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EXPENSE_GROUP_EDIT}
                        component={ExpenseGroups}
                        moduleName={ModuleName.ExpenseGroups}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EXPENSE_GROUP_READ}
                        component={ExpenseGroups}
                        moduleName={ModuleName.ExpenseGroups}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EXPENSE_GROUPS}
                        component={ExpenseGroups}
                        moduleName={ModuleName.ExpenseGroups}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_DOCUMENTS_SUBPAGE_EDIT}
                        component={DocumentPage}
                        moduleName={ModuleName.Documents}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_DOCUMENTS_SUBPAGE_NEW}
                        component={DocumentPage}
                        moduleName={ModuleName.Documents}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_DOCUMENTS_SUBPAGE_READ}
                        component={DocumentPage}
                        moduleName={ModuleName.Documents}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_DOCUMENTS_EDIT}
                        component={DocumentPage}
                        moduleName={ModuleName.Documents}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_DOCUMENTS_SUBPAGE}
                        component={DocumentPage}
                        moduleName={ModuleName.Documents}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_DOCUMENTS_NEW}
                        component={Documents}
                        moduleName={ModuleName.Documents}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_DOCUMENTS_READ}
                        component={DocumentPage}
                        moduleName={ModuleName.Documents}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_DOCUMENTS}
                        component={Documents}
                        moduleName={ModuleName.Documents}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PEOPLE_SCHEDULE}
                        component={PeopleSchedule}
                        moduleName={ModuleName.PeopleSchedule}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PROJECT_ITEMS_OVERVIEW}
                        component={CostItems}
                        moduleName={ModuleName.ProjectItemsOverview}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_SALARIES}
                        component={Salaries}
                        moduleName={ModuleName.Salaries}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PAYROLLS}
                        component={Payrolls}
                        moduleName={ModuleName.Payrolls}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_EMPLOYE_EXPENSES}
                        component={EmployeeExpenses}
                        moduleName={ModuleName.EmployeeExpenses}
                    />
                </Switch>
            </Layout>
        );
    }
}

export default withRouter(App);
