import { Table, TablePaginationConfig, Tooltip } from 'antd';
import React from 'react';
import { defaultTablePagination } from '../../../config/constants';
import { translations } from '../../../config/translations';
import { getSearchFilter } from '../../../helpers/FilterHelper';
import { getTableLocale } from '../../../helpers/TableHelper';
import { Props } from './index';
import {
    applySearchParams,
    getFilterParamsString,
    getFiltersParams,
    getPagingSortingParams,
} from '../../../helpers/SearchParamsHelper';
import { capitalizeFirstLetter } from '../../../helpers/StringHelper';
import { ProjectCooperatorItemsOverviewVm, ProjectCooperatorItemsClient, FileResponse } from '../../../utils/api';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { formatPrice } from '../../../helpers/PriceHelper';
import { FileExcelOutlined } from '@ant-design/icons';
import { Button, PageHeader } from 'antd';
import { showSuccess } from '../../../helpers/NotificationHelper';
import { downloadFile } from '../../../helpers/FileHelper';

interface State {
    projectItems?: ProjectCooperatorItemsOverviewVm[];
    loading?: boolean;
    totalItems?: number;
}

class ProjectItemsOverviewTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            projectItems: [],
        };
    }

    public componentDidMount = () => {
        this.getProjectCooperatorItemsOverview();
    };

    public componentDidUpdate(prevProps: Props) {
        const {
            location: { search },
        } = this.props;

        if (prevProps.location.search !== search) {
            this.getProjectCooperatorItemsOverview();
        }
    }

    private handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter:
            | SorterResult<ProjectCooperatorItemsOverviewVm>
            | SorterResult<ProjectCooperatorItemsOverviewVm>[] = {}
    ) => {
        const {
            history,
            location: { search },
        } = this.props;

        const currSearchParams = new URLSearchParams(search);
        const tableSearchParams = this.createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    private createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter:
            | SorterResult<ProjectCooperatorItemsOverviewVm>
            | SorterResult<ProjectCooperatorItemsOverviewVm>[] = {}
    ): URLSearchParams => {
        const filterParamsString = getFilterParamsString(filters);
        const singleSorter = sorter as SorterResult<ProjectCooperatorItemsOverviewVm>;
        const tableSearchParams = new URLSearchParams(filterParamsString);

        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );    
        tableSearchParams.append('sortBy', singleSorter.field?.toString() && singleSorter.order ? singleSorter.field?.toString() : '');
        tableSearchParams.append('sortOrder', singleSorter.order || '');

        return tableSearchParams;
    };

    private getProjectCooperatorItemsOverview = async () => {
        this.setState({
            loading: true,
        });
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { pageIndexParam, pageSizeParam, sortByParam, sortOrderParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        const result = await new ProjectCooperatorItemsClient().getProjectCooperatorItemsOverview(
            filters.ordinal && Number(filters.ordinal) ? Number(filters.ordinal) : undefined,
            filters.projectItem?.toString(),
            filters.unit?.toString(),
            filters.quantity && parseInt(filters.quantity.toString())
                ? parseInt(filters.quantity.toString())
                : undefined,
            filters.unitPrice && Number(filters.unitPrice) ? Number(filters.unitPrice) : undefined,
            filters.totalPrice && Number(filters.totalPrice)
                ? Number(filters.totalPrice)
                : undefined,
            filters.client?.toString(),
            filters.cooperator?.toString(),
            filters.project?.toString(),
            filters.projectCode?.toString(),
            filters.year && Number(filters.year) ? Number(filters.year) : undefined,
            pageIndexParam && parseInt(pageIndexParam) ? parseInt(pageIndexParam) : 1,
            pageSizeParam && parseInt(pageSizeParam)
                ? parseInt(pageSizeParam)
                : defaultTablePagination.defaultPageSize,
            sortByParam ? capitalizeFirstLetter(sortByParam) : undefined,
            sortOrderParam
        );

        this.setState(() => ({
            projectItems: result.pageItems,
            loading: false,
            totalItems: result.totalItems,
        }));
    };

    private handleExport = async() => {
        this.setState({
            loading: true,
        });
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { pageIndexParam, pageSizeParam, sortByParam, sortOrderParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        const result = await new ProjectCooperatorItemsClient().exportXLSX(
            filters.ordinal && Number(filters.ordinal) ? Number(filters.ordinal) : undefined,
            filters.projectItem?.toString(),
            filters.unit?.toString(),
            filters.quantity && parseInt(filters.quantity.toString())
                ? parseInt(filters.quantity.toString())
                : undefined,
            filters.unitPrice && Number(filters.unitPrice) ? Number(filters.unitPrice) : undefined,
            filters.totalPrice && Number(filters.totalPrice)
                ? Number(filters.totalPrice)
                : undefined,
            filters.client?.toString(),
            filters.cooperator?.toString(),
            filters.project?.toString(),
            filters.projectCode?.toString(),
            filters.year && Number(filters.year) ? Number(filters.year) : undefined,
            pageIndexParam && parseInt(pageIndexParam) ? parseInt(pageIndexParam) : 1,
            pageSizeParam && parseInt(pageSizeParam)
                ? parseInt(pageSizeParam)
                : defaultTablePagination.defaultPageSize,
            sortByParam ? capitalizeFirstLetter(sortByParam) : undefined,
            sortOrderParam
        );
        this.handleExportSuccess(result);

        this.setState(() => ({
            loading: false,
        }));
    }

    private handleExportSuccess = (result: FileResponse) => {
        showSuccess(translations.documents.exportSuccess);

        downloadFile(result);
    };

    public render(): React.ReactElement {
        const { projectItems, loading, totalItems } = this.state;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam, pageIndexParam, pageSizeParam } =
            getPagingSortingParams(params);
        const filters = getFiltersParams(params);

        const pagination: TablePaginationConfig = {
            ...defaultTablePagination,
            current:
                pageIndexParam && parseInt(pageIndexParam)
                    ? parseInt(pageIndexParam)
                    : defaultTablePagination.defaultCurrent,
            pageSize:
                pageSizeParam && parseInt(pageSizeParam)
                    ? parseInt(pageSizeParam)
                    : defaultTablePagination.defaultPageSize,
            total: totalItems,
        };

        const columns = [
            {
                title: translations.administration.projectItems.itemIndex,
                dataIndex: 'ordinal',
                ...getSearchFilter(),
                filteredValue: filters.ordinal ? [filters.ordinal] : null,
                sortOrder: sortByParam === 'ordinal' ? sortOrderParam : null,
                sorter: true,
                width: 40,
                ellipsis: true,
                fixed: 'left',
                align: 'right',
            },
            {
                title: translations.administration.projectItems.item,
                dataIndex: 'projectItem',
                ...getSearchFilter(),
                filteredValue: filters.projectItem ? [filters.projectItem] : null,
                sortOrder: sortByParam === 'projectItem' ? sortOrderParam : null,
                sorter: true,
                width: 110,
                ellipsis: true,
                render: (value?: string) => (
                    <Tooltip>{value}</Tooltip>
                ),
            },
            {
                title: translations.administration.projectItems.unit,
                dataIndex: 'unit',
                ...getSearchFilter(),
                filteredValue: filters.unit ? [filters.unit] : null,
                sortOrder: sortByParam === 'unit' ? sortOrderParam : null,
                sorter: true,
                width: 40,
                ellipsis: true,
                align: 'right',
            },
            {
                title: translations.administration.projectItems.quantity,
                dataIndex: 'quantity',
                ...getSearchFilter(),
                filteredValue: filters.quantity ? [filters.quantity] : null,
                sortOrder: sortByParam === 'quantity' ? sortOrderParam : null,
                sorter: true,
                width: 40,
                ellipsis: true,
                align: 'right',
                render: (value?: number) => (value ? formatPrice(value) : '0,00'),
            },
            {
                title: translations.administration.projectItems.oneItemPrice,
                dataIndex: 'unitPrice',
                ...getSearchFilter(),
                filteredValue: filters.unitPrice ? [filters.unitPrice] : null,
                sortOrder: sortByParam === 'unitPrice' ? sortOrderParam : null,
                sorter: true,
                width: 60,
                ellipsis: true,
                align: 'right',
                render: (value?: number) => (value ? formatPrice(value) : '0,00'),
            },
            {
                title: translations.administration.projectItems.totalPrice,
                dataIndex: 'totalPrice',
                ...getSearchFilter(),
                filteredValue: filters.totalPrice ? [filters.totalPrice] : null,
                sortOrder: sortByParam === 'totalPrice' ? sortOrderParam : null,
                sorter: true,
                width: 60,
                ellipsis: true,
                align: 'right',
                render: (value?: number) => (value ? formatPrice(value) : '0,00'),
            },
            {
                title: translations.administration.projectItems.cooperator,
                dataIndex: 'cooperator',
                ...getSearchFilter(),
                filteredValue: filters.cooperator ? [filters.cooperator] : null,
                sortOrder: sortByParam === 'cooperator' ? sortOrderParam : null,
                sorter: true,
                width: 110,
                ellipsis: false,
            },
            {
                title: translations.administration.projectItems.conctractor,
                dataIndex: 'client',
                ...getSearchFilter(),
                filteredValue: filters.client ? [filters.client] : null,
                sortOrder: sortByParam === 'client' ? sortOrderParam : null,
                sorter: true,
                width: 110,
                ellipsis: false,
            },
            {
                title: translations.administration.projectItems.project,
                dataIndex: 'project',
                ...getSearchFilter(),
                filteredValue: filters.project ? [filters.project] : null,
                sortOrder: sortByParam === 'project' ? sortOrderParam : null,
                sorter: true,
                width: 110,
                ellipsis: true,
                render: (value?: string) => (
                    <Tooltip title={value}>{value}</Tooltip>
                ),
            },
            {
                title: translations.administration.projectItems.projectCode,
                dataIndex: 'projectCode',
                ...getSearchFilter(),
                filteredValue: filters.projectCode ? [filters.projectCode] : null,
                sortOrder: sortByParam === 'projectCode' ? sortOrderParam : null,
                sorter: true,
                width: 70,
                ellipsis: true,
            },
            {
                title: translations.administration.projectItems.year,
                dataIndex: 'year',
                ...getSearchFilter(),
                filteredValue: filters.year ? [filters.year] : null,
                sortOrder: sortByParam === 'year' ? sortOrderParam : null,
                sorter: true,
                width: 40,
                ellipsis: true,
                align: 'right',
                render: (value: number) => <>{value !== 1 ? value : ''}</>,
            },
        ];

        return (
            <>
                <PageHeader
                    title={translations.administration.projectItems.title}
                    extra={
                        <Button
                            style={{
                                zIndex: 10,
                                float: 'right',
                            }}
                            onClick={this.handleExport}
                        >
                            <FileExcelOutlined />
                            {translations.general.export}
                        </Button>
                    }
                />

                <Table
                    locale={getTableLocale()}
                    columns={columns}
                    dataSource={projectItems}
                    loading={loading}
                    //rowKey={(record: ProjectCooperatorItemsOverviewVm): string => record.ordinal?.toString()}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    bordered
                    size="small"
                />
            </>
        );
    }
}

export default ProjectItemsOverviewTable;
