import { Empty } from 'antd';
import { TableLocale } from 'antd/lib/table/interface';
import { translations } from '../config/translations';

export const getTableLocale = (): TableLocale => ({
    emptyText: <Empty description={translations.general.noData} />,
    cancelSort: translations.general.cancelSort,
    triggerAsc: translations.general.triggerSortAsc,
    triggerDesc: translations.general.triggerSortDesc,
});
