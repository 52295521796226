import { Form, Input, Select } from 'antd';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../components/drawer-buttons/index';
import { formItemLayout724 } from '../../../config/formLayouts';
import { translations } from '../../../config/translations';
import { DropdownOption } from '../../../core/models/DropdownOption';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
} from '../../../helpers/AutocompleteDropdownHelper';
import { getEmployeesToDropdownOptions } from '../../../helpers/FetchAndTransformHelpers';
import { showError, showSuccess } from '../../../helpers/NotificationHelper';
import { arrayValidator } from '../../../helpers/ValidationHelper';
import {
    ApiException,
    CodebooksClient,
    RoleEnum,
    SelectOptionVm,
    UsersClient,
    UserVm,
} from '../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    roles?: SelectOptionVm[];
    selectedRoleId?: number;
    employees?: DropdownOption[];
    selectedEmployee?: DropdownOption[];
}

class UserForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
            selectedRoleId: props.user?.roleId,
        };
    }

    public componentDidMount = () => {
        this.getUserRoles();
        this.getEmployees();
    };

    private getUserRoles = async () => {
        this.setState({
            roles: await new CodebooksClient().getByCodebookName('Role'),
        });
    };

    private getEmployees = async () => {
        const { user } = this.props;

        const results = await getEmployeesToDropdownOptions(user?.employeeId);
        this.setState({
            employees: results.entities,
            selectedEmployee: results.selectedEntity,
        });
    };

    private handleSubmit = async (values: any) => {
        const { user } = this.props;
        const { selectedEmployee } = this.state;

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: user ? user.id : undefined,
            employeeId:
                selectedEmployee && selectedEmployee.length > 0
                    ? parseInt(selectedEmployee[0].id, 10)
                    : undefined,
        };

        try {
            let result;
            if (request.id) {
                result = await new UsersClient().update(request.id, request);
            } else {
                result = await new UsersClient().create(request);
            }

            this.handleSuccess(result);
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (result: UserVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.users.savedMessage);

        onSuccess(result);
    };

    public render() {
        const { isSaving, roles, selectedRoleId, employees, selectedEmployee } = this.state;
        const { onClose, user } = this.props;

        if (!roles || !employees) return null;

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout724}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />

                <Form.Item
                    name="name"
                    label={translations.employees.name}
                    initialValue={user?.name}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="surname"
                    label={translations.employees.surname}
                    initialValue={user?.surname}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="email"
                    label={translations.users.email}
                    initialValue={user?.email}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { type: 'email', message: translations.general.invalidEmail },
                        { max: 100, message: translations.general.maxLength100 },
                    ]}
                >
                    <Input disabled={!!user} />
                </Form.Item>

                <Form.Item
                    name="roleId"
                    label={translations.users.role}
                    initialValue={user?.roleId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select<number>
                        placeholder={translations.users.chooseRole}
                        options={roles}
                        onChange={(value: number) =>
                            this.setState({
                                selectedRoleId: value,
                            })
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="employeeId"
                    label={translations.users.employee}
                    rules={[
                        {
                            required: selectedRoleId === RoleEnum.SiteManager,
                            validator:
                                selectedRoleId === RoleEnum.SiteManager
                                    ? (_rule: any, value: any, callback: any) =>
                                          arrayValidator(
                                              _rule,
                                              value,
                                              callback,
                                              translations.general.requiredField,
                                              selectedEmployee
                                          )
                                    : undefined,
                        },
                    ]}
                >
                    <AutocompleteDropdown
                        placeholder={translations.users.chooseEmployee}
                        getOptionsFrontend={(value: string): DropdownOption[] =>
                            filterDropdownOptions(value, employees)
                        }
                        isAllowedToClear={selectedRoleId !== RoleEnum.SiteManager}
                        initialValues={selectedEmployee}
                        confirmDirty={(options: DropdownOption[]) =>
                            this.setState({
                                selectedEmployee: confirmDropdownHelper(options),
                            })
                        }
                    />
                </Form.Item>
            </Form>
        );
    }
}

export default UserForm;
