import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteParams';
import { UserVm } from '../../../utils/api';
import UserForm from './UserForm';

interface OwnProps {
    user?: UserVm;
    onClose: () => void;
    onSuccess: (user: UserVm) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(UserForm);
