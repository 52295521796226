import { translations } from '../config/translations';
import {
    CitiesClient,
    CooperatorsClient,
    CooperatorContactsClient,
    CountriesClient,
    EmployeesClient,
    ProjectCooperatorsClient,
    AttachmentsClient,

    ProjectsClient,
    ProjectSituationsClient,
    ExpenseGroupsClient,
    WarehouseItemsClient,
    ApiException,
    DocumentsClient,
    DocumentItemsClient,
    ProjectEmployeesClient,
} from '../utils/api';
import { showError, showSuccess } from './NotificationHelper';

type PossibleClientTypes =
    | CitiesClient
    | CountriesClient
    | ProjectsClient
    | EmployeesClient
    | CooperatorsClient
    | CooperatorContactsClient
    | ProjectCooperatorsClient
    | ProjectSituationsClient
    | ExpenseGroupsClient
    | DocumentsClient
    | DocumentItemsClient;

export const create = async (
    client: PossibleClientTypes | ProjectEmployeesClient,
    request: any,
    callback: (result: any) => void
) => {
    try {
        const result = await client.create(request);
        callback(result);
    } catch (error) {
        if (error instanceof ApiException) {
            showError(error.response);
        } else {
            showError(translations.general.errorSavingData);
        }
    }
};

export const update = async (
    client: PossibleClientTypes | WarehouseItemsClient | ProjectEmployeesClient,
    request: any,
    callback: (result: any) => void
) => {
    try {
        const result = await client.update(request.id, request);
        callback(result);
    } catch (error) {
        if (error instanceof ApiException) {
            showError(error.response);
        } else {
            showError(translations.general.errorSavingData);
        }
    }
};

export const remove = async (
    client: PossibleClientTypes | AttachmentsClient,
    message: string,
    callback: (id: number) => void,
    id: number
) => {
    try {
        const result = await client.delete(id);
        callback(result);
        showSuccess(message);
    } catch (error) {
        if (error instanceof ApiException) {
            showError(error.response);
        } else {
            showError(translations.general.errorDeletingData);
        }
    }
};
