import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader } from 'antd';
import React from 'react';
import { defaultTablePagination, DRAWER_WIDTH } from '../../config/constants';
import ReplaceStrings from '../../config/replaceStrings';
import Routes from '../../config/routes';
import { translations } from '../../config/translations';
import { ActionType, DrawerState, ModuleName } from '../../core/models/enum';
import { authorizeAction } from '../../helpers/CheckPermissionHelper';
import { getNewRoute } from '../../helpers/RoutingHelper';
import { CooperatorDetailVm } from '../../utils/api';
import CooperatorForm from './cooperator-form';
import CooperatorTable from './cooperator-table';
import { Props } from './index';

interface State {
    allowCreate: boolean;
    drawerState: DrawerState;
}

class Cooperators extends React.Component<Props, State> {
    private tableRef: any = React.createRef();

    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;

        this.state = {
            allowCreate: authorizeAction(
                props.userProfile,
                ModuleName.Cooperators,
                ActionType.Create
            ),
            drawerState:
                pathname === Routes.ROUTE_COOPERATORS_NEW ? DrawerState.Edit : DrawerState.Closed,
        };
    }

    private handleDrawerMode = (drawerState: DrawerState, id?: string) => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_COOPERATORS,
                Routes.ROUTE_COOPERATORS_READ,
                Routes.ROUTE_COOPERATORS_NEW,
                drawerState,
                ReplaceStrings.COOPERATOR_ID,
                id
            )
        );
    };

    private handleSuccessCreate = (_: CooperatorDetailVm) => {
        this.handleDrawerMode(DrawerState.Closed);
        this.tableRef.current.getCooperators(defaultTablePagination);
    };

    public render(): React.ReactElement {
        const { allowCreate, drawerState } = this.state;

        return (
            <>
                <PageHeader
                    title={translations.cooperators.cooperators}
                    extra={
                        allowCreate
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      onClick={() => this.handleDrawerMode(DrawerState.Edit)}
                                      className="no-print"
                                  >
                                      <PlusOutlined />
                                      {translations.cooperators.addCooperator}
                                  </Button>,
                              ]
                            : []
                    }
                />

                <CooperatorTable
                    wrappedComponentRef={this.tableRef}
                />

                <Drawer
                    title={translations.cooperators.addCooperator}
                    open={!!drawerState}
                    onClose={() => this.handleDrawerMode(DrawerState.Closed)}
                    width={DRAWER_WIDTH}
                    destroyOnClose
                >
                    <CooperatorForm
                        onClose={() => this.handleDrawerMode(DrawerState.Closed)}
                        onSuccess={this.handleSuccessCreate}
                    />
                </Drawer>
            </>
        );
    }
}

export default Cooperators;
