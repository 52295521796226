import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteParams';
import EmployeeExpensesTable from './EmployeeExpensesTable';
import { EmployeeExpenseEmployeeVm, ProjectColumn } from '../../../utils/api';

interface OwnProps {
    wrappedComponentRef?: any;
    selectedCompanyId?: number;
    selectedMonth: number;
    selectedYear: number;
    loading: boolean;
    totalCount: number;
    employeeExpensesEmployee: EmployeeExpenseEmployeeVm[];
    fetchEmployeeExpenses: () => void;
    projectColumns: ProjectColumn[];
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(EmployeeExpensesTable);
