import { ColumnsType } from 'antd/lib/table';
import { ColumnContentValue } from '../components/column-content-picker';

export const getNumericValueFromColumnString = (columnString: string): number => {
    let base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let baseNumber = base.length;

    let runningTotal = 0;
    let characterIndex = 0;
    let indexExponent = columnString.length - 1;

    while (characterIndex < columnString.length) {
        let digit = columnString[characterIndex];
        let digitValue = base.indexOf(digit) + 1;
        runningTotal += Math.pow(baseNumber, indexExponent) * digitValue;

        characterIndex += 1;
        indexExponent -= 1;
    }

    return runningTotal;
};

export const getColumnStringFromNumericValue = (columnNumber: number): string => {
    let dividend = columnNumber;
    let columnName = '';
    let modulo;

    while (dividend > 0) {
        modulo = (dividend - 1) % 26;
        columnName = String.fromCharCode(65 + modulo) + columnName;
        dividend = Math.floor((dividend - modulo) / 26);
    }

    return columnName;
};

export const getColumnsFromExcelRange = (
    excelRange: string,
    extraColumn?: string
): ColumnsType<ColumnContentValue> => {
    const excelColumnStrings = excelRange.match(/[A-Z]+/g);
    let columns = [] as ColumnsType<ColumnContentValue>;

    let firstColumnNumericValue = getNumericValueFromColumnString(excelColumnStrings![0]);
    let lastColumnNumericValue = getNumericValueFromColumnString(excelColumnStrings![1]);

    let columnWidth =
        100 / (lastColumnNumericValue - firstColumnNumericValue + 1 + (extraColumn ? 1 : 0)) + '%';

    if (extraColumn) {
        columns.push({
            title: extraColumn,
            dataIndex: lastColumnNumericValue - firstColumnNumericValue + 1,
            key: extraColumn,
            width: columnWidth,
        });
    }

    for (let i = firstColumnNumericValue; i <= lastColumnNumericValue; i++) {
        let columnString = getColumnStringFromNumericValue(i);
        columns.push({
            title: columnString,
            dataIndex: i - firstColumnNumericValue,
            key: columnString,
            width: columnWidth,
        });
    }

    return columns;
};
