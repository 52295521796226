import { Badge } from 'antd';
import { documents } from '../config/translations';
import { DocumentTypeTranslations } from '../core/models/Translations';
import {
    DocumentItemStatusEnum,
    DocumentItemVm,
    DocumentStatusEnum,
    DocumentTypeEnum,
    DocumentVm,
    SelectOptionVm,
    WarehouseItemVm,
} from '../utils/api';

export const getDocumentTypeTranslations = (
    typeId?: DocumentTypeEnum
): DocumentTypeTranslations => {
    if (!typeId) {
        return documents.documents;
    }
    const type = DocumentTypeEnum[typeId];

    return documents[type.charAt(0).toLowerCase() + type.slice(1)];
};

export const getPossibleChildrenTypes = (documentTypeId: DocumentTypeEnum): DocumentTypeEnum[] => {
    switch (documentTypeId) {
        case DocumentTypeEnum.RequisitionOrder:
            return [DocumentTypeEnum.PurchaseOrder, DocumentTypeEnum.TransferNote];
        case DocumentTypeEnum.PurchaseOrder:
            return [
                DocumentTypeEnum.PurchaseReceivedNote,
                DocumentTypeEnum.Order,
                DocumentTypeEnum.Liquidation,
            ];
        case DocumentTypeEnum.PurchaseReceivedNote:
            return [
                DocumentTypeEnum.WriteOffNote,
                DocumentTypeEnum.ConsumptionNote,
                DocumentTypeEnum.Liquidation,
            ];
        case DocumentTypeEnum.TransferNote:
            return [DocumentTypeEnum.TransferReceivedNote];
        case DocumentTypeEnum.TransferReceivedNote:
            return [DocumentTypeEnum.WriteOffNote, DocumentTypeEnum.ConsumptionNote];
        default:
            return [];
    }
};

export const getPossibleChildrenTypesForWarehouseItems = (): DocumentTypeEnum[] => {
    return [
        DocumentTypeEnum.WriteOffNote,
        DocumentTypeEnum.ConsumptionNote,
        DocumentTypeEnum.TransferNote,
        DocumentTypeEnum.Liquidation,
    ];
};

export const getPossibleParentTypes = (
    documentTypeId: DocumentTypeEnum
): DocumentTypeEnum[] | null => {
    switch (documentTypeId) {
        case DocumentTypeEnum.PurchaseOrder:
        case DocumentTypeEnum.TransferNote:
            return [DocumentTypeEnum.RequisitionOrder];
        case DocumentTypeEnum.PurchaseReceivedNote:
            return [DocumentTypeEnum.PurchaseOrder];
        case DocumentTypeEnum.TransferReceivedNote:
            return [DocumentTypeEnum.TransferNote];
        case DocumentTypeEnum.WriteOffNote:
            return [DocumentTypeEnum.PurchaseReceivedNote, DocumentTypeEnum.TransferReceivedNote];
        case DocumentTypeEnum.ConsumptionNote:
            return [DocumentTypeEnum.PurchaseReceivedNote, DocumentTypeEnum.TransferReceivedNote];
        case DocumentTypeEnum.Order:
            return [DocumentTypeEnum.PurchaseOrder];
        case DocumentTypeEnum.Liquidation:
            return [DocumentTypeEnum.PurchaseOrder, DocumentTypeEnum.PurchaseReceivedNote];
        default:
            return null;
    }
};

export const initNewDocumentItem = (document: DocumentVm): DocumentItemVm =>
    DocumentItemVm.fromJS({ documentId: document.id });

export const isConsumptionDocumentType = (documentTypeId: DocumentTypeEnum): boolean =>
    documentTypeId === DocumentTypeEnum.ConsumptionNote ||
    documentTypeId === DocumentTypeEnum.WriteOffNote;

export const isOutgoingDocumentType = (documentTypeId: DocumentTypeEnum): boolean =>
    documentTypeId === DocumentTypeEnum.TransferNote ||
    documentTypeId === DocumentTypeEnum.ConsumptionNote ||
    documentTypeId === DocumentTypeEnum.WriteOffNote;

export const hasHiddenDocumentItemStatus = (documentTypeId: DocumentTypeEnum): boolean =>
    documentTypeId !== DocumentTypeEnum.RequisitionOrder &&
    documentTypeId !== DocumentTypeEnum.PurchaseOrder &&
    documentTypeId !== DocumentTypeEnum.TransferNote;

export const isStornableDocumentType = (documentTypeId: DocumentTypeEnum): boolean =>
    documentTypeId === DocumentTypeEnum.PurchaseReceivedNote ||
    documentTypeId === DocumentTypeEnum.TransferNote ||
    documentTypeId === DocumentTypeEnum.TransferReceivedNote ||
    documentTypeId === DocumentTypeEnum.ConsumptionNote ||
    documentTypeId === DocumentTypeEnum.WriteOffNote;

export const isProcessableDocumentType = (documentTypeId: DocumentTypeEnum): boolean =>
    documentTypeId === DocumentTypeEnum.RequisitionOrder ||
    documentTypeId === DocumentTypeEnum.TransferNote;

export const isCompletableDocumentType = (documentTypeId: DocumentTypeEnum): boolean =>
    documentTypeId !== DocumentTypeEnum.RequisitionOrder;

export const isTransferDocumentType = (documentTypeId: DocumentTypeEnum): boolean =>
    documentTypeId === DocumentTypeEnum.TransferNote ||
    documentTypeId === DocumentTypeEnum.TransferReceivedNote;

export const getItemStatusesForDocumentType = (
    statuses: SelectOptionVm[],
    typeId: DocumentTypeEnum
): SelectOptionVm[] => {
    switch (typeId) {
        case DocumentTypeEnum.RequisitionOrder:
            return statuses.filter(
                (status: SelectOptionVm) =>
                    status.value === DocumentItemStatusEnum.Draft ||
                    status.value === DocumentItemStatusEnum.Processing ||
                    status.value === DocumentItemStatusEnum.Procured ||
                    status.value === DocumentItemStatusEnum.Dispatched ||
                    status.value === DocumentItemStatusEnum.Canceled
            );
        case DocumentTypeEnum.PurchaseOrder:
            return statuses.filter(
                (status: SelectOptionVm) =>
                    status.value === DocumentItemStatusEnum.Draft ||
                    status.value === DocumentItemStatusEnum.Procured ||
                    status.value === DocumentItemStatusEnum.Received
            );
        case DocumentTypeEnum.TransferNote:
            return statuses.filter(
                (status: SelectOptionVm) =>
                    status.value === DocumentItemStatusEnum.Draft ||
                    status.value === DocumentItemStatusEnum.Processing ||
                    status.value === DocumentItemStatusEnum.Dispatched ||
                    status.value === DocumentItemStatusEnum.Received
            );
        default:
            return [];
    }
};

export const getUnreceivedDocItemStatuses = (): DocumentItemStatusEnum[] => {
    return [
        DocumentItemStatusEnum.Canceled,
        DocumentItemStatusEnum.Dispatched,
        DocumentItemStatusEnum.Draft,
        DocumentItemStatusEnum.None,
        DocumentItemStatusEnum.Processing,
        DocumentItemStatusEnum.Procured,
    ];
};

export const getDocumentTypeTagColor = (typeId: DocumentTypeEnum): string => {
    switch (typeId) {
        case DocumentTypeEnum.RequisitionOrder:
            return 'purple';
        case DocumentTypeEnum.PurchaseOrder:
            return 'blue';
        case DocumentTypeEnum.PurchaseReceivedNote:
            return 'green';
        case DocumentTypeEnum.TransferNote:
            return 'gold';
        case DocumentTypeEnum.TransferReceivedNote:
            return 'cyan';
        case DocumentTypeEnum.ConsumptionNote:
            return 'magenta';
        case DocumentTypeEnum.WriteOffNote:
            return 'geekblue';
        case DocumentTypeEnum.Liquidation:
            return 'lime';
        case DocumentTypeEnum.Order:
            return 'volcano';
        default:
            return 'red';
    }
};

export const getDocumentStatusBadge = (
    status: DocumentStatusEnum,
    text?: string
): React.ReactElement => {
    switch (status) {
        case DocumentStatusEnum.Draft:
            return <Badge status="default" text={text} />;
        case DocumentStatusEnum.Processing:
            return <Badge status="processing" text={text} />;
        case DocumentStatusEnum.Processed:
            return <Badge status="success" text={text} />;
        case DocumentStatusEnum.Completed:
            return <Badge status="success" text={text} />;
        default:
            return <></>;
    }
};

export const getDocumentItemStatusBadge = (
    status: DocumentItemStatusEnum,
    text?: string
): React.ReactElement => {
    switch (status) {
        case DocumentItemStatusEnum.None:
            return <Badge status="default" text={text} />;
        case DocumentItemStatusEnum.Draft:
            return <Badge status="default" text={text} />;
        case DocumentItemStatusEnum.Processing:
            return <Badge status="processing" text={text} />;
        case DocumentItemStatusEnum.Procured:
            return <Badge status="warning" text={text} />;
        case DocumentItemStatusEnum.Dispatched:
            return <Badge status="warning" text={text} />;
        case DocumentItemStatusEnum.Received:
            return <Badge status="success" text={text} />;
        case DocumentItemStatusEnum.Canceled:
            return <Badge status="error" text={text} />;
        default:
            return <></>;
    }
};

export const getItemDisplayName = (item: DocumentItemVm | WarehouseItemVm): string =>
    `${item.name} (${item.quantity} ${item.unitOfMeasurement?.shortName})`;
