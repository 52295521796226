import { FormProps } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../core/models/RouteParams';
import { WarehouseItemVm } from '../../../utils/api';
import WarehouseItemForm from './WarehouseItemForm';

interface OwnProps {
    warehouseItem?: WarehouseItemVm;
    onClose: () => void;
    onSuccess: (warehouseItem: WarehouseItemVm) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric> & FormProps;

export default withRouter(WarehouseItemForm);
