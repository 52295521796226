import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Row, Form, Input, Button, Space, InputNumber } from 'antd';
import React from 'react';
import { formItemLayout724 } from '../../../../config/formLayouts';
import { translations } from '../../../../config/translations';
import { ActionType, ModuleName } from '../../../../core/models/enum';
import { authorizeAction } from '../../../../helpers/CheckPermissionHelper';
import { formatDate } from '../../../../helpers/DateHelper';
import { showConfirm } from '../../../../helpers/NotificationHelper';
import { Props } from './index';

interface State {
    allowUpdate: boolean;
    allowDelete: boolean;
}

class ProjectDetails extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        const { userProfile } = props;

        this.state = {
            allowUpdate: authorizeAction(userProfile, ModuleName.Projects, ActionType.Update),
            allowDelete: authorizeAction(userProfile, ModuleName.Projects, ActionType.Delete),
        };
    }

    public render(): React.ReactElement {
        const { project, onUpdate, onDelete } = this.props;
        const { allowDelete, allowUpdate } = this.state;

        return (
            <Row>
                <Col span={3} offset={21}>
                    <Space style={{ float: 'right', marginBottom: 15 }}>
                        {allowUpdate && (
                            <Button icon={<EditOutlined />} onClick={() => onUpdate()}>
                                {translations.general.edit}
                            </Button>
                        )}
                        {allowDelete && (
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                    showConfirm(
                                        () => onDelete(),
                                        translations.projects.deleteMessage,
                                        translations.general.delete,
                                        true
                                    )
                                }
                            >
                                {translations.general.delete}
                            </Button>
                        )}
                    </Space>
                </Col>

                <Col span={12}>
                    <Form size="small" {...formItemLayout724}>
                        <Form.Item label={translations.projects.name}>
                            <Input readOnly value={project.name} />
                        </Form.Item>
                        <Form.Item label={translations.projects.status}>
                            <Input readOnly value={project.projectStatus} />
                        </Form.Item>
                        <Form.Item label={translations.projects.shortName}>
                            <Input readOnly value={project.shortName} />
                        </Form.Item>
                        <Form.Item label={translations.projects.code}>
                            <Input readOnly value={project.code} />
                        </Form.Item>
                        <Form.Item label={translations.projects.bankGuarantees}>
                            <Input readOnly value={project.bankGuarantees} />
                        </Form.Item>
                        <Form.Item label={translations.projects.paymentMethod}>
                            <Input readOnly value={project.paymentMethod} />
                        </Form.Item>
                        <Form.Item label={translations.projects.paymentTerms}>
                            <Input readOnly value={project.paymentTerms} />
                        </Form.Item>
                        <Form.Item label={translations.projects.description}>
                            <Input readOnly value={project.description} />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={12}>
                    <Form size="small" {...formItemLayout724}>
                        <Form.Item label={translations.projects.startDate}>
                            <Input readOnly value={formatDate(project.startDate)} />
                        </Form.Item>
                        <Form.Item label={translations.projects.endDate}>
                            <Input readOnly value={formatDate(project.endDate)} />
                        </Form.Item>
                        <Form.Item label={translations.projects.country}>
                            <Input readOnly value={project.country} />
                        </Form.Item>
                        <Form.Item label={translations.projects.city}>
                            <Input readOnly value={project.city} />
                        </Form.Item>
                        <Form.Item label={translations.projects.address}>
                            <Input readOnly value={project.address} />
                        </Form.Item>
                        <Form.Item label={translations.projects.longitude}>
                            <InputNumber
                                precision={8}
                                readOnly
                                value={project.longitude}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item label={translations.projects.latitude}>
                            <InputNumber
                                precision={8}
                                readOnly
                                value={project.latitude}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item label={translations.projects.WorkHourEntryDeadline}>
                            <InputNumber
                                precision={0}
                                readOnly
                                value={project.workHourEntryDeadlineExtensionInDays}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        );
    }
}

export default ProjectDetails;
