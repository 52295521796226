import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { Props } from '.';
import { translations } from '../../../config/translations';
import { showConfirm } from '../../../helpers/NotificationHelper';

const HodogramActions = (props: Props) => {
    const {
        item,
        allowCreate,
        allowDelete,
        allowUpdate,
        onAdd,
        onEdit,
        onDelete,
        onActivate,
        onDeactivate,
    } = props;

    if (!(allowCreate || allowDelete || allowUpdate)) {
        return <div />;
    }

    return (
        <Dropdown
            trigger={['click']}
            overlay={
                <Menu>
                    {allowCreate && (
                        <>
                            <Menu.Item
                                key="1"
                                onClick={(e) => {
                                    e.domEvent.stopPropagation();
                                    onAdd(item.id);
                                }}
                                icon={<PlusOutlined />}
                            >
                                {translations.hodogram.addHodogram}
                            </Menu.Item>
                        </>
                    )}
                    {allowUpdate && (
                        <>
                            {item.isActive ? (
                                <Menu.Item
                                    key="2.1"
                                    onClick={(e) => {
                                        e.domEvent.stopPropagation();
                                        onDeactivate(item);
                                    }}
                                    icon={<CloseOutlined />}
                                >
                                    {translations.hodogram.deactivateHodogram}
                                </Menu.Item>
                            ) : (
                                <Menu.Item
                                    key="2.2"
                                    onClick={(e) => {
                                        e.domEvent.stopPropagation();
                                        onActivate(item);
                                    }}
                                    icon={<CheckOutlined />}
                                >
                                    {translations.hodogram.activateHodogram}
                                </Menu.Item>
                            )}

                            <Menu.Item
                                key="3"
                                onClick={(e) => {
                                    e.domEvent.stopPropagation();
                                    onEdit(item);
                                }}
                                icon={<EditOutlined />}
                            >
                                {translations.hodogram.editHodogram}
                            </Menu.Item>
                        </>
                    )}
                    {allowDelete && (
                        <Menu.Item
                            key="4"
                            onClick={(e) => {
                                e.domEvent.stopPropagation();
                                showConfirm(
                                    () => onDelete(item),
                                    translations.hodogram.deleteMessage,
                                    translations.general.delete,
                                    true
                                );
                            }}
                            icon={<DeleteOutlined />}
                            danger
                        >
                            {translations.hodogram.deleteHodogram}
                        </Menu.Item>
                    )}
                </Menu>
            }
        >
            <Button
                shape="circle"
                type="text"
                size="small"
                onClick={(e) => e.stopPropagation()}
                icon={<EllipsisOutlined />}
            />
        </Dropdown>
    );
};

export default HodogramActions;
