import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteParams';
import { ExpenseGroupVm } from '../../../utils/api';
import ExpenseGroupForm from './ExpenseGroupForm';

interface OwnProps {
    expenseGroup?: ExpenseGroupVm;
    onClose: () => void;
    onSuccess: (group: ExpenseGroupVm) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(ExpenseGroupForm);
