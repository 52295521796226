import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../../core/models/RouteParams';
import { ProjectCooperatorItemVm, ProjectCooperatorVm, ProjectItemVm } from '../../../../utils/api';
import ProjectContractors from './ProjectContractors';

interface OwnProps {
    projectId: number;
    projectItems?: ProjectItemVm[];
    projectCooperatorItems?: ProjectCooperatorItemVm[];
    contractors: ProjectCooperatorVm[];
    editingContractorItem?: Partial<ProjectCooperatorItemVm>;
    getProjectItems: () => void;
    onAdd: (projectItemId: number, projectCooperatorId: number) => void;
    onEdit: (item: Partial<ProjectCooperatorItemVm>) => void;
    onCancel: () => void;
    onDelete: (id: number) => void;
    onSave: (item: Partial<ProjectCooperatorItemVm>) => void;
    userProfile: Profile;
    loading: boolean;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(ProjectContractors);
