import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    FolderAddOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Space } from 'antd';
import { translations } from '../../config/translations';
import { showConfirm } from '../../helpers/NotificationHelper';
import { Props } from './index';

const ProjectItemActions = (props: Props) => {
    const {
        item,
        editingItem,
        allowCreate,
        allowDelete,
        allowUpdate,
        onCancel,
        onAdd,
        onEdit,
        onDelete,
    } = props;

    if (!(allowCreate || allowDelete || allowUpdate)) {
        return <div />;
    }

    return editingItem?.id === item.id ? (
        <Space>
            <Button
                shape="circle"
                type="primary"
                size="small"
                htmlType="submit"
                onClick={(e) => e.stopPropagation()}
                icon={<CheckOutlined />}
            />
            <Button
                shape="circle"
                size="small"
                onClick={(e) => {
                    e.stopPropagation();
                    onCancel();
                }}
                icon={<CloseOutlined />}
            />
        </Space>
    ) : (
        <Dropdown
            trigger={['click']}
            overlay={
                <Menu>
                    {item.isGroup && (
                        <>
                            {allowCreate && (
                                <>
                                    <Menu.Item
                                        key="1"
                                        onClick={(e) => {
                                            e.domEvent.stopPropagation();
                                            onAdd(false, item.id);
                                        }}
                                        icon={<PlusOutlined />}
                                    >
                                        {translations.projects.addItem}
                                    </Menu.Item>
                                    <Menu.Item
                                        key="2"
                                        onClick={(e) => {
                                            e.domEvent.stopPropagation();
                                            onAdd(true, item.id);
                                        }}
                                        icon={<FolderAddOutlined />}
                                    >
                                        {translations.projects.addGroup}
                                    </Menu.Item>
                                </>
                            )}
                        </>
                    )}
                    {allowUpdate && (
                        <Menu.Item
                            key="3"
                            onClick={(e) => {
                                e.domEvent.stopPropagation();
                                onEdit(item);
                            }}
                            icon={<EditOutlined />}
                        >
                            {translations.projects.editItem}
                        </Menu.Item>
                    )}
                    {allowDelete && (
                        <Menu.Item
                            key="4"
                            onClick={(e) => {
                                e.domEvent.stopPropagation();
                                showConfirm(
                                    () => onDelete(item.id),
                                    translations.projects.deleteItemConfirm,
                                    translations.general.delete,
                                    true
                                );
                            }}
                            icon={<DeleteOutlined />}
                            danger
                        >
                            {translations.projects.deleteItem}
                        </Menu.Item>
                    )}
                </Menu>
            }
        >
            <Button
                shape="circle"
                type="text"
                size="small"
                onClick={(e) => e.stopPropagation()}
                icon={<EllipsisOutlined />}
            />
        </Dropdown>
    );
};

export default ProjectItemActions;
