import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../../../core/models/RouteParams';
import { CooperatorVm, CooperatorContactVm } from '../../../../../utils/api';
import CooperatorContactForm from './CooperatorContactForm';

interface OwnProps {
    cooperator?: CooperatorVm;
    cooperatorContact?: CooperatorContactVm;
    refreshAfterSave?: () => void;
    onClose: () => void;
    onSuccess: (result: CooperatorContactVm) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CooperatorContactForm);
