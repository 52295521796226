import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../core/models/RouteParams';
import ProjectTable from './ProjectTable';

interface OwnProps {
    wrappedComponentRef?: any;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export default withRouter(ProjectTable);
