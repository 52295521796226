import { Form, Input } from 'antd';
import React from 'react';
import { Props } from '.';
import { formItemLayout724 } from '../../../config/formLayouts';
import { translations } from '../../../config/translations';
import { getHodogramById } from '../../../helpers/HodogramHelper';
import { showError, showSuccess } from '../../../helpers/NotificationHelper';
import { ApiException, HodogramClient, HodogramVm } from '../../../utils/api';
import DrawerButtons from '../../drawer-buttons';

interface State {
    isSaving: boolean;
}

class HodogramForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
        };
    }

    private handleSuccess = (hodogramEntities: HodogramVm[]) => {
        const { onSuccess } = this.props;

        showSuccess(translations.news.savedMessage);

        onSuccess(hodogramEntities);
    };

    private handleSubmit = async (elem: any) => {
        const { hodogramEntities, hodogram } = this.props;

        if (!hodogram) return;

        let newEntities = [...hodogramEntities];
        let formHodogram: any = getHodogramById(hodogram.id, newEntities);

        if (!formHodogram) {
            formHodogram = new HodogramVm({ ...hodogram, id: 0, isActive: true });

            let parentElement = getHodogramById(hodogram.parentId ?? 0, newEntities);
            if (parentElement) {
                if (parentElement.children) {
                    formHodogram.ordinal = parentElement.children.length + 1;
                    parentElement.children.push(formHodogram);
                } else {
                    formHodogram.ordinal = 1;
                    parentElement.children = [formHodogram];
                }
            } else {
                formHodogram.ordinal = newEntities.length + 1;
                newEntities.push(formHodogram);
            }
        }

        for (const [key, value] of Object.entries(elem)) {
            formHodogram[key] = value;
        }

        try {
            const request: any = {
                hodogramEntities: newEntities,
            };

            newEntities = await new HodogramClient().updateHodogram(request);

            this.handleSuccess(newEntities);
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    };

    public render(): React.ReactNode {
        const { isSaving } = this.state;
        const { onClose, hodogram } = this.props;

        return (
            <Form onFinish={this.handleSubmit} {...formItemLayout724}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />

                <Form.Item
                    name="name"
                    label={translations.hodogram.name}
                    initialValue={hodogram?.name}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="description"
                    label={translations.hodogram.description}
                    initialValue={hodogram?.description}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="code"
                    label={translations.hodogram.code}
                    initialValue={hodogram?.code}
                    rules={[{ max: 50, message: translations.general.maxLength50 }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        );
    }
}

export default HodogramForm;
