import { Profile } from 'oidc-client';
import { DrawerState } from '../../../core/models/enum';
import { HodogramVm } from '../../../utils/api';
import HodogramRegular from './HodogramRegular';

interface OwnProps {
    userProfile: Profile;
    hodogramEntities: HodogramVm[];
    updateHodogramState: (state: any) => void;
    handleDrawerMode: (drawerState: DrawerState, id?: string) => void;
    drawerState: DrawerState;
    selectedHodogram?: HodogramVm;
    loading: boolean;
}

export type Props = OwnProps;

export default HodogramRegular;
