import { PageHeader } from 'antd';
import React from 'react';
import WorkHourRecordsTable from '../../components/work-hour-records-table';
import { translations } from '../../config/translations';
import { Props } from './index';

class WorkHourRecords extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): React.ReactElement {
        return (
            <>
                <PageHeader title={translations.workHourRecods.title} />

                <WorkHourRecordsTable />
            </>
        );
    }
}

export default WorkHourRecords;
