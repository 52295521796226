import { Form, Input, Select } from 'antd';
import React from 'react';
import DrawerButtons from '../../../components/drawer-buttons';
import { formItemLayout724 } from '../../../config/formLayouts';
import { translations } from '../../../config/translations';
import { showSuccess } from '../../../helpers/NotificationHelper';
import { create, update } from '../../../helpers/SubmitHelper';
import { mbgValidator, mbValidator, oibValidator } from '../../../helpers/ValidationHelper';
import {
    CityVm,
    CodebooksClient,
    CooperatorDetailVm,
    CooperatorsClient,
    CountriesClient,
    CountryVm,
    SelectOptionVm,
} from '../../../utils/api';
import { Props } from './index';
import { SelectOption } from '../../../core/models/SelectOption';
import SelectCity from '../../../components/select-city/SelectCity';

interface State {
    isSaving: boolean;
    personTypes?: SelectOptionVm[];
    associateTypes?: SelectOptionVm[];
    countries: SelectOption[];
    cities: SelectOption[];
}

class CooperatorForm extends React.Component<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
            countries: [],
            cities: [],
        };
    }

    public componentDidMount = () => {
        this.getCountries();
        this.getPersonTypes();
        this.getAssociateTypes();
    };

    private getPersonTypes = async () => {
        this.setState({
            personTypes: await new CodebooksClient().getByCodebookName('PersonType'),
        });
    };

    private getAssociateTypes = async () => {
        this.setState({
            associateTypes: await new CodebooksClient().getByCodebookName('AssociateType'),
        });
    };

    private getCountries = async () => {
        const { cooperator } = this.props;

        if (cooperator?.countryId) {
            this.getCountryCities(cooperator?.countryId);
        }

        this.setState({
            countries: (await new CountriesClient().getAll()).map(
                (unit: CountryVm): SelectOption => ({
                    label: unit.name,
                    value: unit.id,
                })
            ),
        });
    };

    private getCountryCities = async (countryId: number) => {
        this.setState({
            cities: (await new CountriesClient().getById(countryId)).cities.map(
                (unit: CityVm): SelectOption => ({
                    label: unit.name,
                    value: unit.id,
                })
            ),
        });
    };

    private handleCityAdded = () => {
        const countryId = this.formRef?.current?.getFieldValue('countryId');

        this.getCountryCities(countryId);
    };

    private handleSubmit = (values: any) => {
        const { cooperator } = this.props;

        const cooperatorClient = new CooperatorsClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: cooperator?.id,
        };

        if (request.id) {
            update(cooperatorClient, request, this.handleSuccess);
        } else {
            create(cooperatorClient, request, this.handleSuccess);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccess = (result: CooperatorDetailVm) => {
        const { onSuccess } = this.props;

        showSuccess(translations.cooperators.successfullySaved);

        onSuccess(result);
    };

    public render() {
        const { cooperator, onClose } = this.props;
        const { isSaving, countries, cities, personTypes, associateTypes } = this.state;

        const countryId = this.formRef?.current?.getFieldValue('countryId');

        return (
            <Form ref={this.formRef} onFinish={this.handleSubmit} {...formItemLayout724}>
                <DrawerButtons isSaving={isSaving} onCancelAction={onClose} />
                <Form.Item
                    name="name"
                    label={translations.cooperators.name}
                    initialValue={cooperator?.name}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="personTypeId"
                    label={translations.cooperators.personType}
                    initialValue={cooperator?.personTypeId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select<number>
                        placeholder={translations.cooperators.choosePersonType}
                        options={personTypes}
                    />
                </Form.Item>
                <Form.Item
                    name="associateTypeId"
                    label={translations.cooperators.associateType}
                    initialValue={cooperator?.associateTypeId}
                    rules={[
                        {
                            required: true,
                            message: translations.general.requiredField,
                        },
                    ]}
                >
                    <Select<number>
                        placeholder={translations.cooperators.chooseAssociateType}
                        options={associateTypes}
                    />
                </Form.Item>
                <Form.Item
                    name="oib"
                    label={translations.cooperators.oib}
                    initialValue={cooperator?.oib}
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                oibValidator(
                                    _rule,
                                    value,
                                    callback,
                                    translations.general.requiredField,
                                    cooperator?.oib
                                ),
                            message: translations.cooperators.oibInvalid,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="mbg"
                    label={translations.cooperators.mbg}
                    initialValue={cooperator?.mbg}
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                mbgValidator(
                                    _rule,
                                    value,
                                    callback,
                                    translations.general.requiredField,
                                    cooperator?.mbg
                                ),
                            message: translations.cooperators.mbgInvalid,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="mb"
                    label={translations.cooperators.mb}
                    initialValue={cooperator?.mb}
                    rules={[
                        {
                            validator: (_rule: any, value: any, callback: any) =>
                                mbValidator(
                                    _rule,
                                    value,
                                    callback,
                                    translations.general.requiredField,
                                    cooperator?.mb
                                ),
                            message: translations.cooperators.mbInvalid,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="iban"
                    label={translations.cooperators.iban}
                    initialValue={cooperator?.iban}
                    rules={[
                        {
                            max: 32,
                            message: translations.cooperators.ibanTooLong,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="countryId"
                    label={translations.cooperators.country}
                    initialValue={cooperator?.countryId}
                >
                    <Select<number>
                        showSearch
                        allowClear
                        placeholder={translations.cooperators.countryPlaceholder}
                        options={countries}
                        onSelect={(value) => this.getCountryCities(value)}
                    />
                </Form.Item>
                <Form.Item
                    name="cityId"
                    label={translations.cooperators.city}
                    initialValue={cooperator?.cityId}
                >
                    <SelectCity
                        showSearch
                        allowClear
                        placeholder={translations.cooperators.cityPlaceholder}
                        options={cities}
                        countryId={countryId}
                        onCityAdded={this.handleCityAdded}
                    />
                </Form.Item>
                <Form.Item
                    name="address"
                    label={translations.cooperators.address}
                    initialValue={cooperator?.address}
                    rules={[{ max: 200, message: translations.general.maxLength200 }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="zip"
                    label={translations.cooperators.zip}
                    initialValue={cooperator?.zip}
                    rules={[{ max: 200, message: translations.general.maxLength200 }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label={translations.cooperators.email}
                    initialValue={cooperator?.email}
                    rules={[
                        { type: 'email', message: translations.general.invalidEmail },
                        { max: 200, message: translations.general.maxLength200 },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label={translations.cooperators.phone}
                    initialValue={cooperator?.phone}
                    rules={[{ max: 200, message: translations.general.maxLength200 }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="web"
                    label={translations.cooperators.web}
                    initialValue={cooperator?.web}
                    rules={[{ max: 200, message: translations.general.maxLength200 }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        );
    }
}

export default CooperatorForm;
