import { FileOutlined } from '@ant-design/icons';
import { Drawer, Skeleton, Tree } from 'antd';
import { TreeProps } from 'antd/es/tree';
import { DataNode } from 'antd/lib/tree';
import React from 'react';
import { CheckedType, Props } from '.';
import ProjectHodogramDetail from '../../../../components/hodogram/project-hodogram-detail';
import { DRAWER_WIDTH } from '../../../../config/constants';
import routes from '../../../../config/routes';
import { translations } from '../../../../config/translations';
import { ActionType, DrawerState, ModuleName, ProjectSubPage } from '../../../../core/models/enum';
import { authorizeAction } from '../../../../helpers/CheckPermissionHelper';
import { getHodogramById, getTreeData } from '../../../../helpers/HodogramHelper';
import { showError, showSuccess } from '../../../../helpers/NotificationHelper';
import { buildSubPageRouteImproved } from '../../../../helpers/RoutingHelper';
import { remove } from '../../../../helpers/SubmitHelper';
import {
    ApiException,
    AttachmentsClient,
    AttachmentVm,
    HodogramClient,
    ProjectHodogramVm,
    UpdateProjectHodogramCommand,
} from '../../../../utils/api';
import AttachmentForm from '../../../attachments/attachment-form';

interface State {
    hodogramEntities: ProjectHodogramVm[];
    expandedKeys: React.Key[];
    checkedKeys: CheckedType;
    selectedHodogram?: ProjectHodogramVm;
    autoExpandParent: boolean;
    drawerState: DrawerState;
    selectedAttachment?: AttachmentVm;
    isAttachmentDrawerMode: boolean;
    loading: boolean;
    allowUpdate: boolean;
    selectedKeys: React.Key[];
}

class HodogramProjectDisplay extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            hodogramEntities: [],
            expandedKeys: [],
            checkedKeys: [],
            selectedKeys: [],
            autoExpandParent: true,
            drawerState: DrawerState.Closed,
            isAttachmentDrawerMode: false,
            loading: true,
            allowUpdate: authorizeAction(
                props.userProfile,
                ModuleName.ProjectHodogram,
                ActionType.Update
            ),
        };
    }
    public componentDidMount(): void {
        const { projectId } = this.props;

        this.getProjectHodogram(projectId);
    }

    private initDrawer = () => {
        const {
            match: {
                path,
                params: { subEntityId },
            },
        } = this.props;

        let subEntity = getHodogramById(+(subEntityId ?? 0), this.state.hodogramEntities);

        if (!subEntity) return;

        switch (path) {
            case routes.ROUTE_PROJECT_SUBPAGE_READ:
                this.setState({
                    selectedHodogram: subEntity as ProjectHodogramVm,
                    drawerState: DrawerState.Read,
                });
        }
    };

    private getProjectHodogram = async (projectId: number) => {
        const result = await new HodogramClient().getAllProjectHodogram(projectId);

        let checkedKeys: React.Key[] = [];
        let expandedKeys: React.Key[] = [];

        this.findCheckedAndExpandedKeys(checkedKeys, expandedKeys, result);

        this.setState(
            {
                hodogramEntities: result,
                checkedKeys: checkedKeys,
                expandedKeys: expandedKeys,
            },
            () => {
                this.initDrawer();
                this.setState({ loading: false });
            }
        );
    };

    private async sendHodogramStatusUpdate(completedIds: number[]) {
        try {
            let request = new UpdateProjectHodogramCommand({
                projectId: this.props.projectId,
                completedIds: completedIds,
            });

            new HodogramClient().updateProjectHodogram(request);

            showSuccess(translations.hodogram.successfulUpdate);
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    }

    public onExpand = (expandedKeysValue: React.Key[]) => {
        this.setState({
            expandedKeys: expandedKeysValue,
            autoExpandParent: false,
        });
    };

    public onCheck = (checkedKeysValue: CheckedType, info: any) => {
        this.sendHodogramStatusUpdate(checkedKeysValue as number[]);

        this.setState({ checkedKeys: checkedKeysValue });
    };

    public findCheckedAndExpandedKeys = (
        checkedKeys: React.Key[],
        expandedKeys: React.Key[],
        nodeArray: ProjectHodogramVm[]
    ): void => {
        nodeArray.forEach((elem) => {
            if (elem.isCompleted) {
                checkedKeys.push(elem.id);
            }
            if (elem.children && elem.children.length > 0) {
                expandedKeys.push(elem.id);
                this.findCheckedAndExpandedKeys(checkedKeys, expandedKeys, elem.children);
            }
        });
    };

    private handleDrawerMode = (drawerState: DrawerState) => {
        const { projectId, history } = this.props;
        const { selectedHodogram } = this.state;

        this.setState({
            drawerState,
        });

        history.push(
            buildSubPageRouteImproved(
                drawerState,
                projectId.toString(),
                ProjectSubPage.Hodogram,
                selectedHodogram?.id.toString()
            )
        );
    };

    private handleDrawerClose = () => {
        this.setState(
            {
                selectedHodogram: undefined,
                isAttachmentDrawerMode: false,
                selectedAttachment: undefined,
                selectedKeys: [],
            },
            () => {
                this.handleDrawerMode(DrawerState.Closed);
            }
        );
    };

    private handleAttachmentDeleted = (id: number) => {
        const { hodogramEntities, selectedHodogram } = this.state;

        if (!selectedHodogram || !hodogramEntities) return;

        let newEntities = [...hodogramEntities];
        let newSelectedHodogram = getHodogramById(
            selectedHodogram.id,
            newEntities
        ) as ProjectHodogramVm;

        newSelectedHodogram.attachments = newSelectedHodogram.attachments?.filter(
            (elem) => elem.id !== id
        );

        this.setState({ hodogramEntities: newEntities });
    };

    private handleAttachmentEdit = (id: number) => {
        const { selectedHodogram } = this.state;

        if (!id) return;

        const attachments = selectedHodogram?.attachments || [];
        const attachment = attachments.find((pd: AttachmentVm): boolean => pd.id === id);

        if (!attachment) return;

        this.setState({ selectedAttachment: attachment, isAttachmentDrawerMode: true }, () => {
            this.handleDrawerMode(DrawerState.Edit);
        });
    };

    private handleAttachmentCreate = () => {
        this.setState({ selectedAttachment: undefined, isAttachmentDrawerMode: true }, () => {
            this.handleDrawerMode(DrawerState.Create);
        });
    };

    private handleAttachmentSaved = (result: AttachmentVm) => {
        const { hodogramEntities, selectedHodogram } = this.state;

        if (!selectedHodogram || !hodogramEntities) return;

        let newHodogramEntities = [...hodogramEntities];
        let newSelectedHodogram = getHodogramById(
            selectedHodogram.id,
            newHodogramEntities
        ) as ProjectHodogramVm;

        let attachment: any = newSelectedHodogram.attachments?.find(
            (elem) => elem.id === result.id
        );

        if (attachment) {
            for (const [key, value] of Object.entries(result)) {
                attachment[key] = value;
            }
        } else {
            if (newSelectedHodogram.attachments) {
                newSelectedHodogram.attachments.push(result);
            } else {
                newSelectedHodogram.attachments = [result];
            }
        }

        this.setState({ hodogramEntities: newHodogramEntities, isAttachmentDrawerMode: false });

        this.handleDrawerMode(DrawerState.Read);
    };

    private onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
        let hodogram = getHodogramById(
            +selectedKeys[0],
            this.state.hodogramEntities
        ) as ProjectHodogramVm;

        if (!hodogram) return;

        this.setState({ selectedHodogram: hodogram, selectedKeys: selectedKeys }, () => {
            this.handleDrawerMode(DrawerState.Read);
        });
    };

    public render(): React.ReactNode {
        const {
            hodogramEntities,
            expandedKeys,
            autoExpandParent,
            checkedKeys,
            drawerState,
            selectedHodogram,
            selectedAttachment,
            isAttachmentDrawerMode,
            loading,
            allowUpdate,
            selectedKeys,
        } = this.state;

        const { userProfile } = this.props;

        const treeData: DataNode[] = getTreeData(
            '',
            hodogramEntities,
            (value: ProjectHodogramVm) => {
                if (value.attachments && value.attachments.length > 0) {
                    return <FileOutlined />;
                }

                return undefined;
            }
        );

        return !loading ? (
            treeData.length > 0 ? (
                <>
                    <Tree
                        checkable={allowUpdate}
                        onExpand={this.onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        onCheck={this.onCheck}
                        checkedKeys={checkedKeys}
                        treeData={treeData}
                        onSelect={this.onSelect}
                        selectedKeys={selectedKeys}
                    />
                    <Drawer
                        title={translations.hodogram.hodogram}
                        open={!!drawerState}
                        onClose={this.handleDrawerClose}
                        width={DRAWER_WIDTH}
                        destroyOnClose
                    >
                        {isAttachmentDrawerMode ? (
                            <AttachmentForm
                                attachment={selectedAttachment}
                                hodogram={selectedHodogram}
                                onSuccess={this.handleAttachmentSaved}
                                onClose={() => {
                                    this.setState(
                                        {
                                            selectedAttachment: undefined,
                                            isAttachmentDrawerMode: false,
                                        },
                                        () => {
                                            this.handleDrawerMode(DrawerState.Read);
                                        }
                                    );
                                }}
                            />
                        ) : (
                            <ProjectHodogramDetail
                                attachments={selectedHodogram?.attachments}
                                userProfile={userProfile}
                                selectedHodogram={selectedHodogram}
                                onAttachmentCreate={this.handleAttachmentCreate}
                                onAttachmentDelete={(id: number) =>
                                    remove(
                                        new AttachmentsClient(),
                                        translations.attachments.deleteSuccess,
                                        () => this.handleAttachmentDeleted(id),
                                        id
                                    )
                                }
                                onAttachmentUpdate={this.handleAttachmentEdit}
                            />
                        )}
                    </Drawer>
                </>
            ) : (
                <p>{translations.hodogram.noEntities}</p>
            )
        ) : (
            <Skeleton active />
        );
    }
}

export default HodogramProjectDisplay;
