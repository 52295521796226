import { Profile } from 'oidc-client';
import { Filter } from '../../../../../core/models/Filter';
import { ProjectItemExtendedVm } from '../../../../../core/models/ProjectItems';
import {
    ProjectCooperatorItemVm,
    ProjectCooperatorVm,
    ProjectItemVm,
} from '../../../../../utils/api';
import ProjectContractorItemTable from './ProjectContractorItemTable';

interface OwnProps {
    projectId: number;
    projectItems?: ProjectItemVm[];
    contractors: ProjectCooperatorVm[];
    projectContractorItems?: ProjectCooperatorItemVm[];
    editingContractorItem?: Partial<ProjectCooperatorItemVm>;
    prevEditingItemId?: number;
    activeContractor: ProjectCooperatorVm;
    activeSubcontractors?: ProjectCooperatorVm[];
    forceRenderContractorColumns?: boolean;
    expandedRowKeys?: React.Key[];
    filter?: Filter;
    showAllItems: boolean;
    onAdd: (projectItemId: number, projectCooperatorId: number) => void;
    onEdit: (item: Partial<ProjectCooperatorItemVm>) => void;
    onCancel: () => void;
    onSave: (item: Partial<ProjectCooperatorItemVm>) => void;
    onDelete: (item: ProjectItemExtendedVm) => void;
    onExpand: (expand: boolean, item: ProjectItemExtendedVm) => void;
    onFilterChange: (filters: any) => void;
    userProfile: Profile;
}

export type Props = OwnProps;

export default ProjectContractorItemTable;
