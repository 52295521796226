import { InputNumber } from 'antd';
//import { formatPrice, parsePrice } from '../../helpers/PriceHelper';
import { Props } from './index';

type InputPriceProps = Props & {precision?: Number};

const InputPrice = (props: InputPriceProps) => (
    <InputNumber
        {...props}
        precision={props.precision ?? 2}
        style={{ textAlign: 'right' }}
        decimalSeparator=","
        // formatter={(value) => formatPrice(value)}
        // parser={(value) => parsePrice(value)}
    />
);

export default InputPrice;
