import { ProjectItemExtendedVm, SubcontractorItem } from '../core/models/ProjectItems';
import {
    CreateProjectCooperatorItemCommandOfProjectCooperatorItem,
    CreateProjectItemCommand,
    CreateProjectSituationItemCommandOfProjectSituationItem,
    ProjectCooperatorItemVm,
    ProjectCooperatorVm,
    ProjectItemVm,
    ProjectSituationItemVm,
    ProjectSituationVm,
    UpdateProjectCooperatorItemCommandOfProjectCooperatorItem,
    UpdateProjectItemCommandOfProjectItem,
    UpdateProjectSituationItemCommandOfProjectSituationItem,
} from '../utils/api';

export const connectSituationItems = (
    projectItems: ProjectItemVm[],
    contractorItems: ProjectCooperatorItemVm[],
    situationItems: ProjectSituationItemVm[],
    matchedItemsOnly: boolean,
    situations: ProjectSituationVm[],
    contractors: ProjectCooperatorVm[],
    situationId: number,
    contractorId?: number,
    subcontractorIds?: number[]
): ProjectItemExtendedVm[] => {
    return projectItems.flatMap((i: ProjectItemVm): ProjectItemExtendedVm[] => {
        const children = i.children
            ? connectSituationItems(
                  i.children,
                  contractorItems,
                  situationItems,
                  matchedItemsOnly,
                  situations,
                  contractors,
                  situationId,
                  contractorId,
                  subcontractorIds
              )
            : [];

        let item = {
            ...i,
            children,
        } as ProjectItemExtendedVm;

        if (item.isGroup) {
            item.totalPrice = sumPropValues(children, 'totalPrice');
            item.contractorTotalPrice = sumPropValues(children, 'contractorTotalPrice');
            item.situationTotalPrice = sumPropValues(children, 'situationTotalPrice');
            item.situationCumulativePrice = sumPropValues(children, 'situationCumulativePrice');
            item.situationIncomeTotalPrice = sumPropValues(children, 'situationIncomeTotalPrice');
            item.situationIncomeCumulativeTotalPrice = sumPropValues(
                children,
                'situationIncomeCumulativeTotalPrice'
            );
            item.situationExpenseTotalPrice = sumPropValues(children, 'situationExpenseTotalPrice');
            item.situationExpenseCumulativeTotalPrice = sumPropValues(
                children,
                'situationExpenseCumulativeTotalPrice'
            );
            item.completedPercent = sumPropValues(children, 'completedPercent') / children.length;
            item.hasCooperatorItem = children.some(
                (c: ProjectItemExtendedVm) => c.hasCooperatorItem
            );
        } else {
            const currSituationIndex = situations.findIndex(
                (si: ProjectSituationVm) => si.id === situationId
            );

            // find all situations until current one inclusively
            const situationIds = situations
                .slice() // copies array to avoid mutation
                .sort((s1, s2) => s1.activeTo.getTime() - s2.activeTo.getTime())
                .slice(0, currSituationIndex + 1) // inclusive slice
                .map((s: ProjectSituationVm) => s.id);

            // find situation items for the current project item until the current situation
            let itemSituationItems = situationItems.filter(
                (si: ProjectSituationItemVm) =>
                    situationIds.includes(si.projectSituationId) && si.projectItemId === item.id
            );

            if (contractorId) {
                const contractorSituationItems = itemSituationItems.filter(
                    (si: ProjectSituationItemVm) => si.projectCooperatorId === contractorId
                );

                const subcontractorSituationItems = itemSituationItems.filter(
                    (si: ProjectSituationItemVm) =>
                        subcontractorIds?.includes(si.projectCooperatorId)
                );

                const contractorItem = contractorItems.find(
                    (ci: ProjectCooperatorItemVm) =>
                        ci.projectItemId === item.id && ci.projectCooperatorId === contractorId
                );

                if (contractorItem) {
                    item.contractorItem = contractorItem;
                    item.contractorQuantity = contractorItem.quantity;
                    item.contractorUnitPrice = contractorItem.unitPrice;
                    item.contractorTotalPrice = contractorItem.totalPrice;
                    item.hasCooperatorItem = true;

                    // find situation item for the current situation
                    const situationItem = contractorSituationItems.find(
                        (si: ProjectSituationItemVm) => si.projectSituationId === situationId
                    );

                    if (situationItem) {
                        item.situationItem = situationItem;
                        item.situationQuantity = situationItem.quantity;
                        item.situationTotalPrice = situationItem.totalPrice;
                    }

                    item.situationCumulativeQuantity = sumPropValues(
                        contractorSituationItems,
                        'quantity'
                    );
                    item.situationCumulativePrice = sumPropValues(
                        contractorSituationItems,
                        'totalPrice'
                    );

                    const subcontractorCurrSituationItems = subcontractorSituationItems.filter(
                        (si: ProjectSituationItemVm) => si.projectSituationId === situationId
                    );

                    item.situationExpenseQuantity = sumPropValues(
                        subcontractorCurrSituationItems,
                        'quantity'
                    );
                    item.situationExpenseTotalPrice = sumPropValues(
                        subcontractorCurrSituationItems,
                        'totalPrice'
                    );
                    item.situationExpenseCumulativeQuantity = sumPropValues(
                        subcontractorSituationItems,
                        'quantity'
                    );
                    item.situationExpenseCumulativeTotalPrice = sumPropValues(
                        subcontractorSituationItems,
                        'totalPrice'
                    );

                    item.situationIncomeQuantity =
                        item.situationExpenseQuantity + (item.situationQuantity ?? 0);
                    item.situationIncomeTotalPrice =
                        item.situationExpenseTotalPrice + (item.situationTotalPrice ?? 0);
                    item.situationIncomeCumulativeQuantity =
                        item.situationExpenseCumulativeQuantity +
                        (item.situationCumulativeQuantity ?? 0);
                    item.situationIncomeCumulativeTotalPrice =
                        item.situationExpenseCumulativeTotalPrice +
                        (item.situationCumulativePrice ?? 0);

                    item.completedPercent =
                        item.situationIncomeCumulativeQuantity / contractorItem.quantity!;
                }
            } else {
                // get items for contractors that are not subcontractors
                const itemContractorItems = contractorItems.filter(
                    (ci: ProjectCooperatorItemVm) =>
                        ci.projectItemId === item.id &&
                        !contractors.some(
                            (c: ProjectCooperatorVm) =>
                                c.clientId && c.id === ci.projectCooperatorId
                        )
                );

                item.hasCooperatorItem = !!itemContractorItems.length;

                item.contractorQuantity = sumPropValues(itemContractorItems, 'quantity');
                item.contractorTotalPrice = sumPropValues(itemContractorItems, 'totalPrice');

                // find situations items for the current situation
                const currSituationItems = itemSituationItems.filter(
                    (si: ProjectSituationItemVm) => si.projectSituationId === situationId
                );

                item.situationQuantity = sumPropValues(currSituationItems, 'quantity');
                item.situationTotalPrice = sumPropValues(currSituationItems, 'totalPrice');

                item.situationCumulativeQuantity = sumPropValues(itemSituationItems, 'quantity');
                item.situationCumulativePrice = sumPropValues(itemSituationItems, 'totalPrice');
                item.completedPercent = item.situationCumulativeQuantity / item.quantity!;
            }
        }

        // if the item wasn't matched then exclude it
        if (matchedItemsOnly && !item.hasCooperatorItem) {
            return [];
        }

        return [item];
    });
};

export const connectContractorItems = (
    projectItems: ProjectItemVm[],
    contractorItems: ProjectCooperatorItemVm[],
    contractorId: number,
    subcontractors: ProjectCooperatorVm[] | undefined,
    matchedItemsOnly: boolean
): ProjectItemExtendedVm[] => {
    return projectItems.flatMap((i: ProjectItemVm): ProjectItemExtendedVm[] => {
        const children = i.children
            ? connectContractorItems(
                  i.children,
                  contractorItems,
                  contractorId,
                  subcontractors,
                  matchedItemsOnly
              )
            : [];

        let item = {
            ...i,
            children,
        } as ProjectItemExtendedVm;

        if (item.isGroup) {
            item.totalPrice = sumPropValues(children, 'totalPrice');
            item.contractorTotalPrice = sumPropValues(children, 'contractorTotalPrice');
            item.hasCooperatorItem = children.some(
                (c: ProjectItemExtendedVm) => c.hasCooperatorItem
            );

            item.subcontractorItems = subcontractors?.map((subcontractor: ProjectCooperatorVm) => {
                const subcontractorItems = item.children?.flatMap((i: ProjectItemExtendedVm) => {
                    const subcontractorItem = i.subcontractorItems?.find(
                        (pci: SubcontractorItem) => pci.projectCooperatorId === subcontractor.id
                    );

                    if (!subcontractorItem) {
                        return [];
                    }

                    return [subcontractorItem];
                });

                return {
                    projectCooperatorId: subcontractor.id,
                    totalPrice: sumPropValues(subcontractorItems || [], 'totalPrice'),
                };
            });
        } else {
            const contractorItem = contractorItems.find(
                (ci: ProjectCooperatorItemVm) =>
                    ci.projectCooperatorId === contractorId && ci.projectItemId === item.id
            );

            if (contractorItem) {
                item.contractorItem = contractorItem;
                item.contractorQuantity = contractorItem.quantity;
                item.contractorUnitPrice = contractorItem.unitPrice;
                item.contractorTotalPrice = contractorItem.totalPrice;
                item.hasCooperatorItem = true;
            }

            const subcontractorItems = contractorItems.filter(
                (ci: ProjectCooperatorItemVm) =>
                    subcontractors?.some(
                        (subcontractor: ProjectCooperatorVm) =>
                            subcontractor.id === ci.projectCooperatorId
                    ) && ci.projectItemId === item.id
            );

            item.subcontractorItems = subcontractorItems.map((ci: ProjectCooperatorItemVm) => ({
                projectCooperatorId: ci.projectCooperatorId,
                quantity: ci.quantity,
                unitPrice: ci.unitPrice,
                totalPrice: ci.totalPrice,
            }));
        }

        // if the item wasn't matched then exclude it
        if (matchedItemsOnly && !item.hasCooperatorItem) {
            return [];
        }

        return [item];
    });
};

export const connectItems = (
    projectItems: ProjectItemVm[],
    situationItems: ProjectSituationItemVm[]
): ProjectItemExtendedVm[] => {
    return projectItems.map((i: ProjectItemVm): ProjectItemExtendedVm => {
        const children = i.children ? connectItems(i.children, situationItems) : [];

        let item = {
            ...i,
            children,
        } as ProjectItemExtendedVm;

        if (item.isGroup) {
            item.totalPrice = sumPropValues(children, 'totalPrice');
            item.completedPrice = sumPropValues(children, 'completedPrice');
            item.remainingPrice = sumPropValues(children, 'remainingPrice');
            item.completedPercent = sumPropValues(children, 'completedPercent') / children.length;
        } else {
            const itemSituationItems = situationItems.filter(
                (si: ProjectSituationItemVm): boolean => si.projectItemId === i.id
            );
            item.completedQuantity = sumPropValues(itemSituationItems, 'quantity');
            item.completedPrice = sumPropValues(itemSituationItems, 'totalPrice');
            item.remainingQuantity = item.quantity! - item.completedQuantity;
            item.remainingPrice = item.totalPrice! - item.completedPrice;
            item.completedPercent = item.completedQuantity / item.quantity!;
        }

        return item;
    });
};

// recursively inserts the item while updating the parent's total price
export const insertItem = (items: ProjectItemVm[], newItem: ProjectItemVm): ProjectItemVm[] => {
    let inserted = false;

    if (!newItem.parentId) {
        inserted = true;
        return [
            ...items,
            new ProjectItemVm({
                ...newItem,
                ordinalDisplay: calculateOrdinal(items),
            }),
        ];
    }

    return items.map((i: ProjectItemVm): ProjectItemVm => {
        if (inserted) {
            return i;
        }

        if (i.id === newItem.parentId) {
            inserted = true;
            const newChild = new ProjectItemVm({
                ...newItem,
                ordinalDisplay: calculateOrdinal(i.children || [], i),
            });

            const newChildren = [...(i.children || []), newChild];
            return new ProjectItemVm({
                ...i,
                children: newChildren,
                totalPrice: sumPropValues(newChildren, 'totalPrice'),
            });
        }
        if (i.children?.length) {
            const newChildren = insertItem(i.children, newItem);
            return new ProjectItemVm({
                ...i,
                children: newChildren,
                totalPrice: sumPropValues(newChildren, 'totalPrice'),
            });
        }

        return i;
    });
};

// recursively updates the item while updating the parent's total price
export const updateItem = (items: ProjectItemVm[], updatedItem: ProjectItemVm): ProjectItemVm[] => {
    let updated = false;

    return items.map((i: ProjectItemVm): ProjectItemVm => {
        if (updated) {
            return i;
        }

        if (i.id === updatedItem.id) {
            updated = true;
            return new ProjectItemVm({
                ...i,
                code: updatedItem.code,
                name: updatedItem.name,
                unitOfMeasurementId: updatedItem.unitOfMeasurementId,
                unitOfMeasurement: updatedItem.unitOfMeasurement,
                quantity: updatedItem.quantity,
                unitPrice: updatedItem.unitPrice,
                totalPrice: updatedItem.totalPrice,
            });
        }

        if (i.children?.length) {
            const newChildren = updateItem(i.children, updatedItem);
            return new ProjectItemVm({
                ...i,
                children: newChildren,
                totalPrice: sumPropValues(newChildren, 'totalPrice'),
            });
        }

        return i;
    });
};

// recursively remove the item while updating the parent's total price
export const removeItem = (items: ProjectItemVm[], id: number): ProjectItemVm[] => {
    let removed = false;

    const index = items.findIndex((pi: ProjectItemVm): boolean => pi.id === id);

    if (index !== -1) {
        removed = true;
        return items.filter((_, i) => i !== index);
    }

    return items.map((i: ProjectItemVm): ProjectItemVm => {
        if (removed || !i.children?.length) {
            return i;
        }

        const index = i.children.findIndex((pi: ProjectItemVm): boolean => pi.id === id);

        if (index !== -1) {
            removed = true;
            const newChildren = i.children.filter((_, i) => i !== index);
            return new ProjectItemVm({
                ...i,
                children: newChildren,
                totalPrice: sumPropValues(newChildren, 'totalPrice'),
            });
        }

        const newChildren = removeItem(i.children, id);
        return new ProjectItemVm({
            ...i,
            children: newChildren,
            totalPrice: sumPropValues(newChildren, 'totalPrice'),
        });
    });
};

export const findNextItemRecursively = (
    tree: ProjectItemVm[],
    currentItemId: number
): [ProjectItemVm | undefined, boolean] => {
    const matchingNode = tree.find((it) => it.id === currentItemId);

    if (matchingNode) {
        const index = tree.indexOf(matchingNode);
        const children = tree[index].children;

        if (children?.length) {
            return handleItemReturnRecursive(children, children[0]);
        }

        return handleItemReturnRecursive(tree, tree[index + 1]);
    } else {
        for (let i = 0; i < tree.length; ++i) {
            const descendants = tree[i].children;
            if (descendants) {
                const [item, isFound] = findNextItemRecursively(descendants, currentItemId);
                if (!isFound) {
                    continue;
                }
                if (item) {
                    return [item, true];
                }

                return handleItemReturnRecursive(tree, tree[i + 1]);
            }
        }
    }

    return [undefined, false];
};

const handleItemReturnRecursive = (
    tree: ProjectItemVm[],
    item: ProjectItemVm
): [ProjectItemVm | undefined, boolean] => {
    if (!item?.isGroup) {
        return [item, true];
    }

    return findNextItemRecursively(tree, item.id);
};

export const findNextExtendedItemRecursively = (
    tree: ProjectItemExtendedVm[],
    currentItemId: number,
    cooperatorId: number
): [ProjectItemExtendedVm | undefined, boolean] => {
    const matchingNode = tree.find((it) => it.id === currentItemId);

    if (matchingNode) {
        const index = tree.indexOf(matchingNode);
        const children = tree[index].children;

        if (children?.length) {
            const childDefined = handleExtendedItemReturnRecursive(
                children,
                children[0],
                cooperatorId
            );
            if (childDefined[0]) {
                return childDefined;
            }
        }

        return handleExtendedItemReturnRecursive(tree, tree[index + 1], cooperatorId);
    } else {
        for (let i = 0; i < tree.length; ++i) {
            const descendants = tree[i].children;
            if (descendants) {
                const [item, isFound] = findNextExtendedItemRecursively(
                    descendants,
                    currentItemId,
                    cooperatorId
                );
                if (!isFound) {
                    continue;
                }

                if (item) {
                    return [item, true];
                }

                return handleExtendedItemReturnRecursive(tree, tree[i + 1], cooperatorId);
            }
        }
    }

    return [undefined, false];
};

const handleExtendedItemReturnRecursive = (
    tree: ProjectItemExtendedVm[],
    item: ProjectItemExtendedVm,
    cooperatorId: number
): [ProjectItemExtendedVm | undefined, boolean] => {
    if (!item || item.contractorItem?.projectCooperatorId === cooperatorId) {
        return [item, true];
    }

    return findNextExtendedItemRecursively(tree, item.id, cooperatorId);
};

const calculateOrdinal = (siblings: ProjectItemVm[], parent?: ProjectItemVm): string => {
    const lastSibling = [...siblings].pop();

    if (lastSibling) {
        const splits = lastSibling.ordinalDisplay!.split('.');
        var newSplits = splits.map((split: string, index: number) => {
            return index === splits.length - 1 ? +split + 1 : +split;
        });
        return newSplits.join('.');
    } else if (parent) {
        return parent.ordinalDisplay! + '.1';
    } else {
        return '1';
    }
};

export const sumPropValues = (items: readonly any[], prop: string): number => {
    return items.reduce((acc: number, curr: any): number => {
        return acc + (curr[prop] || 0);
    }, 0);
};

export const createCreateRequest = (
    item: Partial<ProjectItemExtendedVm>
): CreateProjectItemCommand => {
    return new CreateProjectItemCommand({
        code: item.code,
        name: item.name || '',
        quantity: item.quantity || 0,
        unitPrice: item.unitPrice || 0,
        unitOfMeasurementId: item.unitOfMeasurementId || 0,
        projectId: item.projectId!,
        parentId: item.parentId,
        isGroup: item.isGroup!,
    });
};

export const createUpdateRequest = (
    item: Partial<ProjectItemExtendedVm>
): UpdateProjectItemCommandOfProjectItem => {
    return new UpdateProjectItemCommandOfProjectItem({
        id: item.id!,
        ordinal: item.ordinal!,
        code: item.code,
        name: item.name || '',
        quantity: item.quantity || 0,
        unitPrice: item.unitPrice || 0,
        unitOfMeasurementId: item.unitOfMeasurementId || 0,
        projectId: item.projectId!,
        parentId: item.parentId,
        isGroup: item.isGroup!,
    });
};

export const createContractorItemCreateRequest = (item: Partial<ProjectCooperatorItemVm>) => {
    return new CreateProjectCooperatorItemCommandOfProjectCooperatorItem({
        projectItemId: item.projectItemId!,
        projectCooperatorId: item.projectCooperatorId!,
        quantity: item.quantity!,
        unitPrice: item.unitPrice!,
    });
};

export const createContractorItemUpdateRequest = (item: Partial<ProjectCooperatorItemVm>) => {
    return new UpdateProjectCooperatorItemCommandOfProjectCooperatorItem({
        id: item.id!,
        projectItemId: item.projectItemId!,
        projectCooperatorId: item.projectCooperatorId!,
        quantity: item.quantity!,
        unitPrice: item.unitPrice!,
    });
};
export const createSituationItemCreateRequest = (item: Partial<ProjectSituationItemVm>) => {
    return new CreateProjectSituationItemCommandOfProjectSituationItem({
        quantity: item.quantity!,
        projectCooperatorItemId: item.projectCooperatorItemId!,
        projectSituationId: item.projectSituationId!,
    });
};

export const createSituationItemUpdateRequest = (item: Partial<ProjectSituationItemVm>) => {
    return new UpdateProjectSituationItemCommandOfProjectSituationItem({
        id: item.id!,
        quantity: item.quantity!,
        projectCooperatorItemId: item.projectCooperatorItemId!,
        projectSituationId: item.projectSituationId!,
    });
};

// checks if and element is currently visible inside a scrolling container
export const isVisible = (element: HTMLElement | null, container: HTMLElement | null) => {
    if (!element || !container) return false;

    const eleTop = element.offsetTop;
    const eleBottom = eleTop + element.clientHeight;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;

    const visibleVertically = eleTop >= containerTop && eleBottom <= containerBottom;
    // The element is fully visible in the container
    // Some part of the element is visible in the container
    // || (eleTop < containerTop && containerTop < eleBottom)
    // || (eleTop < containerBottom && containerBottom < eleBottom)

    const eleLeft = element.offsetLeft;
    const eleRight = eleLeft + element.clientWidth;

    const containerLeft = container.scrollLeft;
    const containerRight = containerLeft + container.clientWidth;

    const visibleHorizonatally = eleLeft >= containerLeft && eleRight <= containerRight;
    // The element is fully visible in the container
    // Some part of the element is visible in the container
    // || (eleTop < containerTop && containerTop < eleBottom)
    // || (eleTop < containerBottom && containerBottom < eleBottom)

    return visibleVertically && visibleHorizonatally;
};

// gets all item id's up to a given depth
export const getItemIds = (
    items: ProjectItemVm[],
    maxDepth: number,
    currDepth: number = 0
): React.Key[] => {
    return items
        .map((i: ProjectItemVm) => {
            if (!i.children?.length) return [];

            if (currDepth + 1 > maxDepth) {
                return i.id;
            }

            return [i.id, ...getItemIds(i.children, maxDepth, currDepth + 1)];
        })
        .flat();
};

// calculates max depth of items
export const getItemsMaxDepth = (items: ProjectItemVm[]): number => {
    let level = 0;
    for (let item of items) {
        if (item.children?.length) {
            const depth = getItemsMaxDepth(item.children) + 1;
            level = Math.max(depth, level);
        }
    }
    return level;
};

// calculates max depth of expanded keys
export const getItemsExpandLevel = (items: ProjectItemVm[], expandedKeys: React.Key[]): number => {
    let level = -1;
    for (let item of items) {
        if (expandedKeys.includes(item.id)) {
            const depth = getItemsExpandLevel(item.children || [], expandedKeys) + 1;
            level = Math.max(depth, level);
        }
    }
    return level;
};

export const statusFilterRangeOptions = [
    { id: '%-0', name: '0%' },
    { id: '0.001-99.999', name: '0-100%' },
    { id: '100-100', name: '100%' },
    { id: '100.001-%', name: '>100%' },
];
