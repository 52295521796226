import { OrderedListOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader, Skeleton, Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import React from 'react';
import { Props } from '.';
import HodogramActions from '../../../components/hodogram/hodogram-actions';
import HodogramForm from '../../../components/hodogram/hodogram-form';
import { DRAWER_WIDTH } from '../../../config/constants';
import { translations } from '../../../config/translations';
import {
    ActionType,
    DrawerState,
    HodogramDisplayState,
    ModuleName,
} from '../../../core/models/enum';
import { authorizeAction } from '../../../helpers/CheckPermissionHelper';
import { getHodogramById, getParentKeys, getTreeData } from '../../../helpers/HodogramHelper';
import { showError, showSuccess } from '../../../helpers/NotificationHelper';
import { ApiException, HodogramClient, HodogramVm } from '../../../utils/api';

interface State {
    expandedKeys: React.Key[];
    autoExpandParent: boolean;
    allowUpdate: boolean;
    allowCreate: boolean;
    allowDelete: boolean;
}

class HodogramRegular extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            expandedKeys: [],
            autoExpandParent: true,
            allowUpdate: false,
            allowCreate: false,
            allowDelete: false,
        };
    }

    public componentDidMount(): void {
        this.setState({ expandedKeys: getParentKeys(this.props.hodogramEntities) });

        this.getPermissions();
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.hodogramEntities !== this.props.hodogramEntities) {
            this.setState({ expandedKeys: getParentKeys(this.props.hodogramEntities) });
        }
    }

    public onExpand = (expandedKeysValue: React.Key[]) => {
        this.setState({
            expandedKeys: expandedKeysValue,
            autoExpandParent: false,
        });
    };

    private getPermissions = async () => {
        const { userProfile } = this.props;

        const allowUpdate = await authorizeAction(
            userProfile,
            ModuleName.Hodogram,
            ActionType.Update
        );
        const allowDelete = await authorizeAction(
            userProfile,
            ModuleName.Hodogram,
            ActionType.Delete
        );
        const allowCreate = await authorizeAction(
            userProfile,
            ModuleName.Hodogram,
            ActionType.Create
        );

        this.setState({
            allowUpdate,
            allowCreate,
            allowDelete,
        });
    };

    private handleEntityDelete = async (entity: HodogramVm) => {
        const { hodogramEntities, updateHodogramState } = this.props;

        let newEntities = [...hodogramEntities];
        let parentElement = getHodogramById(entity.parentId ?? 0, hodogramEntities);

        if (parentElement && parentElement.children) {
            parentElement.children = parentElement.children.filter(
                (value) => value.id !== entity.id
            );
        } else {
            newEntities = newEntities.filter((value) => value.id !== entity.id);
        }

        try {
            const request: any = {
                hodogramEntities: newEntities,
            };

            newEntities = await new HodogramClient().updateHodogram(request);

            showSuccess(translations.hodogram.successfulEdit);

            updateHodogramState({ hodogramEntities: newEntities });
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    };

    private recursiveActivation = (items: HodogramVm[], isActive: boolean) => {
        for (let item of items) {
            item.isActive = isActive;
            if (item.children && item.children.length > 0) {
                this.recursiveActivation(item.children, isActive);
            }
        }
    };

    private handleActivation = async (item: HodogramVm, isActive: boolean) => {
        const { hodogramEntities, updateHodogramState } = this.props;

        let newEntities = [...hodogramEntities];
        let updatingEntity = getHodogramById(item.id, newEntities);

        if (!updatingEntity) return;

        updatingEntity.isActive = isActive;
        if (updatingEntity.children && updatingEntity.children.length > 0) {
            this.recursiveActivation(updatingEntity.children, isActive);
        }

        try {
            const request: any = {
                hodogramEntities: newEntities,
            };

            newEntities = await new HodogramClient().updateHodogram(request);

            showSuccess(translations.hodogram.successfulEdit);

            updateHodogramState({ hodogramEntities: newEntities });
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorSavingData);
            }
        }
    };

    public getButtonGroup = (item: HodogramVm): React.ReactNode => {
        const { allowCreate, allowDelete, allowUpdate } = this.state;
        const { handleDrawerMode, updateHodogramState } = this.props;

        return (
            <HodogramActions
                item={item}
                allowCreate={allowCreate}
                allowDelete={allowDelete}
                allowUpdate={allowUpdate}
                onAdd={(parentId) => {
                    updateHodogramState({
                        selectedHodogram: { parentId: parentId },
                    });
                    handleDrawerMode(DrawerState.Create);
                }}
                onDelete={(item) => {
                    this.handleEntityDelete(item);
                }}
                onEdit={(item) => {
                    updateHodogramState({
                        selectedHodogram: item,
                    });
                    handleDrawerMode(DrawerState.Edit, `${item.id}`);
                }}
                onActivate={(item) => {
                    this.handleActivation(item, true);
                }}
                onDeactivate={(item) => {
                    this.handleActivation(item, false);
                }}
            />
        );
    };

    render(): React.ReactNode {
        const {
            hodogramEntities,
            handleDrawerMode,
            drawerState,
            selectedHodogram,
            updateHodogramState,
            loading,
        } = this.props;
        const { expandedKeys, autoExpandParent, allowUpdate } = this.state;

        const treeData: DataNode[] = getTreeData('', hodogramEntities, this.getButtonGroup);

        return (
            <>
                <PageHeader
                    title={translations.hodogram.hodogram}
                    extra={
                        allowUpdate ? (
                            [
                                <Button
                                    key="1"
                                    type="default"
                                    style={{
                                        zIndex: 10,
                                        float: 'right',
                                        margin: '0px 10px',
                                    }}
                                    onClick={() =>
                                        this.props.updateHodogramState({
                                            displayState: HodogramDisplayState.Order,
                                        })
                                    }
                                    className="no-print"
                                >
                                    <OrderedListOutlined />
                                    {translations.hodogram.reorderHodogram}
                                </Button>,
                                <Button
                                    key="2"
                                    type="default"
                                    style={{
                                        zIndex: 10,
                                        float: 'right',
                                    }}
                                    onClick={() => {
                                        updateHodogramState({
                                            selectedHodogram: {},
                                        });
                                        handleDrawerMode(DrawerState.Create);
                                    }}
                                    className="no-print"
                                >
                                    <PlusOutlined />
                                    {translations.hodogram.addHodogram}
                                </Button>,
                            ]
                        ) : (
                            <></>
                        )
                    }
                />
                {loading ? (
                    <Skeleton active />
                ) : (
                    <Tree
                        key="regular-tree"
                        autoExpandParent={autoExpandParent}
                        onExpand={this.onExpand}
                        expandedKeys={expandedKeys}
                        treeData={treeData}
                        selectable={false}
                    />
                )}
                <Drawer
                    title={translations.hodogram.hodogram}
                    open={!!drawerState}
                    onClose={() => handleDrawerMode(DrawerState.Closed)}
                    width={DRAWER_WIDTH}
                    destroyOnClose
                >
                    <HodogramForm
                        onClose={() => {
                            handleDrawerMode(DrawerState.Closed);
                        }}
                        onSuccess={(hodogramEntities: HodogramVm[]) => {
                            updateHodogramState({
                                hodogramEntities: hodogramEntities,
                            });
                            handleDrawerMode(DrawerState.Closed);
                        }}
                        hodogram={selectedHodogram}
                        hodogramEntities={hodogramEntities}
                    />
                </Drawer>
            </>
        );
    }
}

export default HodogramRegular;
