import {
    CheckOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
} from '@ant-design/icons';
import { Table, TablePaginationConfig } from 'antd';
import React from 'react';
import { Props } from '.';
import { defaultFormatWithTime, defaultTablePagination } from '../../../../config/constants';
import ReplaceStrings from '../../../../config/replaceStrings';
import Routes from '../../../../config/routes';
import { translations } from '../../../../config/translations';
import { ActionType, ModuleName } from '../../../../core/models/enum';
import { authorizeAction } from '../../../../helpers/CheckPermissionHelper';
import { formatDate } from '../../../../helpers/DateHelper';
import {
    getDefaultFilter,
    getKeyFilter,
    getRadioFilter,
    getSearchFilter,
} from '../../../../helpers/FilterHelper';
import { showConfirm, showError, showSuccess } from '../../../../helpers/NotificationHelper';
import {
    getBooleanComparer,
    getDateComparer,
    getDefaultComparer,
} from '../../../../helpers/SortHelper';
import { getTableLocale } from '../../../../helpers/TableHelper';
import { ApiException, NewsClient, NewsVm } from '../../../../utils/api';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import {
    applySearchParams,
    getFilterParamsString,
    getFiltersParams,
    getPagingSortingParams,
} from '../../../../helpers/SearchParamsHelper';

interface State {
    news?: NewsVm[];
    loading?: boolean;
    allowEdit: boolean;
    allowDelete: boolean;
    allowCreate: boolean;
}

class NewsTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            allowEdit: authorizeAction(props.userProfile, ModuleName.NewsAdmin, ActionType.Update),
            allowDelete: authorizeAction(
                props.userProfile,
                ModuleName.NewsAdmin,
                ActionType.Delete
            ),
            allowCreate: authorizeAction(
                props.userProfile,
                ModuleName.NewsAdmin,
                ActionType.Create
            ),
        };
    }

    public componentDidMount = () => {
        this.getNews();
    };

    public componentDidUpdate(prevProps: Props) {
        const {
            location: { search },
        } = this.props;

        if (prevProps.location.search !== search) {
            this.getNews();
        }
    }

    private handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<NewsVm> | SorterResult<NewsVm>[] = {}
    ) => {
        const {
            history,
            location: { search },
        } = this.props;

        const currSearchParams = new URLSearchParams(search);
        const tableSearchParams = this.createTableSearchParams(pagination, filters, sorter);
        const newSearchParams = applySearchParams(currSearchParams, tableSearchParams);

        history.push({
            pathname: history.location.pathname,
            search: newSearchParams.toString(),
        });
    };

    private createTableSearchParams = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null> = {},
        sorter: SorterResult<NewsVm> | SorterResult<NewsVm>[] = {}
    ): URLSearchParams => {
        const filterParamsString = getFilterParamsString(filters);
        const singleSorter = sorter as SorterResult<NewsVm>;
        const tableSearchParams = new URLSearchParams(filterParamsString);

        tableSearchParams.append('pageIndex', pagination.current?.toString() || '1');
        tableSearchParams.append(
            'pageSize',
            pagination.pageSize?.toString() || pagination.defaultPageSize?.toString() || ''
        );
        tableSearchParams.append('sortBy', singleSorter.field?.toString() && singleSorter.order ? singleSorter.field?.toString() : '');
        tableSearchParams.append('sortOrder', singleSorter.order || '');

        return tableSearchParams;
    };

    public getNews = async () => {
        this.setState({
            loading: true,
        });

        this.setState({
            news: await new NewsClient().getAll(),
            loading: false,
        });
    };

    private handleDelete = async (id: number) => {
        try {
            await new NewsClient().delete(id);

            showSuccess(translations.news.deletedMessage);
            this.getNews();
        } catch (error) {
            if (error instanceof ApiException) {
                showError(error.response);
            } else {
                showError(translations.general.errorDeletingData);
            }
        }
    };

    public render(): React.ReactElement {
        const { news, loading, allowDelete, allowEdit, allowCreate } = this.state;
        const { history, location } = this.props;
        const params = new URLSearchParams(location.search);
        const { sortByParam, sortOrderParam, pageIndexParam, pageSizeParam } = getPagingSortingParams(params);
        const filters = getFiltersParams(params);
        const pagination: TablePaginationConfig = {
            ...defaultTablePagination,
            current:
                pageIndexParam && parseInt(pageIndexParam)
                    ? parseInt(pageIndexParam)
                    : defaultTablePagination.defaultCurrent,
            pageSize:
                pageSizeParam && parseInt(pageSizeParam)
                    ? parseInt(pageSizeParam)
                    : defaultTablePagination.defaultPageSize,
        };

        const columns = [
            {
                title: translations.news.title,
                dataIndex: 'title',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('title'),
                sorter: getDefaultComparer('title'),
                filteredValue: filters.title ? [filters.title] : null,
                sortOrder: sortByParam === 'title' ? sortOrderParam : null,
            },
            {
                title: translations.news.date,
                dataIndex: 'date',
                sorter: getDateComparer('date'),
                sortOrder: sortByParam === 'date' ? sortOrderParam : null,
                render: (value: Date) => formatDate(value, defaultFormatWithTime),
            },
            {
                title: translations.news.author,
                dataIndex: 'author',
                ...getSearchFilter(),
                onFilter: getDefaultFilter('author'),
                sorter: getDefaultComparer('author'),
                filteredValue: filters.author ? [filters.author] : null,
                sortOrder: sortByParam === 'author' ? sortOrderParam : null,
            },
            {
                title: translations.news.archived,
                dataIndex: 'isArchived',
                width: 150,
                align: 'center',
                ...getRadioFilter([
                    { id: 'true', name: 'Arhiviran' },
                    { id: 'false', name: 'Nije arhiviran' },
                ]),
                onFilter: getKeyFilter('isArchived'),
                sorter: getBooleanComparer('isArchived'),
                filteredValue: filters.isArchived ? [filters.isArchived] : null,
                sortOrder: sortByParam === 'isArchived' ? sortOrderParam : null,
                render: (value: boolean) =>
                    value ? <CheckOutlined style={{ width: '100%' }} /> : null,
            },
            {
                title: translations.news.featured,
                dataIndex: 'isFeatured',
                width: 150,
                align: 'center',
                ...getRadioFilter([
                    { id: 'true', name: 'Istaknut' },
                    { id: 'false', name: 'Nije istaknut' },
                ]),
                onFilter: getKeyFilter('isFeatured'),
                sorter: getBooleanComparer('isFeatured'),
                filteredValue: filters.isFeatured ? [filters.isFeatured] : null,
                sortOrder: sortByParam === 'isFeatured' ? sortOrderParam : null,
                render: (value: boolean) =>
                    value ? <CheckOutlined style={{ width: '100%' }} /> : null,
            },
            {
                title: translations.general.actions,
                key: 'actions',
                width: 105,
                align: 'left',
                className: 'no-print',
                render: (value: NewsVm): React.ReactElement => (
                    <>
                        <EyeOutlined
                            onClick={() =>
                                history.push(
                                    Routes.ROUTE_NEWS_ADMIN_READ.replace(
                                        ReplaceStrings.ENTITY_ID,
                                        value.id.toString()
                                    )
                                )
                            }
                            style={{ marginRight: 10 }}
                        />
                        {allowEdit && (
                            <EditOutlined
                                className="blue-6"
                                onClick={() =>
                                    history.push(
                                        Routes.ROUTE_NEWS_EDIT.replace(
                                            ReplaceStrings.ENTITY_ID,
                                            value.id.toString()
                                        )
                                    )
                                }
                            />
                        )}
                        {allowDelete && (
                            <DeleteOutlined
                                onClick={() =>
                                    showConfirm(
                                        () => this.handleDelete(value.id),
                                        translations.news.deleteConfirmation,
                                        translations.general.delete,
                                        true
                                    )
                                }
                                className="red-5"
                                style={{ marginLeft: 10 }}
                            />
                        )}
                        {allowCreate && (
                            <CopyOutlined
                                onClick={() => {
                                    let template: NewsVm = Object.assign({}, value);
                                    template.id = 0;
                                    this.props.handleTemplateCreate(template);
                                }}
                                className="green-6"
                                style={{ marginLeft: 10 }}
                            />
                        )}
                    </>
                ),
            },
        ];

        return (
            <Table
                locale={getTableLocale()}
                columns={columns}
                dataSource={news}
                loading={loading}
                rowKey={(record: NewsVm): string => record.id?.toString()}
                pagination={pagination}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default NewsTable;
