export enum DrawerState {
    Closed = 0,
    Read = 1,
    Edit = 2,
    Create = 3,
}

export enum HodogramDisplayState {
    Regular = 1,
    Order = 2,
}

export enum ModuleName {
    Dashboard = '',
    Users = 'users',
    Attachments = 'attachments',
    Projects = 'projects',
    Employees = 'employees',
    TimeSheet = 'timesheet',
    Cooperators = 'cooperators',
    Documents = 'documents',
    DocumentItems = 'document-items',
    ProjectItems = 'projectItems',
    ProjectEmployees = 'projectEmployees',
    ProjectCooperators = 'projectCooperators',
    ProjectCooperatorItems = 'projectCooperatorItems',
    ProjectSituations = 'projectSituations',
    ProjectSituationsItems = 'projectSituationsItems',
    Warehouses = 'warehouses',
    WarehouseItems = 'warehouse-items',
    ExpenseGroups = 'expense-groups',
    NewsRegular = 'news',
    NewsAdmin = 'news-admin',
    Hodogram = 'hodogram',
    ProjectHodogram = 'projectHodogram',
    PeopleSchedule = 'peopleSchedule',
    ProjectItemsOverview = 'project-items-overview',
    Salaries = 'salaries',
    Payrolls = 'payroll',
    EmployeeExpenses = 'employee-expenses',
}

export enum SortDirectionEnum {
    ASC = 'ascend',
    DESC = 'descend',
}

export enum ActionType {
    Read,
    Create,
    Update,
    Delete,
}

export enum ExtendedRoleEnum {
    Admin = 0,
}

export enum ProjectSubPage {
    Details = 'details',
    Employees = 'employees',
    Cooperators = 'cooperators',
    Attachments = 'attachments',
    Items = 'items',
    Contractors = 'contractors',
    Situations = 'situations',
    WorkHourRecords = 'timesheet',
    Hodogram = 'hodogram',
}

export enum EmployeeSubPage {
    Details = 'details',
    Attachments = 'attachments',
    Gear = 'gear',
}

export enum DocumentSubPage {
    Details = 'details',
    Attachments = 'attachments',
}

export enum ParamsEnum {
    PageIndex = 'pageIndex',
    PageSize = 'pageSize',
    SortBy = 'sortBy',
    SortOrder = 'sortOrder',
}
