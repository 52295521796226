import { Checkbox, Col, Divider, Form, Input, Row, Tooltip } from 'antd';
import React from 'react';
import { formItemLayout0816, formItemLayout1410 } from '../../../../config/formLayouts';
import { translations } from '../../../../config/translations';
import { formatDate } from '../../../../helpers/DateHelper';
import { Props } from './index';

const EmployeeDetails = (props: Props) => {
    const { employee } = props;

    return (
        <Row gutter={16}>
            <Col xs={20} xxl={10}>
                <Form size="small" {...formItemLayout0816}>
                    <Divider>{translations.employees.generalInfo}</Divider>

                    <Form.Item label={translations.employees.name}>
                        <Input readOnly value={employee.name} />
                    </Form.Item>
                    <Form.Item label={translations.employees.surname}>
                        <Input readOnly value={employee.surname} />
                    </Form.Item>
                    <Form.Item label={translations.employees.oib}>
                        <Input readOnly value={employee.oib} />
                    </Form.Item>
                    <Form.Item label={translations.employees.identificationNumber}>
                        <Input readOnly value={employee.identificationNumber} />
                    </Form.Item>
                    <Form.Item label={translations.employees.birthDate}>
                        <Input readOnly value={formatDate(employee.birthDate)} />
                    </Form.Item>
                    <Form.Item label={translations.employees.gender}>
                        <Input readOnly value={employee.gender} />
                    </Form.Item>
                    <Form.Item label={translations.employees.email}>
                        <Input readOnly value={employee.email} />
                    </Form.Item>
                    <Form.Item label={translations.employees.phone}>
                        <Input readOnly value={employee.phone} />
                    </Form.Item>

                    <Divider>{translations.employees.residenceAddress}</Divider>

                    <Form.Item label={translations.employees.address}>
                        <Input readOnly value={employee.address} />
                    </Form.Item>
                    <Form.Item label={translations.employees.city}>
                        <Input readOnly value={employee.city} />
                    </Form.Item>
                    <Form.Item label={translations.employees.country}>
                        <Input readOnly value={employee.country} />
                    </Form.Item>

                    <Divider>{translations.employees.inhabitancyAddress}</Divider>

                    <Form.Item label={translations.employees.address}>
                        <Input readOnly value={employee.temporaryAddress} />
                    </Form.Item>
                    <Form.Item label={translations.employees.city}>
                        <Input readOnly value={employee.temporaryCity} />
                    </Form.Item>
                    <Form.Item label={translations.employees.country}>
                        <Input readOnly value={employee.temporaryCountry} />
                    </Form.Item>

                    <Divider>{translations.employees.positionInfo}</Divider>

                    <Form.Item label={translations.employees.company}>
                        <Input readOnly value={employee.company?.name} />
                    </Form.Item>
                    <Form.Item label={translations.employees.company2}>
                        <Input readOnly value={employee.company2?.name} />
                    </Form.Item>
                    <Form.Item label={translations.employees.role}>
                        <Input readOnly value={employee.employeeRole} />
                    </Form.Item>
                    <Form.Item label={translations.employees.position}>
                        <Input readOnly value={employee.position} />
                    </Form.Item>
                    <Form.Item label={translations.employees.activeFrom}>
                        <Input readOnly value={formatDate(employee.activeFrom)} />
                    </Form.Item>
                    <Form.Item label={translations.employees.activeTo}>
                        <Input readOnly value={formatDate(employee.activeTo)} />
                    </Form.Item>
                    <Form.Item label={translations.employees.employmentAgency}>
                        <Input readOnly value={employee.agency} />
                    </Form.Item>
                    <Tooltip title={translations.employees.usedBonusAmountBeforeEmployment}>
                        <Form.Item label={translations.employees.usedBonus}>
                            <Input
                                readOnly
                                value={employee.usedBonusAmountBeforeEmployment}
                                suffix="€"
                            />
                        </Form.Item>
                    </Tooltip>
                </Form>
            </Col>

            <Col xs={20} xxl={14}>
                <Form size="small" {...formItemLayout1410}>
                    <Divider>{translations.employees.workSafetyInfo}</Divider>

                    <Form.Item label={translations.employees.workUnderSupervision}>
                        <Checkbox disabled checked={!!employee.isSupervised} />
                    </Form.Item>

                    <Form.Item label={translations.employees.workUnderSupervisionExpiryDate}>
                        <Input
                            readOnly
                            value={formatDate(employee.supervisionDecisionExpirationDate)}
                        />
                    </Form.Item>

                    <Form.Item label={translations.employees.competenceForWorkInSafeManner}>
                        <Checkbox disabled checked={!!employee.isCompetentForSafeWork} />
                    </Form.Item>

                    <Form.Item label={translations.employees.competenceForFireProtectionMeasures}>
                        <Checkbox disabled checked={!!employee.isCompetentForFireProtection} />
                    </Form.Item>

                    <Form.Item label={translations.employees.competenceForFirstAid}>
                        <Checkbox disabled checked={!!employee.isCompetentForFirstAid} />
                    </Form.Item>

                    <Form.Item label={translations.employees.workSafetyAuthorizee}>
                        <Checkbox disabled checked={!!employee.isAuthorizedForWorkSafety} />
                    </Form.Item>

                    <Form.Item label={translations.employees.workSafetyAuthorizationExpiryDate}>
                        <Input readOnly value={formatDate(employee.authorizationExpirationDate)} />
                    </Form.Item>

                    <Divider>{translations.employees.medicalExaminationInfo}</Divider>

                    <Form.Item label={translations.employees.medicalExaminationRequired}>
                        <Checkbox disabled checked={!!employee.isMedicalExaminationRequired} />
                    </Form.Item>

                    <Form.Item label={translations.employees.medicalExaminationExpiryDate}>
                        <Input
                            readOnly
                            value={formatDate(employee.medicalExaminationExpirationDate)}
                        />
                    </Form.Item>

                    <Form.Item label={translations.employees.medicalExaminationScheduled}>
                        <Input readOnly value={employee.medicalExaminationBy} />
                    </Form.Item>

                    <Form.Item label={translations.employees.medicalExaminationNotes}>
                        <Input readOnly value={employee.medicalExaminationNotes} />
                    </Form.Item>

                    <Form.Item label={translations.employees.medicalExaminationSections}>
                        <Input readOnly value={employee.pointList} />
                    </Form.Item>

                    <Divider>{translations.employees.toxicologyInfo}</Divider>

                    <Form.Item label={translations.employees.toxicologyCompetence}>
                        <Checkbox disabled checked={!!employee.isCompetentForToxicology} />
                    </Form.Item>

                    <Form.Item label={translations.employees.responsibleForToxicology}>
                        <Checkbox disabled checked={!!employee.isResponsibleForToxicology} />
                    </Form.Item>

                    <Form.Item label={translations.employees.executantForToxicology}>
                        <Checkbox disabled checked={!!employee.isExecutantForToxicology} />
                    </Form.Item>

                    <Form.Item label={translations.employees.toxicologyExpiryDate}>
                        <Input readOnly value={formatDate(employee.toxicologyExpirationDate)} />
                    </Form.Item>

                    <Form.Item label={translations.employees.workShoesSize}>
                        <Input readOnly value={employee.workShoesSize} />
                    </Form.Item>

                    <Form.Item label={translations.employees.workPantsSize}>
                        <Input readOnly value={employee.workPantsSize} />
                    </Form.Item>

                    <Form.Item label={translations.employees.workJacketSize}>
                        <Input readOnly value={employee.workJacketSize} />
                    </Form.Item>

                    <Divider>{translations.employees.workPermitInfo}</Divider>

                    <Form.Item label={translations.employees.workPermit}>
                        <Checkbox disabled checked={!!employee.hasWorkingPermission} />
                    </Form.Item>
                    <Form.Item label={translations.employees.workPermitExpiryDate}>
                        <Input
                            readOnly
                            value={formatDate(employee.workingPermissionExpirationDate)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={translations.employees.workPermitExtensionRequestDeadlineDate}
                    >
                        <Input
                            readOnly
                            value={formatDate(employee.workingPermissionExtensionStartDate)}
                        />
                    </Form.Item>
                    <Form.Item label={translations.employees.workPermitExtensionRequestSubmitDate}>
                        <Input
                            readOnly
                            value={formatDate(employee.workingPermissionExtensionRequestDate)}
                        />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default EmployeeDetails;
