import { DataNode } from 'antd/lib/tree';
import React from 'react';
import { HodogramVm, ProjectHodogramVm } from '../utils/api';

export const getTreeData = (
    namePrefix: string,
    nodeArray: HodogramVm[],
    icon?: (value: any) => React.ReactNode
): DataNode[] => {
    let sortedData = nodeArray.sort((a: HodogramVm, b: HodogramVm) =>
        a.ordinal > b.ordinal ? 1 : -1
    );

    let result: DataNode[] = [];
    sortedData.forEach((elem, index) => {
        index = index + 1;

        let dataNode: DataNode = {
            key: elem.id,
            title: (
                <>
                    <span style={{ marginRight: '10px' }}>
                        {namePrefix}
                        {index}. {elem.name}
                    </span>
                    {icon ? icon(elem) : undefined}
                </>
            ),
            disabled: !elem.isActive,
        };

        if (elem.children && elem.children.length > 0) {
            let prefix = `${namePrefix}${index}.`;
            dataNode.children = getTreeData(prefix, elem.children, icon);
        }

        result.push(dataNode);
    });

    return result;
};

export const flattenHodogram = (hodograms: HodogramVm[]): HodogramVm[] => {
    let children: HodogramVm[] = [];
    const flattenHodograms = hodograms.map((m) => {
        if (m.children && m.children.length) {
            children = [...children, ...m.children];
        }
        return m;
    });

    return flattenHodograms.concat(children.length ? flattenHodogram(children) : children);
};

export const getParentKeys = (nodeArray: HodogramVm[]): React.Key[] => {
    let result: React.Key[] = [];
    nodeArray.forEach((elem) => {
        result.push(elem.id);
        if (elem.children && elem.children.length > 0) {
            result.push(...getParentKeys(elem.children));
        }
    });
    return result;
};

export const getHodogramById = (
    id: number,
    hodogramEntities: HodogramVm[] | ProjectHodogramVm[]
): HodogramVm | ProjectHodogramVm | undefined => {
    for (let entity of hodogramEntities) {
        if (entity.id === id) {
            return entity;
        }

        if (entity.children && entity.children.length > 0) {
            let result = getHodogramById(id, entity.children);
            if (result) return result;
        }
    }

    return undefined;
};
