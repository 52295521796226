const routes = {
    ROUTE_DASHBOARD: '/',
    ROUTE_NOT_FOUND: '/not-found',

    // USERS

    ROUTE_USERS: '/users',
    ROUTE_USERS_NEW: '/users/new',
    ROUTE_USERS_READ: '/users/:userId',
    ROUTE_USERS_EDIT: '/users/:userId/edit',

    // PROJECTS

    ROUTE_PROJECTS: '/projects',
    ROUTE_PROJECTS_NEW: '/projects/new',
    ROUTE_PROJECTS_READ: '/projects/:entityId',
    ROUTE_PROJECTS_EDIT: '/projects/:entityId/edit',

    ROUTE_PROJECT_SUBPAGE: '/projects/:entityId/:subPage',
    ROUTE_PROJECT_SUBPAGE_NEW: '/projects/:entityId/:subPage/new',
    ROUTE_PROJECT_SUBPAGE_READ: '/projects/:entityId/:subPage/:subEntityId',
    ROUTE_PROJECT_SUBPAGE_EDIT: '/projects/:entityId/:subPage/:subEntityId/edit',

    ROUTE_PROJECT_SUBPAGE_IMPORT: '/projects/:entityId/:subPage/import',

    // NEWS

    ROUTE_NEWS: '/news',
    ROUTE_NEWS_READ: '/news/:entityId',
    ROUTE_NEWS_ADMIN: '/news-admin',
    ROUTE_NEWS_ADMIN_READ: '/news-admin/:entityId',
    ROUTE_NEWS_NEW: '/news-admin/new',
    ROUTE_NEWS_EDIT: '/news-admin/:entityId/edit',

    // HODOGRAM

    ROUTE_HODOGRAM: '/hodogram',
    ROUTE_HODOGRAM_READ: '/hodogram/:entityId',
    ROUTE_HODOGRAM_EDIT: '/hodogram/:entityId/edit',

    // PEOPLE SCHEDULE
    ROUTE_PEOPLE_SCHEDULE: '/schedule/',

    // EMPLOYEES

    ROUTE_EMPLOYEES: '/employees',
    ROUTE_EMPLOYEES_READ: '/employees/:entityId',
    ROUTE_EMPLOYEES_NEW: '/employees/new',
    ROUTE_EMPLOYEES_EDIT: '/employees/:entityId/edit',

    ROUTE_EMPLOYEES_SUBPAGE: '/employees/:entityId/:subPage',
    ROUTE_EMPLOYEES_SUBPAGE_NEW: '/employees/:entityId/:subPage/new',
    ROUTE_EMPLOYEES_SUBPAGE_READ: '/employees/:entityId/:subPage/:subEntityId',
    ROUTE_EMPLOYEES_SUBPAGE_EDIT: '/employees/:entityId/:subPage/:subEntityId/edit',

    // EMPLOYEE ATTACHMENTS

    ROUTE_EMPLOYEES_ATTACHMENTS_NEW: '/employees/:entityId/attachments/new',
    ROUTE_EMPLOYEES_ATTACHMENTS_EDIT: '/employees/:entityId/attachments/:subEntityId/edit',

    // WORK HOUR RECORDS

    ROUTE_WORK_HOUR_RECORDS: '/timesheet',

    // COOPERATORS

    ROUTE_COOPERATORS: '/cooperators',
    ROUTE_COOPERATORS_READ: '/cooperators/:cooperatorId',
    ROUTE_COOPERATORS_NEW: '/cooperators/new',
    ROUTE_COOPERATORS_EDIT: '/cooperators/:cooperatorId/edit',

    ROUTE_COOPERATORS_CONTACTS: '/cooperators/:cooperatorId/contacts',
    ROUTE_COOPERATORS_CONTACTS_NEW: '/cooperators/:cooperatorId/contacts/new',

    // WAREHOUSES

    ROUTE_WAREHOUSES: '/warehouses',
    ROUTE_WAREHOUSES_NEW: '/warehouses/new',
    ROUTE_WAREHOUSES_READ: '/warehouses/:entityId',
    ROUTE_WAREHOUSES_EDIT: '/warehouses/:entityId/edit',

    // WAREHOUSE ITEMS

    ROUTE_WAREHOUSE_ITEMS: '/warehouse-items',
    ROUTE_WAREHOUSE_ITEMS_NEW: '/warehouse-items/new',
    ROUTE_WAREHOUSE_ITEMS_READ: '/warehouse-items/:entityId',
    ROUTE_WAREHOUSE_ITEMS_EDIT: '/warehouse-items/:entityId/edit',

    // EXPENSE GROUPS

    ROUTE_EXPENSE_GROUPS: '/expense-groups',
    ROUTE_EXPENSE_GROUP_READ: '/expense-groups/:entityId',
    ROUTE_EXPENSE_GROUP_NEW: '/expense-groups/new',
    ROUTE_EXPENSE_GROUP_EDIT: '/expense-groups/:entityId/edit',

    //COST ITEMS
    ROUTE_PROJECT_ITEMS_OVERVIEW: '/project-items-overview',

    // DOCUMENTS

    ROUTE_DOCUMENTS: '/documents',
    ROUTE_DOCUMENTS_READ: '/documents/:entityId',
    ROUTE_DOCUMENTS_NEW: '/documents/new',
    ROUTE_DOCUMENTS_SUBPAGE: '/documents/:entityId/:subPage',
    ROUTE_DOCUMENTS_EDIT: '/documents/:entityId/edit',
    ROUTE_DOCUMENTS_SUBPAGE_READ: '/documents/:entityId/:subPage/:subEntityId',
    ROUTE_DOCUMENTS_SUBPAGE_NEW: '/documents/:entityId/:subPage/new',
    ROUTE_DOCUMENTS_SUBPAGE_EDIT: '/documents/:entityId/:subPage/:subEntityId/edit',

    // SALARIES
    ROUTE_SALARIES: '/salaries',

    // PAYROLLS
    ROUTE_PAYROLLS: '/payrolls',

    // EMPLOYEE EXPENSES
    ROUTE_EMPLOYE_EXPENSES: '/employee-expenses',
};

export default routes;
