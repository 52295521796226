import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { RouteParams } from '../../../core/models/RouteParams';
import { DocumentTypeEnum, DocumentVm } from '../../../utils/api';
import DocumentTable from './DocumentTable';

interface OwnProps {
    documents: DocumentVm[];
    typeId?: DocumentTypeEnum;
    loading?: boolean;
    onHandleTableChange?: (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<DocumentVm> | SorterResult<DocumentVm>[]
    ) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(DocumentTable);
