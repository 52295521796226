import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DrawerState } from '../../../../core/models/enum';
import { RouteParams } from '../../../../core/models/RouteParams';
import { NewsVm } from '../../../../utils/api';
import NewsForm from './NewsForm';

interface OwnProps {
    news?: NewsVm;
    onClose: () => void;
    onSuccess: (news: NewsVm) => void;
    drawerState: DrawerState;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(NewsForm);