import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParamsGeneric } from '../../../../core/models/RouteParams';
import HodogramProjectDisplay from './HodogramProjectDisplay';

interface OwnProps {
    projectId: number;
    userProfile: Profile;
}

export type Props = OwnProps & RouteComponentProps<RouteParamsGeneric>;

export type CheckedType = React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] };

export default withRouter(HodogramProjectDisplay);
