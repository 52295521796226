import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Drawer, PageHeader, Switch, Tabs } from 'antd';
import React from 'react';
import { Props } from '.';
import { DRAWER_WIDTH } from '../../../../config/constants';
import Routes from '../../../../config/routes';
import { translations } from '../../../../config/translations';
import { Filter } from '../../../../core/models/Filter';
import { ProjectItemExtendedVm } from '../../../../core/models/ProjectItems';
import { ActionType, DrawerState, ModuleName, ProjectSubPage } from '../../../../core/models/enum';
import { authorizeAction } from '../../../../helpers/CheckPermissionHelper';
import { showSuccess } from '../../../../helpers/NotificationHelper';
import {
    getItemIds,
    getItemsExpandLevel,
    getItemsMaxDepth,
} from '../../../../helpers/ProjectItemHelper';
import { buildFilterRequest } from '../../../../helpers/RecursionHelper';
import { buildSubPageRouteImproved } from '../../../../helpers/RoutingHelper';
import { remove } from '../../../../helpers/SubmitHelper';
import {
    ProjectCooperatorVm,
    ProjectSituationItemVm,
    ProjectSituationsClient,
    ProjectSituationVm,
} from '../../../../utils/api';
import ProjectSituationDetails from './project-situation-details';
import ProjectSituationForm from './project-situation-form';
import ProjectSituationItemTable from './project-situation-item-table';

const { TabPane } = Tabs;

interface State {
    allowCreate: boolean;
    drawerState: DrawerState;
    activeSituation?: ProjectSituationVm;
    activeContractor?: ProjectCooperatorVm;
    activeSubcontractors?: ProjectCooperatorVm[];
    expandedRowKeys?: React.Key[];
    expandedLevel: number;
    prevEditingItemId?: number;
    filter?: Filter;
    forceRenderContractorColumns?: boolean;
    forceRenderSituationColumns?: boolean;
    showAllItems: boolean;
}

class ProjectSituations extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            allowCreate: false,
            drawerState: DrawerState.Closed,
            expandedLevel: 0,
            showAllItems: false,
        };
    }

    public componentDidMount = () => {
        this.initActiveTab();
        this.initExpandedRows();
        this.getPermissions();
    };

    public componentDidUpdate = (prevProps: Props) => {
        const { projectItems } = this.props;

        if (!prevProps.projectItems && projectItems) {
            this.initExpandedRows();
        }
    };

    private getPermissions = async () => {
        const { userProfile } = this.props;

        const allowCreate = await authorizeAction(
            userProfile,
            ModuleName.ProjectSituations,
            ActionType.Create
        );

        this.setState({
            allowCreate,
        });
    };

    private initActiveTab = () => {
        const {
            situations,
            match: {
                path,
                params: { subEntityId },
            },
        } = this.props;

        let situation = subEntityId
            ? situations.find((s: ProjectSituationVm): boolean => s.id === +subEntityId)
            : undefined;

        if (!situation && situations.length) {
            situation = situations[situations.length - 1];
        }

        let drawerState = DrawerState.Closed;

        switch (path) {
            case Routes.ROUTE_PROJECT_SUBPAGE_NEW:
                drawerState = DrawerState.Create;
                break;
            case Routes.ROUTE_PROJECT_SUBPAGE_EDIT:
                drawerState = DrawerState.Edit;
                break;
            case Routes.ROUTE_PROJECT_SUBPAGE_READ:
                drawerState = DrawerState.Read;
                break;
            default:
                break;
        }

        this.setState(
            {
                activeSituation: situation,
            },
            () => this.handleDrawerMode(drawerState)
        );
    };

    private initExpandedRows = () => {
        const { projectItems } = this.props;

        if (!projectItems) return;

        const maxParentDepth = getItemsMaxDepth(projectItems) - 1;

        this.setState({
            expandedRowKeys: getItemIds(projectItems, maxParentDepth),
            expandedLevel: maxParentDepth,
        });
    };

    private handleTabChange = (id: number) => {
        const { situations } = this.props;

        this.handleCancel();

        this.setState(
            {
                activeSituation: situations.find((s: ProjectSituationVm): boolean => s.id === +id),
                forceRenderSituationColumns: true,
                forceRenderContractorColumns: false,
            },
            () => this.handleDrawerMode(DrawerState.Read)
        );
    };

    private handleDrawerMode = (drawerState: DrawerState) => {
        const { projectId, history } = this.props;
        const { activeSituation } = this.state;

        this.setState({
            drawerState,
        });

        history.push(
            buildSubPageRouteImproved(
                drawerState,
                projectId.toString(),
                ProjectSubPage.Situations,
                activeSituation?.id.toString()
            )
        );
    };

    private handleClose = () => {
        const { activeSituation } = this.state;

        if (activeSituation) {
            this.handleDrawerMode(DrawerState.Read);
        } else {
            this.handleDrawerMode(DrawerState.Closed);
        }
    };

    private handleContractorSelect = (id: number) => {
        const { contractors } = this.props;

        this.handleCancel();

        const contractor = contractors.find((c: ProjectCooperatorVm): boolean => c.id === id);

        this.setState({
            activeContractor: contractor,
            activeSubcontractors: contractors.filter(
                (c: ProjectCooperatorVm) => c.clientId === contractor?.cooperatorId
            ),
            forceRenderSituationColumns: true,
            forceRenderContractorColumns: true,
        });
    };

    private handleSituationSaved = (result: ProjectSituationVm) => {
        const { onSituationSaved } = this.props;

        onSituationSaved(result);

        showSuccess(translations.projects.situations.successfullySaved);

        this.setState(
            {
                activeSituation: result,
                forceRenderSituationColumns: true,
                forceRenderContractorColumns: false,
            },
            () => this.handleDrawerMode(DrawerState.Read)
        );
    };

    private handleSituationDeleted = (id: number) => {
        const { situations, onSituationDeleted } = this.props;

        onSituationDeleted(id);

        const remainingSituations = situations.filter((s) => s.id !== id);

        this.setState(
            {
                activeSituation: remainingSituations.length
                    ? remainingSituations[remainingSituations.length - 1]
                    : undefined,
                forceRenderSituationColumns: true,
                forceRenderContractorColumns: false,
            },
            () => this.handleDrawerMode(DrawerState.Read)
        );
    };

    private handleAdd = (projectItemId: number, cooperatorItemId: number, situationId: number) => {
        const { editingSituationItem, onItemAdd, onItemCancel } = this.props;

        if (editingSituationItem) {
            onItemCancel();
        }

        this.setState(
            {
                prevEditingItemId: editingSituationItem?.projectItemId,
                forceRenderSituationColumns: false,
                forceRenderContractorColumns: false,
            },
            () => onItemAdd(projectItemId, cooperatorItemId, situationId)
        );
    };

    private handleEdit = (item: Partial<ProjectSituationItemVm>) => {
        const { editingSituationItem, onItemEdit } = this.props;

        onItemEdit(item);

        this.setState({
            prevEditingItemId: editingSituationItem?.projectItemId,
            forceRenderSituationColumns: false,
            forceRenderContractorColumns: false,
        });
    };

    private handleCancel = () => {
        const { editingSituationItem, onItemCancel } = this.props;

        onItemCancel();

        this.setState({
            prevEditingItemId: editingSituationItem?.projectItemId,
        });
    };

    private handleSave = async (values: Partial<ProjectSituationItemVm>) => {
        const { editingSituationItem, onItemSave } = this.props;

        onItemSave(values);

        this.setState({
            prevEditingItemId: editingSituationItem?.projectItemId,
        });
    };

    private handleFilterChange = (filters: any) => {
        const filter: Filter = {
            filterRequest: buildFilterRequest(filters),
        };

        this.setState({
            filter: filter,
        });
    };

    private handleExpand = (expand: boolean, item: ProjectItemExtendedVm) => {
        const { projectItems } = this.props;
        const { expandedRowKeys } = this.state;

        const newExpandedRowKeys = expand
            ? [...(expandedRowKeys || []), item.id]
            : expandedRowKeys?.filter((k) => k !== item.id);

        this.setState({
            expandedRowKeys: newExpandedRowKeys,
            expandedLevel: getItemsExpandLevel(projectItems || [], newExpandedRowKeys || []),
            forceRenderSituationColumns: false,
            forceRenderContractorColumns: false,
        });
    };

    private handleToggleLevelExpand = (expand: boolean) => {
        const { projectItems } = this.props;
        const { expandedLevel } = this.state;

        if (!projectItems) return;

        const maxDepth = getItemsMaxDepth(projectItems) - 1;
        let newExpandedLevel = expand ? expandedLevel + 1 : expandedLevel - 1;

        if (newExpandedLevel > maxDepth) {
            newExpandedLevel = maxDepth;
        }

        let newExpandedRowKeys: React.Key[] = [];
        if (newExpandedLevel > -1) {
            newExpandedRowKeys = getItemIds(projectItems, newExpandedLevel);
        }

        this.setState({
            expandedRowKeys: newExpandedRowKeys,
            expandedLevel: newExpandedLevel,
            forceRenderSituationColumns: false,
            forceRenderContractorColumns: false,
        });
    };

    private handleToggleShowAllItems = () => {
        this.setState((prevState: State) => ({
            showAllItems: !prevState.showAllItems,
        }));
    };

    public render(): React.ReactElement {
        const {
            situations,
            contractors,
            projectId,
            projectItems,
            projectContractorItems,
            projectSituationItems,
            editingSituationItem,
            userProfile,
            onItemDelete,
        } = this.props;
        const {
            drawerState,
            expandedRowKeys,
            expandedLevel,
            prevEditingItemId,
            forceRenderContractorColumns,
            forceRenderSituationColumns,
            filter,
            activeSituation,
            activeContractor,
            activeSubcontractors,
            allowCreate,
            showAllItems,
        } = this.state;

        return (
            <>
                <Tabs
                    type="editable-card"
                    hideAdd={!allowCreate}
                    activeKey={activeSituation?.id.toString()}
                    onTabClick={(key: string) => this.handleTabChange(+key)}
                    onEdit={() => this.handleDrawerMode(DrawerState.Create)}
                    destroyInactiveTabPane={true}
                >
                    {situations.map(
                        (s: ProjectSituationVm): React.ReactElement => (
                            <TabPane tab={s.name} key={s.id.toString()} closable={false}></TabPane>
                        )
                    )}
                </Tabs>

                {situations.length === 0 && (
                    <Alert
                        message={translations.projects.situations.noSituationsWarning.message}
                        type="warning"
                        showIcon
                    />
                )}

                {activeSituation && (
                    <>
                        <ProjectSituationDetails
                            projectId={projectId}
                            situation={activeSituation}
                            contractors={contractors}
                            userProfile={userProfile}
                            onContractorChange={this.handleContractorSelect}
                            onDelete={(id: number) =>
                                remove(
                                    new ProjectSituationsClient(),
                                    translations.projects.situations.deleted,
                                    () => this.handleSituationDeleted(id),
                                    id
                                )
                            }
                            onEdit={() => this.handleDrawerMode(DrawerState.Edit)}
                        />
                        <PageHeader
                            title={
                                <>
                                    <Button
                                        size="small"
                                        onClick={() => this.handleToggleLevelExpand(true)}
                                        icon={<PlusOutlined />}
                                    />
                                    <Button
                                        size="small"
                                        onClick={() =>
                                            expandedLevel > -1
                                                ? this.handleToggleLevelExpand(false)
                                                : null
                                        }
                                        icon={<MinusOutlined />}
                                    />
                                    <Divider type="vertical" />
                                    <Switch
                                        checked={showAllItems}
                                        onChange={this.handleToggleShowAllItems}
                                        checkedChildren={translations.projects.items.allItems}
                                        unCheckedChildren={translations.projects.items.allItems}
                                    />
                                </>
                            }
                        />
                        <ProjectSituationItemTable
                            projectId={projectId}
                            situations={situations}
                            contractors={contractors}
                            projectItems={projectItems}
                            projectContractorItems={projectContractorItems}
                            projectSituationItems={projectSituationItems}
                            editingSituationItem={editingSituationItem}
                            prevEditingItemId={prevEditingItemId}
                            activeSituation={activeSituation}
                            activeContractor={activeContractor}
                            activeSubcontractors={activeSubcontractors}
                            forceRenderSituationColumns={forceRenderSituationColumns}
                            forceRenderContractorColumns={forceRenderContractorColumns}
                            expandedRowKeys={expandedRowKeys}
                            filter={filter}
                            showAllItems={showAllItems}
                            onAdd={this.handleAdd}
                            onEdit={this.handleEdit}
                            onCancel={this.handleCancel}
                            onSave={this.handleSave}
                            onDelete={onItemDelete}
                            onExpand={this.handleExpand}
                            onFilterChange={this.handleFilterChange}
                            userProfile={userProfile}
                        />
                    </>
                )}

                <Drawer
                    title={
                        drawerState === DrawerState.Create
                            ? translations.projects.situations.addSituation
                            : translations.projects.situations.editSituation
                    }
                    open={drawerState === DrawerState.Create || drawerState === DrawerState.Edit}
                    onClose={this.handleClose}
                    width={DRAWER_WIDTH}
                    destroyOnClose
                >
                    <ProjectSituationForm
                        projectId={projectId}
                        situation={drawerState === DrawerState.Edit ? activeSituation : undefined}
                        onClose={this.handleClose}
                        onSuccess={this.handleSituationSaved}
                    />
                </Drawer>
            </>
        );
    }
}

export default ProjectSituations;
